/*eslint-disable*/
import React from "react";
import { Link, useParams, } from "react-router-dom";
import jsCookie from 'js-cookie';
import axios from 'axios';

import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar() {
  return (
    <CardStats1 path={useParams()} />
  )

}

class CardStats1 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPath: this.props.path,
      activePath: '',
      activeSubPath: '',
      isSubPath: false,
      collapseShow: 'hidden',
      showUploadModal: false,
      showDownloadModal: false,
      dashboardShow: true,
      audienceShow: true,
      behaviourShow: true,
      acquisitionShow: true,
      overviewShow: true,
      leadsShow: true,
      accountShow: true,
      profileShow: true,
      teamMembersShow: true,
      downloadShow: true,
      dataSourceShow: true,
      syncUploadShow: true,
      syncNewsletterShow: true,
      uploadShow: true,
      settingShow:true,
      automateAssignCityShow:true,
      logShow:false,
      logUploadShow:false,
    };
    this.handleCollapseShow = this.handleCollapseShow.bind(this);
    this.handleShowUploadModal = this.handleShowUploadModal.bind(this);
    this.handleShowDownloadModal = this.handleShowDownloadModal.bind(this);
    this.handleAutoAssign = this.handleAutoAssign.bind(this);
    
  }

  getActivePath() {
    const path = this.props.path[0];
    const exp_path = path.split('/');
    this.setState({
      currentPath: this.props.path,
      activeSubPath: exp_path,
      activePath: exp_path[0],
      activeSubPath: exp_path[1],
      isSubPath: exp_path.length == 2 ? true : false,
    });

  }

  componentDidMount() {
    if (jsCookie.get('privilege') == 'Uploader') {
      this.setState({
        dashboardShow: false,
        audienceShow: false,
        behaviourShow: false,
        acquisitionShow: false,
        overviewShow: false,
        leadsShow: false,
        accountShow: true,
        profileShow: true,
        teamMembersShow: false,
        dataSourceShow: false,
        syncUploadShow: false,
        syncNewsletterShow: false,
        downloadShow: false,
        uploadShow: true,
        settingShow:false,
        automateAssignCityShow:false,
        logShow:true,
        logUploadShow:true,
      });
    }
    else if (jsCookie.get('privilege') == 'Brand Associate') {
      this.setState({
        dashboardShow: true,
        audienceShow: true,
        behaviourShow: true,
        acquisitionShow: true,
        overviewShow: true,
        leadsShow: true,
        accountShow: true,
        profileShow: true,
        teamMembersShow: false,
        dataSourceShow: false,
        syncUploadShow: false,
        syncNewsletterShow: false,
        downloadShow: true,
        uploadShow: false,
        settingShow:false,
        automateAssignCityShow:false,
      });
    }
    else if (jsCookie.get('privilege') == 'Sales Manager') {
      this.setState({
        dashboardShow: true,
        audienceShow: true,
        behaviourShow: true,
        acquisitionShow: true,
        overviewShow: true,
        leadsShow: true,
        accountShow: true,
        profileShow: true,
        teamMembersShow: true,
        dataSourceShow: false,
        syncUploadShow: false,
        syncNewsletterShow: false,
        downloadShow: true,
        uploadShow: false,
        settingShow:false,
        automateAssignCityShow:false,
      });
    }
    else if (jsCookie.get('privilege') == 'Super Admin') {
      this.setState({
        uploadShow: false,
        logShow:true,
        logUploadShow:true,
      });
    }
    this.getActivePath();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.path !== prevState.currentPath) {
      this.setState({
        collapseShow: 'hidden',
      });
      this.getActivePath();
    }
  }
  handleCollapseShow(event) {
    this.setState({
      collapseShow: event,
    });
  }

  handleShowUploadModal(event) {
    this.setState({
      showUploadModal: event,
    });
  }
  handleShowDownloadModal(event) {
    this.setState({
      showDownloadModal: event,
    });
  }

  handleAutoAssign() {
    axios.get(`${process.env.REACT_APP_API_URL}automate-assign`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.setState({ isSubmitted: false });
        throw error;
      })
      .then(res => {
        this.setState({
          successMessage:
            <div id='alerts' className='notifBar bg-emerald-400'>
              <div id="ofBar-content" className="notifBar-content">
                <b>Data has been submitted.</b>
              </div>
              <div id="ofBar-right" className="notifBar-right">
                <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert('successMessage')} />
              </div>
            </div>,
          isSubmitted: false,
        }, () => {
        });
      })
  }

  render() {
    return (
      <>
        <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-gray flex flex-wrap items-center justify-between relative md:w-64 z-10 pl-6">
          <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
            {/* Toggler */}
            <button
              className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              type="button"
              onClick={() => this.handleCollapseShow(" m-2 py-3 px-6")}
            >
              <i className="fas fa-bars"></i>
            </button>
            {/* Brand */}
            <Link
              className="md:block items-center text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold pt-4"
              to="/dashboard/audience"
            >
              <img src={require("assets/img/logo/logo-white.svg").default} width="150px" />
            </Link>
            {/* User */}
            <ul className="md:hidden items-center flex flex-wrap list-none">
              <li className="inline-block relative">
                <UserDropdown />
              </li>
            </ul>
            {/* Collapse */}
            <div
              className={
                "bg-gray md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                this.state.collapseShow
              }
            >
              {/* Collapse header */}
              <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                <div className="flex flex-wrap">
                  <div className="w-6/12">
                    <Link
                      className="md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                      to="/dashboard/audience"
                    >
                      Lexus
                    </Link>
                  </div>
                  <div className="w-6/12 flex justify-end">
                    <button
                      type="button"
                      className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent  border-transparent"
                      onClick={() => this.handleCollapseShow("hidden")}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              {/* Form */}
              <form className="mt-6 mb-4 md:hidden">
                <div className="mb-3 pt-0">
                  <input
                    type="text"
                    placeholder="Search"
                    className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-white bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                  />
                </div>
              </form>
              <div className={(this.state.dashboardShow ? '' : 'hidden')}>
                {/* Divider */}
                <hr className="my-4 md:min-w-full text-white opacity-50" />
                {/* Heading */}
                <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  Dashboard
                </h6>
                {/* Navigation */}
              </div>

              <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                <li className={`items-center ` + (this.state.audienceShow ? '' : 'hidden')}>
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (this.state.activeSubPath == 'audience'
                        ? "text-white hover:text-gold"
                        : "text-white opacity-50 hover:text-gold")
                    }
                    to="/dashboard/audience"
                    onClick={this.state.activeSubPath == 'audience' && !this.state.isSubPath ? (e) => e.preventDefault() : ""}
                  >
                    <i
                      className={
                        "fas fa-user mr-2 text-sm " +
                        (this.state.activeSubPath == 'audience'
                          ? "opacity-75"
                          : "opacity-50")
                      }
                    ></i>{" "}
                    Audience
                  </Link>
                </li>
                <li className={`items-center ` + (this.state.behaviourShow ? '' : 'hidden')}>
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (this.state.activeSubPath == 'behaviour'
                        ? "text-white hover:text-gold"
                        : "text-white opacity-50 hover:text-gold")
                    }
                    to="/dashboard/behaviour"
                    onClick={this.state.activeSubPath == 'behaviour' && !this.state.isSubPath ? (e) => e.preventDefault() : ""}
                  >
                    <i
                      className={
                        "fas fa-table mr-2 text-sm " +
                        (this.state.activeSubPath == 'behaviour'
                          ? "opacity-75"
                          : "opacity-50")
                      }
                    ></i>{" "}
                    Behaviour
                  </Link>
                </li>
                <li className={`items-center ` + (this.state.acquisitionShow ? '' : 'hidden')}>
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (this.state.activeSubPath == 'acquisition'
                        ? "text-white hover:text-gold"
                        : "text-white opacity-50 hover:text-gold")
                    }
                    to="/dashboard/acquisition"
                    onClick={this.state.activeSubPath == 'acquisition' && !this.state.isSubPath ? (e) => e.preventDefault() : ""}
                  >
                    <i
                      className={
                        "fas fa-share-alt mr-2 text-sm " +
                        (this.state.activeSubPath == 'acquisition'
                          ? "opacity-75"
                          : "opacity-50")
                      }
                    ></i>{" "}
                    Acquisition
                  </Link>
                </li>
                <div className={(this.state.overviewShow ? '' : 'hidden')}>
                  {/* Divider */}
                  <hr className="my-4 md:min-w-full text-white opacity-50" />
                  {/* Heading */}
                  <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                    Overview
                  </h6>
                </div>
                {/* Navigation */}
                <li className={`items-center ` + (this.state.leadsShow ? '' : 'hidden')}>
                  <a
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (this.state.activePath == 'leads'
                        ? "text-white hover:text-gold"
                        : "text-white opacity-50 hover:text-gold")
                    }
                    href="/leads"
                  >
                    <i
                      className={
                        "fas fa-users mr-2 text-sm " +
                        (this.state.activePath == 'leads'
                          ? "opacity-75"
                          : "opacity-50")
                      }
                    ></i>{" "}
                    Leads
                  </a>
                </li>
                <li className={`items-center ` + (this.state.leadsShow ? '' : 'hidden')}>
                  <a
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (this.state.activePath == 'enquiries'
                        ? "text-white hover:text-gold"
                        : "text-white opacity-50 hover:text-gold")
                    }
                    href="/enquiries"
                  >
                    <i
                      className={
                        "fas fa-book mr-2 text-sm " +
                        (this.state.activePath == 'enquiries'
                          ? "opacity-75"
                          : "opacity-50")
                      }
                    ></i>{" "}
                    Enquiries
                  </a>
                </li>
                <div className={(this.state.leadsShow ? '' : 'hidden')}>
                  {/* Divider */}
                  <hr className="my-4 md:min-w-full text-white opacity-50" />
                  {/* Heading */}
                  <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  Form
                  </h6>
                </div>
                <li className={`items-center ` + (this.state.leadsShow ? '' : 'hidden')}>
                  <a
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (this.state.activePath == 'offline-leads'
                        ? "text-white hover:text-gold"
                        : "text-white opacity-50 hover:text-gold")
                    }
                    href="/offline-leads"
                  >
                    <i
                      className={
                        "fab fa-wpforms mr-2 text-sm " +
                        (this.state.activePath == 'offline-leads'
                          ? "opacity-75"
                          : "opacity-50")
                      }
                    ></i>{" "}
                    Offline Leads
                  </a>
                </li>
                <div className={(this.state.uploadShow ? '' : 'hidden')}>
                  {/* Divider */}
                  <hr className="my-4 md:min-w-full text-white opacity-50" />
                  {/* Heading */}
                  <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                    Upload
                  </h6>
                </div>
                {/* Navigation */}
                <li className={`items-center ` + (this.state.uploadShow ? '' : 'hidden')}>
                  <a
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (this.state.activePath == 'upload'
                        ? "text-white hover:text-gold"
                        : "text-white opacity-50 hover:text-gold")
                    }
                    href="/uploader"
                  >
                    <i
                      className={
                        "fas fa-users mr-2 text-sm " +
                        (this.state.activePath == 'uploader'
                          ? "opacity-75"
                          : "opacity-50")
                      }
                    ></i>{" "}
                    Upload
                  </a>
                </li>

                <div className={(this.state.accountShow ? '' : 'hidden')}>
                  {/* Divider */}
                  <hr className="my-4 md:min-w-full text-white opacity-50" />
                  {/* Heading */}
                  <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                    Account
                  </h6>
                </div>
                <li className={`items-center ` + (this.state.profileShow ? '' : 'hidden')}>
                  <a
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (this.state.activePath == 'profile'
                        ? "text-white hover:text-gold"
                        : "text-white opacity-50 hover:text-gold")
                    }
                    href="/profile"
                  >
                    <i
                      className={
                        "fas fa-address-card mr-2 text-sm " +
                        (this.state.activePath == 'profile'
                          ? "opacity-75"
                          : "opacity-50")
                      }
                    ></i>{" "}
                    My Profile
                  </a>
                </li>
                <li className={`items-center ` + (this.state.teamMembersShow ? '' : 'hidden')}>
                  <a
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (this.state.activePath == 'users'
                        ? "text-white hover:text-gold"
                        : "text-white opacity-50 hover:text-gold")
                    }
                    href="/users"
                  >
                    <i
                      className={
                        "fas fa-grin mr-2 text-sm " +
                        (this.state.activePath == 'users'
                          ? "opacity-75"
                          : "opacity-50")
                      }
                    ></i>{" "}
                    Team Members
                  </a>
                </li>
                <div className={(this.state.logShow ? '' : 'hidden')}>
                  {/* Divider */}
                  <hr className="my-4 md:min-w-full text-white opacity-50" />
                  {/* Heading */}
                  <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  History
                  </h6>
                </div>
                <li className={`items-center ` + (this.state.logUploadShow ? '' : 'hidden')}>
                  <a
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (this.state.activeSubPath == 'upload'
                        ? "text-white hover:text-gold"
                        : "text-white opacity-50 hover:text-gold")
                    }
                    href="/log/upload"
                  >
                    <i
                      className={
                        "fas fa-history mr-2 text-sm " +
                        (this.state.activeSubPath == 'upload'
                          ? "opacity-75"
                          : "opacity-50")
                      }
                    ></i>{" "}
                    Upload Log
                  </a>
                </li>
                {/*<div className={(this.state.settingShow ? '' : 'hidden')}>
                  <hr className="my-4 md:min-w-full text-white opacity-50" />
                  <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                    Setting
                  </h6>
                </div>
                <li className={`items-center ` + (this.state.automateAssignCityShow ? '' : 'hidden')}>
                  <a
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (this.state.activePath == 'profile'
                        ? "text-white hover:text-gold"
                        : "text-white opacity-50 hover:text-gold")
                    }
                    href="/automate-assign-city"
                  >
                    <i
                      className={
                        "fas fa-cog mr-2 text-sm " +
                        (this.state.activePath == 'profile'
                          ? "opacity-75"
                          : "opacity-50")
                      }
                    ></i>{" "}
                    Automate Assign City
                  </a>
                </li>*/}

                <div className={(this.state.dataSourceShow ? '' : 'hidden')}>
                  {/* Divider */}
                  <hr className="my-4 md:min-w-full text-white opacity-50" />
                  {/* Heading */}
                  <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                    Data Source Connection
                  </h6>
                </div>

                <li className={`items-center text-center pt-4 pr-4 ` + (this.state.syncUploadShow ? '' : 'hidden')}>
                  <div
                    className={
                      "cursor-pointer button-transparant rounded text-xs uppercase py-3 font-bold block text-white"
                    }
                    onClick={e => this.handleShowUploadModal(true)}
                  >
                    Sync / Upload Data
                  </div>
                </li>
                <li className={`items-center text-center pt-4 pr-4 ` + (this.state.syncNewsletterShow ? '' : 'hidden')}>
                  <a
                    href="/sync_to_odoo"
                    className={
                      "cursor-pointer button-transparant rounded text-xs uppercase py-3 font-bold block text-white"
                    }
                  >
                    Sync to Newsletter
                  </a>
                </li>
                <li className={`items-center text-center pt-4 pr-4 ` + (this.state.downloadShow ? '' : 'hidden')}>
                  <div
                    className={
                      "cursor-pointer button-transparant rounded text-xs uppercase py-3 font-bold block text-white"
                    }
                    onClick={e => this.handleShowDownloadModal(true)}
                  >
                    Download
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {this.state.showUploadModal ? (
          <>
            <div
              className="w-full lg:w-6/12 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mx-auto"
            >
              <div className="relative w-full mx-auto max-w-sm">
                <div>
                  <img
                    src={require("assets/img/logo/logo-white.svg").default}
                    className="relative border-none max-w-150-px  mx-auto"
                  />
                </div>
                <div className="border-0 rounded shadow-lg py-4 relative flex flex-col w-full bg-blueGray-200 outline-none focus:outline-none">

                  <div className="w-full relative p-6 flex-auto mx-auto items-center">
                    <h6 className="w-full text-center text-black font-bold mx-auto uppercase">Sync / Upload Data</h6>
                    <p className="mt-2 text-gray text-sm text-center leading-relaxed">
                      Select type of Data
                    </p>
                  </div>
                  <div className="flex flex-wrap justify-center pb-6 rounded-b px-4">
                    <Link
                      to='/upload'
                      className="bg-white mb-4 flex flex-unwrap justify-between px-4 w-10/12 rounded-t text-black font-bold uppercase text-sm py-3 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 "
                      onClick={() => this.handleShowUploadModal(false)}
                      type="button"
                    >
                      <div className="w-10/12 flex">
                        <img
                          src={require("assets/img/logo/excel-logo.svg").default}
                          className="relative rounded bg-white border-none  max-w-25-px p-1 "
                        />
                        <span className="w-auto my-auto ml-2">Excel File</span>
                      </div>
                      <i
                        className="fa fa-chevron-right float-right  my-auto"
                      />
                    </Link>
                    {/*<Link
                      to="/sync"
                      className="bg-white mb-4 flex flex-unwrap justify-between px-4 w-10/12 border rounded text-black font-bold uppercase text-sm py-3 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 "
                      type="button"
                      onClick={() => this.handleShowUploadModal(false)}
                    >
                      <div className="w-10/12 flex">
                        <img
                          src={require("assets/img/logo/email-logo.svg").default}
                          className="relative rounded bg-white border-none  max-w-25-px p-1 "
                        />
                        <span className="w-auto my-auto ml-2">Email</span>
                      </div>
                      <i
                        className="fa fa-chevron-right float-right  my-auto"
                      />
                    </Link>*/}
                    <button
                      className="w-10/12 mt-4 button-login text-white  font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 "
                      onClick={() => this.handleShowUploadModal(false)}
                    >Cancel
                    </button>
                  </div>

                </div>
              </div>
            </div>
            <div className="fixed inset-0 z-40  bg-login"></div>
          </>
        ) : null}


        {this.state.showDownloadModal ? (
          <>
            <div
              className="w-full lg:w-6/12 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mx-auto"
            >
              <div className="relative w-full mx-auto max-w-sm">
                <div>
                  <img
                    src={require("assets/img/logo/logo-white.svg").default}
                    className="relative border-none max-w-150-px  mx-auto"
                  />
                </div>
                <div className="border-0 rounded shadow-lg py-4 relative flex flex-col w-full bg-blueGray-200 outline-none focus:outline-none">

                  <div className="w-full relative p-6 flex-auto mx-auto items-center">
                    <h6 className="w-full text-center text-black font-bold mx-auto uppercase">Download Data</h6>
                    <p className="mt-2 text-gray text-sm text-center leading-relaxed">
                      Select type of Data
                    </p>
                  </div>
                  <div className="flex flex-wrap justify-center pb-6 rounded-b px-4">
                    {/*<Link
                    to="/download/leads"
                    className="bg-white mb-4 flex flex-unwrap justify-between px-4 w-10/12 border rounded text-black font-bold uppercase text-sm py-3 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 "
                    type="button"
                    onClick={() => this.handleShowDownloadModal(false)}
                  >
                  <div className="w-10/12 flex">
                    <i className="fa fa-users" />
                    <span className="w-auto my-auto ml-2">Leads</span>
                    </div>
                  <i 
                    className="fa fa-chevron-right float-right  my-auto"
                  />
                  </Link>*/}
                    <Link
                      to="/download/enquiry"
                      className="bg-white mb-4 flex flex-unwrap justify-between px-4 w-10/12 border rounded text-black font-bold uppercase text-sm py-3 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 "
                      type="button"
                      onClick={() => this.handleShowDownloadModal(false)}
                    >
                      <div className="w-10/12 flex">
                        <i className="fa fa-tasks" />
                        <span className="w-auto my-auto ml-2">Enquiry</span>
                      </div>
                      <i
                        className="fa fa-chevron-right float-right  my-auto"
                      />
                    </Link>
                    <button
                      className="w-10/12 mt-4 button-login text-white  font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 "
                      onClick={() => this.handleShowDownloadModal(false)}
                    >Cancel
                    </button>
                  </div>

                </div>
              </div>
            </div>
            <div className="fixed inset-0 z-40  bg-login"></div>
          </>
        ) : null}

      </>
    );
  }
}
