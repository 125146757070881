import React from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import axios from 'axios';
import auth from 'global/auth.js';

import jsCookie from 'js-cookie';
import { DateFormat } from "global/custom.js";
import { createPopper } from "@popperjs/core";
import ClipLoader from "react-spinners/ClipLoader";

import { getBadge } from "global/data.js";
import * as moment from 'moment'
/*eslint-disable eqeqeq*/

export default function NotificationDetail() {
  var id = useParams();
  return (
    <>
      <NotificationDetailClass id={id.id} />
    </>
  );
}

class NotificationDetailClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      created_date: jsCookie.get('notification_created_date'),
      /**sort */
      nameSort: '',
      assignedSort: '',
      salesManagerSort: '',
      brandAssociateSort: '',
      statusSort: '',
      lastEnquiryTypeSort: '',
      lastEnquiryStatusSort: '',
      dataInSort: 'desc',
      emailSort: '',
      phoneSort: '',
      citySort: '',
      showroomSort: '',
      enquiryDateSort: '',
      /**sort */
      totalData: 0,
      customersData: [],
      showDetail: false,
      isLoading: true,
      newest_enquiry_date: '',
    };

    this.handleSort = this.handleSort.bind(this);
    this.checkPopover = this.checkPopover.bind(this);
    this.handleChangeField = this.handleChangeField.bind(this);
  }

  getNewestEnquiryDateCustomerData() {
    var getParam = [];

    getParam.push('currentPage=1');
    getParam.push('perPage=1');
    getParam.push('sortBy=last_enquiry_created_date-desc');
    /**sort */
    var url = `${process.env.REACT_APP_API_URL}customers?${getParam.join('&')}`
    if (jsCookie.get('privilege') === 'Sales Manager') {
      url = `${process.env.REACT_APP_API_URL}customers?sales_manager_id=${jsCookie.get('uid')}&${getParam.join('&')}`
    } else if (jsCookie.get('privilege') === 'Brand Associate') {
      url = `${process.env.REACT_APP_API_URL}customers?brand_associate_id=${jsCookie.get('uid')}&${getParam.join('&')}`
    }
    axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
        this.setState({
          isLoading: false
        });
        throw error;
      })
      .then(res => {
        if (res.data !== 'Data Empty') {
          this.setState({
            newest_enquiry_date: res.data.customers_data[0].last_enquiry_created_date
          }, () => {
            this.getCustomerData();
          });

        }
      })
  }

  getCustomerData() {
    var url = `${process.env.REACT_APP_API_URL}users/notification/detail`
    let where_data = [
      jsCookie.get('uid'),
      DateFormat(this.state.created_date)
    ]
    axios.post(url, where_data,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
        this.setState({ isLoading: false });
        throw error;
      })
      .then(res => {
        if (res.data !== 'Data Empty' && res) {
          let data = res.data.customers_data;
          let totalData = res.data.total_data;
          var created_date = this.state.newest_enquiry_date;

          if (typeof data != 'undefined') {
            data.forEach((rows, keys) => {
              data[keys].followup_status =  rows.followup_status == 'Not Reach'? "Can't Reach":(rows.followup_status == ''?'Not Followed Up':rows.followup_status)

              var background = '';
              if (moment(created_date).format('YYYY-MM-DD') == moment(rows.last_enquiry_created_date).format('YYYY-MM-DD') && rows.sales_manager_assigned === 'No' && rows.brand_associate_assigned === 'No') {
                background = 'bg-table-red';
              }
              else {
                if (rows.sales_manager_assigned === 'No' || rows.brand_associate_assigned === 'No') {
                  background = 'bg-table-yellow';
                }
              } 
              if (rows.followup_status === 'Follow Up') {
                background = 'bg-table-blue';
              }
              if (rows.followup_status === 'Not Followed Up' && rows.sales_manager_assigned === 'Yes' && rows.brand_associate_assigned === 'Yes') {
                background = 'bg-table-purple';
              }
              if (rows.followup_status === 'SPK') {
                background = 'bg-table-green';
              }
              if (rows.followup_status === "Can't Reach") {
                background = 'bg-table-gray';
              }
              if (rows.last_enquiry_date === '--') {
                background = '';
              }
              data[keys].background = background;
            })
          }
          this.setState({
            totalData: totalData,
            customersData: data ? Array.from(data) : [],
            isLoading: false,
          });
        } else {
          this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
          this.setState({ isLoading: false });
        }
      })
  }

  componentDidMount() {

    if (!auth()) {
      return false;
    }
    if (this.state.created_date) {
      this.getNewestEnquiryDateCustomerData();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleSort(event) {
    let nameSort = '';
    let salesManagerSort = '';
    let brandAssociateSort = '';
    let statusSort = '';
    let lastEnquiryTypeSort = '';
    let lastEnquiryStatusSort = '';
    let dataInSort = '';
    let emailSort = '';
    let phoneSort = '';
    let citySort = '';
    let enquiryDateSort = '';
    let showroomSort = '';

    if (event === 'name') {
      if (this.state.nameSort === '' || this.state.nameSort === 'desc') {
        nameSort = 'asc';
      } else {
        nameSort = 'desc';
      }
    }

    if (event === 'salesManager') {
      if (this.state.salesManagerSort === '' || this.state.salesManagerSort === 'desc') {
        salesManagerSort = 'asc';
      } else {
        salesManagerSort = 'desc';
      }
    }

    if (event === 'brandAssociate') {
      if (this.state.brandAssociateSort === '' || this.state.brandAssociateSort === 'desc') {
        brandAssociateSort = 'asc';
      } else {
        brandAssociateSort = 'desc';
      }
    }
    if (event === 'status') {
      if (this.state.statusSort === '' || this.state.statusSort === 'desc') {
        statusSort = 'asc';
      } else {
        statusSort = 'desc';
      }
    }

    if (event === 'last_enquiry_type') {
      if (this.state.lastEnquiryTypeSort === '' || this.state.lastEnquiryTypeSort === 'desc') {
        lastEnquiryTypeSort = 'asc';
      } else {
        lastEnquiryTypeSort = 'desc';
      }
    }

    if (event === 'last_enquiry_status') {
      if (this.state.lastEnquiryStatusSort === '' || this.state.lastEnquiryStatusSort === 'desc') {
        lastEnquiryStatusSort = 'asc';
      } else {
        lastEnquiryStatusSort = 'desc';
      }
    }

    if (event === 'enquiry_date') {
      if (this.state.enquiryDateSort === '' || this.state.enquiryDateSort === 'desc') {
        enquiryDateSort = 'asc';
      } else {
        enquiryDateSort = 'desc';
      }
    }
    if (event === 'data_in') {
      if (this.state.dataInSort === '' || this.state.dataInSort === 'desc') {
        dataInSort = 'asc';
      } else {
        dataInSort = 'desc';
      }
    }

    if (event === 'email') {
      if (this.state.emailSort === '' || this.state.emailSort === 'desc') {
        emailSort = 'asc';
      } else {
        emailSort = 'desc';
      }
    }
    if (event === 'phone') {
      if (this.state.phoneSort === '' || this.state.phoneSort === 'desc') {
        phoneSort = 'asc';
      } else {
        phoneSort = 'desc';
      }
    }
    if (event === 'city') {
      if (this.state.citySort === '' || this.state.citySort === 'desc') {
        citySort = 'asc';
      } else {
        citySort = 'desc';
      }
    }
    if (event === 'showroom') {
      if (this.state.showroomSort === '' || this.state.showroomSort === 'desc') {
        showroomSort = 'asc';
      } else {
        showroomSort = 'desc';
      }
    }

    this.setState({
      nameSort: nameSort,
      salesManagerSort: salesManagerSort,
      brandAssociateSort: brandAssociateSort,
      statusSort: statusSort,
      lastEnquiryTypeSort: lastEnquiryTypeSort,
      lastEnquiryStatusSort: lastEnquiryStatusSort,
      enquiryDateSort: enquiryDateSort,
      dataInSort: dataInSort,
      emailSort: emailSort,
      phoneSort: phoneSort,
      citySort: citySort,
      showroomSort: showroomSort

    }, () => {
      this.getCustomerData();
    });
  }

  checkPopover(id) {
    const button_id = 'button' + id;
    const popup_id = 'popup' + id;
    const button = document.querySelector('#' + button_id);
    const popup = document.querySelector('#' + popup_id);

    if (document.getElementById(popup_id).classList.contains('hidden')) {
      createPopper(button, popup, {
        placement: "left-start",
      });

      var inputs = document.querySelectorAll(".popover");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].classList.remove('block');
        inputs[i].classList.add('hidden');
      }

      popup.classList.remove('hidden');
      popup.classList.add('block');
    } else {
      popup.classList.remove('block');
      popup.classList.add('hidden');
    }
  };

  handleChangeField(event) {
    const target = event.target;
    const customers_detail = target.id === 'customers_detail' ? target.checked : this.state.showDetail;
    this.setState({
      showDetail: customers_detail,
    });
  }

  createErrorMessage(status, name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className={'notifBar ' + (status == '404' ? 'bg-red' : 'bg-emerald-400')}>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)} />
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (!this.state.customersData && this.props.id !== 0) {
      return (<span> Data Not Available</span>)
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.successMessage}
          {this.state.errorMessage}
          {this.state.smErrorMessage}
          {this.state.baErrorMessage}
          {this.state.checkBoxErrorMessage}
        </div>
        <div className="flex flex-wrap ">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0">

            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="tabel-panel block w-full ">
                <div className="relative flex flex-col  min-w-0 break-words w-full pb-6 shadow-lg rounded bg-white">
                  <div className="w-full lg:w-10/12 xl:w-10/12 md:w-10/12 flex-grow flex-1">
                    {this.state.headerLabel ?
                      <h3 className="font-semibold text-lg text-blueGray-700 w-6/12 float-left py-4 px-4">
                        {this.state.headerLabel}
                      </h3>
                      : ''}
                    <div className="float-right m-4">
                      {this.state.isAssignBtn == 'yes' ?
                        <a
                          href={`/leads/assign/${(this.state.brand_associate_id ? this.state.brand_associate_id : (this.state.sales_manager_id ? this.state.sales_manager_id : ''))}`}
                          className={`${this.state.user_privilege !== 'Super Admin' ? '' : 'hidden'} button-login-white text-black active:bg-blueGray-600 text-2xs font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                        >Assign Leads</a>
                        : ''}
                      {this.state.isShowMore == 'yes' ?
                        <a href="/leads" className="button-login-white text-black active:bg-blueGray-600 text-xs font-bold uppercase px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        >View More</a>
                        : ''}

                    </div>
                  </div>
                  {/* Projects table */}
                  <div className={"customer-table relative overflow-x-auto bg-white "}>
                    <div className="flex bg-blueGray-100 px-4 ">
                      <div className="relative my-2 mr-4 xs:w-full">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="customers_detail"
                            name="customers_detail"
                            placeholder="Filter by Name"
                            className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                            checked={this.state.showDetail}
                            onChange={this.handleChangeField}
                          />
                          <label className="text-blueGray-400 text-sm ml-2" htmlFor="customers_detail">Show Detail</label>
                        </div>
                      </div>
                    </div>
                    <table className="items-center w-full h-auto overflow-scroll bg-transparent border-collapse">
                      <thead className="sticky-top">
                        <tr className="border border-l-0 border-r-0 border-blueGray-200">
                          <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500 pl-4"} onClick={() => this.handleSort('name')}>
                            Name
                            <i className={`fas ${this.state.nameSort === 'desc' ? "fa-sort-down" : (this.state.nameSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('email')}>
                              Email
                              <i className={`fas ${this.state.emailSort === 'desc' ? "fa-sort-down" : (this.state.emailSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th> : null}
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('phone')}>
                              Phone
                              <i className={`fas ${this.state.phoneSort === 'desc' ? "fa-sort-down" : (this.state.phoneSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th> : null}
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('city')}>
                              City
                              <i className={`fas ${this.state.citySort === 'desc' ? "fa-sort-down" : (this.state.citySort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th> : null}
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('showroom')}>
                              Gallery
                              <i className={`fas ${this.state.showroomSort === 'desc' ? "fa-sort-down" : (this.state.showroomSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th> : null}
                          <th className={(jsCookie.get('privilege') == 'Brand Associate' ? 'hidden' : null) + " cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('salesManager')}>
                            Sales Manager
                            <i className={`fas ${this.state.salesManagerSort === 'desc' ? "fa-sort-down" : (this.state.salesManagerSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={(jsCookie.get('privilege') == 'Brand Associate' ? 'hidden' : null) + " cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('brandAssociate')}>
                            Brand Associate
                            <i className={`fas ${this.state.brandAssociateSort === 'desc' ? "fa-sort-down" : (this.state.brandAssociateSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('status')}>
                            Status
                            <i className={`fas ${this.state.statusSort === 'desc' ? "fa-sort-down" : (this.state.statusSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('last_enquiry_type')}>
                            Last Enquiry Subject
                            <i className={`fas ${this.state.lastEnquiryTypeSort === 'desc' ? "fa-sort-down" : (this.state.lastEnquiryTypeSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('preferred_model')}>
                              Last Enquiry Car Preference
                              <i className={`fas ${this.state.preferredmodelSort === 'desc' ? "fa-sort-down" : (this.state.preferredmodelSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th> : null}
                          <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('last_enquiry_status')}>
                            Last Enquiry Status
                            <i className={`fas ${this.state.LastEnquiryStatusSort === 'desc' ? "fa-sort-down" : (this.state.LastEnquiryStatusSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('enquiry_date')}>
                            Last Enquiry Date
                            <i className={`fas ${this.state.enquiryDateSort === 'desc' ? "fa-sort-down" : (this.state.enquiryDateSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('data_in')}>
                            Data In
                            <i className={`fas ${this.state.dataInSort === 'desc' ? "fa-sort-down" : (this.state.dataInSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={"px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"}>
                            Channel
                          </th>
                          <th className={"px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500 pr-4"} style={{ width: `${20}px` }}>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.isLoading ?
                          <tr key="notfound" className="border border-l-0 border-r-0 border-blueGray-200">
                            <td colSpan="7" className="align-middle text-sm text-center whitespace-wrap py-4 pr-4">
                              <ClipLoader size={20} color="black" />
                            </td>
                          </tr> :
                          this.state.customersData.length > 0 ?
                            this.state.customersData.map((row, key) => (
                              <tr key={'cd' + key} className={(row.background) + " border border-l-0 border-r-0 border-blueGray-200"}>
                                <td className="px-2 align-middle text-sm whitespace-wrap px-4">
                                  <Link
                                    className={"text-sm py-2 font-normal block w-full whitespace-wrap bg-transparent text-blueGray-700"}
                                    to={{
                                      pathname: `/leads/${row.id}`, state: [{ backUrl: this.state.pushUrl }]
                                    }}>
                                    {row.salutation?row.salutation+'. ':' '}{row.first_name}{" "}{row.last_name}
                                  </Link>
                                </td>
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.email}
                                  </td> : null}
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.phone}
                                  </td> : null}
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.city}
                                  </td> : null}
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.showroom_name ? row.showroom_name : '--'}
                                  </td> : null}
                                <td className={(jsCookie.get('privilege') == 'Brand Associate' ? 'hidden' : null) + " px-2 align-middle text-sm whitespace-wrap py-4"}>
                                  {row.sales_manager_name ? row.sales_manager_name : '--'}
                                </td>
                                <td className={(jsCookie.get('privilege') == 'Brand Associate' ? 'hidden' : null) + " px-2 align-middle text-sm whitespace-wrap py-4"}>
                                  {row.brand_associate_name ? row.brand_associate_name : '--'}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.spk_status > 0 ? 'Customer' : 'Leads'}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.last_enquiry ? row.last_enquiry : '--'}
                                </td>
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.last_enquiry_car_preference}
                                  </td> : null}
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.followup_status === '' ? 'Not Followed Up' : row.followup_status}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.last_enquiry_date != null && row.last_enquiry_date != '--' ? DateFormat(row.last_enquiry_date, 'Mdy') : '--'}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.last_enquiry_created_date != null && row.last_enquiry_created_date != '--' ? DateFormat(row.last_enquiry_created_date, 'Mdy') : '--'}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  <div className="flex">
                                    {row.platform_groups.map(function (row_platform_user, index) {
                                      return (<img key={'p' + index}
                                        src={require("assets/img/logo/" + getBadge(row_platform_user.toLowerCase())).default}
                                        alt={row_platform_user}
                                        className={"w-8 h-8 rounded-full border-2 border-blueGray-50 shadow bg-white " + (index > 0 ? '-ml-3 md:-ml-3' : '')}
                                      ></img>
                                      )
                                    })}
                                  </div>
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4 pr-4">
                                  <div
                                    className="cursor-pointer text-blueGray-500 py-1 px-3"
                                    id={`button${row.id}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.checkPopover(row.id)
                                    }}
                                  >
                                    <i className="fas fa-ellipsis-v"></i>
                                  </div>
                                  <div
                                    id={`popup${row.id}`}
                                    className={"popover hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"}
                                  >
                                    <Link
                                      className={"text-sm py-2 px-4 font-normal block w-full whitespace-wrap bg-transparent text-blueGray-700"}
                                      to={{
                                        pathname: `/leads/${row.id}`, state: [{ backUrl: this.state.pushUrl }]
                                      }}>
                                      Edit
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            )) :
                            <tr key="uniq2" className="border border-l-0 border-r-0 border-blueGray-200">
                              <td colSpan="7" className="align-middle text-sm text-center whitespace-wrap py-4 pr-4">
                                Data Not Available
                              </td>
                            </tr>}
                      </tbody>
                    </table>
                  </div>
                  <div className="py-4">
                    <div className='w-full pb-2 px-2'>
                      * The list below can be different with the number shown on notification because it has been assign to another Sales Manager / Brand associate
                    </div>
                    <nav className="block flex justify-end">
                      <div className="w-8/12 legend flex justify-between mr-5">
                        <div className={`${jsCookie.get('privilege')=='Sales Manager' || jsCookie.get('privilege')=='Brand Associate'?'hidden':''} flex my-auto`}><div className="bg-table-red legend-color border-2 my-auto ml-2 mr-2"></div>New Enquiry</div>
                        <div className={`${jsCookie.get('privilege')=='Brand Associate'?'hidden':''} flex my-auto`}><div className="bg-table-yellow legend-color border-2 my-auto ml-2 mr-2"></div>Not Assigned</div>
                        <div className="flex my-auto"><div className="bg-table-purple legend-color border-2 my-auto ml-2 mr-2"></div>Not Followed Up</div>
                        <div className="flex my-auto"><div className="bg-table-blue legend-color border-2 my-auto ml-2 mr-2"></div>Followed Up</div>
                        <div className="flex my-auto"><div className="bg-table-gray legend-color border-2 my-auto ml-2 mr-2"></div>Can't Reach</div>
                        <div className="flex my-auto"><div className="bg-table-green legend-color border-2 my-auto ml-2 mr-2"></div>SPK</div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

