import React from "react";
import { Redirect, Link   } from 'react-router-dom'
import axios from 'axios';
// components
import jsCookie from 'js-cookie';
import auth from 'global/auth.js';

import ClipLoader from "react-spinners/ClipLoader";

import { Validation } from "global/custom.js";
/*eslint-disable eqeqeq*/

export default function SyncToOdoo() {
  if (jsCookie.get('privilege') != 'Super Admin'  ) {
    return <Redirect to="/404" />;
  }
  return (
    <SyncToOdooClass />
  )

}

class SyncToOdooClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      invalid_data:[],
      hideInvalidCustomer:true,
      duplicated_data:[],
      hideDuplicateCustomer:true,
      selectedFile:'',
      isSubmitted:false,
      fileName:'',
      uploadForm:true,
      invalid_customer:0,
      duplicated_customer:0,
      new_customer:0,
      total_customer:0,
      total_enquiry:0,
      privilege:jsCookie.get('privilege'),
      showConfirmation:false,
      confirmCancel:false,
      showOverwriteMsg:false,
      segmentForm:true,
      segmentName:'',
      defaultSegmentName:'Testing',
      notifMessage:'',
      uploadStatus:true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleOverwrite = this.handleOverwrite.bind(this);
    this.handleTabs = this.handleTabs.bind(this);
    this.handleShowConfirmation = this.handleShowConfirmation.bind(this);
    this.handleConfirmCancel = this.handleConfirmCancel.bind(this);

    this.handleChangeSegment = this.handleChangeSegment.bind(this);
    this.handleSubmitSegment = this.handleSubmitSegment.bind(this);
  } 
  
  checkOdooData(){
  }

  handleChangeSegment(event) {
    const target = event.target;
    const segmentName = target.id === 'segment_name' ? target.value : this.state.segmentName;
    this.setState({
      segmentName:segmentName,
    });
  }
  handleSubmitSegment(event) {
    event.preventDefault();
    let getSegment = '?segment='+this.state.defaultSegmentName;
    if(this.state.segmentName){
      getSegment = '?segment='+this.state.segmentName;
    }

    var url = `${process.env.REACT_APP_API_URL}odoo_check${getSegment}`
    axios.get(url,
    {
      headers: { 
        'Access-Control-Allow-Origin' : '*',
        'Authorization': 'Bearer '+process.env.REACT_APP_ACCESS_TOKEN
      }
    })
    .catch(error =>{ 
      throw error;
    })
    .then(res => {
      let data = res.data;

      if(data !== 'Data Empty'){
        this.setState({
          uploadForm:false,
          invalid_data:data.invalid_data,
          duplicated_customer_data:data.duplicate_customer_data,
          invalid_customer:data.invalid_customer,
          duplicated_customer:data.duplicate_customer,
          new_customer:data.new_customer,
          new_customer_data:data.new_customer_data,
          total_customer:data.total_customer,
          total_enquiry:data.total_enquiry,
        });
      }
    })
  }

  handleTabs(name){
    if(name === 'invalid'){
      this.setState({
        hideInvalidCustomer:!this.state.hideInvalidCustomer,
        hideDuplicateCustomer:true,
      });
    }else if(name === 'duplicate'){
      this.setState({
        hideInvalidCustomer:true,
        hideDuplicateCustomer:!this.state.hideDuplicateCustomer,
      });
    }
  }

createErrorMessage(name,errMsg,errMsgName){
  let msg = <><div id='alerts' className='notifBar w-full bg-red'>
    <div id="ofBar-content" className="notifBar-content">
      <b>{name}</b>
    </div>
    <div id="ofBar-right" className="notifBar-right">
      <i className="fa fa-times" id="close-bar" onClick={e=>this.handleCloseAlert(errMsgName)}/>
    </div>
  </div></>
    this.setState({
      [errMsg]:true,
      [errMsgName]:msg
    });
  }
  
  handleValidation(type,value,errorName, errorMessageName, fieldName){
    var valid = Validation(type,value,errorName,errorMessageName,fieldName);
    if(valid.status == 404){
      this.createErrorMessage(valid.msg, errorName,errorMessageName);
      return false;
    }else{
      this.setState({
        [errorName]:'',
        [errorMessageName]:''
      });
      return true;
    }
  }

  handleChange(event){
    this.setState({ selectedFile: event.target.files[0] });
  }

  async handleOverwrite(e){
    e.preventDefault();
    this.setState({ 
      isSubmittedSf: true,
    })
    /*let post_data = {
      'new_customer':this.state.new_customer_data,
      'duplicate_customer':this.state.duplicated_customer_data,
    }*/
    let getSegment = '?segment='+this.state.defaultSegmentName;
    if(this.state.segmentName){
      getSegment = '?segment='+this.state.segmentName;
    }
    const max_upload_data = 50;
    //const total_data = this.state.new_customer_data.length + this.state.duplicated_customer_data.length;
    let total_upload = 0;
    if(this.state.new_customer_data.length > this.state.duplicated_customer_data.length){
      total_upload = Math.ceil(this.state.new_customer_data.length / max_upload_data);
    }else{
      total_upload = Math.ceil(this.state.duplicated_customer_data.length / max_upload_data);
    }
    for(var i= 0; i<total_upload;i++){
      
      let post_data = {
        'new_customer':this.state.new_customer_data.slice(i*max_upload_data,(i*max_upload_data)+max_upload_data),
        'duplicate_customer':this.state.duplicated_customer_data.slice(i*max_upload_data,(i*max_upload_data)+max_upload_data),
      }
      
      let test = await axios.post(`${process.env.REACT_APP_API_URL}export_odoo${getSegment}`,post_data,
      {
        headers: { 
          'Access-Control-Allow-Origin' : '*',
          'Authorization': 'Bearer '+process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error =>{ 
        throw error;
      })
      .then(res => {
        if(res.status==201){
          this.setState({ 
            uploadStatus: false,
          })
          return false;
        }
        return true;
      })
      if(!test){
        break;
      }else{
        continue;
      }
      
    }
    let notifMessage = 'Your Odoo Leads data has been updated.';
    if(!this.state.uploadStatus){
      notifMessage='Segment not Found'
    }
    this.setState({ 
      showOverwriteMsg: true,
      notifMessage: notifMessage,
      isSubmittedSf: false,
    })
  }
  handleShowConfirmation(event){
    this.setState({ 
      showConfirmation: event,
    })
  }
  handleConfirmCancel(){
    this.setState({ 
      confirmCancel: true,
    })
  }



  render() {
    if(!auth()){
      return <Redirect to="/login" />;
    }
    if(this.state.confirmCancel){
      return <Redirect to="/leads" />;
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 z-100">
          {this.state.fileErrorMessage}
        </div>
          <div
            className="w-full lg:w-6/12 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mx-auto"
          >
            <div className="relative w-full mx-auto max-w-sm">
              <div>
                <img 
                  src={require("assets/img/logo/logo-white.svg").default}
                  alt="Lexus Logo"
                  className="relative border-none max-w-150-px  mx-auto"
                />
              </div>
              <div className="border-0 rounded shadow-lg py-4 relative flex flex-col w-full bg-blueGray-200 outline-none focus:outline-none">
          {this.state.uploadForm? (
              this.state.segmentForm?(
              <>
                <div className="w-full relative p-6 flex-auto mx-auto items-center">
                  <h6 className="w-full text-center text-black font-bold mx-auto uppercase">Sync To Odoo</h6>
                  <p className="mt-2 text-gray text-sm text-center leading-relaxed">
                    Please Insert Segment Name.
                  </p>
                </div>
                <div className="flex justify-center pb-6 rounded-b px-4">
                  <form>
                  <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <input className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" 
                        type="text" 
                        name="segment_name" 
                        id="segment_name" 
                        placeholder="Insert Segment Name" 
                        value={this.state.segmentName} 
                        onChange={this.handleChangeSegment}
                        />
                        <label className="relative w-full mb-3">Note: The default segment name is {this.state.defaultSegmentName} and This segment name is case sensitive</label>
                      </div>
                  </div>
                  <div className="w-full">
                  <div className="text-center mt-6">
                    <button
                      className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="submit"
                      onClick={this.handleSubmitSegment}
                    >
                      Submit
                    </button>
                    <Link
                      className="button-login text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="submit"
                      to="/dashboard/audience"
                    >
                      Cancel
                    </Link>
                  </div>
                    </div>
                  </form>
                </div>
                </>):(
                <>
                <div className="w-full relative p-6 flex-auto mx-auto items-center">
                  <h6 className="w-full text-center text-black font-bold mx-auto uppercase">Sync To Odoo</h6>
                  <p className="mt-2 text-gray text-sm text-center leading-relaxed">
                    Please wait until the process finished.
                  </p>
                </div>
                <div className="flex  justify-center pb-6 rounded-b px-4">
                  <form>
                    
                  <div className="w-full">
                      <div className="relative w-full mb-3">
                        <ClipLoader size={30} color="rgba(190, 157, 115)" />
                      </div>
                  </div>

                  </form>
                </div>
                </>)):(
                <>
                <div className="w-full relative p-6 flex-auto mx-auto items-center">
                  <h6 className="w-full text-center text-black font-bold mx-auto uppercase">Sync To Odoo</h6>
                </div>
                <div className="w-full relative   justify-center pb-6 rounded-b px-12">
                  <form>
                  {/*<div className="w-full">
                      <div 
                        className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between"
                        onClick={e=>this.handleTabs('invalid')}
                      >
                        <label
                          className="block uppercase text-black text-xs font-bold my-2 text-left"
                        >
                          Invalid Leads
                        </label>
                        <span className="">{this.state.invalid_customer}</span>
                      </div>
                      {!this.state.hideInvalidCustomer?
                      <>
                        <table className="block overflow-x-auto">
                          <thead>
                            <th className="text-xs">First Name</th>
                            <th className="text-xs">Last Name</th>
                            <th className="text-xs">Email</th>
                            <th className="text-xs">Phone</th>
                          </thead>
                          {this.state.invalid_data.map((e, key) => {
                            return (
                              <tr>
                                <td className="text-xs">{e.first_name}</td>
                                <td className="text-xs">{e.last_name}</td>
                                <td className="text-xs">{e.email}</td>
                                <td className="text-xs">{e.phone}</td>
                              </tr>
                            );
                          })}
                        </table>
                        </>:''}
                    </div>
                      */}
                  <div className="w-full">
                      <div 
                        className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between"
                        onClick={e=>this.handleTabs('duplicate')}
                      >
                        <label
                          className="block uppercase text-black text-xs font-bold my-2 text-left"
                        >
                          Update Leads
                        </label>
                        <span className="">{this.state.duplicated_customer}</span>
                      </div>
                      {!this.state.hideDuplicateCustomer?
                      <>
                        <table className="block overflow-x-auto">
                          <thead>
                            <th className="text-xs">First Name</th>
                            <th className="text-xs">Last Name</th>
                            <th className="text-xs">Email</th>
                            <th className="text-xs">Phone</th>
                          </thead>
                          {this.state.duplicated_data.map((e, key) => {
                            return (
                              <tr>
                                <td className="text-xs">{e.first_name}</td>
                                <td className="text-xs">{e.last_name}</td>
                                <td className="text-xs">{e.email}</td>
                                <td className="text-xs">{e.phone}</td>
                              </tr>
                            );
                          })}
                        </table>
                        </>:''}
                 
                        </div>
                  <div className="w-full">
                      <div className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between">
                        <label
                          className="block uppercase text-black text-xs font-bold my-2 text-left"
                        >
                          New Leads
                        </label>
                        <span className="">{this.state.new_customer}</span>
                      </div>
                    </div>
                  {/*<div className="w-full">
                    <div className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between">
                      <label
                        className="block uppercase text-black text-xs font-bold my-2 text-left"
                      >
                        Total Leads
                      </label>
                        <span className="">{this.state.total_customer}</span>
                    </div>
                  </div>
                  {/*<div className="w-full">
                    <div className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between">
                      <label
                        className="block uppercase text-black text-xs font-bold my-2 text-left"
                      >
                        Total Enquiry
                      </label>
                        <span className="">{this.state.total_enquiry}</span>
                    </div>
                  </div>*/}
                  
                  <div className="flex flex-wrap pt-4">
                    <div className="w-full">
                      <div className="relative w-full items-center text-center">
                        {/*<Link
                          type="button"
                          to="/leads"
                          className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          onClick={e=>this.handleShowConfirmation(true)}
                        >
                          Ok
                        </Link>*/}
                        {this.state.isSubmittedSf ? 
                          <button
                            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                          ><ClipLoader size={20} color="white" /></button> : 
                          <><button
                            className={`${this.state.disabledSf?'disabled':''}  button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                            disabled={this.state.disabledSf?'disabled':''} 
                            onClick={this.handleOverwrite}
                          >
                            Overwrite Leads
                          </button>
                        <button
                          type="button"
                          className="button-login text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          onClick={e=>this.handleShowConfirmation(true)}
                        >
                          DISCARD PROCESS
                        </button></>
  }
                      </div>
                    </div>
                  </div>
                  </form>
                </div>
                </>
                )}
                </div>
                </div>
          </div>
          <div className="fixed inset-0 z-40  bg-login"></div>

          {this.state.showConfirmation?
          <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="relative p-6 flex-auto mx-auto">
                  <h6 className="text-center text-black font-bold mx-auto">Discard Change? </h6>
                  <p className="mt-2 text-gray text-sm text-center leading-relaxed">
                    Are you sure want to discard this process? Your submited data for this process will be lose
                  </p>
                </div>
                <div className="flex items-center justify-center pb-6 rounded-b text-center">
                  <button
                    className="w-5/12 button-gold text-white  font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                    type="button"
                    onClick={this.handleConfirmCancel}
                  >
                    DISCARD PROCESS
                  </button>
                  <button
                    className="w-5/12 button-login  text-white font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                    type="button"
                    onClick={e=>this.handleShowConfirmation(false)}
                  >
                    cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-50  bg-login opacity-50"></div>
          </>:''}
          {this.state.showOverwriteMsg?
          <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="relative p-6 flex-auto mx-auto">
                  <h6 className="text-center text-black font-bold mx-auto">{this.state.notifMessage} </h6>
                </div>
                <div className="flex items-center justify-center pb-6 rounded-b text-center">
                  <button
                    className="w-5/12 button-gold  text-white  font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                    type="button"
                    onClick={this.handleConfirmCancel}
                  >
                    ok
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-50  bg-login opacity-50"></div>
          </>:''}
        </>
      
       
      );
  }
}