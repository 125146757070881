import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import auth from 'global/auth.js';

import jsCookie from 'js-cookie';
import { DateFormat } from "global/custom.js";
import { createPopper } from "@popperjs/core";

/*eslint-disable eqeqeq*/

export default class UsersSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameFilter: '',
      privilegeFilter: '',
      statusFilter: '',
      showroomFilter: '',
      emailSort: '',
      nameSort: '',
      privilegeSort: '',
      statusSort: '',
      createdDateSort: '',
      phoneSort: '',
      customersSort: '',
      currentPage: '1',
      perPage: '10',
      pagingPrevTemplate: '',
      pagingTemplate: '',
      pagingNextTemplate: '',
      page: '',
      backupData: [],
      usersData: [],
      usersFilteredData: [],
      totalPaging: '',
      statusOption: [
        { value: '', text: 'All' },
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'Inactive' },
        { value: 'blocked', text: 'Blocked' },
      ],
      privilegeOption: [
        { value: '', text: 'All' },
        { value: 'Super Admin', text: 'Super Admin' },
        { value: 'Sales Manager', text: 'Sales Manager' },
        { value: 'Brand Associate', text: 'Brand Associate' },
      ],
      showroomOption: [],
      nameFilled: false,
      checkBoxId: [],//checkbox
      showModal: false,
      refreshPage: false,
      urlStatus: true,
      showMoreFilter: false,
      filterOffsetHeight: 0,
    };
    //qwe

    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkPopoverFilter = this.checkPopoverFilter.bind(this);
    this.checkPopover = this.checkPopover.bind(this);
    this.handleResetFilter = this.handleResetFilter.bind(this);
    /*paging*/
    this.handlePageChange = this.handlePageChange.bind(this);
    /*paging*/

    /*checkbox*/
    this.handleCheckAllChange = this.handleCheckAllChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleDeleteMultiple = this.handleDeleteMultiple.bind(this);
    /*checkbox*/

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);

    this.resetField = this.resetField.bind(this);

    this.handleResize = this.handleResize.bind(this);

  }

  getShowroomData() {
    var url = `${process.env.REACT_APP_API_URL}showroom`;
    if (jsCookie.get('showroom_id') !== 'null') {
      url = `${process.env.REACT_APP_API_URL}showroom/${jsCookie.get('showroom_id')}`;
    }
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({ showroomOption: data });

      })
  }

  handleData() {
    axios.get(`${process.env.REACT_APP_API_URL}user`, {
      headers: {
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        var privilege = jsCookie.get('privilege');
        var uid = jsCookie.get('uid');
        if (privilege === 'Super Admin') {
          data = data.filter(row => {
            return row.name.toLowerCase().match(decodeURI(this.props.value.name))
          });
          this.setState({ userData: Array.from(data) });
          this.setState({ backupData: Array.from(data) });
        } else if (privilege === 'Sales Manager') {
          data = data.filter(row => {
            return row.privilege.toLowerCase().match('brand associate')
          });
          data = data.filter(row => {
            return row.manager_id == uid
          });

          data = data.filter(row => {
            return row.name.toLowerCase().match(decodeURI(this.props.value.name))
          });

          this.setState({ userData: Array.from(data) });
          this.setState({ backupData: Array.from(data) });
          this.setState({
            privilegeOption: [
              { value: '', text: 'All' },
              { value: 'Brand Associate', text: 'Brand Associate' },
            ],
          });
        } else {
          this.setState({ urlStatus: false });
        }

        this.handleFilter();
      })
  }

  componentDidMount() {
    if (jsCookie.get('privilege') === 'Brand Associate') {
      this.setState({ urlStatus: false });
    }
    this.getShowroomData();
    this.handleData();
    /*window.addEventListener('resize', this.handleResize);
    window.addEventListener('load',this.handleResize);
    if(document.readyState === 'complete'){
      this.handleResize()
    }*/
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /*const height = this.divElement.offsetHeight;
    if (height !== prevState.filterOffsetHeight) {
      if(height > 100){
        this.setState({showMoreFilter:true});
      }else{
        this.setState({showMoreFilter:false});
      }
      this.setState({filterOffsetHeight:height});
    }
*/
    if (this.props.value.name != prevProps.value.name) {
      this.handleData();
    }
  }

  handlePageChange(number) {
    this.setState({ currentPage: number }, () => {
      this.handlePaging();
    });
  }

  handlePaging() {
    let data = this.state.usersData;

    let currentPage = this.state.currentPage;
    let perpage = this.state.perPage;
    let totalData = data.length;
    let totalPaging = Math.ceil(totalData / perpage);

    const start = (Number(currentPage) - 1) * perpage;
    const end = Number(start) + Number(perpage);
    let slicedData = data.slice(start, end)

    let startLoop = 1;
    let endLoop = totalPaging;
    if (Number(currentPage) - 2 > 0 && Number(currentPage) + 2 <= totalPaging) {
      startLoop = Number(currentPage) - 2;
      endLoop = Number(currentPage) + 2;
    } else if (Number(currentPage) - 1 > 0 && Number(currentPage) + 3 <= totalPaging) {
      startLoop = Number(currentPage) - 1;
      endLoop = Number(currentPage) + 3;
    } else if (Number(currentPage) - 3 > 0 && Number(currentPage) + 1 <= totalPaging) {
      startLoop = Number(currentPage) - 3;
      endLoop = Number(currentPage) + 1;
    } else if (currentPage == 1 && Number(currentPage) + 4 <= totalPaging) {
      startLoop = Number(currentPage);
      endLoop = Number(currentPage) + 4;
    } else if (currentPage == totalPaging && Number(currentPage) - 4 > 0) {
      startLoop = Number(currentPage) - 4;
      endLoop = Number(currentPage);
    }

    let pagingPrevTemplate = [];
    let pagingTemplate = [];
    let pagingNextTemplate = [];
    if (Number(currentPage) > 1) {
      pagingPrevTemplate.push(
        <>
          <li key="tofirst">
            <button onClick={e => this.handlePageChange(1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
          <li key="toprev">
            <button onClick={e => this.handlePageChange(currentPage - 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    for (let i = startLoop; i <= endLoop; i++) {
      if (i == currentPage) {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 text-white bg-black">
              {i}
            </button>
          </li>
        )
      } else {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button name={i} onClick={() => this.handlePageChange(i)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              {i}
            </button>
          </li>
        )
      }
    }

    if (currentPage < totalPaging && totalPaging > 1) {
      pagingNextTemplate.push(
        <>
          <li key={`${Number(totalPaging) + 3}`}>
            <button onClick={() => this.handlePageChange(Number(currentPage) + 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
          <li key={`${Number(totalPaging) + 4}`}>
            <button onClick={() => this.handlePageChange(totalPaging)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    if (currentPage > 1) {
      if (currentPage > totalPaging) {
        this.setState({
          currentPage: 1,
        }, () => {
          this.handleFilter();
        });
      }
    }

    this.setState({
      usersFilteredData: slicedData,
      pagingPrevTemplate: pagingPrevTemplate,
      pagingTemplate: pagingTemplate,
      pagingNextTemplate: pagingNextTemplate,
      startShowing: slicedData.length > 0 ? start + 1 : 0,
      endShowing: start + 1 < totalData ? (end < totalData ? end : totalData) : '',
      totalData: totalData,
    });
  }

  checkPopover(id) {
    const button_id = 'button' + id;
    const popup_id = 'popup' + id;
    const button = document.querySelector('#' + button_id);
    const popup = document.querySelector('#' + popup_id);

    if (document.getElementById(popup_id).classList.contains('hidden')) {
      createPopper(button, popup, {
        placement: "left-start",
      });

      var inputs = document.querySelectorAll(".popover");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].classList.remove('block');
        inputs[i].classList.add('hidden');
      }

      popup.classList.remove('hidden');
      popup.classList.add('block');
    } else {
      popup.classList.remove('block');
      popup.classList.add('hidden');
    }
  };
  resetField(event) {
    if (event === 'name') {
      this.setState({
        nameFilter: '',
        nameFilled: false
      }, () => {
        this.handleFilter();
      });
    }
    if (event === 'followup') {
      this.setState({
        followupFilter: '',
        followupFilled: false,
      }, () => {
        this.handleFilter();
      });
    }
    if (event === 'pic') {
      this.setState({
        picFilter: '',
        picFilled: false,
      }, () => {
        this.handleFilter();
      });
    }
    if (event === 'enquiry') {
      this.setState({
        enquiryFilter: '',
        enquiryFilled: false,
      }, () => {
        this.handleFilter();
      });
    }
    if (event === 'showroom') {
      this.setState({
        showroomFilter: '',
        showroomFilled: false,
      }, () => {
        this.handleFilter();
      });
    }
    if (event === 'city') {
      this.setState({
        cityFilter: '',
        cityFilled: false,
      }, () => {
        this.handleFilter();
      });
    }
    if (event === 'car') {
      this.setState({
        carFilter: '',
        carFilled: false
      }, () => {
        this.handleFilter();
      });
    }
  }
  handleResetFilter(event) {
    this.setState({
      usersData: Array.from(this.state.backupData),
      usersFilteredData: Array.from(this.state.backupData),
      nameFilter: '',
      privilegeFilter: '',
      statusFilter: '',
      showroomFilter: '',
      pushUrl: '/users',
    }, () => {
      this.handlePaging();
    });
  }

  handleFilter() {
    const name = this.state.nameFilter;
    const privilege = this.state.privilegeFilter;
    const status = this.state.statusFilter;
    const showroom = this.state.showroomFilter;
    if (name) {
      this.setState({ nameFilled: true });
    } else {
      this.setState({ nameFilled: false });
    }

    let rowsNew = this.state.backupData;

    if (name) {
      rowsNew = rowsNew.filter(row => {
        return row.name.toLowerCase().match(name.toLowerCase())
      });
    }

    if (privilege) {
      rowsNew = rowsNew.filter(row => {
        return row.privilege.toLowerCase().match(privilege.toLowerCase())
      });
    }

    if (status) {
      rowsNew = rowsNew.filter(row => {
        return row.status.toLowerCase().match(status.toLowerCase())
      });
    }

    if (showroom) {
      rowsNew = rowsNew.filter(row => {
        return row.showroom_id == showroom
      });
    }

    this.setState({
      usersData: Array.from(rowsNew),
      usersFilteredData: Array.from(rowsNew)
    }, () => {
      this.handlePaging();
    });
  }

  handleChange(event) {
    //let searchData = this.state.backupData;
    const target = event.target;
    const name = target.id === 'name' ? target.value : this.state.nameFilter;
    const privilege = target.id === 'privilege' ? target.value : this.state.privilegeFilter;
    const status = target.id === 'status' ? target.value : this.state.statusFilter;
    const showroom = target.id === 'showroom' ? target.value : this.state.showroomFilter;

    this.setState({
      nameFilter: name,
      privilegeFilter: privilege,
      statusFilter: status,
      showroomFilter: showroom,
      //pushUrl:pushUrl,
    }, () => {
      this.handleFilter();
    });
  }

  handleSort(event) {
    var data = this.state.usersData;
    if (event === 'name') {
      if (this.state.nameSort === '' || this.state.nameSort === 'desc') {
        data = data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
        this.setState({ nameSort: 'asc', emailSort: '', phoneSort: '', privilegeSort: '', statusSort: '', createdDateSort: '', customersSort: '' });
      } else {
        data = data.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? 1 : -1)
        this.setState({ nameSort: 'desc', emailSort: '', phoneSort: '', privilegeSort: '', statusSort: '', createdDateSort: '', customersSort: '' });
      }
    }

    if (event === 'email') {
      if (this.state.emailSort === '' || this.state.emailSort === 'desc') {
        data = data.sort((a, b) => (a.email.toLowerCase() > b.email.toLowerCase()) ? 1 : -1)
        this.setState({ nameSort: '', emailSort: 'asc', phoneSort: '', privilegeSort: '', statusSort: '', createdDateSort: '', customersSort: '' });
      } else {
        data = data.sort((a, b) => (a.email.toLowerCase() < b.email.toLowerCase()) ? 1 : -1)
        this.setState({ nameSort: '', emailSort: 'desc', phoneSort: '', privilegeSort: '', statusSort: '', createdDateSort: '', customersSort: '' });
      }
    }

    if (event === 'phone') {
      if (this.state.phoneSort === '' || this.state.phoneSort === 'desc') {
        data = data.sort((a, b) => (a.phone > b.phone) ? 1 : -1)
        this.setState({ nameSort: '', emailSort: '', phoneSort: 'asc', privilegeSort: '', statusSort: '', createdDateSort: '', customersSort: '' });
      } else {
        data = data.sort((a, b) => (a.phone < b.phone) ? 1 : -1)
        this.setState({ nameSort: '', emailSort: '', phoneSort: 'desc', privilegeSort: '', statusSort: '', createdDateSort: '', customersSort: '' });
      }
    }

    if (event === 'privilege') {
      if (this.state.privilegeSort === '' || this.state.privilegeSort === 'desc') {
        data = data.sort((a, b) => a.privilege > b.privilege ? 1 : -1)
        this.setState({ nameSort: '', emailSort: '', phoneSort: '', privilegeSort: 'asc', statusSort: '', createdDateSort: '', customersSort: '' });
      } else {
        data = data.sort((a, b) => a.privilege < b.privilege ? 1 : -1)
        this.setState({ nameSort: '', emailSort: '', phoneSort: '', privilegeSort: 'desc', statusSort: '', createdDateSort: '', customersSort: '' });
      }
    }

    if (event === 'status') {
      if (this.state.statusSort === '' || this.state.statusSort === 'desc') {
        data = data.sort((a, b) => a.status > b.status ? 1 : -1)
        this.setState({ nameSort: '', emailSort: '', phoneSort: '', privilegeSort: '', statusSort: 'asc', createdDateSort: '', customersSort: '' });
      } else {
        data = data.sort((a, b) => a.status < b.status ? 1 : -1)
        this.setState({ nameSort: '', emailSort: '', phoneSort: '', privilegeSort: '', statusSort: 'desc', createdDateSort: '', customersSort: '' });
      }
    }

    if (event === 'createdDate') {
      if (this.state.createdDateSort === '' || this.state.createdDateSort === 'desc') {
        data = data.sort((a, b) => new Date(a.created_date) > new Date(b.created_date) ? 1 : -1)
        this.setState({ nameSort: '', emailSort: '', phoneSort: '', privilegeSort: '', statusSort: '', createdDateSort: 'asc', customersSort: '' });
      } else {
        data = data.sort((a, b) => new Date(a.created_date) < new Date(b.created_date) ? 1 : -1)
        this.setState({ nameSort: '', emailSort: '', phoneSort: '', privilegeSort: '', statusSort: '', createdDateSort: 'desc', customersSort: '' });
      }
    }

    if (event === 'customers') {
      if (this.state.customersSort === '' || this.state.customersSort === 'desc') {
        data = data.sort((a, b) => a.total_customer > b.total_customer ? 1 : -1)
        this.setState({ nameSort: '', emailSort: '', phoneSort: '', privilegeSort: '', statusSort: '', createdDateSort: '', customersSort: 'asc' });
      } else {
        data = data.sort((a, b) => a.total_customer < b.total_customer ? 1 : -1)
        this.setState({ nameSort: '', emailSort: '', phoneSort: '', privilegeSort: '', statusSort: '', createdDateSort: '', customersSort: 'desc' });
      }
    }

    if (data) {
      this.setState({ usersFilteredData: Array.from(data) }, () => {
        this.handlePaging();
      });
    }
  }


  handleCloseModal() {
    this.setState({ showModal: false });

  }

  handleCheckAllChange(event) {
    var checkBoxAll = [];
    var inputs = '';
    var i = 0;
    if (document.getElementById('checkAll').checked) {
      inputs = document.querySelectorAll("input[type='checkbox']");
      for (i = 0; i < inputs.length; i++) {
        if (inputs[i].value !== 'on') {
          checkBoxAll.push(inputs[i].value);
        }
        inputs[i].checked = true;
      }
    } else {
      inputs = document.querySelectorAll("input[type='checkbox']");
      for (i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
      }
    }
    this.setState({ checkBoxId: Array.from(checkBoxAll) });

  }
  handleCheckBoxChange(event) {
    const target = event.target;
    //const email = target.id === 'email' ? target.value : this.state.email;

    var checkBoxId = this.state.checkBoxId;
    if (document.getElementById(target.id).checked) {
      checkBoxId.push(target.value);
    } else {
      document.getElementById('checkAll').checked = false;
      checkBoxId = checkBoxId.filter(function (value, index, arr) {
        return value !== target.value;
      });
    }
    this.setState({ checkBoxId: Array.from(checkBoxId) });
  }

  handleDeleteMultiple(event) {
    if (this.state.checkBoxId.length > 0) {
      this.setState({ showModal: true });
    } else {
      this.createErrorMessage('Please Select One Team Member for Delete', 'errorName', 'errorMessage');
    }
  }


  handleDelete(id) {

    var inputs = document.querySelectorAll(".popover");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove('block');
      inputs[i].classList.add('hidden');
    }

    this.setState({ showModal: true, checkBoxId: [id] });
  }

  handleConfirmDelete(id) {
    this.setState({ showModal: false });

    axios.post(`${process.env.REACT_APP_API_URL}user/delete/`, this.state.checkBoxId,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(err => {
        throw err;
      })
      .then(res => {
        this.handleData();
      })
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className='notifBar w-full bg-red'>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <div className="cursor-pointer" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)}>
          <i className="fa fa-times" />
        </div>
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  checkPopoverFilter() {
    this.setState({
      showDatePicker: false,
      showFilter: !this.state.showFilter
    }, () => {
      this.handleResize()
    })
  };

  handleResize() {
    var filter = document.getElementsByClassName('filter')
    if (this.state.filterOffsetHeight > 100) {
      if (this.state.showFilter === true) {
        filter[0].style.height = (this.state.filterOffsetHeight) + 'px'
      } else {
        filter[0].style.height = '90px'
      }
      this.setState({ showMoreFilter: true });
    }
    else {
      this.setState({ showMoreFilter: false });
    }

  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (!this.state.urlStatus) {
      window.location.href = '/404'
    }

    if (!this.state.usersData && this.props.id !== 0) {
      return (<span> Data Not Available</span>)
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.errorMessage}
        </div>
        <div className="flex flex-wrap ">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0">

            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="block w-full  ">
                <div className="relative flex flex-col  min-w-0 break-words w-full pb-6 shadow-lg rounded bg-white">
                  <div className="w-full lg:w-10/12 xl:w-10/12 md:w-10/12 flex-grow flex-1"
                  >
                    <h3 className="font-semibold text-lg text-blueGray-700 w-12/12 float-left py-4 px-4">
                      Team Member
                    </h3>
                  </div>
                  {/* Projects table */}
                  <div className={"customer-table relative overflow-x-auto bg-white "}>
                    <table className={"items-center bg-white w-full bg-transparent border-collapse overflow-x-scroll"}>
                      <thead>
                        <tr className="border border-l-0 border-r-0 border-blueGray-200">
                          <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500 pl-4"}
                            style={{ width: `${30}px` }}
                          >
                            <input
                              name="checkAll"
                              id="checkAll"
                              type="checkbox"
                              className="rounded nofocus"
                              onChange={this.handleCheckAllChange} />
                          </th>
                          <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500 "}
                            onClick={() => this.handleSort('name')}
                          >
                            Name
                            <i className={`fas ${this.state.nameSort === 'desc' ? "fa-sort-down" : (this.state.nameSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                            onClick={() => this.handleSort('email')}
                          >
                            Email
                            <i className={`fas ${this.state.emailSort === 'desc' ? "fa-sort-down" : (this.state.emailSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                          </th>
                          <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                            onClick={() => this.handleSort('phone')}
                          >
                            Phone Number
                            <i className={`fas ${this.state.phoneSort === 'desc' ? "fa-sort-down" : (this.state.phoneSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                          </th>
                          <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                            onClick={() => this.handleSort('privilege')}
                          >
                            Role
                            <i className={`fas ${this.state.privilegeSort === 'desc' ? "fa-sort-down" : (this.state.privilegeSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                          </th>
                          <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                            onClick={() => this.handleSort('status')}
                          >
                            Status
                            <i className={`fas ${this.state.statusSort === 'desc' ? "fa-sort-down" : (this.state.statusSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                          </th>
                          <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                            onClick={() => this.handleSort('createdDate')}
                          >
                            Created Date
                            <i className={`fas ${this.state.createdDateSort === 'desc' ? "fa-sort-down" : (this.state.createdDateSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                          </th>
                          <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                            onClick={() => this.handleSort('customers')}
                          >
                            Leads
                            <i className={`fas ${this.state.customersSort === 'desc' ? "fa-sort-down" : (this.state.customersSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                            {/*<i className={`fas ${this.state.customersSort === 'desc'?"fa-sort-down":(this.state.customersSort === 'asc'?"fa-sort-up":"fa-sort")} mr-2 text-sm opacity-50 float-right`}></i>*/}
                          </th>
                          <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500 pr-4"}
                            style={{ width: `${30}px` }}
                          >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.usersFilteredData.map((row) => (
                          <tr key={row.id} className={"border border-l-0 border-r-0 border-blueGray-200"}>
                            <td className="px-2 align-middle text-sm whitespace-nowrap px-4 py-4">
                              <input
                                name={`checked${row.id}`}
                                id={`checked${row.id}`}
                                type="checkbox"
                                className="checkbox rounded nofocus"
                                value={row.id}
                                onChange={this.handleCheckBoxChange} />
                            </td>
                            <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                              <Link to={`/users/${row.id}`}>{row.name}</Link>
                            </td>
                            <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                              {row.email}
                            </td>
                            <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                              {row.phone}
                            </td>
                            <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                              {/*row.privilege=='Super Admin'?'Lexus Indonesia':row.privilege*/}
                              {row.privilege}
                            </td>
                            <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                              {row.status}
                            </td>
                            <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                              {DateFormat(row.created_date, 'Mdy')}
                            </td>
                            <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                              {row.total_customer}
                            </td>
                            <td className="px-2 align-middle text-sm whitespace-nowrap py-4 pr-4">
                              <div
                                className="cursor-pointer text-blueGray-500 py-1 px-3"
                                href="#"
                                id={`button${row.id}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.checkPopover(row.id)
                                }}
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </div>
                              <div
                                id={`popup${row.id}`}
                                className={
                                  "popover hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
                                }
                              >
                                <a
                                  href={`/users/${row.id}`}
                                  className={
                                    "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                                  }
                                >
                                  Detail
                                </a>
                                <a
                                  href={`/users/edit/${row.id}`}
                                  className={
                                    "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                                  }
                                >
                                  Edit
                                </a>
                                <div
                                  className={
                                    "cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleDelete(row.id)
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                        {this.state.usersFilteredData.length == 0 ?
                          <tr key="notfound" className="border border-l-0 border-r-0 border-blueGray-200">
                            <td colSpan="7" className="align-middle text-sm text-center whitespace-nowrap py-4 pr-4">
                              Data Not Available
                            </td>
                          </tr> : null}
                      </tbody>
                    </table>
                  </div>
                  <div className="py-4">
                    <nav className="block">
                      <button
                        className="button-gold mx-4 text-white active:bg-blueGray-600 text-2xs tracking-wide01 uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={this.handleDeleteMultiple}
                      >
                        Delete
                      </button>
                      <ul className="flex rounded list-none flex-wrap float-right mx-4">
                        <div className="mx-4 my-auto">
                          Showing {this.state.startShowing} {this.state.endShowing ? 'to ' + this.state.endShowing : ''} of {this.state.totalData}
                        </div>

                        {/*pagingTmp*/}
                        {this.state.pagingPrevTemplate}
                        {this.state.pagingTemplate}
                        {this.state.pagingNextTemplate}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto mx-auto">
                    <h6 className="text-center text-black font-bold mx-auto">Do you want to delete this data? </h6>
                  </div>
                  <div className="flex items-center justify-center pb-6 rounded-b text-center">
                    <button
                      className="w-5/12 button-gold text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      type="button"
                      onClick={this.handleConfirmDelete}
                    >
                      ok
                    </button>
                    <button
                      className="w-5/12 button-login text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      type="button"
                      onClick={this.handleCloseModal}
                    >
                      cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
}

