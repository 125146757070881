import React from "react";
import { createPopper } from "@popperjs/core";
import axios from 'axios';
import jsCookie from 'js-cookie';
import { DateFormat } from "global/custom.js";

 export default class NotificationDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      id:this.props.id,
      unreadMsg:0,
      notificationContent:[],
      dropdownPopoverShow:false,
      btnDropdownRef:React.createRef(),
      popoverDropdownRef:React.createRef(),
      reloadData:false,
      redirectPage:false,
      urlPath:'',
    };
    this.openDropdownPopover = this.openDropdownPopover.bind(this);
    this.closeDropdownPopover = this.closeDropdownPopover.bind(this);
    //this.handleDelete = this.handleDelete.bind(this);
    this.handleClick = this.handleClick.bind(this);
    
  } 
  getData(){
    var url = `${process.env.REACT_APP_API_URL}users/notification?id=${jsCookie.get('uid')}`;
    axios.get(url,
    {
      headers: { 
        'Access-Control-Allow-Origin' : '*',
        'Authorization': 'Bearer '+process.env.REACT_APP_ACCESS_TOKEN
      }
    })
    .catch(error =>{ 
      return error;
    })
    .then(res => {
      if(res.data){
        let result = res.data;
        let unread = result.filter(row => {
          return  row.status.toLowerCase().match('unread')
        }); 
        this.setState({
          unreadMsg:unread.length,
          notificationContent:result,
          reloadData:false
        });
      }
    })
  }
  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.state.reloadData !== prevState.reloadData){
      this.getData();
    }
  }
  // dropdown props
  openDropdownPopover = () => {
    createPopper(this.state.btnDropdownRef.current, this.state.popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    this.setState({dropdownPopoverShow:true});
  };
  closeDropdownPopover = () => {
    this.setState({dropdownPopoverShow:false});
  };
  handleDelete = (id) => {
    this.props.onSelectDelete(id);
  };

  handleClick(created_date){
    var url = `${process.env.REACT_APP_API_URL}users/notification/read`;
    let where_data = [
      jsCookie.get('uid'),
      DateFormat(created_date)
    ]
    axios.post(url,where_data,
    {
      headers: { 
        'Access-Control-Allow-Origin' : '*',
        'Authorization': 'Bearer '+process.env.REACT_APP_ACCESS_TOKEN
      }
    })
    .catch(error =>{ 
      return error;
    })
    .then(res => {
      jsCookie.set('notification_created_date',created_date);

      this.setState({
        reloadData:true,
        redirectPage:true,
        urlPath:"/notification/detail"
      });
    })
  }

  resetRedirectPage(){
    this.setState({
      redirectPage:false,
      dropdownPopoverShow:false,
      urlPath:'',
    }); 
  }

  
  checkPopover(id) {
    const button_id = 'button' + id;
    const popup_id = 'popup' + id;
    const button = document.querySelector('#' + button_id);
    const popup = document.querySelector('#' + popup_id);

    if (document.getElementById(popup_id).classList.contains('hidden')) {
      createPopper(button, popup, {
        placement: "left-start",
      });

      var inputs = document.querySelectorAll(".popover");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].classList.remove('block');
        inputs[i].classList.add('hidden');
      }

      popup.classList.remove('hidden');
      popup.classList.add('block');
    } else {
      popup.classList.remove('block');
      popup.classList.add('hidden');
    }
  };
  
  render() {
    if(this.state.redirectPage){ 
      this.resetRedirectPage();
      window.location.href =this.state.urlPath
    }
  return (
    <>
      <div
        className="cursor-pointer relative text-blueGray-500 py-1 pr-4 pl-3"
        ref={this.state.btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          this.state.dropdownPopoverShow ? this.closeDropdownPopover() : this.openDropdownPopover();
        }}
      >
        <i className={"fas fa-bell text-2xl "}></i>
        {this.state.unreadMsg>0?
      <span className="absolute right-10-px -top-5-px px-1 rounded-full border bg-white text-xs text-black">{this.state.unreadMsg}</span>
      :''}
      </div>
      <div
        ref={this.state.popoverDropdownRef}
        className={
          (this.state.dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left  list-none text-left rounded shadow-lg min-w-48 "
        }
      >
        {
        this.state.notificationContent.length>0?(this.state.notificationContent.map((row,key)=>{
          return (<div key={key}
            className={"p-4 cursor-pointer "+ (row.status==="unread"?'bg-table-red text-blueGray-600':'text-blueGray-400')}
            onClick={()=>this.handleClick(row.created_date)}
          >
            <div className="">{row.total_customers} Enquiry and Leads has been assign to you.</div>
            <div className="text-right text-xs">{DateFormat(row.created_date,'Mdy')}</div>
          </div>)
          })):''
        }
        
        {
        this.state.notificationContent.length<1?
        (<div 
          className={"p-4 cursor-pointer text-blueGray-400"}
        >
          <div className="">Notification Empty</div>
        </div>):''
        }
      </div>
    </>
  );
};
}
