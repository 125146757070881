import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import auth from 'global/auth.js';

import jsCookie from 'js-cookie';
import { DateFormat } from "global/custom.js";
import { createPopper } from "@popperjs/core";

import ClipLoader from "react-spinners/ClipLoader";
/*eslint-disable eqeqeq*/

export default function Users() {
  if (jsCookie.get('privilege') == 'Uploader') {
    return <Redirect to="/404" />;
  }
  return (
    <>
      <UsersClass />
    </>
  );
}
class UsersClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameFilter: '',
      privilegeFilter: '',
      statusFilter: '',
      showroomFilter: '',

      nameSort: '',
      emailSort: '',
      privilegeSort: '',
      statusSort: '',
      createdDateSort: '',
      phoneSort: '',
      customersSort: '',
      gallerySort: '',
      salesManagerSort: '',

      currentPage: '1',
      perPage: '10',
      pagingPrevTemplate: '',
      pagingTemplate: '',
      pagingNextTemplate: '',
      page: '',
      backupData: [],
      usersData: [],
      usersFilteredData: [],
      totalPaging: '',
      statusOption: [
        { value: '', text: 'All' },
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'Inactive' },
        { value: 'blocked', text: 'Blocked' },
      ],
      privilegeOption: [
        { value: '', text: 'All' },
        //{ value: 'Super Admin', text: 'Lexus Indonesia' },
        { value: 'Super Admin', text: 'Super Admin' },
        { value: 'Sales Manager', text: 'Sales Manager' },
        { value: 'Brand Associate', text: 'Brand Associate' },
      ],
      showroomOption: [],
      nameFilled: false,
      checkBoxId: [],//checkbox
      showModal: false,
      refreshPage: false,
      urlStatus: true,
      showMoreFilter: false,
      filterOffsetHeight: 0,
      isLoading: true,
    };
    //qwe

    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkPopoverFilter = this.checkPopoverFilter.bind(this);
    this.checkPopover = this.checkPopover.bind(this);
    this.handleResetFilter = this.handleResetFilter.bind(this);
    /*paging*/
    this.handlePageChange = this.handlePageChange.bind(this);
    /*paging*/

    /*checkbox*/
    this.handleCheckAllChange = this.handleCheckAllChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleDeleteMultiple = this.handleDeleteMultiple.bind(this);
    /*checkbox*/

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);

    this.resetField = this.resetField.bind(this);

    this.handleResize = this.handleResize.bind(this);

  }

  getShowroomData() {
    var url = `${process.env.REACT_APP_API_URL}showroom`;
    if (jsCookie.get('showroom_id') !== 'null') {
      url = `${process.env.REACT_APP_API_URL}showroom/${jsCookie.get('showroom_id')}`;
    }
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({ showroomOption: data });
      })
  }

  handleData() {
    axios.get(`${process.env.REACT_APP_API_URL}user`, {
      headers: {
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        this.createErrorMessage('Please check connection.', '', 'errorMessage')
        this.setState({ isLoading: false });
        throw error;
      })
      .then(res => {
        if (res) {
          let data = res.data;
          var privilege = jsCookie.get('privilege');
          var uid = jsCookie.get('uid');
          let privilegeOption = this.state.privilegeOption
          let urlStatus = this.state.urlStatus
          if (privilege === 'Sales Manager') {
            data = data.filter(row => {
              return row.privilege.toLowerCase().match('brand associate')
            });
            data = data.filter(row => {
              return row.manager_id == uid
            });

            privilegeOption = [
              { value: '', text: 'All' },
              { value: 'Brand Associate', text: 'Brand Associate' },
            ];
          } else if (privilege === 'Brand Associate') {
            urlStatus = false
          }

          var checkUrlPath = window.location.pathname;

          var myarr = checkUrlPath.split("/");
          let nameFilter = '';
          let privilegeFilter = '';
          let statusFilter = '';
          let showroomFilter = '';
          let currentPage = 1;
          if (myarr[2] !== 'null' && typeof myarr[2] !== 'undefined') {
            nameFilter = decodeURI(myarr[2]);
          }
          if (myarr[3] !== 'null' && typeof myarr[3] !== 'undefined') {
            privilegeFilter = decodeURI(myarr[3]);
          }
          if (myarr[4] !== 'null' && typeof myarr[4] !== 'undefined') {
            statusFilter = myarr[4];
          }
          if (myarr[5] !== 'null' && typeof myarr[5] !== 'undefined') {
            showroomFilter = myarr[5];
          }
          if (myarr[6] !== 'null' && typeof myarr[6] !== 'undefined') {
            currentPage = myarr[6];
          }

          this.setState({
            userData: Array.from(data),
            backupData: Array.from(data),
            privilegeOption: privilegeOption,
            urlStatus: urlStatus,
            nameFilter: nameFilter,
            privilegeFilter: privilegeFilter,
            statusFilter: statusFilter,
            showroomFilter: showroomFilter,
            currentPage: currentPage,
            isLoading: false,
          }, () => {
            this.getShowroomData();
            this.handleFilter();
          });
        }
      })
  }

  componentDidMount() {
    document.getElementById('search').value = '';
    if (jsCookie.get('privilege') === 'Brand Associate') {
      this.setState({ urlStatus: false });
    }
    this.handleData();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('load', this.handleResize);
    if (document.readyState === 'complete') {
      this.handleResize()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const height = this.divElement.offsetHeight;
    if (height !== prevState.filterOffsetHeight) {
      let showMoreFilter = false;
      if (height > 100) {
        showMoreFilter = true;
      }
      this.setState({
        showMoreFilter: showMoreFilter,
        filterOffsetHeight: height
      });
    }
  }

  handlePageChange(number) {
    const name = this.state.nameFilter;
    const privilege = this.state.privilegeFilter;
    const status = this.state.statusFilter;
    const showroom = this.state.showroomFilter;

    var pushUrl = '/users'
    if (name || privilege || status || number) {
      pushUrl = '/users/' + (name ? name.toLowerCase() : 'null') + '/' + (privilege ? privilege : 'null') + '/' + (status ? status : 'null') + '/' + (showroom ? showroom.toLowerCase() : 'null') + '/' + number
    }
    window.history.pushState('page2', 'Title', pushUrl);
    this.setState({ currentPage: number }, () => {
      this.handlePaging();
    });
  }

  handlePaging() {
    let data = this.state.usersData;

    let currentPage = this.state.currentPage;
    let perpage = this.state.perPage;
    let totalData = data.length;
    let totalPaging = Math.ceil(totalData / perpage);

    const start = (Number(currentPage) - 1) * perpage;
    const end = Number(start) + Number(perpage);
    let slicedData = data.slice(start, end)

    let startLoop = 1;
    let endLoop = totalPaging;
    if (Number(currentPage) - 2 > 0 && Number(currentPage) + 2 <= totalPaging) {
      startLoop = Number(currentPage) - 2;
      endLoop = Number(currentPage) + 2;
    } else if (Number(currentPage) - 1 > 0 && Number(currentPage) + 3 <= totalPaging) {
      startLoop = Number(currentPage) - 1;
      endLoop = Number(currentPage) + 3;
    } else if (Number(currentPage) - 3 > 0 && Number(currentPage) + 1 <= totalPaging) {
      startLoop = Number(currentPage) - 3;
      endLoop = Number(currentPage) + 1;
    } else if (currentPage == 1 && Number(currentPage) + 4 <= totalPaging) {
      startLoop = Number(currentPage);
      endLoop = Number(currentPage) + 4;
    } else if (currentPage == totalPaging && Number(currentPage) - 4 > 0) {
      startLoop = Number(currentPage) - 4;
      endLoop = Number(currentPage);
    }

    let pagingPrevTemplate = [];
    let pagingTemplate = [];
    let pagingNextTemplate = [];
    if (Number(currentPage) > 1) {
      pagingPrevTemplate.push(
        <>
          <li key="tofirst">
            <button onClick={e => this.handlePageChange(1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
          <li key="toprev">
            <button onClick={e => this.handlePageChange(currentPage - 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    for (let i = startLoop; i <= endLoop; i++) {
      if (i == currentPage) {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 text-white bg-black">
              {i}
            </button>
          </li>
        )
      } else {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button name={i} onClick={() => this.handlePageChange(i)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              {i}
            </button>
          </li>
        )
      }
    }

    if (currentPage < totalPaging && totalPaging > 1) {
      pagingNextTemplate.push(
        <>
          <li key={`${Number(totalPaging) + 3}`}>
            <button onClick={() => this.handlePageChange(Number(currentPage) + 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
          <li key={`${Number(totalPaging) + 4}`}>
            <button onClick={() => this.handlePageChange(totalPaging)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    if (currentPage > 1) {
      if (currentPage > totalPaging) {
        this.setState({
          currentPage: 1,
        }, () => {
          this.handleFilter();
        });
      }
    }

    this.setState({
      usersFilteredData: slicedData,
      pagingPrevTemplate: pagingPrevTemplate,
      pagingTemplate: pagingTemplate,
      pagingNextTemplate: pagingNextTemplate,
      startShowing: slicedData.length > 0 ? start + 1 : 0,
      endShowing: start + 1 < totalData ? (end < totalData ? end : totalData) : '',
      totalData: totalData,
    });
  }

  checkPopover(id) {
    const button_id = 'button' + id;
    const popup_id = 'popup' + id;
    const button = document.querySelector('#' + button_id);
    const popup = document.querySelector('#' + popup_id);

    if (document.getElementById(popup_id).classList.contains('hidden')) {
      createPopper(button, popup, {
        placement: "left-start",
      });

      var inputs = document.querySelectorAll(".popover");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].classList.remove('block');
        inputs[i].classList.add('hidden');
      }

      popup.classList.remove('hidden');
      popup.classList.add('block');
    } else {
      popup.classList.remove('block');
      popup.classList.add('hidden');
    }
  };

  resetField(event) {
    if (event === 'name') {
      this.setState({
        nameFilter: '',
        nameFilled: false
      }, () => {
        this.handleFilter();
      });
    }
    if (event === 'followup') {
      this.setState({
        followupFilter: '',
        followupFilled: false,
      }, () => {
        this.handleFilter();
      });
    }
    if (event === 'pic') {
      this.setState({
        picFilter: '',
        picFilled: false,
      }, () => {
        this.handleFilter();
      });
    }
    if (event === 'enquiry') {
      this.setState({
        enquiryFilter: '',
        enquiryFilled: false,
      }, () => {
        this.handleFilter();
      });
    }
    if (event === 'showroom') {
      this.setState({
        showroomFilter: '',
        showroomFilled: false,
      }, () => {
        this.handleFilter();
      });
    }
    if (event === 'city') {
      this.setState({
        cityFilter: '',
        cityFilled: false,
      }, () => {
        this.handleFilter();
      });
    }
    if (event === 'car') {
      this.setState({
        carFilter: '',
        carFilled: false
      }, () => {
        this.handleFilter();
      });
    }
  }

  handleResetFilter(event) {
    window.history.pushState('page2', 'Title', '/users');
    this.setState({
      usersData: Array.from(this.state.backupData),
      usersFilteredData: Array.from(this.state.backupData),
      nameFilter: '',
      privilegeFilter: '',
      statusFilter: '',
      showroomFilter: '',
      pushUrl: '/users',
    }, () => {
      this.handlePaging();
    });
  }

  handleFilter() {
    const name = this.state.nameFilter;
    const privilege = this.state.privilegeFilter;
    const status = this.state.statusFilter;
    const showroom = this.state.showroomFilter;
    const number = this.state.currentPage;
    let nameFilled = false;
    if (name) {
      nameFilled = true
    }

    this.setState({ nameFilled: nameFilled });

    var pushUrl = '/users'
    if (name || privilege || status || showroom) {
      pushUrl = '/users/' + (name ? name.toLowerCase() : 'null') + '/' + (privilege ? privilege : 'null') + '/' + (status ? status : 'null') + '/' + (showroom ? showroom.toLowerCase() : 'null') + '/' + number
    }
    window.history.pushState('Team Member', 'Team Member', pushUrl);

    let rowsNew = this.state.backupData;

    if (name) {
      rowsNew = rowsNew.filter(row => {
        return row.name.toLowerCase().match(name.toLowerCase())
      });
    }

    if (privilege) {
      rowsNew = rowsNew.filter(row => {
        return row.privilege.toLowerCase().match(privilege.toLowerCase())
      });
    }

    if (status) {
      rowsNew = rowsNew.filter(row => {
        return row.status.toLowerCase() ===  status.toLowerCase()
      });
    }

    if (showroom) {
      rowsNew = rowsNew.filter(row => {
        return row.showroom_id == showroom
      });
    }

    this.setState({
      usersData: Array.from(rowsNew),
      usersFilteredData: Array.from(rowsNew)
    }, () => {
      this.handlePaging();
    });
  }

  handleChange(event) {
    //let searchData = this.state.backupData;
    const target = event.target;
    const name = target.id === 'name' ? target.value : this.state.nameFilter;
    const privilege = target.id === 'privilege' ? target.value : this.state.privilegeFilter;
    const status = target.id === 'status' ? target.value : this.state.statusFilter;
    const showroom = target.id === 'showroom' ? target.value : this.state.showroomFilter;

    this.setState({
      nameFilter: name,
      privilegeFilter: privilege,
      statusFilter: status,
      showroomFilter: showroom,
      //pushUrl:pushUrl,
    }, () => {
      this.handleFilter();
    });
  }

  handleSort(event) {
    let data = this.state.usersData;
    let nameSort = '';
    let emailSort = '';
    let phoneSort = '';
    let privilegeSort = '';
    let statusSort = '';
    let createdDateSort = '';
    let customersSort = '';
    let gallerySort = '';
    let salesManagerSort = '';

    if (event === 'name') {
      if (this.state.nameSort === '' || this.state.nameSort === 'desc') {
        data = data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
        nameSort = 'asc';
      } else {
        data = data.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? 1 : -1)
        nameSort = 'desc';
      }
    }

    if (event === 'email') {
      if (this.state.emailSort === '' || this.state.emailSort === 'desc') {
        data = data.sort((a, b) => (a.email.toLowerCase() > b.email.toLowerCase()) ? 1 : -1)
        emailSort = 'asc';
      } else {
        data = data.sort((a, b) => (a.email.toLowerCase() < b.email.toLowerCase()) ? 1 : -1)
        emailSort = 'desc';
      }
    }

    if (event === 'phone') {
      if (this.state.phoneSort === '' || this.state.phoneSort === 'desc') {
        data = data.sort((a, b) => (a.phone > b.phone) ? 1 : -1)
        phoneSort = 'asc';
      } else {
        data = data.sort((a, b) => (a.phone < b.phone) ? 1 : -1)
        phoneSort = 'desc';
      }
    }

    if (event === 'privilege') {
      if (this.state.privilegeSort === '' || this.state.privilegeSort === 'desc') {
        data = data.sort((a, b) => a.privilege > b.privilege ? 1 : -1)
        privilegeSort = 'asc';
      } else {
        data = data.sort((a, b) => a.privilege < b.privilege ? 1 : -1)
        privilegeSort = 'desc';
      }
    }
    if (event === 'gallery') {
      if (this.state.gallerySort === '' || this.state.gallerySort === 'desc') {
        data = data.sort((a, b) => a.showroom_name > b.showroom_name ? 1 : -1)
        gallerySort = 'asc';
      } else {
        data = data.sort((a, b) => a.showroom_name < b.showroom_name ? 1 : -1)
        gallerySort = 'desc';
      }
    }
    if (event === 'salesManager') {
      if (this.state.salesManagerSort === '' || this.state.salesManagerSort === 'desc') {
        data = data.sort((a, b) => a.sales_manager_name > b.sales_manager_name ? 1 : -1)
        salesManagerSort = 'asc';
      } else {
        data = data.sort((a, b) => a.sales_manager_name < b.sales_manager_name ? 1 : -1)
        salesManagerSort = 'desc';
      }
    }

    if (event === 'status') {
      if (this.state.statusSort === '' || this.state.statusSort === 'desc') {
        data = data.sort((a, b) => a.status > b.status ? 1 : -1)
        statusSort = 'asc';
      } else {
        data = data.sort((a, b) => a.status < b.status ? 1 : -1)
        statusSort = 'desc';
      }
    }

    if (event === 'createdDate') {
      if (this.state.createdDateSort === '' || this.state.createdDateSort === 'desc') {
        data = data.sort((a, b) => new Date(a.created_date) > new Date(b.created_date) ? 1 : -1)
        createdDateSort = 'asc';
      } else {
        data = data.sort((a, b) => new Date(a.created_date) < new Date(b.created_date) ? 1 : -1)
        createdDateSort = 'desc';
      }
    }

    if (event === 'customers') {
      if (this.state.customersSort === '' || this.state.customersSort === 'desc') {
        data = data.sort((a, b) => a.total_customer > b.total_customer ? 1 : -1)
        customersSort = 'asc';
      } else {
        data = data.sort((a, b) => a.total_customer < b.total_customer ? 1 : -1)
        customersSort = 'desc';
      }
    }

    if (data) {
      this.setState({
        nameSort: nameSort,
        emailSort: emailSort,
        phoneSort: phoneSort,
        privilegeSort: privilegeSort,
        statusSort: statusSort,
        createdDateSort: createdDateSort,
        customersSort: customersSort,
        gallerySort: gallerySort,
        salesManagerSort: salesManagerSort,
        usersFilteredData: Array.from(data)
      }, () => {
        this.handlePaging();
      });
    }
  }


  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleCheckAllChange(event) {
    var checkBoxAll = [];
    var inputs = '';
    var i = 0;
    if (document.getElementById('checkAll').checked) {
      inputs = document.querySelectorAll("input[type='checkbox']");
      for (i = 0; i < inputs.length; i++) {
        if (inputs[i].value !== 'on') {
          checkBoxAll.push(inputs[i].value);
        }
        inputs[i].checked = true;
      }
    } else {
      inputs = document.querySelectorAll("input[type='checkbox']");
      for (i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
      }
    }
    this.setState({ checkBoxId: Array.from(checkBoxAll) });

  }

  handleCheckBoxChange(event) {
    const target = event.target;
    //const email = target.id === 'email' ? target.value : this.state.email;

    var checkBoxId = this.state.checkBoxId;
    if (document.getElementById(target.id).checked) {
      checkBoxId.push(target.value);
    } else {
      document.getElementById('checkAll').checked = false;
      checkBoxId = checkBoxId.filter(function (value, index, arr) {
        return value !== target.value;
      });
    }
    this.setState({ checkBoxId: Array.from(checkBoxId) });
  }

  handleDeleteMultiple(event) {
    if (this.state.checkBoxId.length > 0) {
      this.setState({ showModal: true });
    } else {
      this.createErrorMessage('Please Select One Team Member for Delete', 'errorName', 'errorMessage');
    }
  }

  handleDelete(id) {
    var inputs = document.querySelectorAll(".popover");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove('block');
      inputs[i].classList.add('hidden');
    }

    this.setState({ showModal: true, checkBoxId: [id] });
  }

  handleConfirmDelete(id) {
    this.setState({ showModal: false });
    axios.post(`${process.env.REACT_APP_API_URL}user/delete/checkValid`, this.state.checkBoxId,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(err => {
        this.createErrorMessage('One of the selected accounts cannot be deleted. Your request has been cancelled', 'errorName', 'errorMessage');
        throw err;
      })
      .then(res => {
        axios.post(`${process.env.REACT_APP_API_URL}user/delete/`, this.state.checkBoxId,
          {
            headers: {
              'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
            }
          })
          .catch(err => {
            throw err;
          })
          .then(res => {
            this.handleData();
          })
      })
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className='notifBar w-full bg-red'>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <div className="cursor-pointer" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)}>
          <i className="fa fa-times" />
        </div>
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  checkPopoverFilter() {
    this.setState({
      showDatePicker: false,
      showFilter: !this.state.showFilter
    }, () => {
      this.handleResize()
    })
  };

  handleResize() {
    var filter = document.getElementsByClassName('filter')
    let showMoreFilter = false;
    if (this.state.filterOffsetHeight > 100) {
      if (this.state.showFilter === true) {
        filter[0].style.height = (this.state.filterOffsetHeight) + 'px'
      } else {
        filter[0].style.height = '90px'
      }
      showMoreFilter = true
    }
    this.setState({ showMoreFilter: showMoreFilter });

  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (!this.state.urlStatus) {
      window.location.href = '/404'
    }

    if (!this.state.usersData && this.props.id !== 0) {
      return (<span> Data Not Available</span>)
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.errorMessage}
        </div>
        <div className="flex flex-wrap ">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0">

            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="block w-full  ">
                <div className="relative flex flex-col  min-w-0 break-words w-full pb-6 shadow-lg rounded bg-white">
                  <div className="block w-full min-h-screen-75">
                    <div className="w-full lg:w-10/12 xl:w-10/12 md:w-10/12 flex-grow flex-1">
                      <div className="float-right m-4">
                        {jsCookie.get('privilege') === 'Super Admin' ?
                          <a href="/users/edit/0" className="button-login-white text-black active:bg-blueGray-600 text-2xs font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                            Add Account
                          </a> : ''}
                      </div>
                    </div>
                    <div className="filter grid-2 w-full h-full rounded-tl rounded-tr bg-blueGray-100 items-center px-4"
                      style={{ height: `90px` }}
                    >
                      <div className="flex flex-wrap pb-4"
                        ref={(divElement) => { this.divElement = divElement }}>
                        <div className="relative my-2 mr-4 xs:w-full ">
                          <div className="text-blueGray-400 text-sm">Name</div>
                          <div className="relative">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Filter by Name"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.nameFilter}
                              onChange={this.handleChange}
                            />
                            <span className="h-full leading-snug font-normal absolute right-0 inline-flex items-center text-blueGray-300 text-base mr-2">
                              {!this.state.nameFilled ? <i className="fas fa-user"></i> : <i className="cursor-pointer fas fa-times" onClick={() => this.resetField('name')}></i>}
                            </span>
                          </div>
                        </div>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <div className="text-blueGray-400 text-sm">Role</div>
                          <select
                            type="text"
                            id="privilege"
                            name="privilege"
                            className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.privilegeFilter}
                            onChange={this.handleChange}
                            style={{ width: `${130}px` }}
                          >
                            {this.state.privilegeOption.map((e, key) => {
                              return <option key={key} value={e.value} >{e.text}</option>;
                            })}
                          </select>
                        </div>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <div className="text-blueGray-400 text-sm">Status</div>
                          <select
                            type="text"
                            id="status"
                            name="status"
                            className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.statusFilter}
                            onChange={this.handleChange}
                            style={{ width: `${130}px` }}
                          >
                            {this.state.statusOption.map((e, key) => {
                              return <option key={key} value={e.value} >{e.text}</option>;
                            })}
                          </select>
                        </div>
                        <div className="relative my-2  mr-4 xs:w-full">
                          <div className="text-blueGray-400 text-sm">Gallery</div>
                          <select
                            type="text"
                            id="showroom"
                            name="showroom"
                            className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.showroomFilter}
                            style={{ width: `${(8 * (this.state.showroomFilter ? this.state.showroomFilter.length : 0)) + 150}px` }}
                            onChange={this.handleChange}
                          >
                            <option value="">All</option>
                            {this.state.showroomOption.map((e, key) => {
                              return <option key={key} value={e.id}>{e.name}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="lg:py-4 lg:flex align-self-start pt-2 lg:pt-4">
                        {this.state.showMoreFilter ?
                          <button
                            className="nofocus rounded text-black text-sm pt-6"
                            href="#"
                            id="filterBtn"
                            onClick={(e) => {
                              e.preventDefault();
                              this.checkPopoverFilter()
                            }}
                          >
                            {!this.state.showFilter ? <>More Filter <i className="fas fa-angle-down ml-1"></i></> : <>Less Filter<i className="fas fa-angle-up ml-2"></i></>}
                          </button> : ''}
                        <button
                          className="nofocus rounded text-black text-sm pt-6 pl-4 "
                          onClick={this.handleResetFilter}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    {/* Projects table */}
                    <div className={"customer-table relative overflow-x-auto bg-white "}>
                      <table className={"items-center bg-white w-full bg-transparent border-collapse overflow-x-scroll"}>
                        <thead>
                          <tr className="border border-l-0 border-r-0 border-blueGray-200">
                            <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500 pl-4"}
                              style={{ width: `${30}px` }}
                            >
                              <input
                                name="checkAll"
                                id="checkAll"
                                type="checkbox"
                                className="rounded nofocus"
                                onChange={this.handleCheckAllChange} />
                            </th>
                            <th className={"px-2 cursor-pointer align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500 "}
                              onClick={() => this.handleSort('name')}
                            >
                              Name
                              <i className={`fas ${this.state.nameSort === 'desc' ? "fa-sort-down" : (this.state.nameSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th>
                            <th className={"px-2 cursor-pointer align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                              onClick={() => this.handleSort('phone')}
                            >
                              Phone Number
                              <i className={`fas ${this.state.phoneSort === 'desc' ? "fa-sort-down" : (this.state.phoneSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                            </th>
                            <th className={"px-2 cursor-pointer align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                              onClick={() => this.handleSort('privilege')}
                            >
                              Role
                              <i className={`fas ${this.state.privilegeSort === 'desc' ? "fa-sort-down" : (this.state.privilegeSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                            </th>
                            <th className={"px-2 cursor-pointer align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                              onClick={() => this.handleSort('gallery')}
                            >
                              Gallery
                              <i className={`fas ${this.state.gallerySort === 'desc' ? "fa-sort-down" : (this.state.gallerySort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                            </th>
                            <th className={`${jsCookie.get('privilege') == 'Sales Manager' ? 'hidden' : ''} px-2 cursor-pointer align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500`}
                              onClick={() => this.handleSort('salesManager')}
                            >
                              Sales Manager
                              <i className={`fas ${this.state.salesManagerSort === 'desc' ? "fa-sort-down" : (this.state.salesManagerSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                            </th>
                            <th className={"px-2 cursor-pointer align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                              onClick={() => this.handleSort('status')}
                            >
                              Status
                              <i className={`fas ${this.state.statusSort === 'desc' ? "fa-sort-down" : (this.state.statusSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                            </th>
                            <th className={"px-2 cursor-pointer align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                              onClick={() => this.handleSort('createdDate')}
                            >
                              Created Date
                              <i className={`fas ${this.state.createdDateSort === 'desc' ? "fa-sort-down" : (this.state.createdDateSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                            </th>
                            <th className={"px-2 cursor-pointer align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                              onClick={() => this.handleSort('customers')}
                            >
                              Leads
                              <i className={`fas ${this.state.customersSort === 'desc' ? "fa-sort-down" : (this.state.customersSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              {/*<i className={`fas ${this.state.customersSort === 'desc'?"fa-sort-down":(this.state.customersSort === 'asc'?"fa-sort-up":"fa-sort")} mr-2 text-sm opacity-50 float-right`}></i>*/}
                            </th>
                            <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500 pr-4"}
                              style={{ width: `${30}px` }}
                            >
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isLoading ?
                            <tr key="notfound" className="border border-l-0 border-r-0 border-blueGray-200">
                              <td colSpan="7" className="align-middle text-sm text-center whitespace-nowrap py-4 pr-4">
                                <ClipLoader size={20} color="black" />
                              </td>
                            </tr> :
                            this.state.usersFilteredData.length > 0 ?
                              this.state.usersFilteredData.map((row) => (
                                <tr key={row.id} className={"border border-l-0 border-r-0 border-blueGray-200"}>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap px-4 py-4">
                                    <input
                                      name={`checked${row.id}`}
                                      id={`checked${row.id}`}
                                      type="checkbox"
                                      className="checkbox rounded nofocus"
                                      value={row.id}
                                      onChange={this.handleCheckBoxChange} />
                                  </td>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                                    <Link to={`/users/${row.id}`}>{row.name}</Link>
                                  </td>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                                    {row.phone}
                                  </td>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                                    {/*row.privilege=='Super Admin'?'Lexus Indonesia':row.privilege*/}
                                    {row.privilege}
                                  </td>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                                    {row.showroom_name ? row.showroom_name : '--'}
                                  </td>
                                  <td className={`${jsCookie.get('privilege') == 'Sales Manager' ? 'hidden' : ''} px-2 align-middle text-sm whitespace-nowrap py-4`}>
                                    {row.sales_manager_name ? row.sales_manager_name : '--'}
                                  </td>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                                    {row.status}
                                  </td>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                                    {DateFormat(row.created_date, 'Mdy')}
                                  </td>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                                    {row.total_customer}
                                  </td>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap py-4 pr-4">
                                    <div
                                      className="cursor-pointer text-blueGray-500 py-1 px-3"
                                      href="#"
                                      id={`button${row.id}`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.checkPopover(row.id)
                                      }}
                                    >
                                      <i className="fas fa-ellipsis-v"></i>
                                    </div>
                                    <div
                                      id={`popup${row.id}`}
                                      className={
                                        "popover hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
                                      }
                                    >
                                      <a
                                        href={`/users/${row.id}`}
                                        className={
                                          "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                                        }
                                      >
                                        Detail
                                      </a>
                                      {jsCookie.get('privilege') === 'Super Admin' ?
                                        <a
                                          href={`/users/edit/${row.id}`}
                                          className={
                                            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                                          }
                                        >
                                          Edit
                                        </a> : ''}
                                      {jsCookie.get('privilege') === 'Super Admin' ?
                                        <div
                                          className={
                                            "cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleDelete(row.id)
                                          }}
                                        >
                                          Delete
                                        </div> : ''}
                                    </div>
                                  </td>
                                </tr>
                              ))
                              :
                              <tr key="notfound" className="border border-l-0 border-r-0 border-blueGray-200">
                                <td colSpan="7" className="align-middle text-sm text-center whitespace-nowrap py-4 pr-4">
                                  Data Not Available
                                </td>
                              </tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="py-4">
                    <nav className="block">
                    {jsCookie.get('privilege') === 'Super Admin' ?
                      <button
                        className="button-gold mx-4 text-white active:bg-blueGray-600 text-2xs tracking-wide01 uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={this.handleDeleteMultiple}
                      >
                        Delete
                      </button>:''}
                      <ul className="flex rounded list-none flex-wrap float-right mx-4">
                        <div className="mx-4 my-auto">
                          Showing {this.state.startShowing} {this.state.endShowing ? 'to ' + this.state.endShowing : ''} of {this.state.totalData}
                        </div>

                        {/*pagingTmp*/}
                        {this.state.pagingPrevTemplate}
                        {this.state.pagingTemplate}
                        {this.state.pagingNextTemplate}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto mx-auto">
                    <h6 className="text-center text-black font-bold mx-auto">Do you want to delete this data? </h6>
                  </div>
                  <div className="flex items-center justify-center pb-6 rounded-b text-center">
                    <button
                      className="w-5/12 button-gold text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      type="button"
                      onClick={this.handleConfirmDelete}
                    >
                      ok
                    </button>
                    <button
                      className="w-5/12 button-login text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      type="button"
                      onClick={this.handleCloseModal}
                    >
                      cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
}

