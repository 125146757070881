import React from 'react'
import { Redirect   } from 'react-router-dom'
import jsCookie from 'js-cookie'

const Logout = () => {
  jsCookie.remove('token');
  jsCookie.remove('token2');
  jsCookie.remove('username');
  jsCookie.remove('privilege');
  return <Redirect to="/login" />;
}

export default Logout;
