import React from "react";
import { Redirect, Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import axios from 'axios';
import md5 from 'md5';
//import jsCookie from 'js-cookie'
import auth from 'global/auth.js';

import { Validation } from "global/custom.js";

import 'assets/styles/custom.css';
/*eslint-disable eqeqeq*/

export default function Login() {
  if (auth()) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <main>
        <section className="flex items-center w-full h-full min-h-screen">
          <div
            className="absolute top-0 w-full h-full animated-background"
          ></div>
          <div className="container mx-auto px-4 h-full z-100">
            <div className="flex justify-center h-full">
              <img
                alt="..."
                src={require("assets/img/logo/logo-white.svg").default}
                className="shadow-xl rounded-full h-auto align-middle border-none max-w-150-px"
              />
            </div>
            <div className="flex content-center items-center justify-center h-full">
              <ResetPasswordForm />
            </div>
          </div>
          {/*<FooterSmall absolute />*/}
        </section>
      </main>
    </>
  );
}


class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      login: false,
      password: '',
      password2: '',
      isSubmitted: false,
      redirect: false,
      errorMessage: '',
      successMessage: '',
      urlStatus: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleChangeValidation = this.handleChangeValidation.bind(this);

  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const getQuery = queryParams.get('code');
    if (getQuery) {
      var valid_date = getQuery.substr((getQuery.length - 9), (getQuery.length - 6)).substr(0, 6);
      /*
      var id = getQuery.substr(getQuery.length - 3); 
      var code = getQuery.substr(0,getQuery.length - 9); 
  */

      let newDate = new Date()
      let date = newDate.getDate();
      let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
      let year = newDate.getFullYear();
      let full_date = date + '' + month + '' + year.toString().substr(-2);
      var urlStatus = true;

      if (parseInt(valid_date) >= parseInt(full_date)) {
        urlStatus = true;
      } else {
        urlStatus = false;
      }
      if (urlStatus) {
        axios.get(`${process.env.REACT_APP_API_URL}user?code=${getQuery}&page=resetpassword`, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
          }
        })
          .catch(error => {
            this.setState({ urlStatus: false });
            throw error;
          })
          .then(res => {
            let data = res.data;
            if (data !== 'Data Empty') {
            } else {
              this.setState({ urlStatus: false });
            }
          })
      }

      //this.state = { id: id, code: code, urlStatus: urlStatus};
      this.setState({ query: getQuery, urlStatus: urlStatus });
    }

  }

  handleRedirect(event) {
    this.setState({
      redirect: true,
    });
  }

  handleChange(event) {
    const target = event.target;
    const password = target.id === 'password' ? target.value : this.state.password;
    const password2 = target.id === 'password2' ? target.value : this.state.password2;

    this.setState({
      password: password,
      password2: password2,
    });
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className='notifBar bg-red'>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <div className="cursor-pointer" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)}>
          <i className="fa fa-times" />
        </div>
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  handleChangeValidation(event) {
    const target = event.target;

    if (target.id === 'password') {
      this.handleValidation('newpassword', this.state.password, 'passwordError', 'passwordErrorMessage', 'Password', this.state.password2);
    }
    if (target.id === 'password2') {
      this.handleValidation('newRepeatePassword', this.state.password2, 'repeatePasswordError', 'repeatePasswordErrorMessage', 'Password', this.state.password);
    }
  }

  handleValidation(type, value, errorName, errorMessageName, fieldName, value2) {
    var valid = Validation(type, value, errorName, errorMessageName, fieldName, value2);
    if (valid.status == 404) {
      this.createErrorMessage(valid.msg, errorName, errorMessageName);
      return false;
    } else {
      this.setState({
        [errorName]: '',
        [errorMessageName]: ''
      });
      return true;
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ isSubmitted: true });
    var validate = 1;

    if (!this.handleValidation('text', this.state.password, 'passwordError', 'passwordErrorMessage', 'Password')) {
      validate = 0;
    }
    if (this.state.password && !this.state.password2) {
      if (!this.handleValidation('newpassword', this.state.password2, 'repeatePasswordError', 'repeatePasswordErrorMessage', 'Password', this.state.password)) {
        validate = 0;
      }
    }

    if (validate == 0) {
      this.setState({ isSubmitted: false });
      return false;
    }

    const inputData = {
      password: md5(this.state.password),
      code: this.state.query
    };
    axios.post(`${process.env.REACT_APP_API_URL}resetpassword`, { inputData },
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.setState({ isSubmitted: false })
        if (error.response.status == 401) {
          this.setState({
            errorMessage:
              <><div id='alerts' className='notifBar w-full bg-red'>
                <div id="ofBar-content" className="notifBar-content">
                  <b>{error.response.data}</b>
                </div>
                <div id="ofBar-right" className="notifBar-right">
                  <div className="cursor-pointer" id="close-bar" onClick={e => this.handleCloseAlert('errorMessage')}>
                    <i className="fa fa-times" />
                  </div>
                </div>
              </div></>
          });
        }
        throw error;
      })
      .then(result => {
        this.setState({ showModal: true });
      }
      )
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to='/login' />;
    }
    if (!this.state.urlStatus) {
      return <Redirect to='/404' />;
    }
    return (
      <>
        <div id="notificationArea" className="notificationArea-login w-full lg:w-4/12">
          {this.state.passwordErrorMessage}
          {this.state.repeatePasswordErrorMessage}
          {this.state.errorMessage}
        </div>
        <div className="lg:w-4/12 md:w-6/12  px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-login-panel border-0">
            <div className="rounded-t  flex-auto px-8 mt-5 lg:px-10 py-10 pt-0">

              <div className="text-blueGray-400 text-center mb-5 font-bold mt-5">
                <h6 className="text-white text-lg font-bold">
                  Reset Password
                </h6>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="New Password"
                    id="password"
                    value={this.state.password} onChange={this.handleChange} onBlur={this.handleChangeValidation}
                  />
                  {this.state.passwordError ?
                    <>
                      <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                        <i className="fas fa-exclamation"></i>
                      </span>
                    </>
                    : ''}
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Repeat New Password
                  </label>
                  <input
                    type="password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Repeat New Password"
                    id="password2"
                    value={this.state.password2} onChange={this.handleChange} onBlur={this.handleChangeValidation}
                  />
                  {this.state.repeatePasswordError ?
                    <>
                      <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                        <i className="fas fa-exclamation"></i>
                      </span>
                    </>
                    : ''}
                </div>

                <div className="text-center mt-6">
                  {this.state.isSubmitted ? <button
                    className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  ><ClipLoader size={15} color="white" /></button> :
                    <button
                      className="button-login text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Reset Password
                    </button>}
                </div>
                <Link
                  to="../login"
                  className="text-gold"
                >
                  <small>Back</small>
                </Link>
              </form>
            </div>
          </div>
        </div>

        {this.state.showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto mx-auto">
                    <h6 className="text-center text-black font-bold mx-auto">Password successfully reset </h6>
                    <p className="mt-4 text-blueGray-500 text-sm text-center leading-relaxed">
                      Please login again with your new password
                    </p>
                  </div>
                  <div className="flex items-center justify-center pb-6 rounded-b text-center">
                    <button
                      className="w-10/12 button-gold text-white font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      type="button"
                      onClick={this.handleRedirect}
                    >
                      ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
}