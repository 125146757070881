import React from "react";
import { Redirect, Link   } from 'react-router-dom'
import axios from 'axios';
// components
import jsCookie from 'js-cookie';
import auth from 'global/auth.js';

import readXlsxFile from 'read-excel-file'
import ClipLoader from "react-spinners/ClipLoader";

import { Validation } from "global/custom.js";
/*eslint-disable eqeqeq*/

export default function Odoo() {
  return (
    <OdooClass />
  )

}

class OdooClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      invalid_data:[],
      hideInvalidCustomer:true,
      duplicated_data:[],
      hideDuplicateCustomer:true,
      selectedFile:'',
      isSubmitted:false,
      fileName:'',
      uploadForm:true,
      invalid_customer:0,
      duplicated_customer:0,
      new_customer:0,
      total_customer:0,
      total_enquiry:0,
      privilege:jsCookie.get('privilege'),
      showConfirmation:false,
      confirmCancel:false,
      showOverwriteMsg:false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOverwrite = this.handleOverwrite.bind(this);
    this.handleTabs = this.handleTabs.bind(this);
    this.handleShowConfirmation = this.handleShowConfirmation.bind(this);
    this.handleConfirmCancel = this.handleConfirmCancel.bind(this);
  } 
  
  componentDidMount() {
    var url = `${process.env.REACT_APP_API_URL}odoo`
    axios.get(url,
    {
      headers: { 
        'Access-Control-Allow-Origin' : '*',
        'Authorization': 'Bearer '+process.env.REACT_APP_ACCESS_TOKEN
      }
    })
    .catch(error =>{ 
      throw error;
    })
    .then(res => {
      let data = res.data;
      if(data !== 'Data Empty'){
        this.setState({
          uploadForm:false,
          invalid_data:data.invalid_data,
          duplicated_data:data.duplicate_data,
          invalid_customer:data.invalid_customer,
          duplicated_customer:data.duplicate_customer,
          new_customer:data.new_customer,
          total_customer:data.total_customer,
          total_enquiry:data.total_enquiry,
        });
      }
    })
  }
  handleTabs(name){
    if(name === 'invalid'){
      this.setState({
        hideInvalidCustomer:!this.state.hideInvalidCustomer,
        hideDuplicateCustomer:true,
      });
    }else if(name === 'duplicate'){
      this.setState({
        hideInvalidCustomer:true,
        hideDuplicateCustomer:!this.state.hideDuplicateCustomer,
      });
    }
  }

createErrorMessage(name,errMsg,errMsgName){
  let msg = <><div id='alerts' className='notifBar w-full bg-red'>
    <div id="ofBar-content" className="notifBar-content">
      <b>{name}</b>
    </div>
    <div id="ofBar-right" className="notifBar-right">
      <i className="fa fa-times" id="close-bar" onClick={e=>this.handleCloseAlert(errMsgName)}/>
    </div>
  </div></>
    this.setState({
      [errMsg]:true,
      [errMsgName]:msg
    });
  }
  
  handleValidation(type,value,errorName, errorMessageName, fieldName){
    var valid = Validation(type,value,errorName,errorMessageName,fieldName);
    if(valid.status == 404){
      this.createErrorMessage(valid.msg, errorName,errorMessageName);
      return false;
    }else{
      this.setState({
        [errorName]:'',
        [errorMessageName]:''
      });
      return true;
    }
  }

  handleChange(event){
    this.setState({ selectedFile: event.target.files[0] });
  }

  handleSubmit(event){
    event.preventDefault();
    this.setState({isSubmitted:true});
    
    var validate = 1;
    
    if(!this.handleValidation('xlsx',this.state.selectedFile,'fileError','fileErrorMessage','file')){
      validate = 0;
    }

    if(validate == 0){
      this.setState({isSubmitted:false});
      return false;
    }

      readXlsxFile(this.state.selectedFile).then((rows) => {
        // `rows` is an array of rows
        // each row being an array of cells.
        var results = {
          data: rows.slice(1,rows.length),
          user_id: jsCookie.get('uid'),
          platform: 'Excel',
        }
        axios.post(`${process.env.REACT_APP_API_URL}upload`,results,
          {
            headers: { 
              'Access-Control-Allow-Origin' : '*',
              'Authorization': 'Bearer '+process.env.REACT_APP_ACCESS_TOKEN
            }
          })
          .catch(error =>{ 
            throw error;
          })
          .then(res => {
            let data = res.data;

            this.setState({ 
              uploadForm: false,
              fileName: this.state.selectedFile.name,
              invalid_data:data.invalid_data,
              duplicated_data:data.duplicate_data,
              invalid_customer:data.invalid_customer,
              duplicated_customer:data.duplicate_customer,
              new_customer:data.new_customer,
              total_customer:data.total_customer,
              total_enquiry:data.total_enquiry,
            });
          })
      })
  }
  handleOverwrite(e){
    e.preventDefault();
    if(this.state.duplicated_data){
      axios.post(`${process.env.REACT_APP_API_URL}customers`,this.state.duplicated_data,
      {
        headers: { 
          'Access-Control-Allow-Origin' : '*',
          'Authorization': 'Bearer '+process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error =>{ 
        throw error;
      })
      .then(res => {
        this.setState({ 
          showOverwriteMsg: true,
        })
      })
    }
  }
  handleShowConfirmation(event){
    this.setState({ 
      showConfirmation: event,
    })
  }
  handleConfirmCancel(){
    this.setState({ 
      confirmCancel: true,
    })
  }



  render() {
    if(!auth()){
      return <Redirect to="/login" />;
    }
    if(this.state.confirmCancel){
      return <Redirect to="/leads" />;
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 z-100">
          {this.state.fileErrorMessage}
        </div>
          <div
            className="w-full lg:w-6/12 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mx-auto"
          >
            <div className="relative w-full mx-auto max-w-sm">
              <div>
                <img 
                  src={require("assets/img/logo/logo-white.svg").default}
                  alt="Lexus Logo"
                  className="relative border-none max-w-150-px  mx-auto"
                />
              </div>
              <div className="border-0 rounded shadow-lg py-4 relative flex flex-col w-full bg-blueGray-200 outline-none focus:outline-none">
          {this.state.uploadForm? (
                <>
                <div className="w-full relative p-6 flex-auto mx-auto items-center">
                  <h6 className="w-full text-center text-black font-bold mx-auto uppercase">Sync From Odoo</h6>
                  <p className="mt-2 text-gray text-sm text-center leading-relaxed">
                    Please wait until the process finished.
                  </p>
                </div>
                <div className="flex  justify-center pb-6 rounded-b px-4">
                  <form>
                    
                  <div className="w-full">
                      <div className="relative w-full mb-3">
                        <ClipLoader size={30} color="rgba(190, 157, 115)" />
                      </div>
                    </div>
                  </form>
                </div>
                </>):(
                <>
                <div className="w-full relative p-6 flex-auto mx-auto items-center">
                  <h6 className="w-full text-center text-black font-bold mx-auto uppercase">Sync From Odoo</h6>
                </div>
                <div className="w-full relative   justify-center pb-6 rounded-b px-12">
                  <form>
                  <div className="w-full">
                      <div 
                        className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between"
                        onClick={e=>this.handleTabs('invalid')}
                      >
                        <label
                          className="block uppercase text-black text-xs font-bold my-2 text-left"
                        >
                          Invalid Leads
                        </label>
                        <span className="">{this.state.invalid_customer}</span>
                      </div>
                      {/*!this.state.hideInvalidCustomer?
                      <>
                        <table className="block overflow-x-auto">
                          <thead>
                            <th className="text-xs">First Name</th>
                            <th className="text-xs">Last Name</th>
                            <th className="text-xs">Email</th>
                            <th className="text-xs">Phone</th>
                          </thead>
                          {this.state.invalid_data.map((e, key) => {
                            return (
                              <tr>
                                <td className="text-xs">{e.first_name}</td>
                                <td className="text-xs">{e.last_name}</td>
                                <td className="text-xs">{e.email}</td>
                                <td className="text-xs">{e.phone}</td>
                              </tr>
                            );
                          })}
                        </table>
                        </>:''*/}
                    </div>
                      
                  {/*<div className="w-full">
                      <div 
                        className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between"
                        onClick={e=>this.handleTabs('duplicate')}
                      >
                        <label
                          className="block uppercase text-black text-xs font-bold my-2 text-left"
                        >
                          Duplicate Leads
                        </label>
                        <span className="">{this.state.duplicated_customer}</span>
                      </div>
                      {!this.state.hideDuplicateCustomer?
                      <>
                        <table className="block overflow-x-auto">
                          <thead>
                            <th className="text-xs">First Name</th>
                            <th className="text-xs">Last Name</th>
                            <th className="text-xs">Email</th>
                            <th className="text-xs">Phone</th>
                          </thead>
                          {this.state.duplicated_data.map((e, key) => {
                            return (
                              <tr>
                                <td className="text-xs">{e.first_name}</td>
                                <td className="text-xs">{e.last_name}</td>
                                <td className="text-xs">{e.email}</td>
                                <td className="text-xs">{e.phone}</td>
                              </tr>
                            );
                          })}
                        </table>
                        </>:''}
                 
                        </div>*/}
                  <div className="w-full">
                      <div className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between">
                        <label
                          className="block uppercase text-black text-xs font-bold my-2 text-left"
                        >
                          New Leads
                        </label>
                        <span className="">{this.state.new_customer}</span>
                      </div>
                    </div>
                  {/*<div className="w-full">
                    <div className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between">
                      <label
                        className="block uppercase text-black text-xs font-bold my-2 text-left"
                      >
                        Total Leads
                      </label>
                        <span className="">{this.state.total_customer}</span>
                    </div>
                  </div>
                  {/*<div className="w-full">
                    <div className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between">
                      <label
                        className="block uppercase text-black text-xs font-bold my-2 text-left"
                      >
                        Total Enquiry
                      </label>
                        <span className="">{this.state.total_enquiry}</span>
                    </div>
                  </div>*/}
                  
                  <div className="flex flex-wrap pt-4">
                    <div className="w-full">
                      <div className="relative w-full items-center text-center">
                        <Link
                          type="button"
                          to="/leads"
                          className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          onClick={e=>this.handleShowConfirmation(true)}
                        >
                          Ok
                        </Link>
                        {/*{this.state.isSubmittedSf ? 
                          <button
                            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                          ><ClipLoader size={20} color="white" /></button> : 
                          <button
                            className={`${this.state.disabledSf?'disabled':''}  button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                            disabled={this.state.disabledSf?'disabled':''} 
                            onClick={this.handleOverwrite}
                          >
                            Overwrite Leads
                          </button>
                        }
                        <button
                          type="button"
                          className="button-login text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          onClick={e=>this.handleShowConfirmation(true)}
                        >
                          Don't Overwrite
                        </button>*/}
                      </div>
                    </div>
                  </div>
                  </form>
                </div>
                </>
                )}
                </div>
                </div>
          </div>
          <div className="fixed inset-0 z-40  bg-login"></div>

          {this.state.showConfirmation?
          <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="relative p-6 flex-auto mx-auto">
                  <h6 className="text-center text-black font-bold mx-auto">Discard Change? </h6>
                  <p className="mt-2 text-gray text-sm text-center leading-relaxed">
                    Are you sure want to discard this process? Your submited data for this process will be lose
                  </p>
                </div>
                <div className="flex items-center justify-center pb-6 rounded-b text-center">
                  <button
                    className="w-5/12 button-gold text-white  font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                    type="button"
                    onClick={this.handleConfirmCancel}
                  >
                    DISCARD PROCESS
                  </button>
                  <button
                    className="w-5/12 button-login  text-white font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                    type="button"
                    onClick={e=>this.handleShowConfirmation(false)}
                  >
                    cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-50  bg-login opacity-50"></div>
          </>:''}
          {this.state.showOverwriteMsg?
          <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="relative p-6 flex-auto mx-auto">
                  <h6 className="text-center text-black font-bold mx-auto">Your Leads data has been overwrite. </h6>
                </div>
                <div className="flex items-center justify-center pb-6 rounded-b text-center">
                  <button
                    className="w-5/12 button-gold  text-white  font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                    type="button"
                    onClick={this.handleConfirmCancel}
                  >
                    ok
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-50  bg-login opacity-50"></div>
          </>:''}
        </>
      
       
      );
  }
}