import React from 'react'
import axios from 'axios';
import { Redirect, useParams, Link } from 'react-router-dom'
import auth from 'global/auth.js';
import jsCookie from 'js-cookie';
import ClipLoader from "react-spinners/ClipLoader";

import { Validation } from "global/custom.js";
/*eslint-disable eqeqeq*/

const User = ({ match }) => {
  var id = useParams();
  return (
    <>
      <div className="flex flex-wrap">
        <UserDetailClass id={id.id} />
      </div>
    </>
  )
}

class UserDetailClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      backPath: this.props.id > 0 ? '/users/' + this.props.id : '/users',
      bckupData: [],
      id: '',
      name: '',
      nameError: false,
      nameErrorMessage: false,
      email: '',
      emailError: false,
      emailErrorMessage: false,
      showroomId: '',
      showroomIdError: false,
      showroomIdErrorMessage: false,
      phone: '',
      phoneError: false,
      phoneErrorMessage: false,
      privilege: '',
      privilegeError: false,
      privilegeErrorMessage: false,
      status: '',
      statusError: false,
      statusErrorMessage: false,
      salesManager: '',
      salesManagerError: false,
      salesManagerErrorMessage: false,
      successMessage: '',
      errorMessage: '',
      urlStatus: true,
      privilegeOption: [
        { value: '', text: 'Please Select ' },
        //{ value: 'Super Admin', text: 'Lexus Indonesia' },
        { value: 'Super Admin', text: 'Super Admin' },
        { value: 'Sales Manager', text: 'Sales Manager' },
        { value: 'Brand Associate', text: 'Brand Associate' },
      ],
      statusOption: [
        { value: '', text: 'Please Select ' },
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'Inactive' },
        { value: 'blocked', text: 'Blocked' },
      ],
      salesManagerStatus: false,
      salesManagerListBackup: [],
      salesManagerList: [],
      showroomOption: [],
      redirectMain: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleChangeValidation = this.handleChangeValidation.bind(this);
  }

  getShowroomData() {
    var url = `${process.env.REACT_APP_API_URL}showroom`;
    if (jsCookie.get('showroom_id') !== 'null') {
      url = `${process.env.REACT_APP_API_URL}showroom/${jsCookie.get('showroom_id')}`;
    }
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          showroomOption: data
        }, () => {
          this.getUserDetail();
        });

      })
  }

  handleGetSalesManager() {
    axios.get(`${process.env.REACT_APP_API_URL}user`,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(err => {
        throw err;
      })
      .then(res => {
        var data = res.data;
        data = data.filter(row => {
          return row.privilege.toLowerCase().match('sales manager') && row.status == 'active'
        });

        let shownData = data.filter(row => {
          return row.showroom_id == this.state.showroomId
        });

        if (jsCookie.get('privilege') === 'Sales Manager') {
          shownData = shownData.filter(row => {
            return row.id == jsCookie.get('uid')
          });
        }

        this.setState({
          salesManagerList: shownData,
          salesManagerListBackup: data
        });
      });
  }

  getUserDetail() {
    if (jsCookie.get('privilege') != 'Super Admin' ) {
      this.setState({
        redirectMain: true,
      });
    }
    if (this.props.id > 0) {
      axios.get(`${process.env.REACT_APP_API_URL}user/${this.props.id}`,
        {
          headers: {
            'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
          }
        })
        .catch(err => {
          this.setState({ urlStatus: false });
          throw err;
        })
        .then(res => {
          const data = res.data[0];
          this.setState({ userData: data });
          if (data.privilege === 'Super Admin') {
            this.setState({ storeDisabled: 'disabled' })
          }
          else if (data.privilege === 'Sales Manager') {
            this.setState({ storeDisabled: '' })
          }
          else if (data.privilege === 'Brand Associate') {
            this.setState({
              storeDisabled: '',
              salesManagerStatus: true
            })
          }
          else {
            this.setState({
              storeDisabled: 'disabled',
              salesManagerStatus: false
            })
          }

          this.setState({
            bckupData: data,
            id: data.id,
            name: data.name,
            email: data.email ? data.email : '',
            phone: data.phone ? data.phone : '',
            showroomId: data.showroom_id ? data.showroom_id : '',
            privilege: data.privilege ? data.privilege : '',
            salesManager: data.manager_id ? data.manager_id : '',
            status: data.status ? data.status : '',
          }, () => {
            this.handleGetSalesManager();
          });
          //this.setState({ userData: Object.entries(data) });
        })
    } else if (this.props.id == 0) {
      if (jsCookie.get('privilege') == 'Sales Manager') {
        this.setState({
          redirectMain: true,
          //id: '',
          //name: '',
          //email: '',
          //showroomId: jsCookie.get('showroom_id'),
          //salesManager: jsCookie.get('uid'),
          //phone: '',
        }, () => {
          //this.handleGetSalesManager();
        });
      } else {
        this.setState({
          id: 0,
          name: '',
          email: '',
          showroomId: '',
          sales_manager_id: '',
          phone: '',
        }, () => {
          this.handleGetSalesManager();
        });
      }
    }
  }

  componentDidMount() {
    if (jsCookie.get('privilege') === 'Sales Manager') {
      this.setState({
        privilege: 'Brand Associate',
        privilegeOption: [
          { value: 'Brand Associate', text: 'Brand Associate' },
        ],
        statusOption: [
          { value: '', text: 'Please Select' },
          { value: 'active', text: 'Active' },
          { value: 'inactive', text: 'Inactive' },
        ],
        storeDisabled: '',
        salesManagerStatus: true
      });
    } else if (jsCookie.get('privilege') === 'Brand Associate') {
      this.setState({
        privilegeOption: [
        ],
        statusOption: [],
        storeDisabled: 'disabled'
      });
    } else {
      this.setState({
        storeDisabled: 'disabled'
      });
    }

    this.getShowroomData()
  }

  handleChange(event) {
    let target = event.target;
    let name = target.id === 'name' ? target.value : this.state.name;
    let email = target.id === 'email' ? target.value : this.state.email;
    let phone = target.id === 'phone' ? target.value : this.state.phone;
    let showroomId = target.id === 'showroom' ? target.value : this.state.showroomId;
    let privilege = target.id === 'privilege' ? target.value : this.state.privilege;
    let status = target.id === 'status' ? target.value : this.state.status;
    let salesManager = target.id === 'salesManager' ? target.value : this.state.salesManager;

    if (target.id === 'privilege') {
      if (privilege === 'Super Admin') {
        showroomId = '';
        this.setState({ storeDisabled: 'disabled' })
        this.setState({ salesManagerStatus: false })
      }
      else if (privilege === 'Sales Manager') {
        this.setState({ storeDisabled: '' })
        this.setState({ salesManagerStatus: false })
      }
      else if (privilege === 'Brand Associate') {
        var salesManagerList = this.state.salesManagerList
        salesManagerList = salesManagerList.filter(row => {
          if (showroomId) {
            return row.id != this.state.id && row.showroom_id == showroomId && row.status == 'active'
          } else {
            return row.id != this.state.id
          }
        });
        this.setState({
          storeDisabled: '',
          salesManagerStatus: true,
          salesManagerList: salesManagerList
        })

      }
      else if (privilege === '') {
        showroomId = '';
        this.setState({ storeDisabled: 'disabled' });
        this.setState({ salesManagerStatus: false });
      }
    }
    if (target.id === 'showroom') {
      let data = this.state.salesManagerListBackup;
      data = data.filter(row => {
        return row.showroom_id == showroomId && row.status == 'active'
      });

      salesManager = '';
      this.setState({
        salesManager: '',
        salesManagerList: data,
      });
    }
    this.setState({
      name: name,
      email: email,
      phone: phone,
      showroomId: showroomId,
      privilege: privilege,
      salesManager: salesManager,
      status: status,
    });
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(status, name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className={'notifBar ' + (status == '404' ? 'bg-red' : 'bg-emerald-400')}>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)} />
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  handleChangeValidation(event) {

    const target = event.target;
    if (target.id === 'name') {
      this.handleValidation('text', this.state.name, 'nameError', 'nameErrorMessage', 'Name')
    }

    if (target.id === 'email') {
      this.handleValidation('email', this.state.email, 'emailError', 'emailErrorMessage', 'Email')
    }

    if (target.id === 'phone') {
      this.handleValidation('text', this.state.phone, 'phoneError', 'phoneErrorMessage', 'Phone')
    }

    if (target.id === 'privilege') {
      this.handleValidation('select', this.state.privilege, 'privilegeError', 'privilegeErrorMessage', 'Privilege')
    }

    if (target.id === 'showroom') {
      this.handleValidation('select', this.state.showroomId, 'showroomError', 'showroomErrorMessage', 'Showroom')
      var data = this.state.salesManagerListBackup;
      data = data.filter(row => {
        return row.showroom_id == this.state.showroomId
      });
      this.setState({ salesManagerList: data });
    }

    if (target.id === 'salesManager') {
      this.handleValidation('select', this.state.salesManager, 'salesManagerError', 'salesManagerErrorMessage', 'Sales Manager')
    }

    if (target.id === 'status') {
      this.handleValidation('select', this.state.status, 'statusError', 'statusErrorMessage', 'Status')
    }

  }

  handleValidation(type, value, errorName, errorMessageName, fieldName) {
    var valid = Validation(type, value, errorName, errorMessageName, fieldName);
    if (valid.status == 404) {
      this.createErrorMessage(valid.status, valid.msg, errorName, errorMessageName);
      return false;
    } else {
      this.setState({
        [errorName]: '',
        [errorMessageName]: ''
      });
      return true;
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitted: true });
    var validate = 1;
    if (!this.handleValidation('text', this.state.name, 'nameError', 'nameErrorMessage', 'Name')) {
      validate = 0;
    }

    if (!this.handleValidation('email', this.state.email, 'emailError', 'emailErrorMessage', 'Email')) {
      validate = 0;
    }

    if (!this.handleValidation('text', this.state.phone, 'phoneError', 'phoneErrorMessage', 'Phone')) {
      validate = 0;
    }

    if (!this.handleValidation('select', this.state.privilege, 'privilegeError', 'privilegeErrorMessage', 'Privilege')) {
      validate = 0;
    }

    if (this.state.privilege !== 'Super Admin' && this.state.privilege !== '') {
      if (!this.handleValidation('select', this.state.showroomId, 'showroomError', 'showroomErrorMessage', 'Showroom')) {
        validate = 0;
      }
    }

    if (this.state.privilege === 'Brand Associate' && this.state.privilege !== '') {
      if (!this.handleValidation('select', this.state.salesManager, 'salesManagerError', 'salesManagerErrorMessage', 'Sales Manager')) {
        validate = 0;
      }
    }

    if (!this.handleValidation('select', this.state.status, 'statusError', 'statusErrorMessage', 'Status')) {
      validate = 0;
    }

    if ((this.state.privilege != this.state.bckupData.privilege || this.state.showroomId != this.state.bckupData.showroom_id || this.state.status != this.state.bckupData.status) && this.state.bckupData.privilege == 'Sales Manager' && this.state.status == 'inactive') {
      let checkData = {
        showroom_id: this.state.bckupData.showroom_id,
        privilege: 'Sales Manager',
        id: this.state.id
      }

      await axios.post(`${process.env.REACT_APP_API_URL}user/checkTotalSM`, checkData,
        {
          headers: {
            'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
          }
        })
        .then(res => {
          if (res.data == 0) {
            validate = 0;
            this.createErrorMessage('404', 'Please create another Sales Manager to automate assign', '', 'totalSMErrorMessage')
          }
        })
    }
    if (validate == 0) {
      this.setState({ isSubmitted: false });
      return false;
    }

    let inputData = [];
    if (this.state.privilege === 'Super Admin') {
      inputData = {
        id: this.state.id,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        privilege: this.state.privilege,
        status: this.state.status,
        uid: jsCookie.get('uid'),
      };
    } else if (this.state.privilege === 'Sales Manager') {
      inputData = {
        id: this.state.id,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        showroom_id: this.state.showroomId,
        privilege: this.state.privilege,
        status: this.state.status,
        uid: jsCookie.get('uid'),
      };
    } else if (this.state.privilege === 'Brand Associate') {
      inputData = {
        id: this.state.id,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        manager_id: this.state.salesManager,
        showroom_id: this.state.showroomId,
        privilege: this.state.privilege,
        status: this.state.status,
        uid: jsCookie.get('uid'),
      };
    }
    if (this.props.id == 0) {
      delete (inputData.id)
    }
    axios.post(`${process.env.REACT_APP_API_URL}user/${this.props.id}`, inputData,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ isSubmitted: false });
        this.createErrorMessage('404', 'Email has been used.', '', 'errorMessage');
      })
      .then(res => {
        if (res) {
          this.createErrorMessage('200', 'Data has been submitted.', '', 'successMessage');
          this.setState({
            isSubmitted: false
          });
          if (res.data.id) {
            this.setState({
              id: res.data.id,
            });
          }
          setTimeout(
            function () {
              this.setState({ redirectMain: true });
            }
              .bind(this),
            1000
          );
        }
      })
  }

  reset = () => {
    if (this.state.id > 0) {
      this.setState({
        name: this.state.userData.name,
        status: this.state.userData.status,
      });
    } else if (this.state.id == 0) {
      this.setState({
        name: '',
        status: '',
      });
    }
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (this.state.redirectMain) {
      return <Redirect to={"/users/"} />;
    }
    if (!this.state.urlStatus) {
      return <Redirect to='/404' />;
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.successMessage}
          {this.state.errorMessage}
          {this.state.nameErrorMessage}
          {this.state.emailErrorMessage}
          {this.state.phoneErrorMessage}
          {this.state.statusErrorMessage}
          {this.state.showroomErrorMessage}
          {this.state.privilegeErrorMessage}
          {this.state.showroomIdErrorMessage}
          {this.state.salesManagerErrorMessage}
          {this.state.totalSMErrorMessage}
        </div>
        <div className="w-full text-white z-30 pb-4 pr-4">
          <Link to={this.state.backPath}
            className="text-white cursor-pointer"><i className="fa fa-angle-left"></i> Back
          </Link>
        </div>
        <div className="w-full ">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Team Members Detail</h6>

              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-10">
              <form onSubmit={this.handleSubmit}>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={this.state.name}
                        onChange={this.handleChange}
                        onBlur={this.handleChangeValidation}
                      />
                      {this.state.nameError ?
                        <>
                          <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}

                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email Address
                      </label>
                      <input
                        type="texxt"
                        id="email"
                        name="email"
                        className={` border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        value={this.state.email}
                        onChange={this.handleChange}
                        onBlur={this.handleChangeValidation}
                      />
                      {this.state.emailError ?
                        <>
                          <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={this.state.phone}
                        onChange={this.handleChange}
                        onBlur={this.handleChangeValidation}
                      />
                      {this.state.phoneError ?
                        <>
                          <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Role
                      </label>
                      <select
                        type="text"
                        id="privilege"
                        name="privilege"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-15"
                        value={this.state.privilege}
                        onChange={this.handleChange}
                        onBlur={this.handleChangeValidation}
                      >
                        {this.state.privilegeOption.map((e, key) => {
                          return <option key={key} value={e.value}>{e.text}</option>;
                        })}
                      </select>
                      {this.state.privilegeError ?
                        <>
                          <span className="mr-5 z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0  py-3">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                    </div>
                  </div>
                  <div className={(!this.state.storeDisabled ? '' : 'hidden') + " w-full lg:w-6/12 px-4"}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Gallery
                      </label>
                      <select
                        type="text"
                        id="showroom"
                        name="showroom"
                        className={`${this.state.storeDisabled} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        disabled={this.state.storeDisabled}
                        value={this.state.showroomId}
                        onChange={this.handleChange}
                        onBlur={this.handleChangeValidation}
                      >
                        {jsCookie.get('privilege') == 'Sales Manager' ? '' :
                          <option value="">Please Select</option>}
                        {this.state.showroomOption.map((e, key) => {
                          return <option key={key} value={e.id}>{e.name}</option>;
                        })}
                      </select>
                      {this.state.showroomError ?
                        <>
                          <span className="mr-5 z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0  py-3">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                    </div>
                  </div>
                  <div className={(this.state.salesManagerStatus ? '' : 'hidden') + " w-full lg:w-6/12 px-4"}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Sales Manager
                      </label>
                      <select
                        type="text"
                        id="salesManager"
                        name="salesManager"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        disabled={this.state.salesManagerStatus ? '' : 'disabled'}
                        value={this.state.salesManager}
                        onChange={this.handleChange}
                        onBlur={this.handleChangeValidation}
                      >
                        {jsCookie.get('privilege') == 'Sales Manager' ? '' :
                          <option value="">Please Select</option>}
                        {this.state.salesManagerList.map((e, key) => {
                          return <option key={key} value={e.id}>{e.name}</option>;
                        })}
                      </select>
                      {this.state.salesManagerError ?
                        <>
                          <span className="mr-5 z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0  py-3">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Status
                      </label>
                      <select
                        type="text"
                        id="status"
                        name="status"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={this.state.status}
                        onChange={this.handleChange}
                        onBlur={this.handleChangeValidation}
                      >
                        {this.state.statusOption.map((e, key) => {
                          return <option key={key} value={e.value}>{e.text}</option>;
                        })}
                      </select>
                      {this.state.statusError ?
                        <>
                          <span className="mr-5 z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0  py-3">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                    </div>
                  </div>
                </div>


                <div className="text-right mt-6 mr-4">
                  {this.state.isSubmitted ? <button
                    className="button-gold text-white text-sm font-bold uppercase px-8 py-2 rounded "
                    type="button"
                  ><ClipLoader size={15} color="white" />
                  </button> :
                    <button
                      className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Save
                    </button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default User
