import React from "react";
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios';
// components
import jsCookie from 'js-cookie';
import auth from 'global/auth.js';

import readXlsxFile from 'read-excel-file'
import ClipLoader from "react-spinners/ClipLoader";

import { Validation } from "global/custom.js";
import { DateFormat } from "global/custom.js";
import { getBadge } from "global/data.js";

/*eslint-disable eqeqeq*/

export default function Uploader() {
  if (jsCookie.get('privilege') !== 'Uploader') {
    return <Redirect to="/404" />;
  }
  return (
    <UploaderClass />
  )

}

class UploaderClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hideInvalidCustomer: true,
      hideDuplicateCustomer: true,
      selectedFile: '',
      isSubmitted: false,
      fileName: '',
      uploadForm: true,
      privilege: jsCookie.get('privilege'),
      showConfirmation: false,
      showConfirmationTitle: 'Upload data success',
      showConfirmationText: 'Data successfully store to cdp and assigned to Sales Manager ',
      confirmCancel: false,
      showOverwriteMsg: false,
      enquiryHistoryShowDetail: false,

      invalid_enquiry: 0,
      invalid_enquiry_data: [],
      new_enquiry: 0,
      new_enquiry_data: [],
      duplicate_enquiry: 0,
      duplicate_enquiry_data: [],
      new_leads: 0,
      new_leads_data: [],
      duplicate_leads: 0,
      duplicate_leads_data: [],
      total_customer: 0,
      total_enquiry: 0,
      minimizeNewLeads: true,
      minimizeExistingLeads: true,
      minimizeNewEnquiry: true,
      minimizeExistingEnquiry: true,
      minimizeInvalidEnquiry: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOverwrite = this.handleOverwrite.bind(this);
    this.handleTabs = this.handleTabs.bind(this);
    this.handleShowConfirmation = this.handleShowConfirmation.bind(this);
    this.handleConfirmCancel = this.handleConfirmCancel.bind(this);
    this.handleChangeFieldEnquiryHistory = this.handleChangeFieldEnquiryHistory.bind(this);

    this.handleTable = this.handleTable.bind(this);

  }

  handleChangeFieldEnquiryHistory(event) {
    const target = event.target;
    const history_detail = target.id === 'history_detail' ? target.checked : this.state.enquiryHistoryShowDetail;
    this.setState({
      enquiryHistoryShowDetail: history_detail,
    });
  }

  handleTabs(name) {
    if (name === 'invalid') {
      this.setState({
        hideInvalidCustomer: !this.state.hideInvalidCustomer,
        hideDuplicateCustomer: true,
      });
    } else if (name === 'duplicate') {
      this.setState({
        hideInvalidCustomer: true,
        hideDuplicateCustomer: !this.state.hideDuplicateCustomer,
      });
    }
  }

  createErrorMessage(name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className='notifBar w-full bg-red'>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)} />
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  handleValidation(type, value, errorName, errorMessageName, fieldName) {
    var valid = Validation(type, value, errorName, errorMessageName, fieldName);
    if (valid.status == 404) {
      this.createErrorMessage(valid.msg, errorName, errorMessageName);
      return false;
    } else {
      this.setState({
        [errorName]: '',
        [errorMessageName]: ''
      });
      return true;
    }
  }

  handleChange(event) {
    this.setState({ selectedFile: event.target.files[0] });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitted: true });

    var validate = 1;

    if (!this.handleValidation('xlsx', this.state.selectedFile, 'fileError', 'fileErrorMessage', 'file')) {
      validate = 0;
    }

    if (validate == 0) {
      this.setState({ isSubmitted: false });
      return false;
    }

    readXlsxFile(this.state.selectedFile).then((rows) => {
      // `rows` is an array of rows
      // each row being an array of cells.
      rows = rows.filter((elem) => {
        //if(elem[0]==null && elem[1]==null && elem[2]==null && elem[3]==null && elem[4]==null && elem[5]==null && elem[6]!=null && elem[7]==null && elem[8]==null && elem[9]==null && elem[10]==null && elem[11]==null && elem[12]==null){
        if (elem[0] == null && elem[1] == null && elem[2] == null && elem[3] == null && elem[4] == null && elem[5] != null && elem[6] == null && elem[7] == null && elem[8] == null && elem[9] == null && elem[10] == null && elem[11] == null) {

        } else {
          return elem
        }
        //return elem[0]!=null
      })
      var results = {
        data: rows.slice(1, rows.length),
        user_id: jsCookie.get('uid'),
        filename: this.state.selectedFile.name,
        platform: 'Excel',
      }
      axios.post(`${process.env.REACT_APP_API_URL}upload`, results,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
          }
        })
        .catch(error => {
          throw error;
        })
        .then(res => {
          let data = res.data;
          this.setState({
            showConfirmation: true,
            showConfirmationTitle: data.invalid_enquiry > 0 ? 'An Excel sheet contains invalid/blank data.' : this.state.showConfirmationTitle,
            showConfirmationText: data.invalid_enquiry > 0 ? 'Please check the value and try again.' : this.state.showConfirmationText,
            uploadForm: false,
            fileName: this.state.selectedFile.name,
            invalid_enquiry: data.invalid_enquiry,
            invalid_enquiry_data: data.invalid_enquiry_data,
            new_enquiry: data.new_enquiry,
            new_enquiry_data: data.new_enquiry_data,
            duplicate_enquiry: data.duplicate_enquiry,
            duplicate_enquiry_data: data.duplicate_enquiry_data,
            new_leads: data.new_leads,
            new_leads_data: data.new_leads_data,
            duplicate_leads: data.duplicate_leads,
            duplicate_leads_data: data.duplicate_leads_data,
            total_customer: data.total_customer,
            total_enquiry: data.total_enquiry,
          });
        })
    })
  }
  handleOverwrite(e) {
    e.preventDefault();
    if (this.state.duplicated_data) {
      axios.post(`${process.env.REACT_APP_API_URL}customers`, this.state.duplicated_data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
          }
        })
        .catch(error => {
          throw error;
        })
        .then(res => {
          this.setState({
            showOverwriteMsg: true,
          })
        })
    }
  }

  handleShowConfirmation(event) {
    this.setState({
      showConfirmation: event,
    })
  }

  handleConfirmCancel() {
    this.setState({
      confirmCancel: true,
    })
  }

  handleTable(event) {
    if (event === 1) {
      this.setState({
        minimizeNewLeads: !this.state.minimizeNewLeads,
      })
    }
    if (event === 2) {
      this.setState({
        minimizeExistingLeads: !this.state.minimizeExistingLeads,
      })
    }
    if (event === 3) {
      this.setState({
        minimizeNewEnquiry: !this.state.minimizeNewEnquiry,
      })
    }
    if (event === 4) {
      this.setState({
        minimizeExistingEnquiry: !this.state.minimizeExistingEnquiry,
      })
    }
    if (event === 5) {
      this.setState({
        minimizeInvalidEnquiry: !this.state.minimizeInvalidEnquiry,
      })
    }
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (this.state.confirmCancel) {
      return <Redirect to="/uploader" />;
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 z-100">
          {this.state.fileErrorMessage}
        </div>
        <div
          className="w-full justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-full  ">
            <div className="border-0 rounded shadow-lg py-4 relative flex flex-col w-full  bg-blueGray-200 outline-none focus:outline-none">
              {this.state.uploadForm ? (
                <>
                  <div className="lg:flex  justify-center pb-6 rounded-b px-4">
                    <form>
                      <div className="w-full relative pt-6 pb-6 flex-auto mx-auto items-center">
                        <h6 className="w-full  text-black font-bold mx-auto uppercase">Upload File</h6>
                        <p className="mt-2 text-gray text-sm leading-relaxed">
                          Upload file with .xlsx type.
                        </p>
                      </div>
                      <div className="w-full lg:flex">
                        <div className="relative w-full mb-3 ">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Excel File
                          </label>
                          <input
                            type="file"
                            name="file"
                            id="file"
                            className="w-10/12 lg:w-full border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                            value={this.state.first_name}
                            onChange={this.handleChange}
                          />
                          {this.state.fileError ?
                            <>
                              <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                                <i className="fas fa-exclamation"></i>
                              </span>
                            </>
                            : ''}
                        </div>
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Template File : <Link to={require("assets/templateu.xlsx").default} className="text-gold" target="_blank" download>Download</Link>

                        </label>
                      </div>
                      <div className="flex flex-wrap pt-4">
                        <div className="w-full">
                          <div className="relative w-full">
                            {this.state.isSubmitted ?
                              <button
                                className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                              ><ClipLoader size={20} color="white" /></button> :
                              <button
                                className={`${this.state.disabledSf ? 'disabled' : ''}  button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                                disabled={this.state.disabledSf ? 'disabled' : ''}
                                onClick={this.handleSubmit}
                              >
                                Submit
                              </button>
                            }
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>) : (
                <>
                  <div className="w-full relative p-6 flex-auto mx-auto items-center">
                    <h6 className="w-full text-center text-black font-bold mx-auto uppercase">Upload File</h6>
                    <p className="mt-2 text-gray text-sm text-center leading-relaxed">
                      Some of data already exist
                    </p>
                  </div>
                  <div className="w-full relative   justify-center pb-6 rounded-b px-12">
                    <form>
                      <div className="w-full">
                        <div className="relative w-full mb-3 rounded border border-blueGray-500 items-center">
                          <label
                            className="block uppercase text-blueGray-500 text-xs font-bold my-2 text-center"
                            htmlFor="grid-password"
                          >
                            {this.state.fileName}
                          </label>
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between">
                          <label
                            className="block uppercase text-black text-xs font-bold my-2 text-left"
                          >
                            New Leads
                          </label>
                          <span className="">{this.state.new_leads}</span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between"
                        >
                          <label
                            className="block uppercase text-black text-xs font-bold my-2 text-left"
                          >
                            Existing Leads
                          </label>
                          <span className="">{this.state.duplicate_leads}</span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between"
                        >
                          <label
                            className="block uppercase text-black text-xs font-bold my-2 text-left"
                          >
                            New Enquiry
                          </label>
                          <span className="">{this.state.new_enquiry}</span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between"
                        >
                          <label
                            className="block uppercase text-black text-xs font-bold my-2 text-left"
                          >
                            Existing Enquiry
                          </label>
                          <span className="">{this.state.duplicate_enquiry}</span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between"
                        >
                          <label
                            className="block uppercase text-black text-xs font-bold my-2 text-left"
                          >
                            Invalid Enquiry
                          </label>
                          <span className="">{this.state.invalid_enquiry}</span>
                        </div>
                      </div>
                      {/*<div className="w-full">
                        <div className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between">
                          <label
                            className="block uppercase text-black text-xs font-bold my-2 text-left"
                          >
                            Total Leads
                          </label>
                          <span className="">{this.state.total_customer}</span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="flex w-full mb-3 border-b border-blueGray-500 items-center justify-between">
                          <label
                            className="block uppercase text-black text-xs font-bold my-2 text-left"
                          >
                            Total Enquiry
                          </label>
                          <span className="">{this.state.total_enquiry}</span>
                        </div>
                      </div>*/}

                      <div className="flex flex-wrap pt-4">
                        <div className="w-full">
                          <div className="relative w-full items-center text-center">
                            <a
                              href="/uploader"
                              className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            >
                              close
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>

            {/*new leads*/}
            {this.state.new_leads > 0 ?
              <div className="w-full">
                <div className="relative flex flex-col min-w-0 break-words w-full  mt-5 rounded-t rounded-b bg-white">
                  <div className="flex flex-wrap">
                    <div className="w-full">
                      <div className="bg-table-green mb-0 px-4 py-6 flex">
                        <div className="w-full lg:w-6/12 relative flex ">
                          <i className={"fa pr-4 " + (this.state.minimizeNewLeads ? 'fa-plus' : 'fa-minus')} id="close-bar" onClick={e => this.handleTable(1)} /> <h3 className="text-blueGray-700 text-xs font-bold uppercase">New Leads ({this.state.new_leads})</h3>
                        </div>
                      </div>
                      <div className={"relative flex flex-col min-w-0 w-full shadow-lg " + (this.state.minimizeNewLeads ? 'h-0' : 'h-auto')}>
                        <div className="block w-full overflow-x-auto">
                          <div className="relative flex flex-col  min-w-0 w-full shadow-lg bg-white rounded-b ">
                            <div className="block w-full overflow-x-auto rounded-b max-h-400">
                              <table className="relative items-center w-full overflow-x-scroll">
                                <thead>
                                  <tr className="border border-l-0 border-r-0 border-blueGray-200">
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      First Name
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Last Name
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Email
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Phone
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Province
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.new_leads_data.map((f, key) => {
                                    return (
                                      <tr key={f.id} className={(f.followup_status === 'No' ? 'bg-table-red' : null) + " border border-l-0 border-r-0 border-blueGray-200"}>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.first_name}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.last_name}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.email}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.phone}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          {f.province}
                                        </td>
                                      </tr>
                                    )
                                  })
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : <></>}
            {/*new leads*/}

            {/*duplicate Leads */}
            {this.state.duplicate_leads > 0 ?
              <div className="w-full">
                <div className="relative flex flex-col min-w-0 break-words w-full  mt-5 rounded-t rounded-b bg-white">
                  <div className="flex flex-wrap">
                    <div className="w-full">
                      <div className="bg-table-yellow mb-0 px-4 py-6 flex">
                        <div className="w-full lg:w-6/12 relative flex ">
                          <i className={"fa pr-4 " + (this.state.minimizeExistingLeads ? 'fa-plus' : 'fa-minus')} id="close-bar" onClick={e => this.handleTable(2)} /><h3 className="text-blueGray-700 text-xs font-bold uppercase">Existing Leads ({this.state.duplicate_leads})</h3>
                        </div>
                      </div>
                      <div className={"relative flex flex-col min-w-0 w-full shadow-lg " + (this.state.minimizeExistingLeads ? 'h-0' : 'h-auto')}>
                        <div className="block w-full overflow-x-auto">
                          <div className="relative flex flex-col  min-w-0 w-full shadow-lg bg-white rounded-b ">
                            <div className="block w-full overflow-x-auto rounded-b max-h-400">
                              <table className="relative items-center w-full overflow-x-scroll">
                                <thead>
                                  <tr className="border border-l-0 border-r-0 border-blueGray-200">
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      First Name
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Last Name
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Email
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Phone
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Province
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.duplicate_leads_data.map((f, key) => {
                                    return (
                                      <tr key={f.id} className={(f.followup_status === 'No' ? 'bg-table-red' : null) + " border border-l-0 border-r-0 border-blueGray-200"}>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.first_name}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.last_name}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.email}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.phone}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          {f.province}
                                        </td>
                                      </tr>
                                    )
                                  })
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : <></>}
            {/*duplicate Leads*/}

            {/*new enquiry*/}
            {this.state.new_enquiry > 0 ?
              <div className="w-full">
                <div className="relative flex flex-col min-w-0 break-words w-full  mt-5 rounded-t rounded-b bg-white">
                  <div className="flex flex-wrap">
                    <div className="w-full">
                      <div className="bg-table-green mb-0 px-4 py-6 flex">
                        <div className="w-full lg:w-6/12 relative flex ">
                          <i className={"fa pr-4 " + (this.state.minimizeNewEnquiry ? 'fa-plus' : 'fa-minus')} id="close-bar" onClick={e => this.handleTable(3)} /><h3 className="text-blueGray-700 text-xs font-bold uppercase">New Enquiry ({this.state.new_enquiry})</h3>
                        </div>
                      </div>
                      <div className={"relative flex flex-col min-w-0 w-full shadow-lg " + (this.state.minimizeNewEnquiry ? 'h-0' : 'h-auto')}>
                        <div className="block w-full overflow-x-auto ">
                          <div className="relative flex flex-col  min-w-0 w-full shadow-lg bg-white rounded-b ">
                            <div className="block w-full overflow-x-auto rounded-b max-h-400">
                              <table className="relative items-center w-full overflow-x-scroll">
                                <thead>
                                  <tr className="border border-l-0 border-r-0 border-blueGray-200">
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      First Name
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Last Name
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Email
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Phone
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Province
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Enquiry Subject
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Type
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Preferred Model
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Test Drive Location
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Enquiry Date
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Channel
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.new_enquiry_data.map((f, key) => {
                                    return (
                                      <tr key={f.id} className={(f.followup_status === 'No' ? 'bg-table-red' : null) + " border border-l-0 border-r-0 border-blueGray-200"}>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.first_name}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.last_name}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.email}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.phone}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          {f.province}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.subject}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.enquiry_type}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.preferred_model}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          {f.test_drive_location}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          {DateFormat(f.enquiry_date, 'Mdy')}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          <img
                                            src={require("assets/img/logo/" + getBadge(f.source.toLowerCase())).default}
                                            alt={f.source}
                                            className="w-8 h-8 rounded-full border-2 border-blueGray-50 shadow bg-white"
                                          ></img>
                                        </td>
                                      </tr>
                                    )
                                  })
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : <></>}
            {/*new enquiry*/}

            {/*duplicate enquiry*/}
            {this.state.duplicate_enquiry > 0 ?
              <div className="w-full">
                <div className="relative flex flex-col min-w-0 break-words w-full  mt-5 rounded-t rounded-b bg-white">
                  <div className="flex flex-wrap">
                    <div className="w-full">
                      <div className="bg-table-yellow mb-0 px-4 py-6 flex">
                        <div className="w-full lg:w-6/12 relative flex ">
                          <i className={"fa pr-4 " + (this.state.minimizeExistingEnquiry ? 'fa-plus' : 'fa-minus')} id="close-bar" onClick={e => this.handleTable(4)} /><h3 className="text-blueGray-700 text-xs font-bold uppercase">Existing Enquiry ({this.state.duplicate_enquiry})</h3>
                        </div>
                      </div>
                      <div className={"relative flex flex-col min-w-0 w-full shadow-lg " + (this.state.minimizeExistingEnquiry ? 'h-0' : 'h-auto')}>
                        <div className="block w-full overflow-x-auto ">
                          <div className="relative flex flex-col  min-w-0 w-full shadow-lg bg-white rounded-b ">
                            <div className="block w-full overflow-x-auto rounded-b max-h-400">
                              <table className="relative items-center w-full overflow-x-scroll duplicate_enquiry">
                                <thead>
                                  <tr className="border border-l-0 border-r-0 border-blueGray-200">
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      First Name
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Last Name
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Email
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Phone
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Province
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Enquiry Subject
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Type
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Preferred Model
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Test Drive Location
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Enquiry Date
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Channel
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.duplicate_enquiry_data.map((f, key) => {
                                    return (
                                      <tr key={f.id} className={(f.followup_status === 'No' ? 'bg-table-red' : null) + " border border-l-0 border-r-0 border-blueGray-200"}>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.first_name}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.last_name}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.email}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.phone}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          {f.province}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.subject}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.enquiry_type}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.preferred_model}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          {f.test_drive_location}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          {DateFormat(f.enquiry_date, 'Mdy')}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          <img
                                            src={require("assets/img/logo/" + getBadge(f.source.toLowerCase())).default}
                                            alt={f.source}
                                            className="w-8 h-8 rounded-full border-2 border-blueGray-50 shadow bg-white"
                                          ></img>
                                        </td>
                                      </tr>
                                    )
                                  })
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : <></>}
            {/*duplicate enquiry*/}


            {/*invalid Enquiry */}
            {this.state.invalid_enquiry > 0 ?
              <div className="w-full">
                <div className="relative flex flex-col min-w-0 break-words w-full  mt-5 rounded-t rounded-b bg-white">
                  <div className="flex flex-wrap">
                    <div className="w-full">
                      <div className="bg-table-red mb-0 px-4 py-6 flex">
                        <div className="w-full lg:w-6/12 relative flex ">
                          <i className={"fa pr-4 " + (this.state.minimizeInvalidEnquiry ? 'fa-plus' : 'fa-minus')} id="close-bar" onClick={e => this.handleTable(5)} /><h3 className="text-blueGray-700 text-xs font-bold uppercase">Invalid Enquiry ({this.state.invalid_enquiry})</h3>
                        </div>
                      </div>
                      <div className={"relative flex flex-col min-w-0 w-full shadow-lg " + (this.state.minimizeInvalidEnquiry ? 'h-0' : 'h-auto')}>
                        <div className="block w-full overflow-x-auto ">
                          <div className="relative flex flex-col  min-w-0 w-full shadow-lg bg-white rounded-b ">
                            <div className="block w-full overflow-x-auto rounded-b max-h-400">
                              <table className="relative items-center w-full overflow-x-scroll">
                                <thead>
                                  <tr className="border border-l-0 border-r-0 border-blueGray-200">
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      First Name
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Last Name
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Email
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Phone
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Province
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Enquiry Subject
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Type
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Prefered model
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Test Drive Location
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Enquiry Date
                                    </th>
                                    <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}>
                                      Channel
                                    </th>
                                    <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500 pr-4"}>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.invalid_enquiry_data.map((f, key) => {
                                    return (
                                      <tr key={f.id} className={(f.followup_status === 'No' ? 'bg-table-red' : null) + " border border-l-0 border-r-0 border-blueGray-200"}>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.invalid_first_name === 'invalid' ? (<div className="bg-red">{f.first_name}&nbsp;</div>)
                                            : f.first_name}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.last_name}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.invalid_email === 'invalid' ? (<div className="bg-red">{f.email}&nbsp;</div>)
                                            : f.email}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.invalid_phone === 'invalid' ? (<div className="bg-red">{f.phone}&nbsp;</div>)
                                            : f.phone}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          {f.invalid_province === 'invalid' ? (<div className="bg-red">{f.province}&nbsp;</div>)
                                            : f.province}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.invalid_subject === 'invalid' ? (<div className="bg-red">{f.subject}&nbsp;</div>)
                                            : f.subject}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.invalid_enquiry_type === 'invalid' ? (<div className="bg-red">{f.enquiry_type}&nbsp;</div>)
                                            : f.enquiry_type}
                                        </td>
                                        <td className="px-2 align-middle text-sm  py-4">
                                          {f.invalid_preferred_model === 'invalid' ? (<div className="bg-red">{f.preferred_model}&nbsp;</div>)
                                            : f.preferred_model}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          {f.test_drive_location}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          {f.invalid_date === 'invalid date' ? (<div className="bg-red">{f.enquiry_date ? DateFormat(f.enquiry_date, 'Mdy') : ''}</div>)
                                            : (f.enquiry_date ? DateFormat(f.enquiry_date, 'Mdy') : '')}
                                        </td>
                                        <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                          {f.invalid_source === 'invalid' ? (<div className="bg-red">{f.source}</div>)
                                            : f.source}
                                          {/*<img
                                            src={require("assets/img/logo/" + getBadge(f.source.toLowerCase())).default}
                                            alt={f.source}
                                            className="w-8 h-8 rounded-full border-2 border-blueGray-50 shadow bg-white"
                                          ></img>*/}
                                        </td>
                                      </tr>
                                    )
                                  })
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : <></>}
            {/*invalid Enquiry*/}

          </div>
        </div>

        {this.state.showConfirmation ?
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto mx-auto">
                    <p className="mt-2 font-bold text-gray text-center leading-relaxed">
                      {this.state.showConfirmationTitle}
                    </p>
                    <p className="mt-2 text-gray text-sm text-center leading-relaxed">
                      {this.state.showConfirmationText}
                    </p>
                  </div>
                  <div className="flex items-center justify-center pb-6 rounded-b text-center">
                    <button
                      className="w-5/12 button-gold  text-white font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      type="button"
                      onClick={e => this.handleShowConfirmation(false)}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </> : ''}
        {this.state.showOverwriteMsg ?
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto mx-auto">
                    <h6 className="text-center text-black font-bold mx-auto">Your Leads data has been overwrite. </h6>
                  </div>
                  <div className="flex items-center justify-center pb-6 rounded-b text-center">
                    <button
                      className="w-5/12 button-gold  text-white  font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      type="button"
                      onClick={this.handleConfirmCancel}
                    >
                      ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </> : ''}
      </>


    );
  }
}