import React from "react";
import { Redirect, Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

//import FooterSmall from "components/Footers/FooterSmall.js";

import axios from 'axios';
//import md5 from 'md5';
//import jsCookie from 'js-cookie'
import auth from 'global/auth.js';

import { Validation } from "global/custom.js";

/*eslint-disable eqeqeq*/
//import 'assets/styles/custom.css';

export default function Login() {
  if (auth()) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <main>
        <section className="flex items-center w-full h-full min-h-screen">
          <div
            className="absolute top-0 w-full h-full animated-background"
          ></div>
          <div className="container mx-auto px-4 h-full z-100">
            <div className="flex justify-center h-full">
              <img
                alt="..."
                src={require("assets/img/logo/logo-white.svg").default}
                className="shadow-xl rounded-full h-auto align-middle border-none max-w-150-px"
              />
            </div>
            <div className="flex content-center items-center justify-center h-full">
              <ForgotPasswordForm />
            </div>
          </div>
          {/*<FooterSmall absolute />*/}
        </section>
      </main>
    </>
  );
}


class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      email: '',
      isSubmitted: false,
      redirect: false,
      errorMessage: '',
      successMessage: '',
      showModal: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleChangeValidation = this.handleChangeValidation.bind(this);
  }

  handleRedirect(event) {
    this.setState({
      redirect: true,
    });
  }

  handleChange(event) {
    const target = event.target;
    const email = target.id === 'email' ? target.value : this.state.email;

    this.setState({
      email: email,
    });
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className='notifBar  bg-red'>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <div className="cursor-pointer" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)}>
          <i className="fa fa-times" />
        </div>
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  handleChangeValidation(event) {

    const target = event.target;
    if (target.id === 'email') {
      this.handleValidation('email', this.state.email, 'emailError', 'emailErrorMessage', 'Email');
    }

  }

  handleValidation(type, value, errorName, errorMessageName, fieldName) {
    var valid = Validation(type, value, errorName, errorMessageName, fieldName);
    if (valid.status == 404) {
      this.createErrorMessage(valid.msg, errorName, errorMessageName);
      return false;
    } else {
      this.setState({
        [errorName]: '',
        [errorMessageName]: ''
      });
      return true;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitted: true });

    var validate = 1;
    if (!this.handleValidation('email', this.state.email, 'emailError', 'emailErrorMessage', 'Email')) {
      validate = 0;
    }

    if (validate == 0) {
      this.setState({ isSubmitted: false });
      return false;
    }

    axios.get(`${process.env.REACT_APP_API_URL}forgotPassword?username=${this.state.email}`,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        if (typeof error.response != "undefined") {
          this.setState({ isSubmitted: false })
          if (error.response.status == 401) {
            this.setState({
              errorMessage:
                <><div id='alerts' className='notifBar w-full bg-red'>
                  <div id="ofBar-content" className="notifBar-content">
                    <b>{error.response.data}</b>
                  </div>
                  <div id="ofBar-right" className="notifBar-right">
                    <div className="cursor-pointer" id="close-bar" onClick={e => this.handleCloseAlert('errorMessage')}>
                      <i className="fa fa-times" />
                    </div>
                  </div>
                </div></>
            });
          }
          if (error.response.status == 403) {
            this.setState({
              errorMessage:
                <><div id='alerts' className='notifBar w-full bg-red'>
                  <div id="ofBar-content" className="notifBar-content">
                    <b>Email not found.</b>
                  </div>
                  <div id="ofBar-right" className="notifBar-right">
                    <div className="cursor-pointer" id="close-bar" onClick={e => this.handleCloseAlert('errorMessage')}>
                      <i className="fa fa-times" />
                    </div>
                  </div>
                </div></>
            });
          }
        } else {
          this.setState({ isSubmitted: false })
          this.createErrorMessage('Please Check Connection', '', 'errorMessage')
        }
        throw error
      })
      .then(result => {
        if (typeof result != "undefined") {
          if (result) {
            this.setState({ showModal: true })
          }
        } else {
          this.setState({ isSubmitted: false })
          this.createErrorMessage('Please Check Connection', '', 'errorMessage')
        }
      }
      )
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/login' />;
    }
    return (
      <>
        <div id="notificationArea" className="w-full lg:w-4/12 notificationArea-login">
          {this.state.emailErrorMessage}
          {this.state.passwordErrorMessage}
          {this.state.errorMessage}
        </div>
        <div className="lg:w-4/12 md:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-login-panel border-0">
            <div className="rounded-t  flex-auto px-8 mt-5 lg:px-10 py-10 pt-0">
              <div className="text-blueGray-400 text-center mb-5 font-bold mt-5">
                <h6 className="text-white text-md font-bold">
                  Forgot Password?
                </h6>
                <div className="text-center text-gray text-xs font-bold mt-3 pl-10 pr-10">
                  Enter your Email and we'll send you a reset password link
                </div>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-gray text-gray bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                    id="email"
                    value={this.state.email} onChange={this.handleChange} onBlur={this.handleChangeValidation}
                  />
                  {this.state.emailError ?
                    <>
                      <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                        <i className="fas fa-exclamation"></i>
                      </span>
                    </>
                    : ''}
                </div>

                <div className="text-center mt-6">
                  {this.state.isSubmitted ? <button
                    className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  ><ClipLoader size={15} color="white" /></button> :
                    <button
                      className="button-login text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      SEND RECOVERY EMAIL
                    </button>}
                </div>
                <Link
                  to="../login"
                  className="text-gold font-semibold"
                >
                  <small>Remember Password?</small>
                </Link>
              </form>
            </div>
          </div>
        </div>
        {this.state.showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto mx-auto">
                    <h6 className="text-center text-black font-bold mx-auto">Check Your Email</h6>
                    <h6 className="text-center text-black font-bold mx-auto">{this.state.email}</h6>
                    <p className="mt-4 text-blueGray-500 text-sm text-center leading-relaxed">
                      We’ve sent the link reset password instructions to your email.<br />
                    </p>
                  </div>
                  <div className="flex items-center justify-center  rounded-b text-center">
                    <button
                      className="w-10/12 button-gold text-white  font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      type="button"
                      onClick={this.handleRedirect}
                    >
                      ok
                    </button>
                  </div>
                  <div className="relative pb-6 px-4 flex-auto mx-auto">
                    <p className="mt-4 text-blueGray-500 text-xs text-center leading-relaxed">
                      If you don't receive instructions within a few minutes, check your spam filter or unblock lexuscreatingamazing@gmail.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
}