import React from "react";
import { Link   } from 'react-router-dom'
import { createPopper } from "@popperjs/core";
import jsCookie from 'js-cookie';

const UserDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const userPrivilege = jsCookie.get('privilege');
  const userName = jsCookie.get('name');
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="# "
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-center text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            {userPrivilege==='Super Admin'?(<img
              alt="..."
              className="bg-gray-100 rounded-full align-middle border-none shadow-lg"
              src={require('assets/img/sa-logo.svg').default}
            />):''}
            {userPrivilege==='Sales Manager'?(<img
              alt="..."
              className="rounded-full align-middle border-none shadow-lg"
              src={require('assets/img/sm-logo.svg').default}
            />):''}
            {userPrivilege==='Brand Associate' || userPrivilege==='Uploader'?(<img
              alt="..."
              className="bg-gray-100 rounded-full align-middle border-none shadow-lg"
              src={require('assets/img/ba-logo.svg').default}
            />):''}
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
      <div
        className={
          "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        }
      >
        <h6 className="uppercase text-blueGray-400 mb-1 text-2xs font-semibold">
          {/*userPrivilege=='Super Admin'?'Lexus Indonesia':userPrivilege*/}
          {userPrivilege}
        </h6>
        <h3 className="text-blueGray-700 text-base font-semibold">
          {userName}
        </h3>
      </div>
        <div className="h-0 my-2 border border-solid border-blueGray-100" />
        <a
          href="/profile"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
        >
          My Profile
        </a>
        <div className="h-0 my-2 border border-solid border-blueGray-100" />
        <Link to="/logout"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
        >
          Logout
        </Link>
      </div>
    </>
  );
};

export default UserDropdown;
