import React from "react";
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios';
// components
import * as moment from 'moment'

import jsCookie from 'js-cookie';
import auth from 'global/auth.js';

import ReactExport from "react-data-export";
import { DateFormat } from "global/custom.js";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
/*eslint-disable eqeqeq*/

export default function Enquiry() {
  return (
    <EnquiryClass />
  )

}

class EnquiryClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      importCsvData: [],
      getData: true,
      privilege: jsCookie.get('privilege'),
      showConfirmation: false,
      confirmCancel: false,
      showOverwriteMsg: false,
      dateRangeValue: moment().subtract(6,'days').format('MM/DD/YYYY') + ' - ' + moment().format('MM/DD/YYYY'),
      fromFilter: moment().subtract(6,'days').format('YYYY-MM-DD'),
      toFilter: moment().format('YYYY-MM-DD'),
    };
    this.getData = this.getData.bind(this);
    this.handleEventDatePicker = this.handleEventDatePicker.bind(this);
  }

  getData(e) {
    var url = `${process.env.REACT_APP_API_URL}customer/history?from=${this.state.fromFilter}&to=${this.state.toFilter}`
    axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        if(jsCookie.get('privilege') === 'Sales Manager'){
          data = data.filter(row => {
            return  row.sales_manager_name == jsCookie.get('name')
          })
        }else if(jsCookie.get('privilege') === 'Brand Associate'){
          data = data.filter(row => {
            return  row.brand_associate_name == jsCookie.get('name')
          })
        }
        if (data !== 'Data Empty') {
          this.setState({
            getData: false,
            importCsvData: data
          }, () => {
            document.getElementById('excelDownload').click();
          });
        }
      })
      
  }

  componentDidMount() {
  }

  handleEventDatePicker(event, picker) {
    if (event.type === 'apply') {
      this.setState({
        dateRangeValue: picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'),
        fromFilter: picker.startDate.format('YYYY-MM-DD'),
        toFilter: picker.endDate.format('YYYY-MM-DD'),
      })
    } else if (event.type === 'hide') {
      if (this.state.fromFilter == '') {
        this.setState({
          dateRangeValue: '',
        });
      } else {
        this.setState({
          dateRangeValue: moment(this.state.fromFilter).format('MM/DD/YYYY') + ' - ' + moment(this.state.toFilter).format('MM/DD/YYYY'),
        })
      }
    }
  }

  handleResetDatePicker() {
    let dateRangeValue = moment().subtract(6,'days').format('MM/DD/YYYY') + ' - ' + moment().format('MM/DD/YYYY');
    let fromFilter = moment().subtract(6,'days').format('YYYY-MM-DD');
    let toFilter = moment().format('YYYY-MM-DD');
    this.setState({
      dateRangeValue: dateRangeValue,
      fromFilter: fromFilter,
      toFilter: toFilter,
    })
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (this.state.confirmCancel) {
      return <Redirect to="/leads" />;
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 z-100">
          {this.state.fileErrorMessage}
        </div>
        <div
          className="w-full lg:w-6/12 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mx-auto"
        >
          <div className="relative w-full mx-auto max-w-sm">
            <div>
              <img
                src={require("assets/img/logo/logo-white.svg").default}
                alt="Lexus Logo"
                className="relative border-none max-w-150-px  mx-auto"
              />
            </div>
            <div className="border-0 rounded shadow-lg py-4 relative flex flex-col w-full bg-blueGray-200 outline-none focus:outline-none">
                <>
                  <div className="w-full relative p-6 flex-auto mx-auto items-center">
                    <h6 className="w-full text-center text-black font-bold mx-auto uppercase">Download Enquiry Data</h6>
                    <p className="mt-2 text-gray text-sm text-center leading-relaxed">
                      Please Select Date Range
                    </p>
                  </div>
                  <div className="w-full relative   justify-center pb-6 rounded-b px-12">
                    <form>
                      <div className="flex flex-wrap">
                        <div className="w-full">
                          <div className="relative w-full items-center text-center">
                      <DateRangePicker
                        startDate={this.state.fromFilter}
                        endDate={this.state.toFilter}
                        initialSettings={{
                          ranges: {
                            'Today': [moment(), moment()],
                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                          },
                          alwaysShowCalendars:true
                        }}
                        onEvent={this.handleEventDatePicker}
                      >
                        <input type="text"
                          value={this.state.dateRangeValue}
                          placeholder="mm-dd-yyyy - mm-dd-yyyy"
                          style={{ width: `${200}px` }}
                          className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                      </DateRangePicker>
                        <button 
                        className="mt-4 button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                        onClick={(e) => {
                          e.preventDefault();
                          this.getData()
                        }}
                        >
                        Download</button>
              <ExcelFile
                              filename={"Enquiry Data " + moment(new Date(), 'DD-MM-YYYY', true).format('DD-MM-YYYY') + ".csv"}
                              element={
                                <button id="excelDownload" className=" hidden button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                >
                                  Download Now</button>
                              }
                            >
                              <ExcelSheet data={this.state.importCsvData} name="Enquirt"
                              >
                                <ExcelColumn label="First Name" value="first_name" />
                                <ExcelColumn label="Last Name" value="last_name" />
                                <ExcelColumn label="Status" 
                                 value={(col) => 
                                  col.customer_status > 0?'Customer':
                                  col.followup_status =="SPK"?'Customer':'Leads'
                                 }/>
                                <ExcelColumn label="Email" value="email" />
                                <ExcelColumn label="Phone" value="phone" />
                                {/*<ExcelColumn label="City" value="city" />*/}
                                <ExcelColumn label="Province" value="province" />
                                <ExcelColumn label="Enquiry Subject" value="subject" />
                                <ExcelColumn label="Type" value="enquiry_type" />
                                <ExcelColumn label="Enquiry Date" 
                                 value={(col) => col.enquiry_date?DateFormat(col.enquiry_date,'ymd'):''}/>
                                <ExcelColumn label="Notes" value="notes" />
                                <ExcelColumn label="Preferred Model" value="preferred_model" />
                                <ExcelColumn label="Test Drive Location" value="test_drive_location" />
                                <ExcelColumn label="Channel"
                                 value={(col) => col.source=='Email'?'Website':col.source}/> 
                                <ExcelColumn label="Gallery" value="showroom_name" />
                                <ExcelColumn label="Sales Manager" value="sales_manager_name" />
                                <ExcelColumn label="Brand Associate" value="brand_associate_name" />
                                <ExcelColumn label="Follow Up Status"
                                 value={(col) => 
                                  !col.sales_manager_name?'New Enquiry':
                                  !col.brand_associate_name?'Not Assigned':
                                  col.followup_status=='No'?'Not Followed Up':col.followup_status
                                   }/> 
                                <ExcelColumn label="Follow Up Date" 
                                 value={(col) => col.followup_date?DateFormat(col.followup_date,'ymd'):''}/>
                                <ExcelColumn label="Follow Up Preferred Model" value="followup_preferred_model" />
                              </ExcelSheet>
                            </ExcelFile>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap pt-4">
                        <div className="w-full">
                          <div className="relative w-full items-center text-center">
                            <Link
                              className="button-login text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 my-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              to="/leads"
                            //ref={this.downloadBtn}
                            >
                              Close
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
            </div>
          </div>
        </div>
        <div className="fixed inset-0 z-40  bg-login"></div>

      </>


    );
  }
}