import React from 'react'
import { useParams  } from 'react-router-dom'
//import CustomerSearch from "./Customer.js";
import Customer from "../customer/Customer.js";
import UsersSearch from "./Users.js";
/*eslint-disable eqeqeq*/

export default function Users() {
  var id = useParams();
  return (
    <>
      <UsersClass value={id}/>
    </>
  );
}

class UsersClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      value:this.props.value ,
    };
  }
  componentDidMount(){
    document.getElementById('search').value = this.state.value.name;
  }
  
  render() {
    return (<>
      <Customer name={this.state.value.name} path={'search/'+this.state.value.name} filter='no' label="Leads"  exports="no"/>
      <UsersSearch value={this.state.value}/>
    </>
    )
  }
}
