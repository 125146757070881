import React from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import axios from 'axios';
import auth from 'global/auth.js';
import jsCookie from 'js-cookie';
import Customers from "views/customer/Customer.js";
import { numberFormat } from "global/custom.js";
/*eslint-disable eqeqeq*/

export default function UsersDetail() {
  var id = useParams();

  return (
    <>
      <UsersDetailClass id={id.id} />
    </>
  );
}
class UsersDetailClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      user_name: '',
      user_email: '',
      user_phone: '',
      user_showroom_id: '',
      user_showroom_name: '',
      user_privilege: '',
      user_sales_manager_id: '',
      user_sales_manager_name: '',
      user_status: '',
      user_total_customer: 0,
      user_total_assign_to_sm: 0,
      user_total_assign_to_ba: 0,
      user_total_sm: 0,
      user_total_ba: 0,
      user_total_followup_customer: 0,
      user_total_not_followup_customer: 0,
      user_total_cant_reach_customer: 0,
      user_total_spk_followup_customer: 0,
      user_total_assign: 0,
      user_total_not_assign: 0,
      urlStatus: true,
      isLoading: true,
      isAssignBtn: '',
    };
  }

  getUserDetailData() {
    axios.get(`${process.env.REACT_APP_API_URL}user/${this.props.id}`,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(err => {
        this.setState({ urlStatus: false });
        throw err;
      })
      .then(res => {
        const data = res.data[0];
        if(data){
        if (jsCookie.get('privilege') === 'Sales Manager') {
          if (data.privilege !== 'Brand Associate') {
            this.setState({ urlStatus: false });
          } else {
            if (data.manager_id != jsCookie.get('uid')) {
              this.setState({ urlStatus: false });
            }
          }
        }

        this.setState({ userData: data });
        if (data.privilege === 'Super Admin') {
          this.setState({ storeDisabled: 'disabled' })
        }
        else if (data.privilege === 'Sales Manager') {
          this.setState({ storeDisabled: '' })
        }
        else if (data.privilege === 'Brand Associate') {
          this.setState({ storeDisabled: '' })
          this.setState({ salesManagerStatus: true })
        }
        else {
          this.setState({ storeDisabled: 'disabled' })
          this.setState({ salesManagerStatus: false })
        }
        this.setState({
          user_id: data.id,
          user_name: data.name,
          user_email: data.email ? data.email : '',
          user_phone: data.phone ? data.phone : '',
          user_showroom_id: data.showroom_id ? data.showroom_id : '',
          user_showroom_name: data.showroom_name,
          user_privilege: data.privilege ? data.privilege : '',
          user_sales_manager_id: data.manager_id ? data.manager_id : '',
          user_sales_manager_name: data.sales_manager_name ? data.sales_manager_name : '',
          user_status: data.status ? data.status : '',
          user_total_sm: data.total_sales_manager ? data.total_sales_manager : 0,
          user_total_ba: data.total_brand_associate ? data.total_brand_associate : 0,
          isAssignBtn: data.privilege != 'Super Admin' ? 'yes' : '',
          isLoading: false,
        }, () => {
          this.getCustomerStatistic();
          this.getEnquiryStatistic();
        });
      }else{
        this.setState({
          urlStatus: false,
        });
      }

      })
  }

  getCustomerStatistic() {
    var url = `${process.env.REACT_APP_API_URL}customers_statistic`;
    if (this.state.user_privilege === 'Sales Manager') {
      url = `${process.env.REACT_APP_API_URL}customers_statistic?sales_manager_id=${this.state.user_id}`;

    }
    else if (this.state.user_privilege === 'Brand Associate') {
      url = `${process.env.REACT_APP_API_URL}customers_statistic?brand_associate_id=${this.state.user_id}`;
    }
    axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        console.log(error);
        throw error;
      })
      .then(res => {
        let data = res.data;
        if (data !== 'Data Empty') {
          this.setState({
            user_total_customer: numberFormat(data.total_customers),
            user_total_assign: numberFormat(data.total_assigned_leads),
            user_total_not_assign: numberFormat(data.total_not_assigned_leads),
          });
        }
      })
  }
  
  getEnquiryStatistic() {
    var url = `${process.env.REACT_APP_API_URL}enquiry_statistic`;
    if (this.state.user_privilege === 'Sales Manager') {
      url = `${process.env.REACT_APP_API_URL}enquiry_statistic?sales_manager_id=${this.state.user_id}`;

    }
    else if (this.state.user_privilege === 'Brand Associate') {
      url = `${process.env.REACT_APP_API_URL}enquiry_statistic?brand_associate_id=${this.state.user_id}`;
    }
    axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        console.log(error);
        throw error;
      })
      .then(res => {
        let data = res.data;
        if (data !== 'Data Empty') {
          this.setState({
            user_total_spk_followup_customer: numberFormat(data.total_spk),
            user_total_cant_reach_customer: numberFormat(data.total_cant_reach),
            user_total_followup_customer: numberFormat(data.total_followup),
            user_total_not_followup_customer: numberFormat(data.total_not_followup),
          });
        }
      })
  }
  

  componentDidMount() {
    if (!auth()) {
      return false;
    }
    if (jsCookie.get('privilege') === 'Brand Associate') {
      this.setState({ urlStatus: false });
    }
    this.getUserDetailData();
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (!this.state.urlStatus) {
      window.location.href = '/404'
    }

    return (
      <>
        <div className="flex flex-wrap ">
          <div className="w-full text-white z-30 pb-4 pr-4">
            <Link to='/users'
              className="text-white cursor-pointer"><i className="fa fa-angle-left"></i> Back
            </Link>
          </div>
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="block w-full overflow-x-auto ">
                <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white">
                  <div className="flex justify-between rounded-t mb-0 px-4 py-3 border-0">
                    <div>
                      <div className="flex flex-wrap items-center">

                        <div className={` w-auto mb-2 pr-10 `}>
                          <div className="relative w-full">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold"
                              htmlFor="grid-password"
                            >
                              Name
                            </label>
                            {this.state.user_name}
                          </div>
                        </div>
                        <div className={` w-auto mb-2 pr-10`}>
                          <div className="relative w-full">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold"
                              htmlFor="grid-password"
                            >
                              Role
                            </label>
                            {/*this.state.user_privilege=='Super Admin'?'Lexus Indonesia':this.state.user_privilege*/}
                            {this.state.user_privilege}
                          </div>
                        </div>
                        <div className={` w-auto mb-2 pr-10`}>
                          <div className="relative w-full">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold"
                              htmlFor="grid-password"
                            >
                              Email
                            </label>
                            {this.state.user_email}
                          </div>
                        </div>
                        <div className={` w-auto  mb-2 pr-10`}>
                          <div className="relative w-full">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold"
                              htmlFor="grid-password"
                            >
                              Phone
                            </label>
                            {this.state.user_phone}
                          </div>
                        </div>
                        <div className={`${this.state.user_showroom_name ? '' : 'hidden'} w-auto mb-2 pr-10`}>
                          <div className="relative w-full">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold"
                              htmlFor="grid-password"
                            >
                              Gallery
                            </label>
                            {this.state.user_showroom_name?this.state.user_showroom_name:'-'}
                          </div>
                        </div>
                        <div className={`${this.state.user_sales_manager_name && jsCookie.get('privilege') != 'Sales Manager' ? ''  : 'hidden'} w-auto mb-2 pr-10`}>
                          <div className="relative w-full">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold"
                              htmlFor="grid-password"
                            >
                              Sales Manager
                            </label>
                            {this.state.user_sales_manager_name}
                          </div>
                        </div>
                      </div>
                    </div>
                    {jsCookie.get('privilege') === 'Super Admin' ?
                      <div className={`my-auto float-right`}>
                        <Link
                          to={"/users/edit/" + this.props.id}
                        >
                          <i className="fa fa-cog" />
                        </Link>
                      </div> : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={` w-full xl:w-12/12 mb-12 xl:mb-0 text-lg text-white pb-2`}>
            {this.state.user_name} Overall Performance Overview
          </div>
          <div className="w-full xl:w-3/12 mb-12 xl:mb-0 pr-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-gray opacity-50 text-xs font-bold"
                >
                  Total Customer
                </label>
                {this.state.user_total_customer}
              </div>
            </div>
          </div>
          <div className={`${this.state.user_privilege !== 'Brand Associate' ? '' : 'hidden'} w-full xl:w-3/12 mb-12 xl:mb-0 pr-4`}>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-gray opacity-50 text-xs font-bold"
                >
                  Assigned to {this.state.user_privilege === 'Super Admin' ? 'Sales Manager' : 'Brand Associate'}
                </label>
                {this.state.user_total_assign}
              </div>
            </div>
          </div>
          <div className={`${this.state.user_privilege !== 'Brand Associate' ? '' : 'hidden'} w-full xl:w-3/12 mb-12 xl:mb-0 pr-4`}>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-gray opacity-50 text-xs font-bold"
                >
                  Not Assigned to {this.state.user_privilege === 'Super Admin' ? 'Sales Manager' : 'Brand Associate'}
                </label>
                {this.state.user_total_not_assign}
              </div>
            </div>
          </div>
          <div className={`${this.state.user_privilege !== 'Brand Associate' ? '' : 'hidden'} w-full xl:w-3/12 mb-12 xl:mb-0 `}>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-gray opacity-50 text-xs font-bold"
                >
                  Total {this.state.user_privilege === 'Super Admin' ? 'Sales Manager' : 'Brand Associate'}
                </label>
                {this.state.user_privilege === 'Super Admin' ? this.state.user_total_sm : this.state.user_total_ba}
              </div>
            </div>
          </div>
          <div className={` w-full xl:w-12/12 mb-12 xl:mb-0 text-lg text-white pb-2`}>
          Leads Follow Up Statistic
          </div>
          <div className="w-full xl:w-3/12 mb-12 xl:mb-0 pr-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-gray opacity-50 text-xs font-bold"
                >
                  SPK
                </label>
                {this.state.user_total_spk_followup_customer}
              </div>
            </div>
          </div>
          <div className="w-full xl:w-3/12 mb-12 xl:mb-0 pr-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-gray opacity-50 text-xs font-bold"
                >
                  Followed Up
                </label>
                {this.state.user_total_followup_customer}
              </div>
            </div>
          </div>
          <div className={`w-full xl:w-3/12 mb-12 xl:mb-0 pr-4`}>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-gray opacity-50 text-xs font-bold"
                >
                  Not Followed Up
                </label>
                {this.state.user_total_not_followup_customer}
              </div>
            </div>
          </div>
          <div className={`w-full xl:w-3/12 mb-12 xl:mb-0 pr-4`}>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded ">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-gray opacity-50 text-xs font-bold"
                >
                  can't Reached
                </label>
                {this.state.user_total_cant_reach_customer}
              </div>
            </div>
          </div>


          <div className="flex flex-wrap w-full xl:w-12/12 mb-12 xl:mb-0">
            {!this.state.isLoading ?
              <Customers path={'users/' + this.props.id} filter="no" label='Leads' assign='no' assignBtn={this.state.isAssignBtn} sales_manager_id={this.state.user_privilege == 'Sales Manager' ? this.props.id : ''} brand_associate_id={this.state.user_privilege == 'Brand Associate' ? this.props.id : ''} exports="no" />
              : ''}
          </div>
        </div>
      </>
    );
  }
}

