import React from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import axios from 'axios';
import auth from 'global/auth.js';

import jsCookie from 'js-cookie';
import { Validation } from "global/custom.js";
import { createPopper } from "@popperjs/core";

import ClipLoader from "react-spinners/ClipLoader";
/*eslint-disable eqeqeq*/

export default function Users() {
  var id = useParams();
  if (jsCookie.get('privilege') == 'Uploader') {
    return <Redirect to="/404" />;
  }
  return (
    <>
      <UsersClass showroom_id={id.showroom_id} />
    </>
  );
}
class UsersClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showroom_id: this.props.showroom_id,
      path: 'automate-assign-city',
      showroomData: [],
      cityData: [],
      idForm: '',
      cityForm: '',
      checkBoxId: [],//checkbox
      cityFilter: '',
      citySort: '',
      cityFilled: false,

      currentPage: '1',
      perPage: '10',
      pagingPrevTemplate: '',
      pagingTemplate: '',
      pagingNextTemplate: '',
      page: '',
      backupData: [],
      totalPaging: '',

      showModal: false,
      showDeleteModal: false,
      refreshPage: false,
      urlStatus: true,
      isLoading: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    /*paging*/
    this.handlePageChange = this.handlePageChange.bind(this);
    /*paging*/

    this.handleSort = this.handleSort.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleResetFilter = this.handleResetFilter.bind(this);
    this.resetField = this.resetField.bind(this);
    
    /*checkbox*/
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.handleCheckAllChange = this.handleCheckAllChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleDeleteMultiple = this.handleDeleteMultiple.bind(this);
    /*checkbox*/

    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
  }

  getShowroomData() {
    const url = `${process.env.REACT_APP_API_URL}showroom/${this.props.showroom_id}`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        this.setState({ urlStatus: false });
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({ showroomData: data[0] }
          , () => {
            this.getParam();
          });
      })
  }

  getParam() {
    var checkUrlPath = window.location.pathname;
    var myarr = checkUrlPath.split("/");
    let cityFilter = '';
    let currentPage = '1';
      if (myarr[3] !== 'null' && typeof myarr[3] !== 'undefined') {
        cityFilter = decodeURI(myarr[3]);
      }
      if (myarr[4] !== 'null' && typeof myarr[4] !== 'undefined') {
        currentPage = myarr[4];
      }

    this.setState({
      cityFilter: cityFilter,
      currentPage: currentPage,
      cityFilled: cityFilter ? true : false,
    }, () => {
      this.getCityData();
    });
  }

  getCityData() {
    var getParam = [];
    if (this.state.cityFilter !== '') {
      getParam.push('city=' + this.state.cityFilter);
    }
    
    if (this.state.currentPage) {
      getParam.push('currentPage=' + this.state.currentPage);
    }
    getParam.push('perPage=' + this.state.perPage);
    
    /**sort */
    if (this.state.citySort !== '') {
      getParam.push('sortBy=city-' + this.state.citySort);
    } 
    /**sort */
    const url = `${process.env.REACT_APP_API_URL}auto-assign-city?showroom_id=${this.props.showroom_id}&${getParam.join('&')}`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          cityData: res.data.data,
          totalData: res.data.total_data,
          isLoading: false
        }, () => {
          //this.handleCheckAllChange();
          this.handlePaging();
        });
      })
  }

  componentDidMount() {
    this.getShowroomData();
  }

  handlePaging() {
    let currentPage = this.state.currentPage;
    let perpage = this.state.perPage;
    let totalData = this.state.totalData;
    let totalPaging = Math.ceil(totalData / perpage);

    const start = (Number(currentPage) - 1) * perpage;
    const end = Number(start) + Number(perpage);

    let startLoop = 1;
    let endLoop = totalPaging;
    if (Number(currentPage) - 2 > 0 && Number(currentPage) + 2 <= totalPaging) {
      startLoop = Number(currentPage) - 2;
      endLoop = Number(currentPage) + 2;
    } else if (Number(currentPage) - 1 > 0 && Number(currentPage) + 3 <= totalPaging) {
      startLoop = Number(currentPage) - 1;
      endLoop = Number(currentPage) + 3;
    } else if (Number(currentPage) - 3 > 0 && Number(currentPage) + 1 <= totalPaging) {
      startLoop = Number(currentPage) - 3;
      endLoop = Number(currentPage) + 1;
    } else if (currentPage == 1 && Number(currentPage) + 4 <= totalPaging) {
      startLoop = Number(currentPage);
      endLoop = Number(currentPage) + 4;
    } else if (currentPage == totalPaging && Number(currentPage) - 4 > 0) {
      startLoop = Number(currentPage) - 4;
      endLoop = Number(currentPage);
    }

    let pagingPrevTemplate = [];
    let pagingTemplate = [];
    let pagingNextTemplate = [];
    if (Number(currentPage) > 1) {
      pagingPrevTemplate.push(
        <>
          <li key="tofirst">
            <button onClick={e => this.handlePageChange(1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
          <li key="toprev">
            <button onClick={e => this.handlePageChange(currentPage - 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    for (let i = startLoop; i <= endLoop; i++) {
      if (i == currentPage) {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 text-white bg-black">
              {i}
            </button>
          </li>
        )
      } else {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button name={i} onClick={() => this.handlePageChange(i)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              {i}
            </button>
          </li>
        )
      }
    }

    if (currentPage < totalPaging && totalPaging > 1) {
      pagingNextTemplate.push(
        <>
          <li key={`${Number(totalPaging) + 3}`}>
            <button onClick={() => this.handlePageChange(Number(currentPage) + 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
          <li key={`${Number(totalPaging) + 4}`}>
            <button onClick={() => this.handlePageChange(totalPaging)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    if (currentPage > 1) {
      if (currentPage > totalPaging) {
        this.setState({
          currentPage: 1,
        });
      }
    }
    this.setState({
      currentPage: currentPage,
      pagingPrevTemplate: pagingPrevTemplate,
      pagingTemplate: pagingTemplate,
      pagingNextTemplate: pagingNextTemplate,
      startShowing: this.state.cityData.length > 0 ? start + 1 : 0,
      endShowing: start + 1 < totalData ? (end < totalData ? end : totalData) : '',
      totalData: totalData,
    });
  }

  handlePageChange(number) {
    const city = this.state.cityFilter;

    var pushUrl = '/' + this.state.path +'/'+ (this.state.showroom_id);
    if (city ||  number > 1) {
      pushUrl = '/' + this.state.path +'/'+ (this.state.showroom_id)+ '/' + (city ? city.toLowerCase() : 'null') + '/' + number
    }
    window.history.pushState('page2', 'Title', pushUrl);
    this.setState({ currentPage: number }, () => {
      this.getParam();
    });
  }
  
  resetField(event) {
    const city = event === 'city' ? '' : this.state.cityFilter;
    const number = 1;
    var pushUrl = '/' + this.state.path +'/'+ (this.state.showroom_id);
    if (city ||  number > 1) {
      pushUrl = '/' + this.state.path +'/'+ (this.state.showroom_id)+ '/' + (city ? city.toLowerCase() : 'null') + '/' + number
    }
    window.history.pushState('page2', 'Title', pushUrl);

    this.setState({
      cityFilter: city,
      currentPage: number,

      cityFilled: city ? true : false,
    }, () => {
      this.getParam();
    });
  }

  handleResetFilter(event) {
    window.history.pushState('page2', 'Title', '/users');
    this.setState({
      usersData: Array.from(this.state.backupData),
      usersFilteredData: Array.from(this.state.backupData),
      nameFilter: '',
      privilegeFilter: '',
      statusFilter: '',
      showroomFilter: '',
      pushUrl: '/users',
    }, () => {
      this.handlePaging();
    });
  }

  handleFilter() {
    const city = this.state.cityFilter;
    const number = this.state.currentPage;
    let cityFilled = false;
    if (city) {
      cityFilled = true
    }

    var pushUrl = '/' + this.state.path +'/'+ (this.state.showroom_id);
    if (city) {
      pushUrl = '/' + this.state.path +'/'+ (this.state.showroom_id)+ '/' + (city ? city.toLowerCase() : 'null') + '/' + number
    }
    window.history.pushState('Team Member', 'Team Member', pushUrl);
    this.setState({ cityFilled: cityFilled }, 
      () => {
      this.getParam();
    });
  }

  handleChangeFilter(event) {
    const target = event.target;
    const city = target.id === 'city' ? target.value : this.state.cityFilter;

    this.setState({
      cityFilter: city,
      //pushUrl:pushUrl,
    }, () => {
      this.handleFilter();
    });
  }

  handleSort(event) {
    let data = this.state.usersData;
    let nameSort = '';
    let emailSort = '';
    let phoneSort = '';
    let privilegeSort = '';
    let statusSort = '';
    let createdDateSort = '';
    let customersSort = '';

    if (event === 'name') {
      if (this.state.nameSort === '' || this.state.nameSort === 'desc') {
        data = data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
        nameSort = 'asc';
      } else {
        data = data.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? 1 : -1)
        nameSort = 'desc';
      }
    }

    if (event === 'email') {
      if (this.state.emailSort === '' || this.state.emailSort === 'desc') {
        data = data.sort((a, b) => (a.email.toLowerCase() > b.email.toLowerCase()) ? 1 : -1)
        emailSort = 'asc';
      } else {
        data = data.sort((a, b) => (a.email.toLowerCase() < b.email.toLowerCase()) ? 1 : -1)
        emailSort = 'desc';
      }
    }

    if (event === 'phone') {
      if (this.state.phoneSort === '' || this.state.phoneSort === 'desc') {
        data = data.sort((a, b) => (a.phone > b.phone) ? 1 : -1)
        phoneSort = 'asc';
      } else {
        data = data.sort((a, b) => (a.phone < b.phone) ? 1 : -1)
        phoneSort = 'desc';
      }
    }

    if (event === 'privilege') {
      if (this.state.privilegeSort === '' || this.state.privilegeSort === 'desc') {
        data = data.sort((a, b) => a.privilege > b.privilege ? 1 : -1)
        privilegeSort = 'asc';
      } else {
        data = data.sort((a, b) => a.privilege < b.privilege ? 1 : -1)
        privilegeSort = 'desc';
      }
    }

    if (event === 'status') {
      if (this.state.statusSort === '' || this.state.statusSort === 'desc') {
        data = data.sort((a, b) => a.status > b.status ? 1 : -1)
        statusSort = 'asc';
      } else {
        data = data.sort((a, b) => a.status < b.status ? 1 : -1)
        statusSort = 'desc';
      }
    }

    if (event === 'createdDate') {
      if (this.state.createdDateSort === '' || this.state.createdDateSort === 'desc') {
        data = data.sort((a, b) => new Date(a.created_date) > new Date(b.created_date) ? 1 : -1)
        createdDateSort = 'asc';
      } else {
        data = data.sort((a, b) => new Date(a.created_date) < new Date(b.created_date) ? 1 : -1)
        createdDateSort = 'desc';
      }
    }

    if (event === 'customers') {
      if (this.state.customersSort === '' || this.state.customersSort === 'desc') {
        data = data.sort((a, b) => a.total_customer > b.total_customer ? 1 : -1)
        customersSort = 'asc';
      } else {
        data = data.sort((a, b) => a.total_customer < b.total_customer ? 1 : -1)
        customersSort = 'desc';
      }
    }

    if (data) {
      this.setState({
        nameSort: nameSort,
        emailSort: emailSort,
        phoneSort: phoneSort,
        privilegeSort: privilegeSort,
        statusSort: statusSort,
        createdDateSort: createdDateSort,
        customersSort: customersSort,
        usersFilteredData: Array.from(data)
      }, () => {
        this.handlePaging();
      });
    }
  }

  handleShowModal(id = null) {
    let idForm = '';
    let city = '';
    if (id) {
      let selected = this.state.cityData.filter(function (value, index, arr) {
        return value.id == id;
      });
      idForm = selected[0].id;
      city = selected[0].city;
      this.checkPopover(id);
    }
    this.setState({
      idForm: idForm,
      cityForm: city,
      showModal: true
    });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleCloseDeleteModal() {
    this.setState({ showDeleteModal: false });
  }

  handleCheckAll(event) {
    let cityData = this.state.cityData;
    let checkBoxId = this.state.checkBoxId;
    var inputs = '';
    if (document.getElementById('checkAll').checked) {
      let inputs = document.querySelectorAll("input[type='checkbox']");
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value !== 'on') {
          checkBoxId.push(inputs[i].value);
          let t_cityData = cityData.filter(function (value) {
            return value.id == inputs[i].value;
          });
        }
        inputs[i].checked = true;
      }
    } else {
      inputs = document.querySelectorAll("input[type='checkbox']");
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
        checkBoxId = checkBoxId.filter(function (value, key) {
          return value != inputs[i].value
        });
      }
    }
    this.setState({
      checkBoxId: Array.from(checkBoxId),
    });

  }

  handleCheckAllChange(event) {
    var checkBoxAll = [];
    var inputs = '';
    var i = 0;
    if (document.getElementById('checkAll').checked) {
      inputs = document.querySelectorAll("input[type='checkbox']");
      for (i = 0; i < inputs.length; i++) {
        if (inputs[i].value !== 'on') {
          checkBoxAll.push(inputs[i].value);
        }
        inputs[i].checked = true;
      }
    } else {
      inputs = document.querySelectorAll("input[type='checkbox']");
      for (i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
      }
    }
    this.setState({ checkBoxId: Array.from(checkBoxAll) });

  }

  handleCheckBoxChange(event) {
    const target = event.target;
    //const email = target.id === 'email' ? target.value : this.state.email;

    var checkBoxId = this.state.checkBoxId;
    if (document.getElementById(target.id).checked) {
      checkBoxId.push(target.value);
    } else {
      document.getElementById('checkAll').checked = false;
      checkBoxId = checkBoxId.filter(function (value, index, arr) {
        return value !== target.value;
      });
    }
    this.setState({ checkBoxId: Array.from(checkBoxId) });
  }

  handleDeleteMultiple(event) {
    if (this.state.checkBoxId.length > 0) {
      this.setState({ showDeleteModal: true });
    } else {
      this.createErrorMessage('Please Select One Team Member for Delete', 'errorName', 'errorMessage');
    }
  }

  handleDelete(id) {
    var inputs = document.querySelectorAll(".popover");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove('block');
      inputs[i].classList.add('hidden');
    }

    this.setState({ showDeleteModal: true, checkBoxId: [id] });
  }

  handleConfirmDelete(id) {
    this.setState({ showDeleteModal: false });
    axios.post(`${process.env.REACT_APP_API_URL}auto-assign-city/delete`, this.state.checkBoxId,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(err => {
        throw err;
      })
      .then(res => {
        this.getParam();
      })
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className='notifBar w-full bg-red'>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <div className="cursor-pointer" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)}>
          <i className="fa fa-times" />
        </div>
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  handleValidation(type, value, errorName, errorMessageName, fieldName) {
    var valid = Validation(type, value, errorName, errorMessageName, fieldName);
    if (valid.status == 404) {
      this.createErrorMessage(valid.msg, errorName, errorMessageName);
      return false;
    } else {
      this.setState({
        [errorName]: '',
        [errorMessageName]: ''
      });
      return true;
    }
  }

  checkPopover(id) {
    const button_id = 'button' + id;
    const popup_id = 'popup' + id;
    const button = document.querySelector('#' + button_id);
    const popup = document.querySelector('#' + popup_id);

    if (document.getElementById(popup_id).classList.contains('hidden')) {
      createPopper(button, popup, {
        placement: "left-start",
      });

      var inputs = document.querySelectorAll(".popover");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].classList.remove('block');
        inputs[i].classList.add('hidden');
      }

      popup.classList.remove('hidden');
      popup.classList.add('block');
    } else {
      popup.classList.remove('block');
      popup.classList.add('hidden');
    }
  };

  handleChange(event) {
    //let searchData = this.state.backupData;
    const target = event.target;
    const city = target.id === 'city' ? target.value : this.state.city;

    this.setState({
      cityForm: city,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitted: true });
    var validate = 1;
    let inputData = [];
    if (!this.handleValidation('text', this.state.cityForm, 'firstNameError', 'firstNameErrorMessage', 'First Name')) {
      validate = 0;
    }

    if (validate == 0) {
      this.setState({ isSubmitted: false });
      return false;
    }

    inputData = {
      id: this.state.idForm,
      showroom_id: this.state.showroomData.id,
      city: this.state.cityForm,
      modified_by: jsCookie.get('uid'),
    };
    if (!this.state.idForm) {
      delete (inputData.id)
    }
    axios.post(`${process.env.REACT_APP_API_URL}auto-assign-city`, inputData,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.setState({ isSubmitted: false });
        throw error;
      })
      .then(res => {
        this.setState({
          successMessage:
            <div id='alerts' className='notifBar bg-emerald-400'>
              <div id="ofBar-content" className="notifBar-content">
                <b>Data has been submitted.</b>
              </div>
              <div id="ofBar-right" className="notifBar-right">
                <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert('successMessage')} />
              </div>
            </div>,
          isSubmitted: false,
          cityForm: '',
          showModal: false
        }, () => {
          this.getParam();
        });
      })
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (!this.state.urlStatus) {
      window.location.href = '/404'
    }

    if (!this.state.cityData && this.props.id !== 0) {
      return (<span> Data Not Available</span>)
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.errorMessage}
          {this.state.successMessage}
        </div>
        <div className="flex flex-wrap ">
      <div className="w-full text-white z-30 pb-4 pr-4">
        <Link to="/automate-assign-city"
          className="cursor-pointer text-white"><i className="fa fa-angle-left"></i> Back
        </Link>
      </div>  
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0">

            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="block w-full  ">
                <div className="relative flex flex-col  min-w-0 break-words w-full pb-6 shadow-lg rounded bg-white">
                  <div className="block w-full min-h-screen-75">
                    <div className="w-full lg:w-10/12 xl:w-10/12 md:w-10/12 flex justify-between">
                      <h3 className='font-semibold text-lg text-blueGray-700 w-6/12 float-left py-4 px-4'>{this.state.showroomData.name}</h3>
                      <div className="float-right m-4">
                        <div
                          onClick={e => this.handleShowModal(0)}
                          className="cursor-pointer button-login-white text-black active:bg-blueGray-600 text-2xs font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                          Add City
                        </div>
                      </div>
                    </div>
                    <div className="filter grid-2 w-full h-full rounded-tl rounded-tr bg-blueGray-100 items-center px-4"
                      style={{ height: `90px` }}
                    >
                      <div className="flex flex-wrap pb-4"
                        ref={(divElement) => { this.divElement = divElement }}>
                        <div className="relative my-2 mr-4 xs:w-full ">
                          <div className="text-blueGray-400 text-sm">City</div>
                          <div className="relative">
                            <input
                              type="text"
                              id="city"
                              name="city"
                              placeholder="Filter by City"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.cityFilter}
                              onChange={this.handleChangeFilter}
                            />
                            <span className="h-full leading-snug font-normal absolute right-0 inline-flex items-center text-blueGray-300 text-base mr-2">
                              {!this.state.cityFilled ? <i className="fas fa-user"></i> : <i className="cursor-pointer fas fa-times" onClick={() => this.resetField('city')}></i>}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Projects table */}
                    <div className={"customer-table relative overflow-x-auto bg-white "}>
                      <table className={"items-center bg-white w-full bg-transparent border-collapse overflow-x-scroll"}>
                        <thead>
                          <tr className="border border-l-0 border-r-0 border-blueGray-200">
                            <th className={"px-2 align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 pl-4"} style={{ width: `${30}px` }}>
                              <input
                                name="checkAll"
                                id="checkAll"
                                type="checkbox"
                                className="rounded nofocus"
                                onChange={this.handleCheckAll} />
                            </th>
                            <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500 pl-4"} onClick={() => this.handleSort('name')}>
                              City
                              <i className={`fas ${this.state.nameSort === 'desc' ? "fa-sort-down" : (this.state.nameSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th>
                            <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500 pr-4"}
                              style={{ width: `${30}px` }}
                            >
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isLoading ?
                            <tr key="notfound" className="border border-l-0 border-r-0 border-blueGray-200">
                              <td colSpan="7" className="align-middle text-sm text-center whitespace-nowrap py-4 pr-4">
                                <ClipLoader size={20} color="black" />
                              </td>
                            </tr> :
                            this.state.cityData.length > 0 ?
                              this.state.cityData.map((row) => (
                                <tr key={row.id} className={"border border-l-0 border-r-0 border-blueGray-200"}>
                                  <td className="px-2 align-middle text-sm whitespace-wrap px-4 py-4">
                                    <input
                                      name={`checked${row.id}`}
                                      id={`checked${row.id}`}
                                      type="checkbox"
                                      className="checkbox rounded nofocus"
                                      value={row.id}
                                      data-checked={row.checked}
                                      onChange={this.handleCheckBoxChange} />
                                  </td>
                                  <td className="px-2 align-middle text-sm whitespace-wrap px-4">
                                    {row.city}
                                  </td>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap py-4 pr-4">
                                    <div
                                      className="cursor-pointer text-blueGray-500 py-1 px-3"
                                      href="#"
                                      id={`button${row.id}`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.checkPopover(row.id)
                                      }}
                                    >
                                      <i className="fas fa-ellipsis-v"></i>
                                    </div>
                                    <div
                                      id={`popup${row.id}`}
                                      className={
                                        "popover hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
                                      }
                                    >
                                      <div
                                        className={"cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"}
                                        onClick={e => this.handleShowModal(row.id)}
                                      >
                                        Edit
                                      </div>
                                      <div
                                        className={
                                          "cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                                        }
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleDelete(row.id)
                                        }}
                                      >
                                        Delete
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                              :
                              <tr key="notfound" className="border border-l-0 border-r-0 border-blueGray-200">
                                <td colSpan="7" className="align-middle text-sm text-center whitespace-nowrap py-4 pr-4">
                                  Data Not Available
                                </td>
                              </tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="py-4">
                    <nav className="block">
                      <button
                        className="button-gold mx-4 text-white active:bg-blueGray-600 text-2xs tracking-wide01 uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={this.handleDeleteMultiple}
                      >
                        Delete
                      </button>
                      <ul className="flex rounded list-none flex-wrap float-right mx-4">
                        <div className="mx-4 my-auto">
                          Showing {this.state.startShowing} {this.state.endShowing ? 'to ' + this.state.endShowing : ''} of {this.state.totalData}
                        </div>

                        {/*pagingTmp*/}
                        {this.state.pagingPrevTemplate}
                        {this.state.pagingTemplate}
                        {this.state.pagingNextTemplate}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm ">
                <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-blueGray-100 outline-none focus:outline-none">
                  <div className="flex-auto px-4 py-6  ">
                    <form>
                      <div className="flex flex-wrap">
                        <div className="w-full  mb-2">
                          <div className="relative w-full mb-4">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold"
                            >
                              City
                            </label>
                            <input
                              id="city"
                              name="city"
                              type="text"
                              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                              value={this.state.cityForm}
                              placeholder="City"
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap">
                        <div className="w-full">
                          <div className="relative w-full  text-right">
                            <button
                              type="button"
                              className="button-login text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              onClick={this.handleCloseModal}
                            >
                              Close
                            </button>
                            {this.state.isSubmitted ?
                              <button
                                className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                              ><ClipLoader size={15} color="white" /></button> :
                              <button
                                className={`${this.state.disabledSf ? 'disabled' : ''}  button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                                disabled={this.state.disabledSf ? 'disabled' : ''}
                                onClick={this.handleSubmit}
                              >
                                save
                              </button>
                            }
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}


        {this.state.showDeleteModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto mx-auto">
                    <h6 className="text-center text-black font-bold mx-auto">Do you want to delete this data? </h6>
                  </div>
                  <div className="flex items-center justify-center pb-6 rounded-b text-center">
                    <button
                      className="w-5/12 button-gold text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      type="button"
                      onClick={this.handleConfirmDelete}
                    >
                      ok
                    </button>
                    <button
                      className="w-5/12 button-login text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      type="button"
                      onClick={this.handleCloseDeleteModal}
                    >
                      cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
}

