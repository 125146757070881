
/*eslint-disable eqeqeq*/
export function DateFormat(new_date, format = null) {
  let dateObj = new Date(new_date);
  dateObj.toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })
  const locale = 'en';
  var date = '';
  let hour = '';
  let time = '';
  if (format == null) {
    let date = ("0" + (dateObj.getDate())).slice(-2);
    let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    let year = dateObj.getFullYear();
    hour = ("0" + dateObj.getHours()).slice(-2);
    let minute = ("0" + dateObj.getMinutes()).slice(-2);
    let second = ("0" + dateObj.getSeconds()).slice(-2);

    return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
  } else if (format === 'Mdy') {
    //const day = dateObj.toLocaleDateString(locale, { weekday: 'long' });
    //const date = `${day}, ${dateObj.getDate()} ${dateObj.toLocaleDateString(locale, { month: 'long' })} ${dateObj.getFullYear()}\n\n`;
    date = ` ${dateObj.getDate()} ${dateObj.toLocaleDateString(locale, { month: 'long' })} ${dateObj.getFullYear()}\n\n`;

    return date;
  } else if (format === 'Mdyhi') {
    //const day = dateObj.toLocaleDateString(locale, { weekday: 'long' });
    //const date = `${day}, ${dateObj.getDate()} ${dateObj.toLocaleDateString(locale, { month: 'long' })} ${dateObj.getFullYear()}\n\n`;
    date = ` ${dateObj.getDate()} ${dateObj.toLocaleDateString(locale, { month: 'long' })} ${dateObj.getFullYear()}\n\n`;

    hour = dateObj.getHours();
    time = dateObj.toLocaleTimeString(locale, {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric'
    });
    return date + ' ' + time;
  } else if (format === 'ym') {
    let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    let year = dateObj.getFullYear();
    return year + '-' + month;
  }else if (format === 'ymd') {
    let date = ("0" + (dateObj.getDate())).slice(-2);
    let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    let year = dateObj.getFullYear();
    return year + '-' + month+ '-'+date;
  }else if (format === 'dmy') {
    let date = ("0" + (dateObj.getDate())).slice(-2);
    let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    let year = dateObj.getFullYear();
    return  date+ '-' + month+ '-'+year;
  }
}

export function Validation(type, value, errorName, errorMessageName, fieldName, value2) {
  var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  var passwordPattern = new RegExp(/^(?=.{6,99}$)(?=\w{6,99})(?=.*[A-Z])(?=.*\d)/i);
  var data = [];
  var status = '';
  var msg = '';

  if (type === 'text') {
    if (value) {
      data = {
        status: 200,
        msg: ''
      }
      return data;
    } else {
      data = {
        status: 404,
        msg: `Please fill ${fieldName} field.`
      }
      return data;
    }
  } else if (type === 'email') {
    if (value) {
      if (!emailPattern.test(value)) {
        data = {
          status: 404,
          msg: `Please input valid Email Address.`
        }
        return data;
      } else {
        data = {
          status: 200,
          msg: ''
        }
        return data;
      }
    } else {
      data = {
        status: 404,
        msg: `Please fill ${fieldName} field.`
      }
      return data;
    }
  } else if (type === 'multiple_email') {
    if (value) {
      var tmp_data = value.split(',\n')
      status = 200;
      msg = '';
      tmp_data.forEach((row)=>{
        if(row){
          if (!emailPattern.test(row.replace(',',''))) {
            status= 404
            msg= `Please input valid Email Address.`
          } 
        }
      });
      data = {
        status: status,
        msg: msg
      }
      return data;
    } else {
      data = {
        status: 404,
        msg: `Please fill ${fieldName} field.`
      }
      return data;
    }
  } else if (type === 'select') {
    if (value !== "") {
      data = {
        status: 200,
        msg: ``
      }
      return data;
    } else {
      data = {
        status: 404,
        msg: `Please fill ${fieldName} field.`
      }
      return data;
    }
  } else if (type === 'select2') {
    if (value.length > 0) {
      data = {
        status: 200,
        msg: ``
      }
      return data;
    } else {
      data = {
        status: 404,
        msg: `Please fill ${fieldName} field.`
      }
      return data;
    }
  } else if (type === 'newpassword') {
    if (!passwordPattern.test(value)) {
      data = {
        status: 404,
        msg: `Password should contains one Capital letter, and should be at least 6 character.`
      }
      return data;
    } else {
      if (!value) {
        data = {
          status: 404,
          msg: `Please fill ${fieldName} field.`
        }
        return data;
      } else {
        data = {
          status: 200,
          msg: ``
        }
        return data;

      }
    }
  } else if (type === 'newRepeatePassword') {
    if (!value || value !== value2) {
      data = {
        status: 404,
        msg: `Password did not match.`
      }
      return data;
    } else {
      data = {
        status: 200,
        msg: ``
      }
      return data;

    }
  }else if(type === 'xlsx'){
    if(value){
      if(value.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
        data = {
          status:404,
          msg:`Please input xlsx file.`
        }
        return data;
      }else{
        data = {
          status:200,
          msg:''
        }
        return data;
      }
    }
    else{
      data = {
        status:404,
        msg:`Please fill ${fieldName} field.`
      }
      return data;
    }
  }else if(type === 'phone'){
    if(value){
        //var phone = value.replace(',','').toString().replace(/\D+/g, '');
        var phone = value.replace(',','');
        var er = /^-?[0-9]+$/;
        if (!phone || phone.length < 9 || !er.test(phone)) {
          data = {
            status: 404,
            msg: `Please input valid Phone.`
          }
          return data;
        } else{
          data = {
            status:200,
            msg:''
          }
          return data;
        }
    }else{
        data = {
          status:404,
          msg:`Please fill ${fieldName} field.`
        }
        return data;
    }
  }else if(type === 'multiple_phone'){
    
      if(value){
        data = value.split(',\n')
        status = 200;
        msg = '';
        data.forEach((row)=>{
          if(row){
            var phone = row.replace(',','').toString().replace(/\D+/g, '');
            if (!phone) {
              status= 404
              msg= `Please input valid Phone.`
            } 
          }
        });
  
        data = {
          status: status,
          msg: msg
        }
        return data;
      }
    else{
      data = {
        status:404,
        msg:`Please fill ${fieldName} field.`
      }
      return data;
    }
  }
}

export function numberFormat(value){
  var nf = new Intl.NumberFormat();
  return nf.format(value); 
}