import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import auth from 'global/auth.js';

import jsCookie from 'js-cookie';
import { DateFormat } from "global/custom.js";
//import Customers from "views/customer/Customer.js";
import { numberFormat } from "global/custom.js";
/*eslint-disable eqeqeq*/

class LogUploadClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      path: 'log/upload',
      isLoading: true,
      urlStatus: true,
      uploadLogData: [],
      totalData: 0,
      currentPage: '1',
      perPage: '10',
      pagingPrevTemplate: '',
      pagingTemplate: '',
      pagingNextTemplate: '',
    };

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  getLogData() {
    var url = `${process.env.REACT_APP_API_URL}upload/log?uid=${jsCookie.get('uid')}&perPage=${this.state.perPage}&currentPage=${this.state.currentPage}`;
    if(jsCookie.get('privilege') === 'Super Admin'){
      var url = `${process.env.REACT_APP_API_URL}upload/log?perPage=${this.state.perPage}&currentPage=${this.state.currentPage}`;
    }
    axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        console.log(error);
        throw error;
      })
      .then(res => {
        let data = res.data;
        if (data !== 'Data Empty') {
          this.setState({
            uploadLogData: data.data,
            totalData: data.total,
          },()=>{
            this.handlePaging();
          });
        }
      })
  }

  componentDidMount() {
      if (jsCookie.get('privilege') !== 'Super Admin' && jsCookie.get('privilege') !== 'Uploader' ) {
        this.setState({
          urlStatus: false,
        });
      }
    var checkUrlPath = window.location.pathname;
    var myarr = checkUrlPath.split("/");
    if (myarr[3] !== 'null' && typeof myarr[3] !== 'undefined') {
      this.setState({
        currentPage: myarr[3],
      })
    }
    this.getLogData();
  }

  handlePaging() {
    let currentPage = this.state.currentPage;
    let perpage = this.state.perPage;
    let totalData = this.state.totalData;
    let totalPaging = Math.ceil(totalData / perpage);

    const start = (Number(currentPage) - 1) * perpage;
    const end = Number(start) + Number(perpage);

    let startLoop = 1;
    let endLoop = totalPaging;
    if (Number(currentPage) - 2 > 0 && Number(currentPage) + 2 <= totalPaging) {
      startLoop = Number(currentPage) - 2;
      endLoop = Number(currentPage) + 2;
    } else if (Number(currentPage) - 1 > 0 && Number(currentPage) + 3 <= totalPaging) {
      startLoop = Number(currentPage) - 1;
      endLoop = Number(currentPage) + 3;
    } else if (Number(currentPage) - 3 > 0 && Number(currentPage) + 1 <= totalPaging) {
      startLoop = Number(currentPage) - 3;
      endLoop = Number(currentPage) + 1;
    } else if (currentPage == 1 && Number(currentPage) + 4 <= totalPaging) {
      startLoop = Number(currentPage);
      endLoop = Number(currentPage) + 4;
    } else if (currentPage == totalPaging && Number(currentPage) - 4 > 0) {
      startLoop = Number(currentPage) - 4;
      endLoop = Number(currentPage);
    }

    let pagingPrevTemplate = [];
    let pagingTemplate = [];
    let pagingNextTemplate = [];
    if (Number(currentPage) > 1) {
      pagingPrevTemplate.push(
        <>
          <li key="tofirst">
            <button onClick={e => this.handlePageChange(1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
          <li key="toprev">
            <button onClick={e => this.handlePageChange(currentPage - 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    for (let i = startLoop; i <= endLoop; i++) {
      if (i == currentPage) {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 text-white bg-black">
              {i}
            </button>
          </li>
        )
      } else {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button name={i} onClick={() => this.handlePageChange(i)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              {i}
            </button>
          </li>
        )
      }
    }

    if (currentPage < totalPaging && totalPaging > 1) {
      pagingNextTemplate.push(
        <>
          <li key={`${Number(totalPaging) + 3}`}>
            <button onClick={() => this.handlePageChange(Number(currentPage) + 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
          <li key={`${Number(totalPaging) + 4}`}>
            <button onClick={() => this.handlePageChange(totalPaging)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    if (currentPage > 1) {
      if (currentPage > totalPaging) {
        this.setState({
          currentPage: 1,
        });
      }
    }
    this.setState({
      currentPage: currentPage,
      pagingPrevTemplate: pagingPrevTemplate,
      pagingTemplate: pagingTemplate,
      pagingNextTemplate: pagingNextTemplate,
      startShowing: this.state.uploadLogData.length > 0 ? start + 1 : 0,
      endShowing: start + 1 < totalData ? (end < totalData ? end : totalData) : '',
      totalData: totalData,
    });

  }
  
  handlePageChange(number) {
    var pushUrl = '/' + this.state.path;
    if (number > 1) {
      pushUrl = '/' + this.state.path + '/' + + number
    }
    window.history.pushState('page2', 'Title', pushUrl);
    this.setState({ currentPage: number }, () => {
      this.getLogData();
    });
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }

    if (!this.state.urlStatus) {
      window.location.href = '/404'
    }

    return (
      <>
        <div className="flex flex-wrap ">
        <div className="w-full ">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="rounded-t bg-white mb-0 px-4 py-6 flex">
                </div>
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg ">
                  <div className="block w-full overflow-x-auto rounded-b">
                    <div className="relative flex flex-col  min-w-0 break-words w-full pb-6 shadow-lg bg-white">
                      <div className="block w-full overflow-x-auto">
                        <table className="relative items-center w-full bg-transparent border-collapse overflow-x-scroll">
                          <thead>
                            <tr className="border border-l-0 border-r-0 border-blueGray-200">
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 pl-4"}
                              >
                                #
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 "}
                              >
                                Filename
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 "}
                              >
                                New Enquiry
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 "}
                              >
                                Existing Enquiry
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 "}
                              >
                                Invalid Enquiry
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 "}
                              >
                                Date
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.uploadLogData.length > 0 ?
                              this.state.uploadLogData.map((f, key) => {
                                return (
                                  <tr key={f.id} className="border border-l-0 border-r-0 border-blueGray-200">
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4 pl-4">
                                      {((this.state.currentPage-1)*10)+(key+1)}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.filename}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.success}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.duplicate}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.invalid}
                                    </td>
                                    <td className="px-2 align-middle text-sm py-4">
                                      {DateFormat(f.created_date,'Mdyhi')}
                                    </td>
                                  </tr>
                                )
                              }) :
                              <tr className="border border-l-0 border-r-0 border-blueGray-200">
                                <td colSpan="7" className="align-middle text-sm whitespace-wrap py-4 pl-4 text-center">
                                  Data Not Available
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className="py-2">
                        <nav className="block">
                          <ul className="flex rounded list-none flex-wrap float-right mx-4">
                          <div className="mx-4 my-auto">
                            Showing {this.state.startShowing} {this.state.endShowing ? 'to ' + this.state.endShowing : ''} of {this.state.totalData} Upload Logs
                          </div>
                            {this.state.pagingPrevTemplate}
                            {this.state.pagingTemplate}
                            {this.state.pagingNextTemplate}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </>
    );
  }
}

export default LogUploadClass