import React from "react";

import { Redirect } from 'react-router-dom'
import axios from 'axios';
import jsCookie from 'js-cookie'
// components

import auth from 'global/auth.js';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import * as moment from 'moment'

//import { MultiSelect } from "react-multi-select-component";
import MultiSelect from "react-multi-select-component";

import CardStatsTotalCustomer from "components/Cards/Dashboard_new/CardStatsTotalCustomer.js";
import CardStatsTotalLeads from "components/Cards/Dashboard_new/CardStatsTotalLeads.js";
import CardStatsNotAssignedLeads from "components/Cards/Dashboard_new/CardStatsNotAssignedLeads.js";
import CardStatsTotalEnquiry from "components/Cards/Dashboard_new/CardStatsTotalEnquiry.js";
import CardStatsTotalSpk from "components/Cards/Dashboard_new/CardStatsTotalSpk.js";

import CardChartEnquiryProportion from "components/Cards/Dashboard_new/CardChartEnquiryProportion.js";
import CardChartCustomerProportion from "components/Cards/Dashboard_new/CardChartCustomerProportion.js";
import CardChartLeadsByChannelPieChart from "components/Cards/Dashboard_new/CardChartLeadsByChannelPieChart.js";
import CardChartEnquiryByProductPieChart from "components/Cards/Dashboard_new/CardChartEnquiryByProductPieChart.js";

/*eslint-disable eqeqeq*/

export default function DashboardBehaviour() {

  if (!auth()) {
    return <Redirect to="/login" />;
  }
  return (
    <DashboardBehaviourClass />
  )

}

class DashboardBehaviourClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      privilege: jsCookie.get('privilege'),
      urlStatus: true,

      selectedSeriesFilter: jsCookie.get('selectedSeriesFilter')?JSON.parse(jsCookie.get('selectedSeriesFilter')):[],
      seriesFilter: jsCookie.get('seriesFilter')?jsCookie.get('seriesFilter'):'',
      selectedModelFilter: jsCookie.get('selectedModelFilter')?JSON.parse(jsCookie.get('selectedModelFilter')):[],
      modelFilter: jsCookie.get('modelFilter')?jsCookie.get('modelFilter'):'',
      selectedChannelFilter: jsCookie.get('selectedChannelFilter')?JSON.parse(jsCookie.get('selectedChannelFilter')):[],
      channelFilter: jsCookie.get('channelFilter')?jsCookie.get('channelFilter'):'',
      dateRangeValue:jsCookie.get('dateRangeValue')?jsCookie.get('dateRangeValue'):moment().subtract(6,'days').format('MM/DD/YYYY') + ' - ' + moment().format('MM/DD/YYYY'),
      fromFilter: jsCookie.get('fromFilter')?moment(jsCookie.get('fromFilter')):moment().subtract(6,'days').format('YYYY-MM-DD'),
      toFilter: jsCookie.get('toFilter')?moment(jsCookie.get('toFilter')):moment().format('YYYY-MM-DD'),

      seriesOptions: [],
      modelOptions: [],
      channelOptions: [],
    };
    this.handleEventDatePicker = this.handleEventDatePicker.bind(this);
    this.handleChangeSeries = this.handleChangeSeries.bind(this);
    this.handleChangeModel = this.handleChangeModel.bind(this);
    this.handleChangeChannel = this.handleChangeChannel.bind(this);
  }

  getCarSeries() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/car-series`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          seriesOptions: res.data,
        }, () => {
          this.getCarModel();
        });
      })
  }

  getCarModel() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/car-model-input`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          modelOptions: res.data,
        }, () => {
          this.getChannel();
        });
      })
  }

  getChannel() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/channel`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          channelOptions: res.data,
        }, () => {
          this.checkCookieFilter();
        });
      })
  }

  componentDidMount() {
    document.getElementById('search').value = '';
    this.getCarSeries()
  }

  checkCookieFilter(){
    let selectedSeriesFilter = [];
    let seriesFilter = '';
    let selectedModelFilter = [];
    let modelFilter = '';
    let selectedChannelFilter = [];
    let channelFilter = '';
    let dateRangeValue = moment().subtract(6,'days').format('MM/DD/YYYY') + ' - ' + moment().format('MM/DD/YYYY');
    let fromFilter = moment().subtract(6,'days').format('YYYY-MM-DD');
    let toFilter = moment().format('YYYY-MM-DD');
    if(jsCookie.get('seriesFilter') && jsCookie.get('selectedSeriesFilter')){
      selectedSeriesFilter = JSON.parse(jsCookie.get('selectedSeriesFilter'))
      seriesFilter = jsCookie.get('seriesFilter')
    }
    if(jsCookie.get('modelFilter') && jsCookie.get('selectedModelFilter')){
      selectedModelFilter = JSON.parse(jsCookie.get('selectedModelFilter'))
      modelFilter = jsCookie.get('modelFilter')
    }
    if(jsCookie.get('channelFilter') && jsCookie.get('selectedChannelFilter')){
      selectedChannelFilter = JSON.parse(jsCookie.get('selectedChannelFilter'))
      channelFilter = jsCookie.get('channelFilter')
    }
    if(jsCookie.get('dateRangeValue')){
      dateRangeValue = jsCookie.get('dateRangeValue')
    }
    if(jsCookie.get('fromFilter')){
      fromFilter = jsCookie.get('fromFilter')
    }
    if(jsCookie.get('toFilter')){
      toFilter = jsCookie.get('toFilter')
    }
    this.setState({
      selectedSeriesFilter: selectedSeriesFilter,
      seriesFilter: seriesFilter,
      selectedModelFilter: selectedModelFilter,
      modelFilter: modelFilter,
      selectedChannelFilter: selectedChannelFilter,
      channelFilter: channelFilter,
      dateRangeValue: dateRangeValue,
      fromFilter: fromFilter,
      toFilter: toFilter,
    });
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(status, name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className={'notifBar ' + (status == '404' ? 'bg-red' : 'bg-emerald-400')}>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)} />
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  handleChangeSeries(selected) {
    let filter = '';
    selected.forEach((val) => {
      filter += val.value + ', '
    })

    this.setState({
      selectedModelFilter: [],
      modelFilter: '',
      selectedSeriesFilter: selected,
      seriesFilter: filter
    }, () => {
      jsCookie.set('selectedSeriesFilter',JSON.stringify(selected));
      jsCookie.set('seriesFilter',filter);
      jsCookie.set('selectedModelFilter',[]);
      jsCookie.set('modelFilter','');
    })
  };

  handleChangeModel(selected) {
    let filter = '';
    selected.forEach((val) => {
      filter += val.value + ', '
    })

    this.setState({
      selectedSeriesFilter: [],
      seriesFilter: '',
      selectedModelFilter: selected,
      modelFilter: filter
    }, () => {
      jsCookie.set('selectedSeriesFilter',[]);
      jsCookie.set('seriesFilter','');
      jsCookie.set('selectedModelFilter',JSON.stringify(selected));
      jsCookie.set('modelFilter',filter);
    })
  };

  handleChangeChannel = (selected) => {
    let filter = '';
    selected.forEach((val) => {
      filter += val.value + ', '
    })
    this.setState({
      selectedChannelFilter: selected,
      channelFilter: filter
    }, () => {
      jsCookie.set('selectedChannelFilter',JSON.stringify(selected));
      jsCookie.set('channelFilter',filter);
    })
  };

  handleEventDatePicker(event, picker) {
    if (event.type === 'apply') {
      this.setState({
        dateRangeValue: picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'),
        fromFilter: picker.startDate.format('YYYY-MM-DD'),
        toFilter: picker.endDate.format('YYYY-MM-DD'),
      }, () => {
        jsCookie.set('dateRangeValue',picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        jsCookie.set('fromFilter',picker.startDate.format('YYYY-MM-DD'));
        jsCookie.set('toFilter',picker.endDate.format('YYYY-MM-DD'));
      })
    } else if (event.type === 'hide') {
      if (this.state.fromFilter == '') {
        this.setState({
          dateRangeValue: '',
        });
      } else {
        this.setState({
          dateRangeValue: moment(this.state.fromFilter).format('MM/DD/YYYY') + ' - ' + moment(this.state.toFilter).format('MM/DD/YYYY'),
        })
      }
    }
  }

  handleResetDatePicker() {
    let dateRangeValue = moment().subtract(6,'days').format('MM/DD/YYYY') + ' - ' + moment().format('MM/DD/YYYY');
    let fromFilter = moment().subtract(6,'days').format('YYYY-MM-DD');
    let toFilter = moment().format('YYYY-MM-DD');
    this.setState({
      dateRangeValue: dateRangeValue,
      fromFilter: fromFilter,
      toFilter: toFilter,
    }, () => {
      jsCookie.set('dateRangeValue',dateRangeValue);
      jsCookie.set('fromFilter',fromFilter);
      jsCookie.set('toFilter',toFilter);
    })
  }
  
  render() {
    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.errorMessage}
        </div>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-12/12 xl:w-12/12 px-4 ">
            <div className="relative flex flex-col min-w-0 break-words bg-blueGray-200 w-full mb-6 shadow-lg rounded">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">

                  <div className="relative my-2 mr-4 w-auto sm:w-full">
                    <div className="relative">
                      <MultiSelect
                        options={this.state.seriesOptions}
                        value={this.state.selectedSeriesFilter}
                        onChange={this.handleChangeSeries}
                        showCheckbox={false}
                        hasSelectAll={true}
                        id="series"
                        overrideStrings={{
                          "selectSomeItems": "Filter by Series"
                        }
                        }
                      />
                    </div>
                  </div>

                  <div className="relative my-2 mr-4 w-auto sm:w-full">
                    <div className="relative">
                      <MultiSelect
                        options={this.state.modelOptions}
                        value={this.state.selectedModelFilter}
                        onChange={this.handleChangeModel}
                        showCheckbox={false}
                        hasSelectAll={true}
                        id="model"
                        overrideStrings={{
                          "selectSomeItems": "Filter by Model"
                        }
                        }
                      />
                    </div>
                  </div>

                  <div className="relative my-2 mr-4 w-auto sm:w-full">
                    <div className="relative">
                      <MultiSelect
                        id="channel"
                        options={this.state.channelOptions}
                        value={this.state.selectedChannelFilter}
                        onChange={this.handleChangeChannel}
                        showCheckbox={false}
                        hasSelectAll={true}
                        overrideStrings={{
                          "selectSomeItems": "Filter by Channel"
                        }
                        }
                      />
                    </div>
                  </div>

                  <div className="relative my-2 mr-4 xs:w-full">
                    <div className="relative">
                      <DateRangePicker
                        startDate={this.state.fromFilter}
                        endDate={this.state.toFilter}
                        initialSettings={{
                          ranges: {
                            'Today': [moment(), moment()],
                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                          },
                          alwaysShowCalendars:true,
                        }}
                        onEvent={this.handleEventDatePicker}
                      >
                        <input type="text"
                          value={this.state.dateRangeValue}
                          placeholder="mm-dd-yyyy - mm-dd-yyyy"
                          style={{ width: `${200}px` }}
                          className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                      </DateRangePicker>
                      <span className={(this.state.dateRangeValue ? 'mr-2' : '') + "  h-full font-normal absolute right-0 text-center text-blueGray-300 items-center mr-3 py-2"}>
                        {this.state.dateRangeValue ? <i className="cursor-pointer fa fa-times"
                          onClick={() => this.handleResetDatePicker()}
                        ></i> :
                          <i className=" fa fa-calendar-alt"
                          ></i>
                        }

                      </span>
                    </div>
                  </div>
                  <div className="relative w-full">
                    You can only select one to filter the following <i>series</i> or <i>models</i>.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.privilege === 'Super Admin' ?
            <>
              <div className="w-full lg:w-6/12 xl:w-1/5 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsTotalCustomer filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-1/5 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsTotalSpk filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-1/5 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsNotAssignedLeads filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-1/5 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsTotalLeads filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-1/5 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsTotalEnquiry filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartEnquiryProportion filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartLeadsByChannelPieChart filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartEnquiryByProductPieChart filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
            </>
            : ''}

          {this.state.privilege === 'Sales Manager' ?
            <>
              
              <div className="w-full lg:w-6/12 xl:w-1/5 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsTotalCustomer filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-1/5 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsTotalSpk filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-1/5 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsNotAssignedLeads filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-1/5 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsTotalLeads filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-1/5 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsTotalEnquiry filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartEnquiryProportion filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartLeadsByChannelPieChart filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartEnquiryByProductPieChart filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
            </>
            : ''}

          {this.state.privilege === 'Brand Associate' ?
            <>
              
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsTotalCustomer filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsTotalSpk filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsTotalLeads filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsTotalEnquiry filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartEnquiryProportion filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartLeadsByChannelPieChart filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartEnquiryByProductPieChart filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
            </>
            : ''}


        </div>
      </>
    );
  }
}