import React from "react";
import { Switch, Route, Redirect} from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import DashboardAudience from "views/dashboard/Audience.js";
import DashboardBehaviour from "views/dashboard/Behaviour.js";
import DashboardAcquisition from "views/dashboard/Acquisition.js";

import Profile from "views/profile/Profile.js";
import ProfileEdit from "views/profile/Profile_edit.js";

import Users from "views/users/Users.js";
import UsersDetail from "views/users/Users_detail.js";
import UsersEdit from "views/users/Users_edit.js";

import Customer from "views/customer/Customer.js";
import CustomerDetail from "views/customer/Customer_detail.js";
import CustomerEdit from "views/customer/Customer_edit.js";
import CustomerAssign from "views/customer/Customer_assign.js";

import Upload from "views/data/Upload.js";
//import Sync from "views/data/Sync.js"; //sync email
import Odoo from "views/data/Odoo.js";
import SyncToOdoo from "views/data/Sync_to_odoo.js";

import DownloadCustomer from "views/download/Customer.js";
import DownloadEnquiry from "views/download/Enquiry.js";

import NotificationDetail from "views/NotificationDetail";

import Integrations from "views/data/Integrations.js";

import Search from "views/search/Search.js";

import Uploader from "views/data/Uploader.js";

import AutomateAssignCity from "views/automateAssignCity/automateAssignCity.js";
import AutomateAssignCityDetail from "views/automateAssignCity/AutomateAssignCityDetail.js";

import AutomateAssign from "views/automateAssign/automateAssign.js";

import Enquiries from "views/enquiries/Enquiries.js";
import OfflineLeads from "views/offline-leads/Form.js";

import LogUpload from "views/log/Upload.js";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-black min-h-screen-100">
        <AdminNavbar />
        <div className="relative bg-header md:pt-32 pb-20 pt-12">
        </div>
          <div className="px-7  bg-black mx-auto w-full -m-24 h-full">
              <Switch>
                <Route path="/dashboard/audience" exact>
                  <DashboardAudience />
                </Route>
                <Route path="/dashboard/behaviour" exact>
                  <DashboardBehaviour />
                </Route>
                <Route path="/dashboard/acquisition" exact>
                  <DashboardAcquisition />
                </Route>

                <Route path="/profile/edit" exact>
                  <ProfileEdit />
                </Route>
                <Route path="/profile">
                  <Profile />
                </Route>

                <Route path="/users/edit/:id(\d+)" exact >
                  <UsersEdit />
                </Route>
                <Route path="/users/:id(\d+)">
                  <UsersDetail />
                </Route>
                <Route path="/users">
                  <Users />
                </Route>
                <Route path="/users/:users(\w+)" >
                  <Users />
                </Route>
                
                <Route path="/leads/assign/:userId(\d+)">
                  <CustomerAssign />
                </Route>
                <Route path="/leads/assign" >
                  <CustomerAssign />
                </Route>

                <Route path="/leads/edit/:customerId(\d+)"   exact>
                  <CustomerEdit />
                </Route>
                <Route path="/leads/:customerId(\d+)" exact>
                  <CustomerDetail />
                </Route>
                <Route path="/leads/:user(\w+)"   exact>
                  <Customer />
                </Route>
                <Route path="/leads" component={Customer}>
                </Route>
                
                <Route path="/enquiries" component={Enquiries}>
                </Route>

                <Route path="/offline-leads" component={OfflineLeads}>
                </Route>

                <Route path="/log/upload">
                  <LogUpload />
                </Route>

                <Route path="/search/:name" >
                  <Search />
                </Route>
                
                <Route path="/upload">
                  <Upload />
                </Route>

                {/*<Route path="/sync">
                  <Sync />
                </Route>*/}

                <Route path="/odoo">
                  <Odoo />
                </Route>
                
                <Route path="/sync_to_odoo">
                  <SyncToOdoo />
                </Route>
                
                <Route path="/integrations">
                  <Integrations />
                </Route>
                
                <Route path="/download/leads">
                  <DownloadCustomer />
                </Route>
                <Route path="/download/enquiry">
                  <DownloadEnquiry />
                </Route>
                
                <Route path="/notification/detail" exact>
                  <NotificationDetail />
                </Route>
                
                <Route path="/uploader">
                  <Uploader />
                </Route>
                
                <Route path="/automate-assign-city/:showroom_id(\d+)">
                  <AutomateAssignCityDetail />
                </Route>

                <Route path="/automate-assign-city">
                  <AutomateAssignCity />
                </Route>
                
                <Route path="/automate-assign">
                  <AutomateAssign />
                </Route>

                <Route path="/" exact>
                  <Redirect from="/" to="/dashboard" />
                </Route>
                <Route path="/*">
                  <Redirect from="/*" to="/404" />
                </Route>

                
              </Switch>
            <FooterAdmin />
          </div>
        </div>
    </>
  );
}
