import React from "react";
import jsCookie from 'js-cookie';
import { Switch, Route } from "react-router-dom";

import UserDropdown from "components/Dropdowns/UserDropdown.js";
import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";

export default class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      search: '',
      submit: false,
    };

    /*checkbox*/
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.resetSubmit = this.resetSubmit.bind(this);

  }
  componentDidMount() {
    this.setState({
      submit: false,
    });
  }

  handleChange(event) {
    const target = event.target;
    const search = target.id === 'search' ? target.value : this.state.search;

    this.setState({
      search: search,
    });
  }
  handleSearch(event) {
    event.preventDefault();
    if (this.state.search) {
      this.setState({
        submit: true,
        url: '/search/' + encodeURI(this.state.search),
      });
    }
  }
  resetSubmit() {
    this.setState({
      submit: false,
    });
  }
  render() {
    if (this.state.submit) {
      this.resetSubmit()
      //return <Redirect to={this.state.url} />;
      window.location.href = this.state.url;
    }
    return (
      <>
        {/* Navbar */}
        <nav className="hidden md:block absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
          <div className="w-full mx-auto items-center flex px-2">
            {/* Brand */}
            <span
              className="w-full lg:w-6/12 xl:w-3/12 text-white text-md uppercase hidden lg:inline-block font-semibold ml-4"
            >
              <Switch>
                <Route path="/dashboard" exact>
                  Dashboard
                </Route>
                <Route path="/dashboard/audience" exact>
                  Audience
                </Route>
                <Route path="/dashboard/behaviour" exact>
                  Behaviour
                </Route>
                <Route path="/dashboard/acquisition" exact>
                  Acquisition
                </Route>
                <Route path="/leads">
                  Leads
                </Route>
                <Route path="/enquiries">
                  Enquiries
                </Route>
                <Route path="/offline-leads">
                  Offline Leads
                </Route>
                <Route path="/log/upload">
                  Upload Log
                </Route>
                <Route path="/users">
                  Team Members
                </Route>
                <Route path="/users/:id">
                  Team Members
                </Route>
                <Route path="/profile">
                  My Profile
                </Route>
                <Route path="/search">
                  Search
                </Route>
              </Switch>
            </span>
            {/* Form */}
            <form className="block w-full lg:w-6/12 xl:w-6/12 mr-3"
              onSubmit={this.handleSearch}
            >
              <div className={`relative flex w-full flex-wrap items-stretch ` + (jsCookie.get('privilege') !== 'Uploader' ? '' : 'hidden')}>
                <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                  <i className="fas fa-search"></i>
                </span>
                <input
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search Customer or Team"
                  className="bg-search nofocus rounded border-0 px-3 py-3 placeholder-blueGray-300 text-black relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                  onChange={this.handleChange}
                />
              </div>
            </form>
            {/* User */}
            <div className="w-full lg:w-6/12 xl:w-3/12 items-center flex justify-end  text-white mr-3">
              <span className="mr-3 text-white">
                {jsCookie.get('name') ? jsCookie.get('name').split(' ')[0] : ''}
              </span>
              <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
                <UserDropdown />
                {jsCookie.get('privilege') !== 'Super Admin' && jsCookie.get('privilege') !== 'Uploader' ? <NotificationDropdown /> : ''}
              </ul>
            </div>
          </div>
        </nav>
        {/* End Navbar */}
      </>
    );
  }
}
