import React from "react";
import axios from 'axios';
import Chart from 'chart.js/auto'
import { colorsDefault } from 'global/data'
import jsCookie from 'js-cookie';
var randomColor = require('randomcolor'); // import the script
/*eslint-disable eqeqeq*/

export default function CardChartEnquiryByChannel({ filterSeries, filterModel, filterChannel, filterFrom, filterTo }) {
  return (
    <CardChartEnquiryByChannelClass filterSeries={filterSeries} filterModel={filterModel} filterChannel={filterChannel} filterFrom={filterFrom} filterTo={filterTo} />
  )
}

class CardChartEnquiryByChannelClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filterSeries: this.props.filterSeries,
      filterModel: this.props.filterModel,
      filterChannel: this.props.filterChannel,
      filterFrom: this.props.filterFrom,
      filterTo: this.props.filterTo,
      data: [],
      chart: '',
      chartCreated: false,
    };
  }

  getData() {
    var url = `${process.env.REACT_APP_API_URL}dashboard/leads/channel`;
    const filter = {
      series: this.state.filterSeries,
      model: this.state.filterModel,
      channel: this.state.filterChannel,
      dateFrom: this.state.filterFrom,
      dateTo: this.state.filterTo,
      leads: "yes",
      level: jsCookie.get('privilege'),
      id: jsCookie.get('uid')
    };
    axios.post(url, filter, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          data: res.data,
          showNoData: res.data.data.length > 0 ? false : true,
        }, () => {
          this.processData();
        });
      })
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevProps.filterSeries != this.props.filterSeries) ||
      (prevProps.filterModel != this.props.filterModel) ||
      (prevProps.filterChannel != this.props.filterChannel) ||
      (prevProps.filterFrom != this.props.filterFrom) ||
      (prevProps.filterTo != this.props.filterTo)
    ) {
      this.setState({
        filterSeries: this.props.filterSeries == 'Series' ? '' : this.props.filterSeries,
        filterModel: this.props.filterModel,
        filterChannel: this.props.filterChannel == 'Channel' ? '' : this.props.filterChannel,
        filterFrom: this.props.filterFrom,
        filterTo: this.props.filterTo,
      }, () => {
        this.getData();
      });
    }
  }

  processData() {
    let data = this.state.data;
    if (data.data) {
      let labels = data.labels;
      data.data.forEach((element, key) => {
        let color = colorsDefault[key] ? colorsDefault[key] : randomColor({
          luminosity: 'bright',
          format: 'rgb'
        });
        data.data[key].backgroundColor = color
        data.data[key].borderColor = color
      });
      let datasets = data.data;
      this.createChart(labels, datasets);
    } else {
      this.setState({
        chart: '',
        chartCreated: false
      });
    }
  }

  createChart(labels, datasets) {
    let config = {
      type: "line",
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: {
            display: true,
            ticks: {
              color: "rgba(0,0,0)",
              font: {
                size: 14
              }
            },
          },
          x: {
            display: true,
            ticks: {
              color: "rgba(0,0,0)",
              font: {
                size: 14
              },
              callback: function (value, index, ticks) {
                return this.getLabelForValue(value).replace(/\((.+?)\)/g, '');
              }
            }
          }
        },
        plugins: {
          title: {
            display: false,
            text: "Orders Chart",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          legend: {
            display: true,
            labels: {
              color: "rgba(0,0,0)",
              font: {
                size: 14
              }
            },
            align: "center",
            position: "bottom",
          },
        }
      },
    };
    if (!this.state.chartCreated) {
      let ctx = document.getElementById("CardChartEnquiryByChannelBar").getContext("2d");
      var chart = new Chart(ctx, config);
      this.setState({
        chart: chart,
        chartCreated: true
      })
    } else {
      chart = this.state.chart;
      chart.data.labels = labels;
      chart.data.datasets = datasets;
      chart.update();
    }
  }

  createChart2(labels, datasets) {
    let config = {
      type: "line",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "IG",
            backgroundColor: colorsDefault[0],
            borderColor: colorsDefault[0],
            data: [30, 78, 56, 34, 100, 45, 13],
            fill: false,
            tension: 0,
          },
          {
            label: "Website",
            fill: false,
            backgroundColor: colorsDefault[1],
            borderColor: colorsDefault[1],
            data: [27, 68, 86, 74, 10, 4, 87],
            tension: 0,
          },
          {
            label: "SEM",
            fill: false,
            backgroundColor: colorsDefault[2],
            borderColor: colorsDefault[2],
            data: [50, 1, 66, 20, 1, 4, 30],
            tension: 0,
          },
          {
            label: "Leads Ads",
            fill: false,
            backgroundColor: colorsDefault[3],
            borderColor: colorsDefault[3],
            data: [2, 20, 44, 30, 20, 4, 50],
            tension: 0,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          mode: "nearest",
        },
        scales: {
          y: {
            display: true,
            ticks: {
              color: "rgba(0,0,0)",
              font: {
                size: 14
              }
            },
          },
          x: {
            display: true,
            ticks: {
              color: "rgba(0,0,0)",
              font: {
                size: 14
              }
            },
          }
        },
        plugins: {
          tooltips: {
            mode: "datasets",
            intersect: false,
          },
          legend: {
            display: true,
            labels: {
              color: "rgba(0,0,0)",
              font: {
                size: 14
              }
            },
            align: "center",
            position: "bottom",
          },
        }

      },
    };
    if (!this.state.chartCreated) {
      let ctx = document.getElementById("CardChartEnquiryByChannelBar").getContext("2d");
      var chart = new Chart(ctx, config);
      this.setState({
        chart: chart,
        chartCreated: true
      })
    } else {
      chart = this.state.chart;
      chart.data.labels = labels;
      chart.data.datasets = datasets;
      chart.update();
    }
  }

  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded h-full">
          <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1 text-center">
                <h3 className="text-blueGray-700 text-base font-bold">
                  Leads By Channel
                </h3>
              </div>
            </div>
          </div>
          <div className="p-4 flex-auto">
            <div className={"relative h-350-px "}>
              {this.state.data ?
                <canvas id="CardChartEnquiryByChannelBar"></canvas>
                : <div className="text-center text-xl text-gray my-auto">Data Not Available</div>}
            </div>
          </div>
        </div>
      </>
    );
  }

}