import React from "react";
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios';
// components
import * as moment from 'moment'

import jsCookie from 'js-cookie';
import auth from 'global/auth.js';

import ClipLoader from "react-spinners/ClipLoader";

import ReactExport from "react-data-export";
import { DateFormat } from "global/custom.js";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/*eslint-disable eqeqeq*/

export default function Customer() {
  return (
    <CustomerClass />
  )

}

class CustomerClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      importCsvData: [],
      getData: true,
      privilege: jsCookie.get('privilege'),
      showConfirmation: false,
      confirmCancel: false,
      showOverwriteMsg: false,
    };
  }

  componentDidMount() {
    var url = `${process.env.REACT_APP_API_URL}customers`
    if(jsCookie.get('privilege') === 'Sales Manager'){
      url = `${process.env.REACT_APP_API_URL}customers?sales_manager_id=${jsCookie.get('uid')}`
    }else if(jsCookie.get('privilege') === 'Brand Associate'){
      url = `${process.env.REACT_APP_API_URL}customers?brand_associate_id=${jsCookie.get('uid')}`
    }
    axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        if (data !== 'Data Empty') {
          this.setState({
            getData: false,
            importCsvData: data.customers_data,
          }, () => {
          });
        }
      })
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 z-100">
          {this.state.fileErrorMessage}
        </div>
        <div
          className="w-full lg:w-6/12 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mx-auto"
        >
          <div className="relative w-full mx-auto max-w-sm">
            <div>
              <img
                src={require("assets/img/logo/logo-white.svg").default}
                alt="Lexus Logo"
                className="relative border-none max-w-150-px  mx-auto"
              />
            </div>
            <div className="border-0 rounded shadow-lg py-4 relative flex flex-col w-full bg-blueGray-200 outline-none focus:outline-none">
              {this.state.getData ? (
                <>
                  <div className="w-full relative p-6 flex-auto mx-auto items-center">
                    <h6 className="w-full text-center text-black font-bold mx-auto uppercase">Download Leads Data</h6>
                    <p className="mt-2 text-gray text-sm text-center leading-relaxed">
                      Please wait until the process finished.
                    </p>
                  </div>
                  <div className="flex  justify-center pb-6 rounded-b px-4">
                    <form>

                      <div className="w-full">
                        <div className="relative w-full mb-3">
                          <ClipLoader size={30} color="rgba(190, 157, 115)" />
                        </div>
                      </div>
                    </form>
                  </div>
                </>) : (
                <>
                  <div className="w-full relative p-6 flex-auto mx-auto items-center">
                    <h6 className="w-full text-center text-black font-bold mx-auto uppercase">Download Leads Data</h6>
                    <p className="mt-2 text-gray text-sm text-center leading-relaxed">
                      Data ready to download
                    </p>
                  </div>
                  <div className="w-full relative   justify-center pb-6 rounded-b px-12">
                    <form>
                      <div className="flex flex-wrap pt-4">
                        <div className="w-full">
                          <div className="relative w-full items-center text-center">
                            <ExcelFile
                              filename={"Customers & Leads Data " + moment(new Date(), 'DD-MM-YYYY', true).format('DD-MM-YYYY') + ".csv"}
                              element={
                                <button className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                >
                                  Download Now</button>
                              }
                            >
                              <ExcelSheet data={this.state.importCsvData} name="Leads"
                              >
                                <ExcelColumn label="First Name" value="first_name" />
                                <ExcelColumn label="Last_name" value="last_name" />
                                <ExcelColumn label="Email" value="email" />
                                <ExcelColumn label="Phone" value="phone" />
                                <ExcelColumn label="City" value="city" />
                                <ExcelColumn label="Gallery" value="showroom_name" />
                                <ExcelColumn label="Sales Manager" value="sales_manager_name" />
                                <ExcelColumn label="Brand Associate" value="brand_associate_name" />
                                <ExcelColumn label="Last Enquiry Subject" value="last_enquiry" />
                                <ExcelColumn label="Last Enquiry Date" 
                                 value={(col) => DateFormat(col.last_enquiry_date,'ymd')}/>
                                <ExcelColumn label="Last Enquiry Preferred Model" value="last_enquiry_car_preference" />
                                <ExcelColumn label="Last Enquiry Channel" value="last_enquiry_source"/> 
                                <ExcelColumn label="Last Enquiry Follow Up Status" value="followup_status" />
                              </ExcelSheet>
                            </ExcelFile>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap pt-4 mt-4">
                        <div className="w-full">
                          <div className="relative w-full items-center text-center">
                            <Link
                              className="button-login text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 my-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              to="/leads"
                            //ref={this.downloadBtn}
                            >
                              Close
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="fixed inset-0 z-40  bg-login"></div>

      </>


    );
  }
}