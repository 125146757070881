import "@fortawesome/fontawesome-free/css/all.min.css";
import 'assets/styles/custom.css';
import "assets/styles/tailwind.css";
// layouts
import Admin from "layouts/Admin.js";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ForgotPassword from "views/ForgotPassword.js";
// views without layouts
import Login from "views/Login.js";
import Logout from "views/Logout.js";
import NotFound from "views/NotFound.js";
import ResetPassword from "views/ResetPassword.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />
      <Route path="/login/forgotpassword" exact component={ForgotPassword} />
      <Route path="/login/resetpassword" exact component={ResetPassword} />
      <Route path="/logout" exact component={Logout} />
      <Route path="/404" component={NotFound} />
      <Route path="/*" component={Admin} />

      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
