import React from 'react'
import {  Redirect } from 'react-router-dom'
import axios from 'axios';
import auth from 'global/auth.js';

import jsCookie from 'js-cookie';

import ClipLoader from "react-spinners/ClipLoader";
/*eslint-disable eqeqeq*/

export default function Users() {
  if (jsCookie.get('privilege') == 'Uploader') {
    return <Redirect to="/404" />;
  }
  return (
    <>
      <UsersClass />
    </>
  );
}
class UsersClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assigned_data: [],
      unassignedCity_data: [],
      isLoading: true,
    };

  }

  autoAssign() {
    axios.get(`${process.env.REACT_APP_API_URL}automate-assign`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.setState({ isSubmitted: false });
        throw error;
      })
      .then(res => {
        this.setState({
          assigned_data: res.data.assignedCustomers,
          //unassignedCity_data: res.data.unassignedCity,
          isLoading: false
        });
      })
  }

  componentDidMount() {
    this.autoAssign();
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.errorMessage}
        </div>
        <div className="flex flex-wrap ">
          <div className="w-full xl:w-12/12 mb-8 xl:mb-0">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="block w-full  ">
                <div className="relative flex flex-col  min-w-0 break-words w-full pb-6 shadow-lg rounded bg-white">
                  <div className="block w-full ">
                    <div className="w-full lg:w-10/12 xl:w-10/12 md:w-10/12 flex-grow flex-1">
                      <div className="float-right m-4">
                      </div>
                    </div>
                    <div className="filter grid-2 w-full h-full rounded-tl rounded-tr bg-blueGray-100 items-center px-4"
                    >
                    </div>
                      <div className={"customer-table relative overflow-x-auto bg-white "}>
                    {/* Projects table */}
                    {this.state.isLoading ?
                        <div className="text-center items-center">
                          <ClipLoader size={20} color="black" />
                        </div>
                      :
                      <div className="ml-2">
                          <div>Automate Assign Success: {this.state.assigned_data.length}</div>
                          {/*<div>Unknown City: {this.state.unassignedCity_data.length}</div>*/}
                          </div>
                    }
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
<div className='flex'>
                        {/*new enquiry Leads asd*/}
                        <div className="w-full lg:w-12/12">
                          <div className="relative flex flex-col min-w-0 break-words w-full">
                            <div className="flex flex-wrap">
                              <div className="w-full">
                                <div className="rounded-t bg-white mb-0 px-4 py-6 flex">
                                  <div className="w-full lg:w-6/12 relative  ">
                                    <h3 className="text-blueGray-700 text-xs font-bold uppercase">Automate Assign Success</h3>
                                  </div>
                                </div>
                                <div className="relative flex flex-col min-w-0 w-full mb-2 shadow-lg ">
                                  <div className="block w-full overflow-x-auto rounded-b">
                                    <div className="relative flex flex-col  min-w-0 w-full shadow-lg bg-white">
                                      <div className="block w-full overflow-x-auto rounded-b h-300-px ">
                                        <table className="relative items-center w-full overflow-y-scroll">
                                          <thead>
                                            <tr className="border border-l-0 border-r-0 border-blueGray-200">
                                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                              >
                                                First Name
                                              </th>
                                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                              >
                                                Last Name
                                              </th>
                                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                              >
                                                Gallery
                                              </th>
                                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                              >
                                                Sales Manager
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.assigned_data.length > 0 ?
                                              this.state.assigned_data.map((f, key) => {
                                                return (
                                                  <tr key={f.id} className={(f.followup_status === 'No' ? 'bg-table-red' : null) + " border border-l-0 border-r-0 border-blueGray-200"}>
                                                    <td className="px-2 align-middle text-sm  py-4">
                                                      {f.first_name}
                                                    </td>
                                                    <td className="px-2 align-middle text-sm  py-4">
                                                      {f.last_name}
                                                    </td>
                                                    <td className="px-2 align-middle text-sm  py-4">
                                                      {f.showroom_name}
                                                    </td>
                                                    <td className="px-2 align-middle text-sm  py-4">
                                                      {f.sales_manager_name}
                                                    </td>
                                                  </tr>
                                                )
                                              }) :
                                              <tr className="border border-l-0 border-r-0 border-blueGray-200">
                                                <td colSpan="4" className="align-middle text-sm whitespace-wrap py-4 pl-4 text-center">
                                                  No Assigned Customers / Leads
                                                </td>
                                              </tr>
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="py-4">
                                        <nav className="block">
                                          <ul className="flex rounded list-none flex-wrap float-right mx-4">
                                          </ul>
                                        </nav>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*new enquiry Leads*/}
                        
                        {/*Unknown City*/}
                        {/*
                        <div className="w-full lg:w-3/12">
                          <div className="relative flex flex-col min-w-0 break-words w-full">
                            <div className="flex flex-wrap">
                              <div className="w-full">
                                <div className="rounded-t bg-white mb-0 px-4 py-6 flex">
                                  <div className="w-full lg:w-6/12 relative  ">
                                    <h3 className="text-blueGray-700 text-xs font-bold uppercase">Unknown City</h3>
                                  </div>
                                </div>
                                <div className="relative flex flex-col min-w-0 w-full mb-2 shadow-lg ">
                                  <div className="block w-full overflow-x-auto rounded-b">
                                    <div className="relative flex flex-col  min-w-0 w-full shadow-lg bg-white">
                                      <div className="block w-full overflow-x-auto rounded-b h-300-px ">
                                        <table className="relative items-center w-full overflow-y-scroll">
                                          <tbody>
                                            {this.state.unassignedCity_data ?
                                              this.state.unassignedCity_data.map((f, key) => {
                                                return (
                                                  <tr key={f.id} className={(f.followup_status === 'No' ? 'bg-table-red' : null) + " border border-l-0 border-r-0 border-blueGray-200"}>
                                                    <td className="px-2 align-middle text-sm  py-4">
                                                      {f.city}
                                                    </td>
                                                    
                                                  </tr>
                                                )
                                              }) :
                                              <tr className="border border-l-0 border-r-0 border-blueGray-200">
                                                <td colSpan="7" className="align-middle text-sm whitespace-wrap py-4 pl-4 text-center">
                                                  No Unknown City
                                                </td>
                                              </tr>
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="py-4">
                                        <nav className="block">
                                          <ul className="flex rounded list-none flex-wrap float-right mx-4">
                                          </ul>
                                        </nav>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        */}
                        {/*Unknown City*/}
                        </div>
      </>
    );
  }
}

