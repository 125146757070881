import React from "react";

import { Redirect } from 'react-router-dom'
import axios from 'axios';
import jsCookie from 'js-cookie'
import ReactTooltip from "react-tooltip";
// components

import auth from 'global/auth.js';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import * as moment from 'moment'
//import {default as Select, components } from 'react-select'
//import { MultiSelect } from "react-multi-select-component";
import MultiSelect from "react-multi-select-component";

import CardStatsMostPerformingDealer from "components/Cards/Dashboard_new/CardStatsMostPerformingDealer.js";
import CardStatsMostPerformingChannel from "components/Cards/Dashboard_new/CardStatsMostPerformingChannel.js";
import CardStatsMostPerformingSeries from "components/Cards/Dashboard_new/CardStatsMostPerformingSeries.js";
import CardStatsMostPerformingBA from "components/Cards/Dashboard_new/CardStatsMostPerformingBA.js";

import CardChartSpkTotalByChannel from "components/Cards/Dashboard_new/CardChartSpkTotalByChannel.js";
import CardChartSpkTotalByDealer from "components/Cards/Dashboard_new/CardChartSpkTotalByDealer.js";
import CardChartSpkTotalBySeries from "components/Cards/Dashboard_new/CardChartSpkTotalBySeries.js";
import CardChartSpkTotalByBA from "components/Cards/Dashboard_new/CardChartSpkTotalByBA.js";

import CardFunnelConversionTotal from "components/Cards/Dashboard_new/CardFunnelConversionTotal.js";

import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
/*eslint-disable eqeqeq*/

export default function DashboardAcquisition() {

  if (!auth()) {
    return <Redirect to="/login" />;
  }
  return (
    <DashboardAcquisitionClass />
  )

}

class DashboardAcquisitionClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      privilege: jsCookie.get('privilege'),
      urlStatus: true,

      selectedSeriesFilter: jsCookie.get('selectedSeriesFilter') ? JSON.parse(jsCookie.get('selectedSeriesFilter')) : [],
      seriesFilter: jsCookie.get('seriesFilter') ? jsCookie.get('seriesFilter') : '',
      selectedModelFilter: jsCookie.get('selectedModelFilter') ? JSON.parse(jsCookie.get('selectedModelFilter')) : [],
      modelFilter: jsCookie.get('modelFilter') ? jsCookie.get('modelFilter') : '',
      selectedChannelFilter: jsCookie.get('selectedChannelFilter') ? JSON.parse(jsCookie.get('selectedChannelFilter')) : [],
      channelFilter: jsCookie.get('channelFilter') ? jsCookie.get('channelFilter') : '',
      dateRangeValue: jsCookie.get('dateRangeValue') ? jsCookie.get('dateRangeValue') : moment().subtract(6, 'days').format('MM/DD/YYYY') + ' - ' + moment().format('MM/DD/YYYY'),
      fromFilter: jsCookie.get('fromFilter') ? moment(jsCookie.get('fromFilter')) : moment().subtract(6, 'days').format('YYYY-MM-DD'),
      toFilter: jsCookie.get('toFilter') ? moment(jsCookie.get('toFilter')) : moment().format('YYYY-MM-DD'),

      seriesOptions: [],
      modelOptions: [],
      channelOptions: [],
      showroomOption: [],
      baOption: [],
      importCsvData: [],
    };
    this.handleEventDatePicker = this.handleEventDatePicker.bind(this);
    this.handleChangeSeries = this.handleChangeSeries.bind(this);
    this.handleChangeModel = this.handleChangeModel.bind(this);
    this.handleChangeChannel = this.handleChangeChannel.bind(this);
    this.handleChangeArea = this.handleChangeArea.bind(this);

  }

  getCarSeries() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/car-series`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          seriesOptions: res.data,
        }, () => {
          this.getCarModel();
        });
      })
  }

  getCarModel() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/car-model-input`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          modelOptions: res.data,
        }, () => {
          this.getChannel();
        });
      })
  }

  getChannel() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/channel`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          channelOptions: res.data,
        }, () => {
          this.checkCookieFilter();
          if (jsCookie.get('privilege') == 'Super Admin') {
            this.getShowroomData();
          } else if (jsCookie.get('privilege') == 'Sales Manager') {
            this.getBrandAssociate();
          }
        });
      })
  }

  getExportData() {
    var url = `${process.env.REACT_APP_API_URL}dashboard/export/spk`;
    const filter = {
      dateFrom: this.state.fromFilter,
      dateTo: this.state.toFilter,
      level: jsCookie.get('privilege'),
      id: jsCookie.get('uid'),
      area: this.state.areaFilter
    };
    axios.post(url, filter, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let return_data = res.data[0];
        this.setState({
          importCsvData: return_data,
        }, () => {
        });
      })
  }

  getShowroomData() {
    var url = `${process.env.REACT_APP_API_URL}showroom`;
    if (jsCookie.get('showroom_id') !== 'null') {
      url = `${process.env.REACT_APP_API_URL}showroom/${jsCookie.get('showroom_id')}`;
    }
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        delete data[3];
        this.setState({
          showroomOption: data
        })
      })
  }

  getBrandAssociate() {
    var url = `${process.env.REACT_APP_API_URL}list/ba`;
    const filter = {
      sales_manager_id: jsCookie.get('uid'),
    };
    axios.post(url, filter, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          baOption: data
        })
      })
  }

  componentDidMount() {
    document.getElementById('search').value = '';
    this.getCarSeries()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.fromFilter != this.state.fromFilter || prevState.toFilter != this.state.toFilter) {
      this.getExportData();
    }
  }

  checkCookieFilter() {
    let selectedSeriesFilter = [];
    let seriesFilter = '';
    let selectedModelFilter = [];
    let modelFilter = '';
    let selectedChannelFilter = [];
    let channelFilter = '';
    let dateRangeValue = moment().subtract(6, 'days').format('MM/DD/YYYY') + ' - ' + moment().format('MM/DD/YYYY');
    let fromFilter = moment().subtract(6, 'days').format('YYYY-MM-DD');
    let toFilter = moment().format('YYYY-MM-DD');
    if (jsCookie.get('seriesFilter') && jsCookie.get('selectedSeriesFilter')) {
      selectedSeriesFilter = JSON.parse(jsCookie.get('selectedSeriesFilter'))
      seriesFilter = jsCookie.get('seriesFilter')
    }
    if (jsCookie.get('modelFilter') && jsCookie.get('selectedModelFilter')) {
      selectedModelFilter = JSON.parse(jsCookie.get('selectedModelFilter'))
      modelFilter = jsCookie.get('modelFilter')
    }
    if (jsCookie.get('channelFilter') && jsCookie.get('selectedChannelFilter')) {
      selectedChannelFilter = JSON.parse(jsCookie.get('selectedChannelFilter'))
      channelFilter = jsCookie.get('channelFilter')
    }
    if (jsCookie.get('dateRangeValue')) {
      dateRangeValue = jsCookie.get('dateRangeValue')
    }
    if (jsCookie.get('fromFilter')) {
      fromFilter = jsCookie.get('fromFilter')
    }
    if (jsCookie.get('toFilter')) {
      toFilter = jsCookie.get('toFilter')
    }
    this.setState({
      selectedSeriesFilter: selectedSeriesFilter,
      seriesFilter: seriesFilter,
      selectedModelFilter: selectedModelFilter,
      modelFilter: modelFilter,
      selectedChannelFilter: selectedChannelFilter,
      channelFilter: channelFilter,
      dateRangeValue: dateRangeValue,
      fromFilter: fromFilter,
      toFilter: toFilter,
    });
  }

  handleChangeSeries(selected) {
    let filter = '';
    selected.forEach((val) => {
      filter += val.value + ', '
    })

    this.setState({
      selectedModelFilter: [],
      modelFilter: '',
      selectedSeriesFilter: selected,
      seriesFilter: filter
    }, () => {
      jsCookie.set('selectedSeriesFilter', JSON.stringify(selected));
      jsCookie.set('seriesFilter', filter);
      jsCookie.set('selectedModelFilter', []);
      jsCookie.set('modelFilter', '');
    })
  };

  handleChangeModel(selected) {
    let filter = '';
    selected.forEach((val) => {
      filter += val.value + ', '
    })

    this.setState({
      selectedSeriesFilter: [],
      seriesFilter: '',
      selectedModelFilter: selected,
      modelFilter: filter
    }, () => {
      jsCookie.set('selectedSeriesFilter', []);
      jsCookie.set('seriesFilter', '');
      jsCookie.set('selectedModelFilter', JSON.stringify(selected));
      jsCookie.set('modelFilter', filter);
    })
  };

  handleChangeChannel = (selected) => {
    let filter = '';
    selected.forEach((val) => {
      filter += val.value + ', '
    })
    this.setState({
      selectedChannelFilter: selected,
      channelFilter: filter
    }, () => {
      jsCookie.set('selectedChannelFilter', JSON.stringify(selected));
      jsCookie.set('channelFilter', filter);
    })
  };

  handleChangeArea(event) {
    this.setState({
      areaFilter: event.target.value,
    },()=>{
      this.getExportData();
    })
  };

  handleEventDatePicker(event, picker) {
    if (event.type === 'apply') {
      this.setState({
        dateRangeValue: picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'),
        fromFilter: picker.startDate.format('YYYY-MM-DD'),
        toFilter: picker.endDate.format('YYYY-MM-DD'),
      }, () => {
        jsCookie.set('dateRangeValue', picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        jsCookie.set('fromFilter', picker.startDate.format('YYYY-MM-DD'));
        jsCookie.set('toFilter', picker.endDate.format('YYYY-MM-DD'));
      })
    } else if (event.type === 'hide') {
      if (this.state.fromFilter == '') {
        this.setState({
          dateRangeValue: '',
        });
      } else {
        this.setState({
          dateRangeValue: moment(this.state.fromFilter).format('MM/DD/YYYY') + ' - ' + moment(this.state.toFilter).format('MM/DD/YYYY'),
        })
      }
    }
  }

  handleResetDatePicker() {
    let dateRangeValue = moment().subtract(6, 'days').format('MM/DD/YYYY') + ' - ' + moment().format('MM/DD/YYYY');
    let fromFilter = moment().subtract(6, 'days').format('YYYY-MM-DD');
    let toFilter = moment().format('YYYY-MM-DD');
    this.setState({
      dateRangeValue: dateRangeValue,
      fromFilter: fromFilter,
      toFilter: toFilter,
    }, () => {
      jsCookie.set('dateRangeValue', dateRangeValue);
      jsCookie.set('fromFilter', fromFilter);
      jsCookie.set('toFilter', toFilter);
    })
  }

  render() {

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.errorMessage}
        </div>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-12/12 xl:w-12/12 px-4 ">
            <div className="relative flex flex-col min-w-0 break-words bg-blueGray-200 w-full mb-6 shadow-lg rounded">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">

                  <div className="relative my-2 mr-4 w-auto sm:w-full">
                    <div className="relative">
                      <MultiSelect
                        options={this.state.seriesOptions}
                        value={this.state.selectedSeriesFilter}
                        onChange={this.handleChangeSeries}
                        showCheckbox={false}
                        hasSelectAll={true}
                        id="series"
                        overrideStrings={{
                          "selectSomeItems": "Filter by Series"
                        }
                        }
                      />
                    </div>
                  </div>

                  <div className="relative my-2 mr-4 w-auto sm:w-full">
                    <div className="relative">
                      <MultiSelect
                        options={this.state.modelOptions}
                        value={this.state.selectedModelFilter}
                        onChange={this.handleChangeModel}
                        showCheckbox={false}
                        hasSelectAll={true}
                        id="model"
                        overrideStrings={{
                          "selectSomeItems": "Filter by Model"
                        }
                        }
                      />
                    </div>
                  </div>

                  <div className="relative my-2 mr-4 w-auto sm:w-full">
                    <div className="relative">
                      <MultiSelect
                        id="channel"
                        options={this.state.channelOptions}
                        value={this.state.selectedChannelFilter}
                        onChange={this.handleChangeChannel}
                        showCheckbox={false}
                        hasSelectAll={true}
                        overrideStrings={{
                          "selectSomeItems": "Filter by Channel"
                        }
                        }
                      />
                    </div>
                  </div>

                  <div className="relative my-2 mr-4 xs:w-full">
                    <div className="relative">
                      <DateRangePicker
                        startDate={this.state.fromFilter}
                        endDate={this.state.toFilter}
                        initialSettings={{
                          ranges: {
                            'Today': [moment(), moment()],
                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                          },
                          alwaysShowCalendars: true
                        }}
                        onEvent={this.handleEventDatePicker}
                      >
                        <input type="text"
                          value={this.state.dateRangeValue}
                          placeholder="mm-dd-yyyy - mm-dd-yyyy"
                          style={{ width: `${200}px` }}
                          className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                      </DateRangePicker>
                      <span className={(this.state.dateRangeValue ? 'mr-2' : '') + "  h-full font-normal absolute right-0 text-center text-blueGray-300 items-center mr-3 py-2"}>
                        {this.state.dateRangeValue ? <i className="cursor-pointer fa fa-times"
                          onClick={() => this.handleResetDatePicker()}
                        ></i> :
                          <i className=" fa fa-calendar-alt"
                          ></i>
                        }

                      </span>
                    </div>
                  </div>
                  <div className="relative w-full">
                    You can only select one to filter the following <i>series</i> or <i>models</i>.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.privilege === 'Super Admin' ?
            <>
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsMostPerformingDealer filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsMostPerformingChannel filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsMostPerformingSeries filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartSpkTotalByDealer filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartSpkTotalByChannel filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartSpkTotalBySeries filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-12/12 px-4 mb-6">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded h-full">
                  <div className="flex justify-end">
                    <div className=" my-auto pr-4">
                      <span className="ml-2">
                        <select
                          type="text"
                          id="area"
                          name="area"
                          className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={this.state.areaFilter}
                          onChange={this.handleChangeArea}
                          style={{ width: `${130}px` }}
                        >
                          <option value="" >Show All</option>;
                          <option value="jakarta" >Jakarta Area</option>;
                          <option value="other" >Outside Jakarta</option>;
                        </select>
                      </span>
                      <span data-tip data-for='carDemandTooltip' className="ml-2">
                        <i className="far fa-question-circle text-blueGray-400 font-light" ></i>
                      </span>
                      <ReactTooltip id='carDemandTooltip' className="bg-tooltip">
                        <span>This conversion is based on Enquiries date from the selected date, There may be differences in data from other dashboards based on Followup-date leads</span></ReactTooltip>
                    </div>
                    <ExcelFile
                      filename={"Spk Data " + moment(new Date(), 'DD-MM-YYYY', true).format('DD-MM-YYYY') + ".csv"}
                      element={
                        <button className="button-gold text-white active:bg-blueGray-600 text-xs uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 float-right mt-4 mr-4"
                        >
                          Export  SPK</button>
                      }
                    >
                      <ExcelSheet data={this.state.importCsvData} name="Customers"
                      >
                        <ExcelColumn label="Name" value="name" />
                        <ExcelColumn label="Email" value="email" />
                        <ExcelColumn label="Phone" value="phone" />
                        <ExcelColumn label="Enquiry Car Model" value="enquiry_car_model" />
                        <ExcelColumn label="SPK Car Model" value="spk_car_model" />
                        <ExcelColumn label="Enquiry Date" value="enquiry_date" />
                        <ExcelColumn label="Follow Up Date" value="follow_up_date" />
                        <ExcelColumn label="SPK Date" value="spk_date" />
                        <ExcelColumn label="Switching Status" value="switching_status" />
                        <ExcelColumn label="Brand Associate" value="brand_associate_name" />
                        <ExcelColumn label="Gallery" value="dealer" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                  <div className="flex justify-between rounded-t mb-0 px-4 py-3 bg-transparent ">
                    <div className="w-full lg:w-4/12 xl:w-1/5 px-4">
                      {this.state.urlStatus ? <CardFunnelConversionTotal title="Enquiry Conversion Total" filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} areaFilter={this.state.areaFilter} /> : ''}
                    </div>
                    {this.state.showroomOption.map((elem) => {
                      return <div className="w-full lg:w-4/12 xl:w-1/5 px-4">
                        {this.state.urlStatus ? <CardFunnelConversionTotal title={elem.name} filterShowroom={elem.id} filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} areaFilter={this.state.areaFilter} /> : ''}
                      </div>
                    })}
                  </div>
                </div>
              </div>
            </>
            : ''}

          {this.state.privilege === 'Sales Manager' ?
            <>
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsMostPerformingBA filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsMostPerformingChannel filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsMostPerformingSeries filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartSpkTotalByBA filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartSpkTotalByChannel filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartSpkTotalBySeries filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-12/12 px-4 mb-6">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded h-full">
                  <div className="flex justify-end">
                    <div className=" my-auto pr-4">
                      <span className="ml-2">
                        <select
                          type="text"
                          id="area"
                          name="area"
                          className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={this.state.areaFilter}
                          onChange={this.handleChangeArea}
                          style={{ width: `${130}px` }}
                        >
                          <option value="" >Show All</option>;
                          <option value="jakarta" >Jakarta Area</option>;
                          <option value="other" >Outside Jakarta</option>;
                        </select>
                      </span>
                      <span data-tip data-for='carDemandTooltip' className="ml-2">
                        <i className="far fa-question-circle text-blueGray-400 font-light" ></i>
                      </span>
                      <ReactTooltip id='carDemandTooltip' className="bg-tooltip">
                        <span>This conversion is based on Enquiries date from the selected date, There may be differences in data from other dashboards based on Followup-date leads</span></ReactTooltip>
                    </div>
                     <ExcelFile
                      filename={"Spk Data " + moment(new Date(), 'DD-MM-YYYY', true).format('DD-MM-YYYY') + ".csv"}
                      element={
                        <button className="button-gold text-white active:bg-blueGray-600 text-xs uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 float-right mt-4 mr-4"
                        >
                          Export SPK</button>
                      }
                    >
                      <ExcelSheet data={this.state.importCsvData} name="Customers"
                      >
                        <ExcelColumn label="Name" value="name" />
                        <ExcelColumn label="Email" value="email" />
                        <ExcelColumn label="Phone" value="phone" />
                        <ExcelColumn label="Enquiry Car Model" value="enquiry_car_model" />
                        <ExcelColumn label="SPK Car Model" value="spk_car_model" />
                        <ExcelColumn label="Enquiry Date" value="enquiry_date" />
                        <ExcelColumn label="Follow Up Date" value="follow_up_date" />
                        <ExcelColumn label="SPK Date" value="spk_date" />
                        <ExcelColumn label="Switching Status" value="switching_status" />
                        <ExcelColumn label="Brand Associate" value="brand_associate_name" />
                        <ExcelColumn label="Gallery" value="dealer" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                  <div className="flex rounded-t mb-0 px-4 py-3 bg-transparent flex-wrap">
                    <div className="w-full lg:w-4/12 xl:w-1/5 px-4">
                      {this.state.urlStatus ? <CardFunnelConversionTotal title="Enquiry Conversion Total" filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} areaFilter={this.state.areaFilter} /> : ''}
                    </div>
                    {this.state.baOption.map((elem) => {
                      return <div className="w-full lg:w-4/12 xl:w-1/5 px-4">
                        {this.state.urlStatus ? <CardFunnelConversionTotal title={elem.name} filterBA={elem.id} filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} areaFilter={this.state.areaFilter} /> : ''}
                      </div>
                    })}
                  </div>
                </div>
              </div>
            </>
            : ''}

          {this.state.privilege === 'Brand Associate' ?
            <>
              <div className="w-full lg:w-6/12 xl:w-6/12 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsMostPerformingChannel filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-6/12 xl:w-6/12 px-4 mb-6">
                {this.state.urlStatus ? <CardStatsMostPerformingSeries filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartSpkTotalByChannel filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                {this.state.urlStatus ? <CardChartSpkTotalBySeries filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} /> : ''}
              </div>
              <div className="w-full lg:w-12/12 xl:w-4/12 px-4 mb-6">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded h-full">
                  <div className="flex justify-end">
                    <div className=" my-auto pr-4">
                      <span className="ml-2">
                        <select
                          type="text"
                          id="area"
                          name="area"
                          className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={this.state.areaFilter}
                          onChange={this.handleChangeArea}
                          style={{ width: `${130}px` }}
                        >
                          <option value="" >Show All</option>;
                          <option value="jakarta" >Jakarta Area</option>;
                          <option value="other" >Outside Jakarta</option>;
                        </select>
                      </span>
                      <span data-tip data-for='carDemandTooltip' className="ml-2">
                        <i className="far fa-question-circle text-blueGray-400 font-light" ></i>
                      </span>
                      <ReactTooltip id='carDemandTooltip' className="bg-tooltip">
                        <span>This conversion is based on Enquiries date from the selected date, There may be differences in data from other dashboards based on Followup-date leads</span></ReactTooltip>
                    </div>
                    <ExcelFile
                      filename={"Spk Data " + moment(new Date(), 'DD-MM-YYYY', true).format('DD-MM-YYYY') + ".csv"}
                      element={
                        <button className="button-gold text-white active:bg-blueGray-600 text-xs uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 float-right mt-4 mr-4"
                        >
                          Export SPK</button>
                      }
                    >
                      <ExcelSheet data={this.state.importCsvData} name="Customers"
                      >
                        <ExcelColumn label="Name" value="name" />
                        <ExcelColumn label="Email" value="email" />
                        <ExcelColumn label="Phone" value="phone" />
                        <ExcelColumn label="Enquiry Car Model" value="enquiry_car_model" />
                        <ExcelColumn label="SPK Car Model" value="spk_car_model" />
                        <ExcelColumn label="Enquiry Date" value="enquiry_date" />
                        <ExcelColumn label="Follow Up Date" value="follow_up_date" />
                        <ExcelColumn label="SPK Date" value="spk_date" />
                        <ExcelColumn label="Switching Status" value="switching_status" />
                        <ExcelColumn label="Brand Associate" value="brand_associate_name" />
                        <ExcelColumn label="Gallery" value="dealer" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                  <div className="flex justify-between rounded-t mb-0 px-4 py-3 bg-transparent ">
                    <div className="w-full lg:w-12/12 xl:w-12/12 px-4">
                      {this.state.urlStatus ? <CardFunnelConversionTotal title="Enquiry Conversion Total" filterSeries={this.state.seriesFilter} filterModel={this.state.modelFilter} filterChannel={this.state.channelFilter} filterFrom={this.state.fromFilter} filterTo={this.state.toFilter} areaFilter={this.state.areaFilter} /> : ''}
                    </div>
                  </div>
                </div>
              </div>
            </>
            : ''}
        </div>
      </>
    );
  }
}
