import React from 'react'
import axios from 'axios';
import { Redirect, useParams } from 'react-router-dom'
import auth from 'global/auth.js';
import jsCookie from 'js-cookie';
import CustomerEdit from '../customer/Customer_edit.js';

import { DateFormat } from "global/custom.js";
import ClipLoader from "react-spinners/ClipLoader";
import { getBadge } from "global/data.js";
import { Validation } from "global/custom.js";
/*eslint-disable eqeqeq*/

const User = ({ match }) => {
  var id = useParams();
  if (jsCookie.get('privilege') == 'Uploader') {
    return <Redirect to="/404" />;
  }
  return (
    <>
      <div className="flex flex-wrap">
        <CustomerDetail id={id.customerId} />
      </div>
    </>
  )
}

class CustomerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers_id: this.props.id,
      errorMessage: '',
      showStatus: false,
      perPage: 10,
      historyData: [],
      historyFollowupData: [],
      bckupHistoryFollowupData: [],
      tableData: [],
      bckupTableData: [],
      isSubmittedSf: false,
      customers_history_id_sf: '',
      first_name_sf: '',
      last_name_sf: '',
      email_sf: '',
      phone_sf: '',
      city_sf: '',
      sales_manager_sf: '',
      brand_associate_sf: '',
      enquiry_type_name_sf: '',
      preferred_model_sf: '',
      test_drive_location_sf: '',
      notes_sf: '',
      followup_id_sf: '',
      followup_preferred_model_sf: '',
      followup_notes_sf: '',
      followup_status_sf: '',
      followup_status2_sf: '',
      hide_followup_status2_sf: false,
      followupOption: [
        { value: '', text: 'Please Choose One' },
        { value: 'follow_up', text: 'Follow Up' },
        { value: "cant_reach", text: "Can't Reach" },
      ],
      followupOption2: [
        { value: '', text: 'Please Choose One' },
        { value: 'Cold', text: 'Cold' },
        { value: "Prospect", text: "Prospect" },
        { value: 'Hot', text: 'Hot' },
        { value: 'SPK', text: 'SPK' },
      ],
      last_followup_sales_manager: '',
      last_followup_brand_associate: '',
      last_followup_status: '',
      last_followup_date: '',
      last_followup_notes: '',
      last_followup_enquiry_name: '',
      last_followup_subject: '',
      reloadPage: false,
      disabledSf: false,
      disabledNotesBtn: true,
      urlStatus: true,
      preferredModelSfError: false,
      currentPageEnquiryHistory: '1',
      perPageEnquiryHistory: '10',
      pagingPrevTemplateEnquiryHistory: '',
      pagingTemplateEnquiryHistory: '',
      pagingNextTemplateEnquiryHistory: '',
      pageEnquiryHistory: '',

      enquiryHistoryShowDetail: false,

      currentPageFollowedupHistory: '1',
      perPageFollowedupHistory: '10',
      pagingPrevTemplateFollowedupHistory: '',
      pagingTemplateFollowedupHistory: '',
      pagingNextTemplateFollowedupHistory: '',
      pageFollowedupHistory: '',

      deleteEnquiryId: '',
      showDeleteConfirmation: false,

      showEditEnquiry: false,
      isSubmittedEditEnquiry: false,
      first_name_enquiry_edit: '',
      last_name_enquiry_edit: '',
      email_enquiry_edit: '',
      phone_enquiry_edit: '',
      city_enquiry_edit: '',
      province_enquiry_edit: '',
      subject_enquiry_edit: '',
      type_enquiry_edit: '',
      notes_enquiry_edit: '',
      model_enquiry_edit: '',
      test_drive_location_enquiry_edit: '',
      modelOptions: [],
      provinceOptions: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStatusForm = this.handleStatusForm.bind(this);
    this.handleChangeSf = this.handleChangeSf.bind(this);
    this.handleSubmitSf = this.handleSubmitSf.bind(this);
    this.handleEditEnquiryForm = this.handleEditEnquiryForm.bind(this);
    this.handleChangeEditEnquiry = this.handleChangeEditEnquiry.bind(this);
    this.handleSubmitEditEnquiry = this.handleSubmitEditEnquiry.bind(this);

    /*paging*/
    this.handlePageChangeEnquiryHistory = this.handlePageChangeEnquiryHistory.bind(this);
    this.handlePageChangeFollowedupHistory = this.handlePageChangeFollowedupHistory.bind(this);
    /*paging*/
    this.handleChangeFieldEnquiryHistory = this.handleChangeFieldEnquiryHistory.bind(this);

    this.handleOpenDeleteEnquiryModal = this.handleOpenDeleteEnquiryModal.bind(this);
    this.handleCloseDeleteEnquiryModal = this.handleCloseDeleteEnquiryModal.bind(this);

    this.handleDeleteEnquiry = this.handleDeleteEnquiry.bind(this);

    this.responseFromChild = this.responseFromChild.bind(this);

  }

  getCarModel() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/car-model-input?type=enquiry`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          modelOptions: res.data,
        }, () => {
          this.getDropdownProvince();
        });
      })
  }

  getDropdownProvince() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/province`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          provinceOption: data,
        });
      })
  }


  handleStatusForm(id, history_followup_id = null) {
    if (!this.state.showStatus === false) {
      this.setState({
        followup_notes_sf: ''
      });
    } else {
      var tmp_historyData = this.state.historyData;
      tmp_historyData = tmp_historyData.filter(row => {
        return row.id == id
      });
      var book_test_drive_sf = false;
      if (tmp_historyData[0].subject.match('Book')) {
        book_test_drive_sf = true;
      }
      var configurator_sf = false;
      if (tmp_historyData[0].subject.match('Config')) {
        configurator_sf = true;
      }
      this.setState({ disabledSf: false })

      var tmp_historyFollowupData = this.state.historyFollowupData.length > 0 ? this.state.historyFollowupData : '';
      if (history_followup_id) {
        tmp_historyFollowupData = tmp_historyFollowupData.filter(row => {
          return row.id == history_followup_id
        });
        this.setState({ disabledSf: true })
      } else {
        if (tmp_historyFollowupData && jsCookie.get('privilege') === 'Super Admin') {
          tmp_historyFollowupData = tmp_historyFollowupData.filter(row => {
            return row.customers_history_id == tmp_historyData[0].id
          });
          this.setState({ disabledSf: true })
        }
      }
      if (jsCookie.get('privilege') === 'Super Admin') {
        this.setState({ disabledSf: true })
      }
      if (tmp_historyData[0].status == 'SPK') {
        tmp_historyFollowupData = tmp_historyData[0].followup_history.sort((a, b) => a.created_date < b.created_date ? 1 : -1)
        this.setState({ disabledSf: true })
      }

      if (tmp_historyFollowupData.length > 0) {
        if (tmp_historyFollowupData[0].status === 'Follow Up') {
          this.setState({ hide_followup_status2_sf: true })
        }
          if (tmp_historyFollowupData[0].followup_status) {
          this.setState({ hide_followup_status2_sf: true })
        }
      }
      this.setState({
        customers_history_id_sf: tmp_historyData[0].id,
        first_name_sf: tmp_historyData[0].first_name,
        last_name_sf: tmp_historyData[0].last_name,
        email_sf: tmp_historyData[0].email,
        phone_sf: tmp_historyData[0].phone,
        city_sf: tmp_historyData[0].city,
        province_sf: tmp_historyData[0].province,
        subject_sf: tmp_historyData[0].subject,
        enquiry_type_name_sf: tmp_historyData[0].enquiry_type,
        enquiry_date_sf: DateFormat(tmp_historyData[0].enquiry_date, 'Mdy'),
        preferred_model_sf: tmp_historyData[0].preferred_model,
        test_drive_location_sf: tmp_historyData[0].test_drive_location,
        notes_sf: tmp_historyData[0].notes.replace(/;/g, ";\n\n"),
        book_test_drive_sf: book_test_drive_sf,
        configurator_sf: configurator_sf,
        sales_manager_sf: tmp_historyFollowupData.length > 0 ? tmp_historyFollowupData[0].sales_manager_name : '',
        brand_associate_sf: tmp_historyFollowupData.length > 0 ? tmp_historyFollowupData[0].brand_associate_name : '',
        followup_id_sf: tmp_historyFollowupData.length > 0 ? tmp_historyFollowupData[0].id : '',
        followup_status_sf: !tmp_historyFollowupData.length > 0 ? '' : (tmp_historyFollowupData[0].status == 'Not Reach' ? "cant_reach" : (tmp_historyFollowupData[0].status == 'Follow Up' ? "follow_up" : tmp_historyFollowupData[0].status)),
        followup_preferred_model_sf: tmp_historyFollowupData.length > 0 ? tmp_historyFollowupData[0].preferred_model : '',
        followup_notes_sf: tmp_historyFollowupData.length > 0 ? tmp_historyFollowupData[0].notes : '',
        followup_date_sf: tmp_historyFollowupData.length > 0 ? DateFormat(tmp_historyFollowupData[0].created_date, 'Mdyhi') : '',
        followup_status2_sf: !tmp_historyFollowupData.length > 0 ? '' : tmp_historyFollowupData[0].followup_status,
      });
    }
    this.setState({ showStatus: !this.state.showStatus, isSubmittedSf: false });
  }

  getCustomerDetail() {
    axios.get(`${process.env.REACT_APP_API_URL}customers/${this.props.id}`,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(err => {
        this.createErrorMessage('Please check connection.', '', 'errorMessage')
        throw err;
      })
      .then(res => {
        if(jsCookie.get('privilege') === 'Sales Manager'){
          if(res.data.customers_data[0].sales_manager_id != jsCookie.get('uid')){
            this.setState({ urlStatus: false });
            return false;
          }
        } else if(jsCookie.get('privilege') === 'Brand Associate'){
          if(res.data.customers_data[0].brand_associate_id != jsCookie.get('uid')){
            this.setState({ urlStatus: false });
            return false;
          }
        }
        if (typeof res.data.customers_data == "undefined") {
          this.setState({ urlStatus: false });
          return false;
        } else {
          const data = res.data.customers_data[0];
          this.setState({ userData: data });
          if (data.email_list) {
            var email = data.email_list.split(',');
            email = email.join(',\n')
          }
          if (data.phone_list) {
            var phone = data.phone_list.split(',')
            phone = phone.join(',\n')
          }
          let disabledNotesBtn = true;
          if (jsCookie.get('privilege') === 'Super Admin') {
            disabledNotesBtn = false
          }
          this.setState({
            customers_id: data.id,
            first_name: data.first_name,
            last_name: data.last_name ? data.last_name : '',
            email: email ? email : '',
            phone: phone ? phone : '',
            sales_manager_id: data.sales_manager_name ? data.sales_manager_id : '',
            sales_manager_name: data.sales_manager_name ? data.sales_manager_name : '',
            brand_associate_id: data.brand_associate_id ? data.brand_associate_id : '',
            brand_associate_name: data.brand_associate_name ? data.brand_associate_name : '',
            city: data.city ? data.city : '',
            province: data.province ? data.province : '',
            platform_groups: data.platform_groups,
            notes: data.notes,
            disabledNotesBtn: disabledNotesBtn,
          }, () => {
            this.getCustomerHistory();
          });
        }
      });
  }

  getCustomerHistory() {
    axios.get(`${process.env.REACT_APP_API_URL}customer/history?cid=${this.state.customers_id}`,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .then(res => {
        var historyData = res.data;
        var tableData = historyData;

        var historyFollowupData = [];
        historyData.map((row, key) => {
          if (row.followup_history.length > 0) {
            row.followup_history.map((row2) => {
              row2.enquiry_type_name = row.enquiry_type_name
              historyFollowupData.push(row2);
              return '';
            })
          }
          return '';
        }
        );
        historyFollowupData = historyFollowupData.sort((a, b) => new Date(b.created_date) - new Date(a.created_date))
        var last_followup = historyFollowupData;
        if (last_followup.length > 0) {
          if (last_followup[0].status !== 'No') {
            this.setState({
              last_followup_id: last_followup[0].id,
              last_followup_customer_history_id: last_followup[0].customers_history_id,
              last_followup_sales_manager: last_followup[0].sales_manager_name,
              last_followup_brand_associate: last_followup[0].brand_associate_name,
              last_followup_status: last_followup[0].status == 'Not Reach' ? "Can't Reach" : last_followup[0].status,
              last_followup_date: last_followup[0].created_date ? DateFormat(last_followup[0].created_date, 'Mdy') : '',
              last_followup_notes: last_followup[0].notes,
              last_followup_enquiry_name: last_followup[0].enquiry_type,
              last_followup_subject: last_followup[0].subject,
              last_followup_preferred_model: last_followup[0].preferred_model,
            });
          }
        }

        this.setState({ tableData: tableData, bckupTableData: tableData }, () => {
          this.handlePagingEnquiryHistory();
        });
        this.setState({ historyData: historyData });
        this.setState({
          historyFollowupData: historyFollowupData,
          bckupHistoryFollowupData: historyFollowupData
        }, () => {
          this.handlePagingFollowedupHistory();
          this.getCarModel();
        });
      });
  }

  componentDidMount() {
    this.getCustomerDetail();
  }


  handleChange(event) {
    const target = event.target;
    const notes = target.id === 'notes' ? target.value : this.state.notes;

    this.setState({
      notes: notes,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitted: true });
    const inputData = {
      id: this.state.customers_id,
      notes: this.state.notes,
      modified_by: jsCookie.get('uid'),
      cat: 'notes',
    };

    axios.post(`${process.env.REACT_APP_API_URL}customers/${this.state.customers_id}`, inputData,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.setState({ isSubmitted: false });
        throw error;
      })
      .then(res => {
        this.setState({ isSubmitted: false });
      })
  }

  handleChangeSf(event) {
    const target = event.target;
    const followup_status_sf = target.id === 'followup_status_sf' ? target.value : this.state.followup_status_sf;
    const followup_notes_sf = target.id === 'followup_notes_sf' ? target.value : this.state.followup_notes_sf;
    const followup_preferred_model_sf = target.id === 'followup_preferred_model_sf' ? target.value : this.state.followup_preferred_model_sf;
    const followup_status2_sf = target.id === 'followup_status2_sf' ? target.value : this.state.followup_status2_sf;
    if (followup_status_sf === 'follow_up') {
      this.setState({
        hide_followup_status2_sf: true,
      });
    } else {
      this.setState({
        hide_followup_status2_sf: false,
      });
    }
    this.setState({
      followup_status_sf: followup_status_sf,
      followup_notes_sf: followup_notes_sf,
      followup_preferred_model_sf: followup_preferred_model_sf,
      followup_status2_sf: followup_status2_sf,
    });
  }

  handleSubmitSf(event) {
    event.preventDefault();
    this.setState({ isSubmittedSf: true });
    var validate = 1;
    if (!this.handleValidation('text', this.state.followup_status_sf, 'statusSfError', 'statusSfErrorMessage', 'Status')) {
      validate = 0;
    }

    if (!this.state.followup_status_sf) {
      this.setState({ isSubmittedSf: false });
      this.setState({
        statusSfError: true,
      });

      validate = 0;
    } else {
      if (this.state.followup_status_sf == 'SPK' || this.state.followup_status_sf == 'follow_up') {
        if (!this.state.followup_preferred_model_sf) {
          this.setState({
            isSubmittedSf: false,
            preferredModelSfError: true,
            preferredModelSfErrorMessage: true
          });
          validate = 0;
        }else{
          this.setState({
            preferredModelSfError: false,
            preferredModelSfErrorMessage: false
          });
        }
        if (!this.state.followup_status2_sf) {
          this.setState({
            isSubmittedSf: false,
            followupStatusSfError: true,
          });
          validate = 0;
        }
      } else {
        this.setState({ statusSfError: false });
      }
    }
    /*if (jsCookie.get('privilege') === 'Sales Manager' && this.state.userData.brand_associate_assigned === 'No') {
      this.setState({
        isSubmittedSf: false,
        baEmptySfError: true,
      });
      validate = 0;
    }*/

    if (validate == 0) {
      return false;
    }
    var status = 'No';
    if (this.state.followup_status_sf === 'cant_reach') {
      //status = "Can't Reach"
      status = "Not Reach"
    } else if (this.state.followup_status_sf === 'follow_up') {
      status = 'Follow up'
    } else if (this.state.followup_status_sf === 'SPK') {
      status = 'SPK'
    }
    let brand_associate_id = this.state.brand_associate_id;
    if (jsCookie.get('privilege') === 'Sales Manager') {
      brand_associate_id = this.state.userData.brand_associate_id;
    }
    const inputData = {
      customers_id: this.state.customers_id,
      customers_history_id: this.state.customers_history_id_sf,
      sales_manager_id: this.state.sales_manager_id,
      brand_associate_id: brand_associate_id,
      showroom_id: jsCookie.get('showroom_id') ? jsCookie.get('showroom_id') : null,
      preferred_model: this.state.followup_preferred_model_sf,
      status: status,
      followup_status: this.state.followup_status2_sf,
      notes: this.state.followup_notes_sf,
      modified_by: 0,
      created_by: jsCookie.get('uid'),
    }

    if (!brand_associate_id) {
      delete (inputData.brand_associate_id)
    }
    /*if (jsCookie.get('privilege') === 'Sales Manager') {
      delete (inputData.brand_associate_id)
    }*/

    axios.post(`${process.env.REACT_APP_API_URL}customer/history/followup`, inputData,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.setState({ isSubmittedSf: false });
        throw error;
      })
      .then(res => {
        this.setState({ 
          notifSf: true,
          reloadPage: true 
        });
        //this.setState({ isSubmittedSf: false });
      })
  }

  handleValidation(type, value, errorName, errorMessageName, fieldName) {
    var valid = Validation(type, value, errorName, errorMessageName, fieldName);
    if (valid.status == 404) {
      this.createErrorMessage(valid.msg, errorName, errorMessageName);
      return false;
    } else {
      this.setState({
        [errorName]: '',
        [errorMessageName]: ''
      });
      return true;
    }
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className='notifBar bg-red'>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)} />
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  handlePageChangeEnquiryHistory(number) {
    this.setState({ currentPageEnquiryHistory: number }, () => {
      this.handlePagingEnquiryHistory();
    });
  }

  handlePagingEnquiryHistory() {
    let data = this.state.bckupTableData;

    let currentPage = this.state.currentPageEnquiryHistory;
    let perpage = this.state.perPage;
    let totalData = data.length;
    let totalPaging = Math.ceil(totalData / perpage);

    const start = (Number(currentPage) - 1) * perpage;
    const end = Number(start) + Number(perpage);
    let slicedData = data.slice(start, end)

    let startLoop = 1;
    let endLoop = totalPaging;
    if (Number(currentPage) - 2 > 0 && Number(currentPage) + 2 <= totalPaging) {
      startLoop = Number(currentPage) - 2;
      endLoop = Number(currentPage) + 2;
    } else if (Number(currentPage) - 1 > 0 && Number(currentPage) + 3 <= totalPaging) {
      startLoop = Number(currentPage) - 1;
      endLoop = Number(currentPage) + 3;
    } else if (Number(currentPage) - 3 > 0 && Number(currentPage) + 1 <= totalPaging) {
      startLoop = Number(currentPage) - 3;
      endLoop = Number(currentPage) + 1;
    } else if (currentPage == 1 && Number(currentPage) + 4 <= totalPaging) {
      startLoop = Number(currentPage);
      endLoop = Number(currentPage) + 4;
    } else if (currentPage == totalPaging && Number(currentPage) - 4 > 0) {
      startLoop = Number(currentPage) - 4;
      endLoop = Number(currentPage);
    }

    let pagingPrevTemplate = [];
    let pagingTemplate = [];
    let pagingNextTemplate = [];
    if (Number(currentPage) > 1) {
      pagingPrevTemplate.push(
        <>
          <li key="tofirst">
            <button onClick={e => this.handlePageChangeEnquiryHistory(1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
          <li key="toprev">
            <button onClick={e => this.handlePageChangeEnquiryHistory(currentPage - 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    for (let i = startLoop; i <= endLoop; i++) {
      if (i == currentPage) {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 text-white bg-black">
              {i}
            </button>
          </li>
        )
      } else {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button name={i} onClick={() => this.handlePageChangeEnquiryHistory(i)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              {i}
            </button>
          </li>
        )
      }
    }

    if (currentPage < totalPaging && totalPaging > 1) {
      pagingNextTemplate.push(
        <>
          <li key={`${Number(totalPaging) + 3}`}>
            <button onClick={() => this.handlePageChangeEnquiryHistory(Number(currentPage) + 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
          <li key={`${Number(totalPaging) + 4}`}>
            <button onClick={() => this.handlePageChangeEnquiryHistory(totalPaging)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    if (currentPage > 1) {
      if (currentPage > totalPaging) {
        this.setState({
          currentPage: 1,
        })
      }
    }

    this.setState({
      tableData: slicedData,
      pagingPrevTemplateEnquiryHistory: pagingPrevTemplate,
      pagingTemplateEnquiryHistory: pagingTemplate,
      pagingNextTemplateEnquiryHistory: pagingNextTemplate,
      startShowingEnquiryHistory: slicedData.length > 0 ? start + 1 : 0,
      endShowingEnquiryHistory: start + 1 < totalData ? (end < totalData ? end : totalData) : '',
      totalDataEnquiryHistory: totalData,
    });
  }

  handlePageChangeFollowedupHistory(number) {
    this.setState({ currentPageFollowedupHistory: number }, () => {
      this.handlePagingFollowedupHistory();
    });
  }

  handlePagingFollowedupHistory() {
    let data = this.state.bckupHistoryFollowupData;

    let currentPage = this.state.currentPageFollowedupHistory;
    let perpage = this.state.perPage;
    let totalData = data.length;
    let totalPaging = Math.ceil(totalData / perpage);

    const start = (Number(currentPage) - 1) * perpage;
    const end = Number(start) + Number(perpage);
    let slicedData = data.slice(start, end)

    let startLoop = 1;
    let endLoop = totalPaging;
    if (Number(currentPage) - 2 > 0 && Number(currentPage) + 2 <= totalPaging) {
      startLoop = Number(currentPage) - 2;
      endLoop = Number(currentPage) + 2;
    } else if (Number(currentPage) - 1 > 0 && Number(currentPage) + 3 <= totalPaging) {
      startLoop = Number(currentPage) - 1;
      endLoop = Number(currentPage) + 3;
    } else if (Number(currentPage) - 3 > 0 && Number(currentPage) + 1 <= totalPaging) {
      startLoop = Number(currentPage) - 3;
      endLoop = Number(currentPage) + 1;
    } else if (currentPage == 1 && Number(currentPage) + 4 <= totalPaging) {
      startLoop = Number(currentPage);
      endLoop = Number(currentPage) + 4;
    } else if (currentPage == totalPaging && Number(currentPage) - 4 > 0) {
      startLoop = Number(currentPage) - 4;
      endLoop = Number(currentPage);
    }

    let pagingPrevTemplate = [];
    let pagingTemplate = [];
    let pagingNextTemplate = [];
    if (Number(currentPage) > 1) {
      pagingPrevTemplate.push(
        <>
          <li key="tofirst">
            <button onClick={e => this.handlePageChangeFollowedupHistory(1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
          <li key="toprev">
            <button onClick={e => this.handlePageChangeFollowedupHistory(currentPage - 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    for (let i = startLoop; i <= endLoop; i++) {
      if (i == currentPage) {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 text-white bg-black">
              {i}
            </button>
          </li>
        )
      } else {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button name={i} onClick={() => this.handlePageChangeFollowedupHistory(i)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              {i}
            </button>
          </li>
        )
      }
    }

    if (currentPage < totalPaging && totalPaging > 1) {
      pagingNextTemplate.push(
        <>
          <li key={`${Number(totalPaging) + 3}`}>
            <button onClick={() => this.handlePageChangeFollowedupHistory(Number(currentPage) + 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
          <li key={`${Number(totalPaging) + 4}`}>
            <button onClick={() => this.handlePageChangeFollowedupHistory(totalPaging)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    if (currentPage > 1) {
      if (currentPage > totalPaging) {
        this.setState({
          currentPage: 1,
        });
      }
    }

    this.setState({
      historyFollowupData: slicedData,
      pagingPrevTemplateFollowedupHistory: pagingPrevTemplate,
      pagingTemplateFollowedupHistory: pagingTemplate,
      pagingNextTemplateFollowedupHistory: pagingNextTemplate,
      startShowingFollowedupHistory: slicedData.length > 0 ? start + 1 : 0,
      endShowingFollowedupHistory: start + 1 < totalData ? (end < totalData ? end : totalData) : '',
      totalDataFollowedupHistory: totalData,
    });
  }

  handleSortEnquiryHistory(event) {
    var data = this.state.bckupTableData;
    if (event === 'id') {
      if (this.state.idSortEnquiryHistory === '' || this.state.idSortEnquiryHistory === 'desc') {
        data = data.sort((a, b) => (a.id > b.id) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: 'asc', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: '', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: '' });
      } else {
        data = data.sort((a, b) => (a.id < b.id) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: 'desc', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: '', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: '' });
      }
    }

    if (event === 'enquirySubject') {
      if (this.state.enquirySubjectSortEnquiryHistory === '' || this.state.enquirySubjectSortEnquiryHistory === 'desc') {
        data = data.sort((a, b) => (a.subject.toLowerCase() > b.subject.toLowerCase()) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: 'asc', typeSortEnquiryHistory: '', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: '' });
      } else {
        data = data.sort((a, b) => (a.subject.toLowerCase() < b.subject.toLowerCase()) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: 'desc', typeSortEnquiryHistory: '', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: '' });
      }
    }

    if (event === 'type') {
      if (this.state.typeSortEnquiryHistory === '' || this.state.typeSortEnquiryHistory === 'desc') {
        data = data.sort((a, b) => ((a.enquiry_type ? a.enquiry_type : '') > (b.enquiry_type ? b.enquiry_type : '')) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: 'asc', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: '' });
      } else {
        data = data.sort((a, b) => ((a.enquiry_type ? a.enquiry_type : '') < (b.enquiry_type ? b.enquiry_type : '')) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: 'desc', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: '' });
      }
    }

    if (event === 'notes') {
      if (this.state.notesSortEnquiryHistory === '' || this.state.notesSortEnquiryHistory === 'desc') {
        data = data.sort((a, b) => ((a.notes ? a.notes : '') > (b.notes ? b.notes : '')) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: '', notesSortEnquiryHistory: 'asc', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: '' });
      } else {
        data = data.sort((a, b) => ((a.notes ? a.notes : '') < (b.notes ? b.notes : '')) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: '', notesSortEnquiryHistory: 'desc', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: '' });
      }
    }

    if (event === 'preferredModel') {
      if (this.state.preferredModelSortEnquiryHistory === '' || this.state.preferredModelSortEnquiryHistory === 'desc') {
        data = data.sort((a, b) => ((a.preferred_model ? a.preferred_model : '') > (b.preferred_model ? b.preferred_model : '')) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: '', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: 'asc', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: '' });
      } else {
        data = data.sort((a, b) => ((a.preferred_model ? a.preferred_model : '') < (b.preferred_model ? b.preferred_model : '')) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: '', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: 'desc', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: '' });
      }
    }

    if (event === 'testDriveLocation') {
      if (this.state.testDriveLocationSortEnquiryHistory === '' || this.state.testDriveLocationSortEnquiryHistory === 'desc') {
        data = data.sort((a, b) => ((a.test_drive_location ? a.test_drive_location : '') > (b.test_drive_location ? b.test_drive_location : '')) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: '', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: 'asc', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: '' });
      } else {
        data = data.sort((a, b) => ((a.test_drive_location ? a.test_drive_location : '') < (b.test_drive_location ? b.test_drive_location : '')) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: '', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: 'desc', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: '' });
      }
    }

    if (event === 'enquiryDate') {
      if (this.state.enquiryDateSortEnquiryHistory === '' || this.state.enquiryDateSortEnquiryHistory === 'desc') {
        data = data.sort((a, b) => new Date(a.enquiry_date) > new Date(b.enquiry_date) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: '', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: 'asc', statusSortEnquiryHistory: '' });
      } else {
        data = data.sort((a, b) => new Date(a.enquiry_date) < new Date(b.enquiry_date) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: '', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: 'desc', statusSortEnquiryHistory: '' });
      }
    }
    if (event === 'status') {
      if (this.state.statusSortEnquiryHistory === '' || this.state.statusSortEnquiryHistory === 'desc') {
        data = data.sort((a, b) => (a.followup_status > b.followup_status) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: '', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: 'asc' });
      } else {
        data = data.sort((a, b) => (a.followup_status < b.followup_status) ? 1 : -1)
        this.setState({ idSortEnquiryHistory: '', enquirySubjectSortEnquiryHistory: '', typeSortEnquiryHistory: '', notesSortEnquiryHistory: '', preferredModelSortEnquiryHistory: '', testDriveLocationSortEnquiryHistory: '', enquiryDateSortEnquiryHistory: '', statusSortEnquiryHistory: 'desc' });
      }
    }

    if (data) {
      this.setState({ tableData: Array.from(data) }, () => {
        this.handlePagingEnquiryHistory();
      });
    }
  }

  handleSortFollowedupHistory(event) {
    var data = this.state.bckupHistoryFollowupData;
    if (event === 'id') {
      if (this.state.idSortFollowedupHistory === '' || this.state.idSortFollowedupHistory === 'desc') {
        data = data.sort((a, b) => (a.id > b.id) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: 'asc', enquiryIdSortFollowedupHistory: '', smSortFollowedupHistory: '', baSortFollowedupHistory: '', notesSortFollowedupHistory: '', dateSortFollowedupHistory: '', statusSortFollowedupHistory: '' });
      } else {
        data = data.sort((a, b) => (a.id < b.id) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: 'desc', enquiryIdSortFollowedupHistory: '', smSortFollowedupHistory: '', baSortFollowedupHistory: '', notesSortFollowedupHistory: '', dateSortFollowedupHistory: '', statusSortFollowedupHistory: '' });
      }
    }

    if (event === 'enquiry_id') {
      if (this.state.enquiryIdSortFollowedupHistory === '' || this.state.enquiryIdSortFollowedupHistory === 'desc') {
        data = data.sort((a, b) => (a.enquiry_id > b.enquiry_id) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: '', enquiryIdSortFollowedupHistory: 'asc', smSortFollowedupHistory: '', baSortFollowedupHistory: '', notesSortFollowedupHistory: '', dateSortFollowedupHistory: '', statusSortFollowedupHistory: '' });
      } else {
        data = data.sort((a, b) => (a.enquiry_id < b.enquiry_id) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: '', enquiryIdSortFollowedupHistory: 'desc', smSortFollowedupHistory: '', baSortFollowedupHistory: '', notesSortFollowedupHistory: '', dateSortFollowedupHistory: '', statusSortFollowedupHistory: '' });
      }
    }

    if (event === 'sales_manager') {
      if (this.state.smSortFollowedupHistory === '' || this.state.smSortFollowedupHistory === 'desc') {
        data = data.sort((a, b) => ((a.sales_manager_name ? a.sales_manager_name : '') > (b.sales_manager_name ? b.sales_manager_name : '')) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: '', enquiryIdSortFollowedupHistory: '', smSortFollowedupHistory: 'asc', baSortFollowedupHistory: '', notesSortFollowedupHistory: '', dateSortFollowedupHistory: '', statusSortFollowedupHistory: '' });
      } else {
        data = data.sort((a, b) => ((a.sales_manager_name ? a.sales_manager_name : '') < (b.sales_manager_name ? b.sales_manager_name : '')) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: '', enquiryIdSortFollowedupHistory: '', smSortFollowedupHistory: 'desc', baSortFollowedupHistory: '', notesSortFollowedupHistory: '', dateSortFollowedupHistory: '', statusSortFollowedupHistory: '' });
      }
    }

    if (event === 'brand_associate') {
      if (this.state.baSortFollowedupHistory === '' || this.state.baSortFollowedupHistory === 'desc') {
        data = data.sort((a, b) => ((a.brand_associate_name ? a.brand_associate_name : '') > (b.brand_associate_name ? b.brand_associate_name : '')) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: '', enquiryIdSortFollowedupHistory: '', smSortFollowedupHistory: '', baSortFollowedupHistory: 'asc', notesSortFollowedupHistory: '', dateSortFollowedupHistory: '', statusSortFollowedupHistory: '' });
      } else {
        data = data.sort((a, b) => ((a.brand_associate_name ? a.brand_associate_name : '') < (b.brand_associate_name ? b.brand_associate_name : '')) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: '', enquiryIdSortFollowedupHistory: '', smSortFollowedupHistory: '', baSortFollowedupHistory: 'desc', notesSortFollowedupHistory: '', dateSortFollowedupHistory: '', statusSortFollowedupHistory: '' });
      }
    }

    if (event === 'notes') {
      if (this.state.notesSortFollowedupHistory === '' || this.state.notesSortFollowedupHistory === 'desc') {
        data = data.sort((a, b) => ((a.notes ? a.notes : '') > (b.notes ? b.notes : '')) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: '', enquiryIdSortFollowedupHistory: '', smSortFollowedupHistory: '', baSortFollowedupHistory: '', notesSortFollowedupHistory: 'asc', dateSortFollowedupHistory: '', statusSortFollowedupHistory: '' });
      } else {
        data = data.sort((a, b) => ((a.notes ? a.notes : '') < (b.notes ? b.notes : '')) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: '', enquiryIdSortFollowedupHistory: '', smSortFollowedupHistory: '', baSortFollowedupHistory: '', notesSortFollowedupHistory: 'desc', dateSortFollowedupHistory: '', statusSortFollowedupHistory: '' });
      }
    }

    if (event === 'date') {
      if (this.state.dateSortFollowedupHistory === '' || this.state.dateSortFollowedupHistory === 'desc') {
        data = data.sort((a, b) => new Date(a.created_date) > new Date(b.created_date) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: '', enquiryIdSortFollowedupHistory: '', smSortFollowedupHistory: '', baSortFollowedupHistory: '', notesSortFollowedupHistory: '', dateSortFollowedupHistory: 'asc', statusSortFollowedupHistory: '' });
      } else {
        data = data.sort((a, b) => new Date(a.created_date) < new Date(b.created_date) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: '', enquiryIdSortFollowedupHistory: '', smSortFollowedupHistory: '', baSortFollowedupHistory: '', notesSortFollowedupHistory: '', dateSortFollowedupHistory: 'desc', statusSortFollowedupHistory: '' });
      }
    }

    if (event === 'status') {
      if (this.state.statusSortFollowedupHistory === '' || this.state.statusSortFollowedupHistory === 'desc') {
        data = data.sort((a, b) => (a.status > b.status) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: '', enquiryIdSortFollowedupHistory: '', smSortFollowedupHistory: '', baSortFollowedupHistory: '', notesSortFollowedupHistory: '', dateSortFollowedupHistory: '', statusSortFollowedupHistory: 'asc' });
      } else {
        data = data.sort((a, b) => (a.status < b.status) ? 1 : -1)
        this.setState({ idSortFollowedupHistory: '', enquiryIdSortFollowedupHistory: '', smSortFollowedupHistory: '', baSortFollowedupHistory: '', notesSortFollowedupHistory: '', dateSortFollowedupHistory: '', statusSortFollowedupHistory: 'desc' });
      }
    }

    if (data) {
      this.setState({ historyFollowupData: Array.from(data) }, () => {
        this.handlePagingFollowedupHistory();
      });
    }
  }

  handleChangeFieldEnquiryHistory(event) {
    const target = event.target;
    const history_detail = target.id === 'history_detail' ? target.checked : this.state.enquiryHistoryShowDetail;
    this.setState({
      enquiryHistoryShowDetail: history_detail,
    });
  }

  handleDeleteEnquiry(event) {
    if (this.state.deleteEnquiryId) {
      axios.get(`${process.env.REACT_APP_API_URL}customer/history/delete?cid=${this.state.deleteEnquiryId}`,
        {
          headers: {
            'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
          }
        })
        .then(res => {
          this.getCustomerHistory();
          this.setState({
            deleteEnquiryId: '',
            showDeleteConfirmation: false,
          });
        })
    }
  }

  handleOpenDeleteEnquiryModal(event) {
    this.setState({
      deleteEnquiryId: event,
      showDeleteConfirmation: true,
    });

  }

  handleCloseDeleteEnquiryModal() {
    this.setState({
      deleteEnquiryId: '',
      showDeleteConfirmation: false,
    });

  }


  handleEditEnquiryForm(id, history_followup_id = null) {
    if (id == 0) {
      this.setState({
        id_enquiry_edit: '',
        first_name_enquiry_edit: '',
        last_name_enquiry_edit: '',
        email_enquiry_edit: '',
        phone_enquiry_edit: '',
        city_enquiry_edit: '',
        province_enquiry_edit: '',
        subject_enquiry_edit: '',
        type_enquiry_edit: '',
        notes_enquiry_edit: '',
        model_enquiry_edit: '',
        test_drive_location_enquiry_edit: '',
        showEditEnquiry: false,
        isSubmittedEditEnquiry: false
      });
    } else {
      var tmp_historyData = this.state.historyData;
      tmp_historyData = tmp_historyData.filter(row => {
        return row.id == id
      });
      this.setState({
        id_enquiry_edit: id,
        first_name_enquiry_edit: tmp_historyData[0].first_name,
        last_name_enquiry_edit: tmp_historyData[0].last_name,
        email_enquiry_edit: tmp_historyData[0].email,
        phone_enquiry_edit: tmp_historyData[0].phone,
        city_enquiry_edit: tmp_historyData[0].city,
        province_enquiry_edit: tmp_historyData[0].province,
        subject_enquiry_edit: tmp_historyData[0].subject,
        type_enquiry_edit: tmp_historyData[0].enquiry_type,
        notes_enquiry_edit: tmp_historyData[0].notes.replace(/;/g, ";\n\n"),
        model_enquiry_edit: tmp_historyData[0].preferred_model,
        test_drive_location_enquiry_edit: tmp_historyData[0].test_drive_location,
        showEditEnquiry: !this.state.showEditEnquiry,
        isSubmittedEditEnquiry: false
      });
    }
  }

  handleChangeEditEnquiry(event) {
    const target = event.target;
    const first_name_enquiry_edit = target.id === 'first_name_enquiry_edit' ? target.value : this.state.first_name_enquiry_edit;
    const last_name_enquiry_edit = target.id === 'last_name_enquiry_edit' ? target.value : this.state.last_name_enquiry_edit;
    const email_enquiry_edit = target.id === 'email_enquiry_edit' ? target.value : this.state.email_enquiry_edit;
    const phone_enquiry_edit = target.id === 'phone_enquiry_edit' ? target.value : this.state.phone_enquiry_edit;
    const city_enquiry_edit = target.id === 'city_enquiry_edit' ? target.value : this.state.city_enquiry_edit;
    const province_enquiry_edit = target.id === 'province_enquiry_edit' ? target.value : this.state.province_enquiry_edit;
    const subject_enquiry_edit = target.id === 'subject_enquiry_edit' ? target.value : this.state.subject_enquiry_edit;
    const type_enquiry_edit = target.id === 'type_enquiry_edit' ? target.value : this.state.type_enquiry_edit;
    const notes_enquiry_edit = target.id === 'notes_enquiry_edit' ? target.value : this.state.notes_enquiry_edit;
    const model_enquiry_edit = target.id === 'model_enquiry_edit' ? target.value : this.state.model_enquiry_edit;
    const test_drive_location_enquiry_edit = target.id === 'test_drive_location_enquiry_edit' ? target.value : this.state.test_drive_location_enquiry_edit;

    this.setState({
      first_name_enquiry_edit: first_name_enquiry_edit,
      last_name_enquiry_edit: last_name_enquiry_edit,
      email_enquiry_edit: email_enquiry_edit,
      phone_enquiry_edit: phone_enquiry_edit,
      city_enquiry_edit: city_enquiry_edit,
      province_enquiry_edit: province_enquiry_edit,
      subject_enquiry_edit: subject_enquiry_edit,
      type_enquiry_edit: type_enquiry_edit,
      //notes_enquiry_edit: notes_enquiry_edit.replace(/;/g, ";\n\n"),
      notes_enquiry_edit: notes_enquiry_edit,
      model_enquiry_edit: model_enquiry_edit,
      test_drive_location_enquiry_edit: test_drive_location_enquiry_edit,
    });
  }

  handleSubmitEditEnquiry(event) {
    event.preventDefault();
    this.setState({ isSubmittedEditEnquiry: true });
    const inputData = {
      id: this.state.id_enquiry_edit,
      first_name: this.state.first_name_enquiry_edit,
      last_name: this.state.last_name_enquiry_edit,
      email: this.state.email_enquiry_edit,
      phone: this.state.phone_enquiry_edit,
      city: this.state.city_enquiry_edit,
      province: this.state.province_enquiry_edit,
      subject: this.state.subject_enquiry_edit,
      enquiry_type: this.state.type_enquiry_edit,
      notes: this.state.notes_enquiry_edit.replace(/;\n\n/g, ";"),
      preferred_model: this.state.model_enquiry_edit,
      test_drive_location: this.state.test_drive_location_enquiry_edit,
      modified_by: jsCookie.get('uid'),
    };

    axios.post(`${process.env.REACT_APP_API_URL}customer/history/edit`, inputData,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.setState({ isSubmittedEditEnquiry: false });
        throw error;
      })
      .then(res => {
        this.setState({ reloadPage: true });
        this.setState({ isSubmittedEditEnquiry: false });
      })
  }

  responseFromChild(event) {
    this.setState({ redirectPage: true });
  }

  checkSalutation(event) {
    this.setState({ redirectPage: true });
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (!this.state.urlStatus) {
      return <Redirect to='/404' />;
    }

    if (this.state.reloadPage) {
      window.location.reload(false);
    }

    if (this.state.redirectPage) {
      return <Redirect to='/leads' />;
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 z-100">
          {this.state.errorMessage}
        </div>
        <CustomerEdit id={this.state.customers_id} returnData={this.responseFromChild} />
        {/*customer info*/}
        {/*latest follow up*/}
        <div className={`w-full lg:w-4/12 pl-4 mb-5`}>
          <div className="h-full relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-4 py-6 flex">
              <div className="w-full lg:w-6/12 relative">
                <h3 className="text-blueGray-700 text-xs font-bold uppercase">Latest Followed Up</h3>
              </div>
            </div>

            <div className="flex-auto px-4  py-10 pt-4">
              <form>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 ">
                    <div className="relative w-full my-2">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold "
                      >
                        Followed Up Date
                      </label>
                      <div
                        className="block text-blueGray-600 text-base"
                      >
                        {this.state.last_followup_date ? this.state.last_followup_date : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 ">
                    <div className="relative w-full my-2">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold "
                      >
                        Enquiry ID
                      </label>
                      <div
                        className="block text-blueGray-600 text-base"
                      >
                        {this.state.last_followup_id ? this.state.last_followup_id : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12">
                    <div className="relative w-full my-2">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold"
                      >
                        Enquiry Subject
                      </label>
                      <div
                        className="block text-blueGray-600 text-base"
                      >
                        {this.state.last_followup_subject ? this.state.last_followup_subject : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12">
                    <div className="relative w-full my-2">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold"
                      >
                        Type
                      </label>
                      <div
                        className="block text-blueGray-600 text-base"
                      >
                        {this.state.last_followup_enquiry_name ? this.state.last_followup_enquiry_name : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12">
                    <div className="relative w-full my-2">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold"
                      >
                        Status
                      </label>
                      <div
                        className="block text-blueGray-600 text-base"
                      >
                        {this.state.last_followup_status ? (this.state.last_followup_status === 'Yes' ? 'Follow Up' : this.state.last_followup_status) : '-'}
                      </div>
                    </div>
                  </div>
                  <hr className="mt-2 mb-4 md:min-w-full text-black border-blueGray-500" />
                  <div className="w-full lg:w-6/12">
                    <div className="relative w-full my-2">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold"
                      >
                        Sales Manager
                      </label>
                      <div
                        className="block text-blueGray-600 text-base"
                      >
                        {this.state.last_followup_sales_manager ? this.state.last_followup_sales_manager : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 ">
                    <div className="relative w-full my-2">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold "
                      >
                        Brand Associate
                      </label>
                      <div
                        className="block text-blueGray-600 text-base"
                      >
                        {this.state.last_followup_brand_associate ? this.state.last_followup_brand_associate : '-'}
                      </div>
                    </div>
                  </div>
                  <hr className="mt-2 mb-4 md:min-w-full text-black border-blueGray-500" />
                  <div className="w-full ">
                    <div className="relative w-full my-2">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold "
                      >
                        Notes
                      </label>
                      <div
                        className="block text-blueGray-600 text-base"
                      >
                        {this.state.last_followup_notes ? this.state.last_followup_notes : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 ">
                    <div className="relative w-full my-2">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold "
                      >
                        preferred_model
                      </label>
                      <div
                        className="block text-blueGray-600 text-base"
                      >
                        {this.state.last_followup_preferred_model ? this.state.last_followup_preferred_model : '-'}
                      </div>
                    </div>
                  </div>
                  {this.state.last_followup_notes ? (
                    <div className="w-full flex flex-wrap">
                      <div className="w-full">
                        <div className="relative w-full  text-right">
                          <button
                            type="button"
                            className={`button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                            onClick={e => this.handleStatusForm(this.state.last_followup_customer_history_id, this.state.last_followup_id,)}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : ''}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/*latest follow up*/}
        {/*enquiry history*/}
        <div className="w-full">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6  mt-5">
            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="rounded-t bg-white mb-0 px-4 py-6 flex">
                  <div className="w-full lg:w-6/12 relative  ">
                    <h3 className="text-blueGray-700 text-xs font-bold uppercase">Enquiry History</h3>
                  </div>
                </div>
                <div className="flex bg-blueGray-100 px-4">
                  <div className="relative my-2 mr-4 xs:w-full">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="history_detail"
                        name="history_detail"
                        placeholder="Filter by Name"
                        className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                        checked={this.state.enquiryHistoryShowDetail}
                        onChange={this.handleChangeFieldEnquiryHistory}
                      />
                      <label className="text-blueGray-400 text-sm ml-2" >Show Detail</label>
                    </div>
                  </div>
                </div>
                <div className="relative flex flex-col min-w-0 w-full mb-6 shadow-lg ">
                  <div className="block w-full overflow-x-auto rounded-b">
                    <div className="relative flex flex-col  min-w-0 w-full shadow-lg bg-white">
                      <div className="block w-full overflow-x-auto rounded-b">
                        <table className="relative items-center w-full overflow-x-scroll">
                          <thead>
                            <tr className="border border-l-0 border-r-0 border-blueGray-200">
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 pl-4"}
                                onClick={() => this.handleSortEnquiryHistory('id')}
                              >
                                ID
                                <i className={`fas ${this.state.idSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.idSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              {this.state.enquiryHistoryShowDetail ?
                                <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                  onClick={() => this.handleSortEnquiryHistory('enquirySubject')}
                                >
                                  First Name
                                  <i className={`fas ${this.state.enquirySubjectSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.enquirySubjectSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                                </th> : null}
                              {this.state.enquiryHistoryShowDetail ?
                                <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                  onClick={() => this.handleSortEnquiryHistory('last_name')}
                                >
                                  Last Name
                                  <i className={`fas ${this.state.enquirySubjectSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.enquirySubjectSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                                </th> : null}
                              {this.state.enquiryHistoryShowDetail ?
                                <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                  onClick={() => this.handleSortEnquiryHistory('email')}
                                >
                                  Email
                                  <i className={`fas ${this.state.enquirySubjectSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.enquirySubjectSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                                </th> : null}
                              {this.state.enquiryHistoryShowDetail ?
                                <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                  onClick={() => this.handleSortEnquiryHistory('phone')}
                                >
                                  Phone
                                  <i className={`fas ${this.state.enquirySubjectSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.enquirySubjectSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                                </th> : null}
                              {/*this.state.enquiryHistoryShowDetail ?
                                <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                  onClick={() => this.handleSortEnquiryHistory('city')}
                                >
                                  City
                                  <i className={`fas ${this.state.enquirySubjectSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.enquirySubjectSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                                </th> : null*/}
                              {this.state.enquiryHistoryShowDetail ?
                                <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                  onClick={() => this.handleSortEnquiryHistory('province')}
                                >
                                  Province
                                  <i className={`fas ${this.state.provinceSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.provinceSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                                </th> : null}
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                onClick={() => this.handleSortEnquiryHistory('enquirySubject')}
                              >
                                Enquiry Subject
                                <i className={`fas ${this.state.enquirySubjectSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.enquirySubjectSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                onClick={() => this.handleSortEnquiryHistory('type')}
                              >
                                Type
                                <i className={`fas ${this.state.typeSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.typeSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                onClick={() => this.handleSortEnquiryHistory('notes')}
                              >
                                Notes
                                <i className={`fas ${this.state.notesSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.notesSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                onClick={() => this.handleSortEnquiryHistory('preferredModel')}
                              >
                                Preferred Model
                                <i className={`fas ${this.state.preferredModelSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.preferredModelSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                onClick={() => this.handleSortEnquiryHistory('testDriveLocation')}
                              >
                                Test Drive Location
                                <i className={`fas ${this.state.testDriveLocationSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.testDriveLocationSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                onClick={() => this.handleSortEnquiryHistory('enquiryDate')}
                              >
                                Enquiry Date
                                <i className={`fas ${this.state.enquiryDateSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.enquiryDateSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                onClick={() => this.handleSortEnquiryHistory('enquiryDate')}
                              >
                                Channel
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                onClick={() => this.handleSortEnquiryHistory('status')}
                              >
                                Status
                                <i className={`fas ${this.state.statusSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.statusSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                onClick={() => this.handleSortEnquiryHistory('status')}
                              >
                                Followup Status
                                <i className={`fas ${this.state.statusSortEnquiryHistory === 'desc' ? "fa-sort-down" : (this.state.statusSortEnquiryHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500 pr-4"}>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.tableData.length > 0 ?
                              this.state.tableData.map((f, key) => {
                                return (
                                  <tr key={f.id} className={(f.followup_status === 'No' ? 'bg-table-red' : null) + " border border-l-0 border-r-0 border-blueGray-200"}>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4 pl-4">
                                      {f.id}
                                    </td>
                                    {this.state.enquiryHistoryShowDetail ?
                                      <td className="px-2 align-middle text-sm  py-4">
                                        {f.first_name}
                                      </td> : null}
                                    {this.state.enquiryHistoryShowDetail ?
                                      <td className="px-2 align-middle text-sm  py-4">
                                        {f.last_name}
                                      </td> : null}
                                    {this.state.enquiryHistoryShowDetail ?
                                      <td className="px-2 align-middle text-sm  py-4">
                                        {f.email}
                                      </td> : null}
                                    {this.state.enquiryHistoryShowDetail ?
                                      <td className="px-2 align-middle text-sm  py-4">
                                        {f.phone}
                                      </td> : null}
                                    {/*this.state.enquiryHistoryShowDetail ?
                                      <td className="px-2 align-middle text-sm  py-4">
                                        {f.city}
                                      </td> : null*/}
                                    {this.state.enquiryHistoryShowDetail ?
                                      <td className="px-2 align-middle text-sm  py-4">
                                        {f.province}
                                      </td> : null}
                                    <td className="px-2 align-middle text-sm  py-4">
                                      {f.subject}
                                    </td>
                                    <td className="px-2 align-middle text-sm  py-4">
                                      {f.enquiry_type}
                                    </td>
                                    <td className="px-2 align-middle msg-wrapper text-sm  py-4">
                                      {f.notes ? f.notes.slice(0, 70).replace(/;/g, ';\n\n') + "..." : ""}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.preferred_model}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.test_drive_location}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {DateFormat(f.enquiry_date, 'Mdy')}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      <img
                                        src={require("assets/img/logo/" + getBadge(f.source.toLowerCase())).default}
                                        alt={f.source}
                                        className="w-8 h-8 rounded-full border-2 border-blueGray-50 shadow bg-white"
                                      ></img>
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.status === 'No' ? 'Not Followed Up' : (f.status == 'Not Reach' ? "Can't Reach" : f.status)}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.followup_status === 'No' ? 'Not Followed Up' : (f.followup_status == 'Not Reach' ? "Can't Reach" : f.followup_status)}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-nowrap py-4 pr-4">
                                      <button
                                        className="cursor-pointer nofocus py-1 "
                                        onClick={e => this.handleStatusForm(f.id)}
                                      >
                                        {jsCookie.get('privilege') === 'Super Admin' || f.followup_status == 'SPK' ?
                                          <i className="fa fa-eye" />
                                          : <i className="fa fa-plus" />}
                                      </button>
                                      {jsCookie.get('privilege') === 'Super Admin' ?
                                        <>

                                          <button
                                            className="cursor-pointer nofocus py-1 ml-2 "
                                            onClick={e => this.handleEditEnquiryForm(f.id)}
                                          >
                                            <i className="fa fa-edit" />
                                          </button>
                                          <button
                                            className="cursor-pointer nofocus py-1 ml-2 text-red"
                                            onClick={e => this.handleOpenDeleteEnquiryModal(f.id)}
                                          >
                                            <i className="fa fa-times" />
                                          </button></>
                                        : ''}
                                    </td>
                                  </tr>
                                )
                              }) :
                              <tr className="border border-l-0 border-r-0 border-blueGray-200">
                                <td colSpan="7" className="align-middle text-sm whitespace-wrap py-4 pl-4 text-center">
                                  Data Not Available
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className="py-4">
                        <nav className="block">
                          <ul className="flex rounded list-none flex-wrap float-right mx-4">
                            {this.state.pagingPrevTemplateEnquiryHistory}
                            {this.state.pagingTemplateEnquiryHistory}
                            {this.state.pagingNextTemplateEnquiryHistory}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*enquiry history*/}
        {/*followed up history*/}
        <div className="w-full ">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="rounded-t bg-white mb-0 px-4 py-6 flex">
                  <div className="w-full lg:w-6/12 relative  ">
                    <h3 className="text-blueGray-700 text-xs font-bold uppercase">Followed Up History</h3>
                  </div>
                </div>
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg ">
                  <div className="block w-full overflow-x-auto rounded-b">
                    <div className="relative flex flex-col  min-w-0 break-words w-full pb-6 shadow-lg bg-white">
                      <div className="block w-full overflow-x-auto">
                        <table className="relative items-center w-full bg-transparent border-collapse overflow-x-scroll">
                          <thead>
                            <tr className="border border-l-0 border-r-0 border-blueGray-200">
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 pl-4"}
                                onClick={() => this.handleSortFollowedupHistory('id')}
                              >
                                ID
                                <i className={`fas ${this.state.idSortFollowedupHistory === 'desc' ? "fa-sort-down" : (this.state.idSortFollowedupHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 "}
                                onClick={() => this.handleSortFollowedupHistory('enquiry_id')}
                              >
                                Enquiry ID
                                <i className={`fas ${this.state.enquiryIdSortFollowedupHistory === 'desc' ? "fa-sort-down" : (this.state.enquiryIdSortFollowedupHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 "}
                                onClick={() => this.handleSortFollowedupHistory('sales_manager')}
                              >
                                Sales Manager
                                <i className={`fas ${this.state.smSortFollowedupHistory === 'desc' ? "fa-sort-down" : (this.state.smSortFollowedupHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 "}
                                onClick={() => this.handleSortFollowedupHistory('brand_associate')}
                              >
                                Brand Associate
                                <i className={`fas ${this.state.baSortFollowedupHistory === 'desc' ? "fa-sort-down" : (this.state.baSortFollowedupHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 "}
                                onClick={() => this.handleSortFollowedupHistory('notes')}
                              >
                                Preferred Model
                                <i className={`fas ${this.state.notesSortFollowedupHistory === 'desc' ? "fa-sort-down" : (this.state.notesSortFollowedupHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 "}
                                onClick={() => this.handleSortFollowedupHistory('notes')}
                              >
                                Notes
                                <i className={`fas ${this.state.notesSortFollowedupHistory === 'desc' ? "fa-sort-down" : (this.state.notesSortFollowedupHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 "}
                                onClick={() => this.handleSortFollowedupHistory('date')}
                              >
                                Date
                                <i className={`fas ${this.state.dateSortFollowedupHistory === 'desc' ? "fa-sort-down" : (this.state.dateSortFollowedupHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                onClick={() => this.handleSortFollowedupHistory('status')}
                              >
                                Status
                                <i className={`fas ${this.state.statusSortFollowedupHistory === 'desc' ? "fa-sort-down" : (this.state.statusSortFollowedupHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500"}
                                onClick={() => this.handleSortFollowedupHistory('status')}
                              >
                                Followup Status
                                <i className={`fas ${this.state.statusSortFollowedupHistory === 'desc' ? "fa-sort-down" : (this.state.statusSortFollowedupHistory === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50  px-2`}></i>
                              </th>
                              <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 pr-4"}>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.historyFollowupData.length > 0 ?
                              this.state.historyFollowupData.map((f, key) => {
                                return (
                                  <tr key={f.id} className="border border-l-0 border-r-0 border-blueGray-200">
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4 pl-4">
                                      {f.id}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.customers_history_id}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.sales_manager_name}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.brand_associate_name}
                                    </td>
                                    <td className="px-2 align-middle text-sm py-4">
                                      {f.preferred_model}
                                    </td>
                                    <td className="px-2 align-middle text-sm py-4">
                                      {f.notes}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {DateFormat(f.created_date, 'Mdy')}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.status === 'Yes' ? 'Follow Up' : (f.status == 'Not Reach' ? "Can't Reach" : f.status)}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                      {f.followup_status}
                                    </td>
                                    <td className="px-2 align-middle text-sm whitespace-wrap py-4 pr-4">
                                      <button
                                        className="cursor-pointer nofocus py-1 "
                                        onClick={e => this.handleStatusForm(f.customers_history_id, f.id,)}
                                      >
                                        <i className="fa fa-eye" />
                                      </button>
                                    </td>
                                  </tr>
                                )
                              }) :

                              <tr className="border border-l-0 border-r-0 border-blueGray-200">
                                <td colSpan="7" className="align-middle text-sm whitespace-wrap py-4 pl-4 text-center">
                                  Data Not Available
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className="py-2">
                        <nav className="block">
                          <ul className="flex rounded list-none flex-wrap float-right mx-4">
                            {this.state.pagingPrevTemplateFollowedupHistory}
                            {this.state.pagingTemplateFollowedupHistory}
                            {this.state.pagingNextTemplateFollowedupHistory}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*followed up history*/}
        {/*sidebar*/}
        {this.state.showStatus ? (
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              className={` overflow-y-auto items-center pt-4 fixed top-0 z-100 h-full right-0 w-full lg:w-4/12 `}>
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-bl rounded-tl bg-blueGray-100 border-0">
                <div className="flex-auto px-4 py-6  bg-white shadow rounded-tl ">
                  <div className="w-full">
                    <div className="flex justify-between items-center">
                      <div className="">
                        <label
                          className="text-blueGray-700 text-sm font-bold uppercase"
                        >{this.state.subject_sf}</label>
                        {this.state.enquiry_type_name_sf ?
                          <div
                            className="w-auto text-blueGray-700 text-xs font-bold uppercase"
                          >Type : {this.state.enquiry_type_name_sf}</div> : null}
                        <div
                          className="w-auto text-blueGray-700 text-xs font-bold uppercase"
                        >Enquiry Date : {this.state.enquiry_date_sf}</div>
                      </div>
                      <span
                        className="text-right text-blueGray-700 text-xs font-bold uppercase float-right"
                      >Enquiry ID : <div>{this.state.customers_history_id_sf}</div></span>
                    </div>
                  </div>
                </div>
                <div className="flex-auto px-4 py-6  ">
                  <form>
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-4/12 mb-2">
                        <div className="relative w-full mb-4">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold "
                          >
                            First Name
                          </label>
                          {this.state.first_name_sf}
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 mb-2">
                        <div className="relative w-full mb-4">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold "
                          >
                            Last Name
                          </label>{this.state.last_name_sf}
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 mb-2">
                        <div className="relative w-full mb-4">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold"
                          >
                            Email
                          </label>{this.state.email_sf}
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 mb-2">
                        <div className="relative w-full mb-4">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold"
                          >
                            Phone
                          </label>{this.state.phone_sf}
                        </div>
                      </div>
                      {!this.state.province_sf ? <div className="w-full lg:w-4/12 mb-2">
                        <div className="relative w-full mb-4">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold"
                          >
                            City
                          </label>{this.state.city_sf}
                        </div>
                      </div> : ''}
                      <div className="w-full lg:w-4/12 mb-2">
                        <div className="relative w-full mb-4">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold"
                          >
                            Province
                          </label>{this.state.province_sf}
                        </div>
                      </div>
                      <div className={`${this.state.book_test_drive_sf ? 'hidden' : 'block'} w-full mb-2`}>
                        <div className="relative w-full mb-4 msg-wrapper ">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold"
                          >
                            Enquiry Notes
                          </label>
                          <p>{this.state.notes_sf ? this.state.notes_sf : '-'}</p>
                        </div>
                      </div>
                      <div className={`${this.state.preferred_model_sf ? 'block' : 'hidden'} w-full lg:w-6/12 mb-2`}>
                        <div className="relative w-full mb-4">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold"
                          >
                            Preferred Model
                          </label>{this.state.preferred_model_sf}
                        </div>
                      </div>
                      <div className={`${this.state.book_test_drive_sf || this.state.configurator_sf ? 'block' : 'hidden'} w-full lg:w-6/12 mb-2`}>
                        <div className="relative w-full mb-4">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold"
                          >
                            Test Drive Location
                          </label>
                          {this.state.test_drive_location_sf}
                        </div>
                      </div>
                      <hr className="mb-4 md:min-w-full text-black border-blueGray-500" />
                      <div className={`${this.state.disabledSf ? '' : 'hidden'} w-full lg:w-6/12 mb-2`}>
                        <div className="relative w-full mb-4">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold"
                          >
                            Sales Manager
                          </label>
                          {this.state.sales_manager_sf ? this.state.sales_manager_sf : '-'}
                        </div>
                      </div>
                      <div className={`${this.state.disabledSf ? '' : 'hidden'} w-full lg:w-6/12 mb-2`}>
                        <div className="relative w-full mb-4">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold"
                          >
                            Brand Associate
                          </label>
                          {this.state.brand_associate_sf ? this.state.brand_associate_sf : '-'}
                        </div>
                      </div>
                      <div className={`${this.state.disabledSf ? '' : 'hidden'}  w-full lg:w-6/12 mb-2`}>
                        <div className="relative w-full mb-4">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold"
                          >
                            Followed Up ID
                          </label>{this.state.followup_id_sf ? this.state.followup_id_sf : '-'}
                        </div>
                      </div>
                      <div className={`${this.state.disabledSf ? '' : 'hidden'}  w-full lg:w-6/12 mb-2`}>
                        <div className="relative w-full mb-4">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold"
                          >
                            Followed Up Date
                          </label>{this.state.followup_date_sf ? this.state.followup_date_sf : '-'}
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Preferred Model
                          </label>

                          <select
                            type="text"
                            id="followup_preferred_model_sf"
                            name="followup_preferred_model_sf"
                            className={`${this.state.disabledSf ? 'disabled' : ''} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            disabled={this.state.disabledSf ? 'disabled' : ''}
                            value={this.state.followup_preferred_model_sf}
                            onChange={this.handleChangeSf}
                          >
                            <option key={'followup_preferred_model_sf'} value="" >Please Choose One</option>
                            {this.state.modelOptions.map((e, key) => {
                              return <option key={'followup_preferred_model_sf' + key} value={e.value} >{e.value}</option>;
                            })}
                          </select>
                          {this.state.preferredModelSfError ?
                            <>
                              <span className="mr-5 z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0  py-3">
                                <i className="fas fa-exclamation"></i>
                              </span>
                            </>
                            : ''}
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Notes
                          </label>
                          <textarea
                            type="text"
                            id="followup_notes_sf"
                            name="followup_notes_sf"
                            className={`${this.state.disabledSf ? 'disabled' : ''} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            disabled={this.state.disabledSf ? 'disabled' : ''}
                            value={this.state.followup_notes_sf}
                            onChange={this.handleChangeSf}
                          />
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Status
                          </label>
                          <select
                            type="text"
                            id="followup_status_sf"
                            name="followup_status_sf"
                            className={`${this.state.disabledSf ? 'disabled' : ''} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            disabled={this.state.disabledSf ? 'disabled' : ''}
                            value={this.state.followup_status_sf}
                            onChange={this.handleChangeSf}
                            placeholder="Please Choose one"
                          >
                            {this.state.followupOption.map((e, key) => {
                              return <option key={key} value={e.value} >{e.text}</option>;
                            })}
                          </select>
                          {this.state.statusSfError ?
                            <>
                              <span className="mr-5 z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0  py-3">
                                <i className="fas fa-exclamation"></i>
                              </span>
                            </>
                            : ''}
                        </div>
                      </div>
                      <div className={`w-full ${this.state.hide_followup_status2_sf ? '' : 'hidden'}`}>
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Followup
                          </label>
                          <select
                            type="text"
                            id="followup_status2_sf"
                            name="followup_status2_sf"
                            className={`${this.state.disabledSf ? 'disabled' : ''}  border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            disabled={this.state.disabledSf ? 'disabled' : ''}
                            value={this.state.followup_status2_sf}
                            onChange={this.handleChangeSf}
                            placeholder="Please Choose one"
                          >
                            {this.state.followupOption2.map((e, key) => {
                              return <option key={key} value={e.value} >{e.text}</option>;
                            })}
                          </select>
                          {this.state.followupStatusSfError ?
                            <>
                              <span className="mr-5 z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0  py-3">
                                <i className="fas fa-exclamation"></i>
                              </span>
                            </>
                            : ''}
                        </div>
                      </div>
                    </div>
                    <div className={`flex flex-wrap ${this.state.baEmptySfError?'':'hidden'}`}>
                      <div className="w-full">
                        <div className="relative w-full text-red-500">
                          Please Assign to BA first!
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap">
                      <div className="w-full">
                        <div className="relative w-full  text-right">
                          {this.state.notifSf?
                          <div className="relative w-full  text-left" style={{color: 'green'}}>Sukses submit follow up.</div>
                          :''}
                          <button
                            type="button"
                            className="button-login text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            onClick={e => this.handleStatusForm(0)}
                          >
                            Close
                          </button>
                          {this.state.isSubmittedSf ?
                            <button
                              className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                            ><ClipLoader size={15} color="white" /></button> :
                            <button
                              className={`${this.state.disabledSf ? 'disabled' : ''}  button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                              disabled={this.state.disabledSf ? 'disabled' : ''}
                              onClick={this.handleSubmitSf}
                            >
                              save
                            </button>
                          }
                        </div>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
            <div
              className="opacity-25 fixed inset-0 z-40 bg-black"
              onClick={e => this.handleStatusForm(0)}
            ></div>

          </div>) : ''}
        {/*sidebar*/}

        {/*Edit Enquiry*/}
        {this.state.showEditEnquiry ? (
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              className={` overflow-y-auto items-center pt-4 fixed top-0 z-100 h-full right-0 w-full lg:w-4/12 `}>
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-bl rounded-tl bg-blueGray-100 border-0">
                <div className="flex-auto px-4 py-6  bg-white shadow rounded-tl ">
                  <div className="w-full">
                    <div className="flex  items-center">
                      <div
                        className="w-full text-blueGray-700 text-xs font-bold uppercase mx-2"
                      >Enquiry ID : {this.state.id_enquiry_edit}</div>
                    </div>
                  </div>
                </div>
                <div className="flex-auto px-4 py-6  ">
                  <form>
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-6/12 mb-2">
                        <div className="relative w-full mb-4 px-2">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            id="first_name_enquiry_edit"
                            name="first_name_enquiry_edit"
                            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            value={this.state.first_name_enquiry_edit}
                            onChange={this.handleChangeEditEnquiry}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 mb-2">
                        <div className="relative w-full mb-4 px-2">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="last_name_enquiry_edit"
                            name="last_name_enquiry_edit"
                            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            value={this.state.last_name_enquiry_edit}
                            onChange={this.handleChangeEditEnquiry}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 mb-2">
                        <div className="relative w-full mb-4 px-2">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            id="email_enquiry_edit"
                            name="email_enquiry_edit"
                            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            value={this.state.email_enquiry_edit}
                            onChange={this.handleChangeEditEnquiry}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 mb-2">
                        <div className="relative w-full mb-4 px-2">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Phone
                          </label>
                          <input
                            type="text"
                            id="phone_enquiry_edit"
                            name="phone_enquiry_edit"
                            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            value={this.state.phone_enquiry_edit}
                            onChange={this.handleChangeEditEnquiry}
                          />
                        </div>
                      </div>
                      {!this.state.province_enquiry_edit ? <div className="w-full lg:w-6/12 mb-2">
                        <div className="relative w-full mb-4 px-2">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            id="city_enquiry_edit"
                            name="city_enquiry_edit"
                            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            value={this.state.city_enquiry_edit}
                            onChange={this.handleChangeEditEnquiry}
                          />
                        </div>
                      </div> : ''}
                      <div className="w-full lg:w-6/12 mb-2">
                        <div className="relative w-full mb-4 px-2">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Province
                          </label>
                          <select
                            type="text"
                            id="province_enquiry_edit"
                            name="province_enquiry_edit"
                            className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.province_enquiry_edit ? this.state.province_enquiry_edit.toLowerCase() : null}
                            onChange={this.handleChangeEditEnquiry}
                          >
                            <option value="">Please Select</option>
                            {this.state.provinceOption.map((e, key) => {
                              return <option key={'status' + key} value={e.label.toLowerCase()} >{e.label}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      <hr className="mb-4 md:min-w-full text-black border-blueGray-500" />
                      <div className="w-full lg:w-6/12 mb-2">
                        <div className="relative w-full mb-4 px-2">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Enquiry Subject
                          </label>
                          <input
                            type="text"
                            id="subject_enquiry_edit"
                            name="subject_enquiry_edit"
                            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            value={this.state.subject_enquiry_edit}
                            onChange={this.handleChangeEditEnquiry}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 mb-2">
                        <div className="relative w-full mb-4 px-2">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Type
                          </label>
                          <input
                            type="text"
                            id="type_enquiry_edit"
                            name="type_enquiry_edit"
                            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            value={this.state.type_enquiry_edit}
                            onChange={this.handleChangeEditEnquiry}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-12/12 mb-2">
                        <div className="relative w-full mb-4 px-2">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Enquiry Notes
                          </label>
                          <textarea
                            type="text"
                            id="notes_enquiry_edit"
                            name="notes_enquiry_edit"
                            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            value={this.state.notes_enquiry_edit}
                            onChange={this.handleChangeEditEnquiry}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-12/12 mb-2">
                        <div className="relative w-full mb-4 px-2">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Preferred Model
                          </label>
                          <select
                            type="text"
                            id="model_enquiry_edit"
                            name="model_enquiry_edit"
                            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            value={this.state.model_enquiry_edit}
                            onChange={this.handleChangeEditEnquiry}
                          >
                            <option key={'followup_preferred_model_sf'} value="" >Please Choose One</option>
                            {this.state.modelOptions.map((e, key) => {
                              return <option key={'followup_preferred_model_sf' + key} value={e.value} >{e.value}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="w-full lg:w-12/12 mb-2">
                        <div className="relative w-full mb-4 px-2">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          >
                            Test Drive Location
                          </label>
                          <input
                            type="text"
                            id="test_drive_location_enquiry_edit"
                            name="test_drive_location_enquiry_edit"
                            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            value={this.state.test_drive_location_enquiry_edit}
                            onChange={this.handleChangeEditEnquiry}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap">
                      <div className="w-full">
                        <div className="relative w-full  text-right">
                          <button
                            type="button"
                            className="button-login text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            onClick={e => this.handleEditEnquiryForm(0)}
                          >
                            Close
                          </button>
                          {this.state.isSubmittedEditEnquiry ?
                            <button
                              className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                            ><ClipLoader size={15} color="white" /></button> :
                            <button
                              className={`button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                              onClick={this.handleSubmitEditEnquiry}
                            >
                              save
                            </button>
                          }
                        </div>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
            <div
              className="opacity-25 fixed inset-0 z-40 bg-black"
              onClick={e => this.handleEditEnquiryForm(0)}
            ></div>

          </div>) : ''}
        {/*Edit Enquiry*/}

        {this.state.showDeleteConfirmation ?
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto mx-auto">
                    <h6 className="text-center text-black font-bold mx-auto">Are you sure you want to delete this data? </h6>
                  </div>
                  <div className="flex items-center justify-center pb-6 rounded-b text-center">
                    <button
                      className="w-5/12 button-gold  text-white  font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      type="button"
                      onClick={this.handleDeleteEnquiry}
                    >
                      Yes
                    </button>
                    <button
                      className="w-5/12 button-login  text-white  font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      type="button"
                      onClick={this.handleCloseDeleteEnquiryModal}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed inset-0 z-50  bg-login opacity-50"></div>
          </> : ''}
      </>
    );
  }
}

export default User
