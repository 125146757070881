import React from 'react'
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import auth from 'global/auth.js';
import jsCookie from 'js-cookie';

import { Validation } from "global/custom.js";
import ClipLoader from "react-spinners/ClipLoader";
import { getBadge } from "global/data.js";
/*eslint-disable eqeqeq*/

export default class CustomerEditClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers_id: this.props.id,
      salutation:'',
      first_name: '',
      firstNameError: false,
      last_name: '',
      lastNameError: false,
      email: '',
      emailError: false,
      phone: '',
      phoneError: false,
      address: '',
      addressError: false,
      city: '',
      cityError: false,
      platform_groups: [],
      notes: '',
      notesError: false,
      sales_manager_id: '',
      sales_manager_name: '',
      brand_associate_id: '',
      brand_associate_name: '',
      errorMessage: '',
      reloadPage: false,
      urlStatus: true,
      salesManagerListBackup: [],
      salesManagerList: [],
      brandAssociateListBackup: [],
      brandAssociateList: [],
      isSubmitted: false,
      showWaModal: false,
      whatsappSmLink: '',
      whatsappBaLink: '',
      redirectMain: false,
      salesManagerDisabled: false,
      brandAssociateDisabled: false,
      firstNameDisabled: false,
      lastNameDisabled: false,
      emailDisabled: false,
      phoneDisabled: false,
      cityDisabled: false,
      provinceDisabled: false,
      provinceOption:[],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGetSalesManager = this.getSalesManager.bind(this);

    this.handleShowWaModal = this.handleShowWaModal.bind(this);

    this.handleCheckSalutation = this.handleCheckSalutation.bind(this);
    
  }

  async getCustomerDetail() {
    await axios.get(`${process.env.REACT_APP_API_URL}customers/${this.state.customers_id}`,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(err => {
        this.setState({ urlStatus: false });
      })
      .then(res => {
        if (res.data.customers_data.length == 0) {
          this.setState({ urlStatus: false });
          return false;
        } else {
          const data = res.data.customers_data[0];
          if(data.email_list){
            var email = data.email_list.split(',')
          email = email.join(',\n')
          }
          if(data.phone_list){
            var phone = data.phone_list.split(',')
          phone = phone.join(',\n')
          }
          let disabledNotesBtn = true;
          if (jsCookie.get('privilege') === 'Super Admin') {
            disabledNotesBtn = false
          }
          this.setState({
            customers_id: data.id,
            salutation: data.salutation,
            first_name: data.first_name,
            last_name: data.last_name ? data.last_name : '',
            email: email ? email : '',
            phone: phone ? phone : '',
            sales_manager_id: data.sales_manager_id ? data.sales_manager_id : '',
            sales_manager_name: data.sales_manager_name ? data.sales_manager_name : '',
            brand_associate_id: data.brand_associate_id ? data.brand_associate_id : '',
            brand_associate_name: data.brand_associate_name ? data.brand_associate_name : '',
            city: data.city ? data.city : '',
            province: data.province ? data.province : '',
            showroom_name:data.showroom_name?data.showroom_name:'',
            notes: data.notes,
            platform_groups: data.platform_groups,
            current_sales_manager_id: data.sales_manager_id,
            current_brand_associate_id: data.brand_associate_id,
            reloadPage: false,
            disabledNotesBtn: disabledNotesBtn,
          }, () => {
            this.getSalesManager();
          });
        }
      });
  }

  getSalesManager() {
    axios.get(`${process.env.REACT_APP_API_URL}user`,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(err => {
        throw err;
      })
      .then(res => {
        var data = res.data;
        data = data.filter(row => {
          return row.privilege.toLowerCase().match('sales manager') && row.status == 'active'
        });

        if (jsCookie.get('privilege') === 'Sales Manager') {
          data = data.filter(row => {
            return row.id == jsCookie.get('uid')
          });
        }
        this.setState({
          salesManagerList: data,
          salesManagerListBackup: data
        }, () => {
          this.getBrandAssociate();
        });
      });
  }

  getBrandAssociate() {
    axios.get(`${process.env.REACT_APP_API_URL}user`,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(err => {
        throw err;
      })
      .then(res => {
        var data = res.data;
        data = data.filter(row => {
          return row.privilege.toLowerCase().match('brand associate') && row.status == 'active'
        });

        if (jsCookie.get('privilege') === 'Sales Manager') {
          data = data.filter(row => {
            return row.manager_id == jsCookie.get('uid')
          });
        }
        this.setState({
          brandAssociateList: data,
          brandAssociateListBackup: data
        }, () => {
          this.getDropdownProvince();
        });
      });
  }

  getDropdownProvince() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/province`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          provinceOption: data,
          isLoading: false
        }, () => {
        });
      })
  }

  componentDidMount() {
    if (jsCookie.get('privilege') == 'Sales Manager') {
      this.setState({
        salesManagerDisabled: true,
      })
    }
    if (jsCookie.get('privilege') == 'Brand Associate') {
      this.setState({
        firstNameDisabled: true,
        lastNameDisabled: true,
        emailDisabled: true,
        phoneDisabled: true,
        cityDisabled: true,
        provinceDisabled: true,
        salesManagerDisabled: true,
        brandAssociateDisabled: true,
      })
    }
    if (this.state.customers_id > 0) {
      this.getCustomerDetail();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.brandAssociateListBackup !== prevState.brandAssociateListBackup || this.state.sales_manager_id !== prevState.sales_manager_id) {
      if (this.state.sales_manager_id) {
        var tmp_data = this.state.brandAssociateList.filter(row => {
          return row.manager_id == this.state.sales_manager_id
        });
        this.setState({ brandAssociateList: tmp_data })
      }
    }

    if (this.state.reloadPage !== prevState.reloadPage && this.state.reloadPage == true) {
      this.getCustomerDetail();
    }
  }


  handleChange(event) {
    const target = event.target;

    const salutation = target.id === 'salutation' ? target.value : this.state.salutation;
    const first_name = target.id === 'first_name' ? target.value : this.state.first_name;
    const last_name = target.id === 'last_name' ? target.value : this.state.last_name;
    const email = target.id === 'email' ? target.value : this.state.email;
    const phone = target.id === 'phone' ? target.value : this.state.phone;
    const city = target.id === 'city' ? target.value : this.state.city;
    const province = target.id === 'province' ? target.value : this.state.province;
    const sales_manager_id = target.id === 'salesManager' ? target.value : this.state.sales_manager_id;
    const brand_associate_id = target.id === 'brandAssociate' ? target.value : this.state.brand_associate_id;
    const notes = target.id === 'notes' ? target.value : this.state.notes;

    if (target.id === 'salesManager') {
      let baList = this.state.brandAssociateListBackup;
      let tmpBAList = baList.filter(row => {
        return row.manager_id == sales_manager_id
      });
      this.setState({ brandAssociateList: tmpBAList })
    }
    this.setState({
      salutation: salutation,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
      city: city,
      province: province,
      sales_manager_id: sales_manager_id,
      brand_associate_id: brand_associate_id,
      notes: notes,
    });
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className='notifBar bg-red'>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)} />
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  handleValidation(type, value, errorName, errorMessageName, fieldName) {
    var valid = Validation(type, value, errorName, errorMessageName, fieldName);
    if (valid.status == 404) {
      this.createErrorMessage(valid.msg, errorName, errorMessageName);
      return false;
    } else {
      this.setState({
        [errorName]: '',
        [errorMessageName]: ''
      });
      return true;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitted: true });
    var validate = 1;
    let inputData = [];
    if (jsCookie.get('privilege') != 'Brand Associate') {
      if (!this.handleValidation('text', this.state.first_name, 'firstNameError', 'firstNameErrorMessage', 'First Name')) {
        validate = 0;
      }
      /*if (!this.handleValidation('text', this.state.last_name, 'lastNameError', 'lastNameErrorMessage', 'Last Name')) {
        validate = 0;
      }*/
      if(!this.state.email && !this.state.phone){
        if (!this.handleValidation('multiple_email', this.state.email, 'emailError', 'emailErrorMessage', 'Email')) {
          validate = 0;
        }

        if (!this.handleValidation('multiple_phone', this.state.phone, 'phoneError', 'phoneErrorMessage', 'Phone')) {
          validate = 0;
        }
      }

      /*if (!this.handleValidation('text', this.state.city, 'cityError', 'cityErrorMessage', 'City')) {
        validate = 0;
      }*/

      if (validate == 0) {
        this.setState({ isSubmitted: false });
        return false;
      }

      inputData = {
        id: this.state.customers_id,
        salutation: this.state.salutation,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email_list: this.state.email,
        phone_list: this.state.phone,
        city: this.state.city,
        province: this.state.province,
        sales_manager_id: this.state.sales_manager_id ? this.state.sales_manager_id : '',
        brand_associate_id: this.state.brand_associate_id ? this.state.brand_associate_id : '',
        notes: this.state.notes,
        uid: jsCookie.get('uid'),
      };

      if (!this.state.brand_associate_id) {
        delete (inputData.brand_associate_id)
      }
      if (!this.state.sales_manager_id) {
        delete (inputData.sales_manager_id)
      }
    } else {
      inputData = {
        id: this.state.customers_id,
        salutation: this.state.salutation,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email_list: this.state.email,
        phone_list: this.state.phone,
        city: this.state.city,
        province: this.state.province,
        sales_manager_id: this.state.sales_manager_id ? this.state.sales_manager_id : '',
        brand_associate_id: this.state.brand_associate_id ? this.state.brand_associate_id : '',
        notes: this.state.notes,
        modified_by: jsCookie.get('uid'),
      };
    }
    axios.post(`${process.env.REACT_APP_API_URL}customers/${this.props.id}`, inputData,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.setState({ isSubmitted: false });
        throw error;
      })
      .then(res => {
        this.setState({
          successMessage:
            <div id='alerts' className='notifBar bg-emerald-400'>
              <div id="ofBar-content" className="notifBar-content">
                <b>Data has been submitted.</b>
              </div>
              <div id="ofBar-right" className="notifBar-right">
                <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert('successMessage')} />
              </div>
            </div>
        });
        var sm = '';
        var ba = '';
        var waSmMsg = '';
        var waBaMsg = '';
        var whatsappMsg = '';
        var showWaModal = false;
        let customers_list = ''
        res.data.forEach((elem, key) => {
          customers_list += (key + 1) + '. ' + elem.name + ' - +' + elem.phone + ' - ' + elem.last_enquiry + ' - ' + elem.last_enquiry_car_preference + '\n\n'
        })
        if (this.state.current_sales_manager_id != this.state.sales_manager_id && this.state.sales_manager_id) {
          var sales_manager_id = this.state.sales_manager_id;
          sm = this.state.salesManagerListBackup.filter(function (value, index, arr) {
            return value.id == sales_manager_id;
          });
          if (sm) {
            whatsappMsg = encodeURIComponent('Hi ' + sm[0].name + ', 1 Customers just assigned by ' + jsCookie.get('name') + ' to you.\n\n' + customers_list + '\nPlease Check the update on Lexus CDP Dashboard \n' + process.env.REACT_APP_URL);
            waSmMsg = 'https://wa.me/' + sm[0].phone + '?text=' + whatsappMsg
            showWaModal = true;
          }
        }
        if (this.state.current_brand_associate_id != this.state.brand_associate_id && this.state.brand_associate_id) {
          var brand_associate_id = this.state.brand_associate_id;
          ba = this.state.brandAssociateListBackup.filter(function (value, index, arr) {
            return value.id == brand_associate_id;
          });
          if (ba) {
            whatsappMsg = encodeURIComponent('Hi ' + ba[0].name + ', 1 Customers just assigned by ' + jsCookie.get('name') + ' to you.\n\n' + customers_list + '\nPlease Check the update on Lexus CDP Dashboard \n' + process.env.REACT_APP_URL);
            waBaMsg = 'https://wa.me/' + ba[0].phone + '?text=' + whatsappMsg
            showWaModal = true;
          }
        }
        this.setState({
          showWaModal: showWaModal,
          reloadPage: true,
          isSubmitted: false,
          whatsappSmLink: waSmMsg,
          whatsappBaLink: waBaMsg,
        });


        setTimeout(
          function () {
            if (!showWaModal) {
              this.props.returnData(true);
            }
          }
            .bind(this),
          1000
        );
      })
  }

  handleShowWaModal(event) {

    this.setState({
      showWaModal: event,
    });
    if (event == false) {
      this.props.returnData(true);
    }
  }

  handleCheckSalutation() {
    if(this.state.salutation){
      let url = window.history.back();
      if(!url){
        window.location.href = '/leads';
      }
    }
    else{
      this.handleValidation('text', this.state.salutation, 'salutationError', 'salutationErrorMessage', 'Salutation');
    }
  }


  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (this.state.redirectMain) {
      return <Redirect to={'/leads/' + this.props.id} />;
    }
    if (!this.state.urlStatus) {
      return <Redirect to='/404' />;
    }

    return (
      <>
      <div className="w-full text-white z-30 pb-4 pr-4">
        <div onClick={() => this.handleCheckSalutation()}
          className="cursor-pointer text-white"><i className="fa fa-angle-left"></i> Back
        </div>
      </div>
      <div id="notificationArea" className="notificationArea w-full lg:w-4/12 z-100">
        {this.state.errorMessage}
        {this.state.statusSfErrorMessage}
        {this.state.preferredModelSfErrorMessage}
      </div>
      {/*customer info*/}
      <div className={`w-full lg:w-8/12 pr-4 mb-5`}>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.successMessage}
          {this.state.salutationErrorMessage}
          {this.state.firstNameErrorMessage}
          {this.state.lastNameErrorMessage}
          {this.state.emailErrorMessage}
          {this.state.phoneErrorMessage}
          {this.state.cityErrorMessage}
        </div>
        <div className={`w-full h-full`}>
          <div className="h-full relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-100 border-0">
            <div className="relative rounded-t mb-0 px-4 border-0 bg-white">
              <div className="rounded-t bg-white mb-0 py-6 flex justify-between">
                <div className="relative  ">
                  <h3 className="text-blueGray-700 text-xs font-bold uppercase">Name</h3>
                  <h6 className="text-blueGray-700 text-xl font-bold">{this.state.salutation?this.state.salutation+'. ':' '}{this.state.first_name} {this.state.last_name}</h6>
                </div>
                <div className="flex text-right">
                  <div>
                    <h3 className="text-blueGray-700 text-xs font-bold uppercase">Channel</h3>
                    <div className="flex flex-wrap">
                      {this.state.platform_groups.map(function (row_platform_user, index) {
                        return (<img key={index}
                          src={require("assets/img/logo/" + getBadge(row_platform_user.toLowerCase())).default}
                          alt={row_platform_user}
                          className="w-8 h-8 rounded-full border-2 border-blueGray-50 shadow ml-2 bg-white"
                        ></img>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-auto px-4  py-10 pt-4">
              <form>
                <div className="flex flex-wrap">
                  <div className="customerinfo-field w-full lg:w-6/12">
                    <div className="relative w-full mb-3 flex">
                      <div className='w-auto place-self-flex-end mr-2 '>
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Mr / Mrs
                        </label>
                        <select
                          type="text"
                          id="salutation"
                          name="salutation"
                          className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150`}
                          value={this.state.salutation}
                          onChange={this.handleChange}
                          placeholder="Please Choose one"
                          style={{ width: `${65}px` }}
                        >
                          <option value="" >-</option>;
                          <option value="Mr" >Mr</option>;
                          <option value="Mrs" >Mrs</option>;
                          <option value="Ms" >Ms</option>;
                        </select>
                      </div>
                      <div className='w-full'>
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          First Name
                        </label>
                        <input
                          id="first_name"
                          name="first_name"
                          type="text"
                          className={`${this.state.firstNameDisabled ? 'disabled' : ''} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                          disabled={this.state.firstNameDisabled ? 'disabled' : ''}
                          value={this.state.first_name}
                          onChange={this.handleChange}
                        />
                        {this.state.firstNameError ?
                          <>
                            <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                              <i className="fas fa-exclamation"></i>
                            </span>
                          </>
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className="customerinfo-field  w-full lg:w-6/12 ">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Last Name
                      </label>
                      <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        className={`${this.state.lastNameDisabled ? 'disabled' : ''} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        disabled={this.state.lastNameDisabled ? 'disabled' : ''}
                        value={this.state.last_name}
                        onChange={this.handleChange}
                      />
                      {this.state.lastNameError ?
                        <>
                          <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                    </div>
                  </div>
                  <div className="customerinfo-field  w-full lg:w-6/12 ">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>
                      <textarea
                        id="email"
                        name="email"
                        className={`${this.state.emailDisabled ? 'disabled' : ''} resize-none border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        disabled={this.state.emailDisabled ? 'disabled' : ''}
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      {this.state.emailError ?
                        <>
                          <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-5">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                      <span className="text-sm text-blueGray-400">Seperate Email by using comma ( , ) and the first one will be Email default. </span>
                    </div>
                  </div>
                  <div className="customerinfo-field  w-full lg:w-6/12">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Phone
                      </label>
                      <textarea
                        id="phone"
                        name="phone"
                        className={`${this.state.phoneDisabled ? 'disabled' : ''} resize-none border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        disabled={this.state.phoneDisabled ? 'disabled' : ''}
                        value={this.state.phone}
                        onChange={this.handleChange}
                      />
                      {this.state.phoneError ?
                        <>
                          <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-5">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                      <span className="text-sm text-blueGray-400">Seperate Phone by using comma ( , ) and the first one will be Phone default. </span>
                    </div>
                  </div>
                  {!this.state.province?
                  <div className="customerinfo-field  w-full lg:w-6/12 ">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        City
                      </label>
                      <input
                        id="city"
                        name="city"
                        type="text"
                        className={`${this.state.cityDisabled ? 'disabled' : ''} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        disabled={this.state.cityDisabled ? 'disabled' : ''}
                        value={this.state.city}
                        onChange={this.handleChange}
                      />
                      {this.state.cityError ?
                        <>
                          <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                    </div>
                  </div>
                  :''}
                  <div className="customerinfo-field  w-full lg:w-6/12 ">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="province"
                      >
                        Province
                      </label>
                          <select
                            type="text"
                            id="province"
                            name="province"
                            className={`${this.state.provinceDisabled ? 'disabled' : ''} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                            disabled={this.state.provinceDisabled ? 'disabled' : ''}
                            value={this.state.province?this.state.province.toLowerCase():null}
                            onChange={this.handleChange}
                          >
                        <option value="">Please Select</option>
                            {this.state.provinceOption.map((e, key) => {
                              return <option key={'status' + key} value={e.label.toLowerCase()} >{e.label}</option>;
                            })}
                          </select>
                      {this.state.provinceError ?
                        <>
                          <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                    </div>
                  </div>
                  <div className="customerinfo-field  w-full lg:w-6/12 ">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Gallery
                    </label>
                    <input
                      id="Gallery"
                      name="Gallery"
                      type="text"
                      className={`disabled border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                      disabled={'disabled'}
                      value={this.state.showroom_name}
                      onChange={this.handleChange}
                    />
                    {this.state.showroomError ?
                      <>
                        <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                          <i className="fas fa-exclamation"></i>
                        </span>
                      </>
                      : ''}
                  </div>
                </div>
                
                {!this.state.province?<div className="customerinfo-field  w-full lg:w-6/12 "></div>:''}
                <div className="customerinfo-field  w-full lg:w-6/12 "></div>
                  <hr className="mt-2 mb-4 md:min-w-full text-black border-blueGray-500" />

                  <div className="customerinfo-field  w-full lg:w-6/12 ">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Sales Manager
                      </label>
                      <select
                        type="text"
                        id="salesManager"
                        name="salesManager"
                        className={`${this.state.salesManagerDisabled ? 'disabled' : ''} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        disabled={this.state.salesManagerDisabled ? 'disabled' : ''}
                        value={this.state.sales_manager_id}
                        onChange={this.handleChange}
                        onBlur={this.handleChangeValidation}
                      >
                        <option value="">Please Select</option>
                        {this.state.salesManagerList.map((e, key) => {
                          return <option key={key} value={e.id}>{e.name}</option>;
                        })}
                      </select>
                      {this.state.salesManagerError ?
                        <>
                          <span className="mr-5 z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0  py-3">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                    </div>
                  </div>
                  <div className="customerinfo-field  w-full lg:w-6/12 ">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Brand Associate
                      </label>
                      <select
                        type="text"
                        id="brandAssociate"
                        name="brandAssociate"
                        className={`${this.state.brandAssociateDisabled ? 'disabled' : ''} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        disabled={this.state.brandAssociateDisabled ? 'disabled' : ''}
                        value={this.state.brand_associate_id}
                        onChange={this.handleChange}
                        onBlur={this.handleChangeValidation}
                      >
                        <option value="">Please Select</option>
                        {this.state.brandAssociateList.map((e, key) => {
                          return <option key={key} value={e.id}>{e.name}</option>;
                        })}
                      </select>
                      {this.state.brandAssociateError ?
                        <>
                          <span className="mr-5 z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0  py-3">
                            <i className="fas fa-exclamation"></i>
                          </span>
                        </>
                        : ''}
                    </div>
                  </div>
                </div>
                <hr className="mt-2 mb-4 md:min-w-full text-black border-blueGray-500" />
                <div className="w-full lg:w-12/12 ">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      Super Admin Notes
                    </label>
                    <textarea
                      type="text"
                      id="notes"
                      name="notes"
                      className={`${this.state.disabledNotesBtn ? 'disabled' : ''} opacity-100 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                      disabled={this.state.disabledNotesBtn ? 'disabled' : ''}
                      value={this.state.notes ? this.state.notes : '-'}
                      onChange={this.handleChange}
                    >{this.state.notes ? this.state.notes : '-'}</textarea>
                  </div>
                </div>
                <div className="text-right mt-6">
                  {jsCookie.get('privilege') != 'Brand Associate2' ?
                    this.state.isSubmitted ? <button
                      className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    ><ClipLoader size={15} color="white" /></button> :
                      <button
                        className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit"
                        onClick={this.handleSubmit}
                      >
                        Save
                      </button> : ''}
                </div>
              </form>
            </div>
          </div>
        </div>
        {this.state.showWaModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto mx-auto items-center">
                    <button
                      className="bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => this.handleShowWaModal(false)}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        <i className="fa fa-times" />
                      </span>
                    </button>
                    <img
                      src={require("assets/img/logo/whatsapp-logo.svg").default}
                      className="relative border-none max-w-150-px  mx-auto"
                      alt="whatsapp-logo"
                    />
                    <h6 className="text-center text-black font-bold mx-auto">Send Whatsapp Messages</h6>
                    <p className="mt-4 text-blueGray-500 text-sm text-center leading-relaxed">
                      Send assigned information message to Sales Manager through Whatsapp
                    </p>
                  </div>
                  {this.state.whatsappSmLink ?
                    <div className="flex items-center justify-center pb-6 rounded-b text-center">
                      <a
                        href={this.state.whatsappSmLink}
                        target="_new"
                        className="w-10/12 button-gold bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      >
                        Open Whatsapp for Sales Manager
                      </a>
                    </div> : ''}
                  {this.state.whatsappBaLink ?
                    <div className="flex items-center justify-center pb-6 rounded-b text-center">
                      <a
                        href={this.state.whatsappBaLink}
                        target="_new"
                        className="w-10/12 button-gold bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      >
                        Open Whatsapp for Brand Associate
                      </a>
                    </div> : ''}
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        </div>

      </>
    );
  }
}
