import React from "react";
import { Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

//import FooterSmall from "components/Footers/FooterSmall.js";

import axios from 'axios';
import md5 from 'md5';
import jsCookie from 'js-cookie'
import auth from 'global/auth.js';

import { Validation } from "global/custom.js";

import 'assets/styles/custom.css';
/*eslint-disable eqeqeq*/

export default function Login() {
  if (auth()) {
    return <Redirect to="/dashboard/audience" />;
  }

  return (
    <>
      <main>
        <section className="flex items-center w-full h-full min-h-screen ">
          <div
            className="absolute top-0 w-full h-full animated-background"
          ></div>
          <div className="container mx-auto px-4 h-full z-100">
            <div className="flex justify-center h-full">
              <img
                alt="..."
                src={require("assets/img/logo/logo-white.svg").default}
                className="h-auto align-middle border-none max-w-150-px"
              />
            </div>
            <div className="flex content-center items-center justify-center h-full">
              <LoginForm />
            </div>
          </div>
          {/*<FooterSmall absolute />*/}
        </section>
      </main>
    </>
  );
}


class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { login: false, username: '', password: '', isSubmitted: false, redirect: false, errorMessage: '', showPassword: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeValidation = this.handleChangeValidation.bind(this);
    this.handleShowPassword = this.handleShowPassword.bind(this);

  }
  handleShowPassword(event) {
    this.setState({ showPassword: !this.state.showPassword });

  }

  handleChange(event) {
    const target = event.target;
    const username = target.id === 'username' ? target.value : this.state.username;
    const password = target.id === 'password' ? target.value : this.state.password;
    /*const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  */

    this.setState({
      username: username,
      password: password,
    });
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className='notifBar w-full bg-red'>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <div className="cursor-pointer" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)}>
          <i className="fa fa-times" />
        </div>
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }
  handleChangeValidation(event) {
    const target = event.target;
    if (target.id === 'username') {
      this.handleValidation('email', this.state.username, 'emailError', 'emailErrorMessage', 'Email');
    }
    if (target.id === 'password') {
      this.handleValidation('text', this.state.password, 'passwordError', 'passwordErrorMessage', 'Password');
    }
  }

  handleValidation(type, value, errorName, errorMessageName, fieldName) {
    var valid = Validation(type, value, errorName, errorMessageName, fieldName);
    if (valid.status == 404) {
      this.createErrorMessage(valid.msg, errorName, errorMessageName);
      return false;
    } else {
      this.setState({
        [errorName]: '',
        [errorMessageName]: ''
      });
      return true;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitted: true });
    var validate = 1;

    if (!this.handleValidation('email', this.state.username, 'emailError', 'emailErrorMessage', 'Email')) {
      validate = 0;
    }
    if (!this.handleValidation('text', this.state.password, 'passwordError', 'passwordErrorMessage', 'Password')) {
      validate = 0;
    }

    if (validate == 0) {
      this.setState({ isSubmitted: false });
      return false;
    }

    const data = {
      email: this.state.username,
      password: this.state.password,
    };
    //axios.get(`${process.env.REACT_APP_API_URL}login?email=${this.state.username}&password=${md5(this.state.password)}`,
    axios.post(`${process.env.REACT_APP_API_URL}login`,data,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        if (error.response) {
          this.setState({ isSubmitted: false })
          if (error.response.status == 401) {
            this.setState({
              errorMessage:
                <><div id='alerts' className='notifBar w-full bg-red'>
                  <div id="ofBar-content" className="notifBar-content">
                    <b>{error.response.data.message}</b>
                  </div>
                  <div id="ofBar-right" className="notifBar-right">
                    <div className="cursor-pointer" id="close-bar" onClick={e => this.handleCloseAlert('errorMessage')}>
                      <i className="fa fa-times" />
                    </div>
                  </div>
                </div></>
            });
          }
          if (error.response.status == 403) {
            this.setState({
              errorMessage:
                <><div id='alerts' className='notifBar w-full bg-red'>
                  <div id="ofBar-content" className="notifBar-content">
                    <b>Email and Password did not match.</b>
                  </div>
                  <div id="ofBar-right" className="notifBar-right">
                    <div className="cursor-pointer" id="close-bar" onClick={e => this.handleCloseAlert('errorMessage')}>
                      <i className="fa fa-times" />
                    </div>
                  </div>
                </div></>
            });
          }
          throw error;
        }

      })

      .then(res => {
        if (typeof res != "undefined") {
          const data = res.data;
          if (data.status === 'active') {
            jsCookie.set('uid', data.uid, { expires: 0.5 })
            jsCookie.set('token', data.token, { expires: 0.5 })
            jsCookie.set('token2', data.token2, { expires: 0.5 });
            jsCookie.set('name', data.name, { expires: 0.5 })
            jsCookie.set('privilege', data.privilege, { expires: 0.5 })
            jsCookie.set('showroom_id', data.showroom_id ? data.showroom_id : 'null', { expires: 0.5 })
            jsCookie.set('phone', data.phone ? data.phone : 'null', { expires: 0.5 })
            jsCookie.set('muid', data.muid ? data.muid : 'null', { expires: 0.5 })
            this.setState({ redirect: true });
          } else {
            this.setState({ isSubmitted: false })
            this.setState({
              errorMessage:
                <><div id='alerts' className='notifBar w-full bg-red'>
                  <div id="ofBar-content" className="notifBar-content">
                    <b>Your account is {data.status}</b>
                  </div>
                  <div id="ofBar-right" className="notifBar-right">
                    <div className="cursor-pointer" id="close-bar" onClick={e => this.handleCloseAlert('errorMessage')}>
                      <i className="fa fa-times" />
                    </div>
                  </div>
                </div></>
            });
          }
        } else {
          this.setState({ isSubmitted: false })
          this.createErrorMessage('Please Check Connection', '', 'errorMessage')
        }
      })
  }

  render() {
    if (this.state.redirect) {
      if(jsCookie.get('privilege') == 'Uploader'){
        return <Redirect to='/uploader' />;
      }else{
      return <Redirect to='/dashboard/audience' />;
      }
    }
    return (
      <>
        <div id="notificationArea" className="notificationArea-login w-full lg:w-4/12 ">
          {this.state.emailErrorMessage}
          {this.state.passwordErrorMessage}
          {this.state.errorMessage}
        </div>
        <div className="xl:w-4/12  md:w-6/12 sm:w-4/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full  shadow-lg rounded bg-login-panel border-0">
            <div className="rounded-t flex-auto px-8 mt-5 lg:px-12 py-10 pt-0">
              <div className="text-blueGray-400 text-center mb-5 font-bold mt-5">
                <h6 className="text-white text-md font-bold uppercase">
                  Sign in
                </h6>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <input
                    type="username"
                    className="border-0 px-3 py-3 placeholder-gray text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                    id="username"
                    value={this.state.username} onChange={this.handleChange} onBlur={this.handleChangeValidation}
                  />
                  {this.state.emailError ?
                    <>
                      <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                        <i className="fas fa-exclamation"></i>
                      </span>
                    </>
                    : ''}
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>
                  <input
                    type={this.state.showPassword ? 'text' : 'password'}
                    className="border-0 px-3 py-3 placeholder-gray text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                    id="password"
                    autoComplete="true"
                    value={this.state.password} onChange={this.handleChange} onBlur={this.handleChangeValidation}
                  />

                  <span className="z-50 h-full leading-snug font-normal absolute text-center text-blueGray-500 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                    <i
                      className={`fas ${this.state.showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                      onClick={this.handleShowPassword}
                    ></i>
                  </span>
                  {this.state.passwordError ?
                    <>
                      <span className="pr-10 z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                        <i className="fas fa-exclamation"></i>
                      </span>
                    </>
                    : ''}
                </div>
                <div className="text-center mt-2">
                  <a
                    href="/login/forgotPassword"
                    className="float-right mb-4 text-sm font-semibold text-gold "
                  >
                    Forgot password?
                  </a>
                  {this.state.isSubmitted ? <button
                    className="button-login text-white active:bg-blueGray-600 text-sm font-bold uppercase px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                  ><ClipLoader size={15} color="white" /></button> :
                    <button
                      className="button-login text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Sign In
                    </button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}