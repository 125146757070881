import React from 'react'
import axios from 'axios';
import { Redirect, useParams } from 'react-router-dom'
import auth from 'global/auth.js';
import jsCookie from 'js-cookie';
import ClipLoader from "react-spinners/ClipLoader";
import { DateFormat } from "global/custom.js";
import { Validation } from "global/custom.js";
/*eslint-disable eqeqeq*/

const User = ({ match }) => {
  var id = useParams();
  return (
    <>
      <div className="flex flex-wrap">
        <UserDetailClass id={id.id} />
      </div>
    </>
  )
}

class UserDetailClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      id: '',
      nameDisplay: '',
      name: '',
      nameError: false,
      email: '',
      emailError: false,
      phone: '',
      phoneError: false,
      store: '',
      storeError: false,
      privilege: 'Super Admin',
      privilegeError: false,
      status: 'active',
      statusError: false,
      notes: '',
      notesError: false,
      created_date: '',
      errorMessage: '',
      successMessage: '',
      reloadPage: false,
      disableName:false,
      disableEmail:false,
      disablePhone:false,
      privilegeOption: [
        //{ value: 'Super Admin', text: 'Lexus Indonesia' },
        { value: 'Super Admin', text: 'Super Admin' },
        { value: 'Sales Manager', text: 'Sales Manager' },
        { value: 'Sales', text: 'Sales' },
      ],
      statusOption: [
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'Inactive' },
        { value: 'blocked', text: 'Blocked' },
      ],
      redirectMain: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getUserDetail() {
    const id = jsCookie.get('uid');
    axios.get(`${process.env.REACT_APP_API_URL}user/${id}`, {
      headers: {
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .then(res => {
        const data = res.data[0];
        this.setState({
          userData: data,
          id: data.id,
          name: data.name,
          nameDisplay: data.name,
          email: data.email ? data.email : '',
          phone: data.phone ? data.phone : '',
          store: data.store_id ? data.store_id : '',
          privilege: data.privilege ? data.privilege : '',
          showroom_name:data.showroom_name ? data.showroom_name:'',
          sales_manager_name:data.sales_manager_name?data.sales_manager_name:'',
          status: data.status ? data.status : '',
          created_date: data.created_date ? DateFormat(data.created_date, 'Mdy') : '',
          last_login: data.last_login ? DateFormat(data.last_login, 'Mdy') : '',
          disableName:jsCookie.get('privilege') === 'Super Admin'?true:false,
          disableEmail:jsCookie.get('privilege') === 'Super Admin'?true:false,
          disablePhone:jsCookie.get('privilege') === 'Super Admin'?true:false,
        });
        //this.setState({ userData: Object.entries(data) });
      })
  }

  componentDidMount() {
    const id = jsCookie.get('uid');

    if (id > 0) {
      this.getUserDetail();
    } else if (this.props.id == 0) {
      this.setState({
        userData: [
          {
            id: 0,
            name: '',
            email: '',
            store: '',
          }
        ]
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.reloadPage !== prevState.reloadPage) {
      this.getUserDetail();
    }
  }

  handleChange(event) {
    const target = event.target;
    const name = target.id === 'name' ? target.value : this.state.name;
    const phone = target.id === 'phone' ? target.value : this.state.phone;
    const password = target.id === 'password' ? target.value : this.state.password;
    const password2 = target.id === 'password2' ? target.value : this.state.password2;
    const email = target.id === 'email' ? target.value : this.state.email;
    this.setState({
      name: name,
      phone: phone,
      password: password,
      password2: password2,
      email:email,
    });
  }

  handleValidation(type, value, errorName, errorMessageName, fieldName, value2) {
    var valid = Validation(type, value, errorName, errorMessageName, fieldName, value2);
    if (valid.status == 404) {
      this.createErrorMessage(valid.status, valid.msg, errorName, errorMessageName);
      return false;
    } else {
      this.setState({
        [errorName]: '',
        [errorMessageName]: ''
      });
      return true;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitted: true });

    var validate = 1;
    if (!this.handleValidation('text', this.state.name, 'nameError', 'nameErrorMessage', 'Name')) {
      validate = 0;
    }

    if (!this.handleValidation('email', this.state.email, 'emailError', 'emailErrorMessage', 'Email')) {
      validate = 0;
    }

    if (!this.handleValidation('phone', this.state.phone, 'phoneError', 'phoneErrorMessage', 'Phone')) {
      validate = 0;
    }
    if (this.state.password) {

      if (!this.handleValidation('newpassword', this.state.password, 'passwordError', 'passwordErrorMessage', 'Password')) {
        validate = 0;
      }
      if (!this.handleValidation('newRepeatePassword', this.state.password2, 'repeatePasswordError', 'repeatePasswordErrorMessage', 'Password', this.state.password)) {
        validate = 0;
      }
    }

    if (validate == 0) {
      this.setState({ isSubmitted: false });
      this.setState({ successMessage: '' });
      return false;
    }

    const inputData = {
      id: jsCookie.get('uid'),
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      phone: this.state.phone,
      privilege: jsCookie.get('privilege'),
      showroom_id: this.state.userData.showroom_id,
      manager_id: this.state.userData.manager_id,
      uid: jsCookie.get('uid'),
      status: 'active',
    };
    axios.post(`${process.env.REACT_APP_API_URL}user/${jsCookie.get('uid')}`, inputData, {
      headers: {
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        this.setState({ isSubmitted: false });
        this.createErrorMessage(404, 'Please try again.', '', 'errorMessage');
        throw error;
      })
      .then(res => {
        this.createErrorMessage(200, 'Data has been submitted.', '', 'successMessage');
        this.setState({
          reloadPage: true,
          errorMessage: '',
          isSubmitted: false
        });
        jsCookie.set('name', inputData.name, { expires: 0.5 })
        setTimeout(
          function () {
            this.setState({ redirectMain: true });
          }
            .bind(this),
          1000
        );
      })
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(status, name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className={'notifBar ' + (status == '404' ? 'bg-red' : 'bg-emerald-400')}>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)} />
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  reset = () => {
    if (this.props.id > 0) {
      this.setState({
        name: this.state.userData.name,
        status: this.state.userData.status,
      });
    } else if (this.props.id == 0) {
      this.setState({
        name: '',
        status: '',
      });
    }
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (this.state.redirectMain) {
      return <Redirect to="/profile" />;
    }
    if (!this.state.userData && this.props.id != 0) {
      return (<span> Data Not Available</span>)
    }

    return (
      <>

        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.successMessage}
          {this.state.errorMessage}
          {this.state.nameErrorMessage}
          {this.state.emailErrorMessage}
          {this.state.phoneErrorMessage}
          {this.state.passwordErrorMessage}
          {this.state.repeatePasswordErrorMessage}
        </div>
        <div className="cursor-pointer w-full text-white z-100 pb-4 pr-4" onClick={() => window.history.back()}>
          <i className="fa fa-angle-left"></i> Back
        </div>
        <div className="w-full  ">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <div className="flex flex-wrap items-center text-left font-bold ">
                  <div className={` w-auto mb-2 pr-10 `}>
                    <div className="relative w-full x ">
                      <label
                        className="block uppercase text-blueGray-600 text-xs "
                      >
                        Name
                      </label>
                      {this.state.nameDisplay}
                    </div>
                  </div>
                  <div className={`${this.state.sales_manager_name ? '' : 'hidden'} w-auto mb-2 pr-10`}>
                    <div className="relative w-full  ">
                      <label
                        className="block uppercase text-blueGray-600 text-xs "
                      >
                        Sales Manager
                      </label>
                      {this.state.sales_manager_name}
                    </div>
                  </div>
                  <div className={` w-auto mb-2 pr-10`}>
                    <div className="relative w-full">
                      <label
                        className="block uppercase text-blueGray-600 text-xs "
                      >
                        Role
                      </label>
                      {/*this.state.privilege=='Super Admin'?'Lexus Indonesia':this.state.privilege*/}
                      {this.state.privilege}
                    </div>
                  </div>
                        <div className={`${this.state.showroom_name ? '' : 'hidden'} w-auto mb-2 pr-10`}>
                          <div className="relative w-full">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold"
                              htmlFor="grid-password"
                            >
                              Gallery
                            </label>
                            {this.state.showroom_name?this.state.showroom_name:'-'}
                          </div>
                        </div>
                  <div className={` w-auto mb-2 pr-10`}>
                    <div className="relative w-full">
                      <label
                        className="block uppercase text-blueGray-600 text-xs "
                      >
                        Join Date
                      </label>
                      {this.state.created_date}
                    </div>
                  </div>
                  <div className={` w-auto  mb-2 pr-10`}>
                    <div className="relative w-full">
                      <label
                        className="block uppercase text-blueGray-600 text-xs "
                      >
                        Last Login
                      </label>
                      {this.state.last_login}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="flex-auto px-6 py-10 pt-10">
              <form onSubmit={this.handleSubmit}>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-4/12 pr-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        disabled={this.state.disableName?'':'disabled'}
                        className={`${this.state.disableName?'':'disabled'} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                      {this.state.nameError ?
                        <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                          <i className="fas fa-exclamation"></i>
                        </span>
                        : ''}

                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 pr-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        disabled={this.state.disableEmail?'':'disabled'}
                        className={`${this.state.disableEmail?'':'disabled'} border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      {this.state.emailError ?
                        <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                          <i className="fas fa-exclamation"></i>
                        </span>
                        : ''}
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 pr-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Phone Number
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        disabled={this.state.disablePhone?'':'disabled'}
                        className={`${this.state.disablePhone?'':'disabled'} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        value={this.state.phone}
                        onChange={this.handleChange}
                      />
                      {this.state.phoneError ?
                        <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                          <i className="fas fa-exclamation"></i>
                        </span>
                        : ''}
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 pr-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        New Password
                      </label>
                      <input
                        type="new-password"
                        id="password"
                        name="password"
                        placeholder="min. 8 (Alphabetical + Number + Capital)"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                      {this.state.passwordError ?
                        <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                          <i className="fas fa-exclamation"></i>
                        </span>
                        : ''}

                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 pr-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        New Repeat Password
                      </label>
                      <input
                        type="new-password"
                        id="password2"
                        name="password2"
                        placeholder="min. 8 (Alphabetical + Number + Capital)"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-gray rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={this.state.password2}
                        onChange={this.handleChange}
                      />
                      {this.state.repeatePasswordError ?
                        <span className="z-10 h-full leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                          <i className="fas fa-exclamation"></i>
                        </span>
                        : ''}
                    </div>
                  </div>
                </div>


                <div className="text-right mt-6">
                  {this.state.isSubmitted ? <button
                    className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  ><ClipLoader size={15} color="white" /></button> :
                    <button
                      className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Save
                    </button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default User
