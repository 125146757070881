import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import auth from 'global/auth.js';

import jsCookie from 'js-cookie';
import { DateFormat } from "global/custom.js";
import { createPopper } from "@popperjs/core";
import ClipLoader from "react-spinners/ClipLoader";

import { getBadge } from "global/data.js";
import ReactExport from "react-data-export";
import * as moment from 'moment'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
/*eslint-disable eqeqeq*/

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Customers({ label, filter, paging, assign, assignBtn, path, sales_manager_id, brand_associate_id, showMore, name, exports, exports_odoo }) {
  const [totalCustomer, setTotalCustomer] = useState(0);
  useEffect(() => {
    const privilege = jsCookie.get('privilege');
    const uid = jsCookie.get('uid');

    let url = `${process.env.REACT_APP_API_URL}check-new-enquiry`
    if (privilege === 'Sales Manager') {
      url = `${process.env.REACT_APP_API_URL}check-new-enquiry?sm=${uid}`
    }
    else if (privilege === 'Brand Associate') {
      url = `${process.env.REACT_APP_API_URL}check-new-enquiry?ba=${uid}`
    }

    var s = new WebSocket('wss://' + (url.replace('https://', '').replace('http://', '')) + '/');
    s.addEventListener('message', function (m) {
      const parsedData = JSON.parse(m.data);
      setTotalCustomer(parsedData.total_data);
    });
  }, []);

  if (jsCookie.get('privilege') == 'Uploader') {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <CustomersClass totalCustomer={totalCustomer} label={label} filter={filter} paging={paging} assign={assign} assignBtn={assignBtn} path={path} sales_manager_id={sales_manager_id} brand_associate_id={brand_associate_id} showMore={showMore} name={name} exports={exports} exports_odoo={typeof exports_odoo != 'undefined' ? exports_odoo : 'no'} />
    </>
  );
}
class CustomersClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      path: this.props.path ? this.props.path : 'enquiries',
      headerLabel: this.props.label,
      filterByName: this.props.name,
      isShowMore: this.props.showMore,
      isFilter: this.props.filter,
      isPaging: this.props.paging,
      isAssign: this.props.assign,
      isAssignBtn: this.props.assignBtn,
      isExportExcel: this.props.exports,
      isExportOdoo: !this.props.path || this.props.path == 'leads' ? 'yes' : this.props.exports_odoo,
      sales_manager_id: this.props.sales_manager_id ? this.props.sales_manager_id : '',
      brand_associate_id: this.props.brand_associate_id ? this.props.brand_associate_id : '',
      totalCustomer: this.props.totalCustomer ? this.props.totalCustomer : 0,
      /**filter */
      nameFilter: '',
      emailFilter: '',
      phoneFilter: '',
      statusFilter: '',
      toFilter: '',
      fromFilter: '',
      dateRangeValue: '',
      followupToFilter: '',
      followupFromFilter: '',
      followupDateRangeValue: '',
      followupFilter: '',
      assignedFilter: '',
      enquiryFilter: '',
      showroomFilter: '',
      carFilter: '',
      smFilter: '',
      baFilter: '',
      sourceFilter: '',
      provinceFilter: '',
      /**filter */
      /**icon */
      nameFilled: false,
      emailFilled: false,
      phoneFilled: false,
      dateInFilled: false,
      followupDateFilled: false,
      followupFilled: false,
      assignedFilled: false,
      smFilled: false,
      baFilled: false,
      enquiryFilled: false,
      showroomFilled: false,
      cityFilled: false,
      carFilled: false,
      sourceFilled: false,
      provinceFilled: false,
      /**icon */
      /**sort */
      nameSort: '',
      assignedSort: '',
      salesManagerSort: '',
      brandAssociateSort: '',
      statusSort: '',
      lastEnquiryTypeSort: '',
      lastEnquiryStatusSort: '',
      dataInSort: 'desc',
      emailSort: '',
      phoneSort: '',
      citySort: '',
      showroomSort: '',
      enquiryDateSort: '',
      provinceSort: '',
      /**sort */

      totalData: 0,
      currentPage: '1',
      perPage: '10',
      pagingPrevTemplate: '',
      pagingTemplate: '',
      pagingNextTemplate: '',
      page: '',
      customersData: [],
      totalPaging: '',
      statusOption: [
        { value: '', text: 'All' },
        { value: 'customer', text: 'Customer' },
        { value: 'leads', text: 'Leads' },
      ],
      followupOption: [
        { value: '', text: 'All' },
        { value: 'new_enquiry', text: 'New Enquiry' },
        { value: 'not_assigned', text: 'Not Assigned' },
        { value: 'no', text: 'Not Followed Up' },
        { value: 'follow_up', text: 'All Followed Up' },
        { value: "cant_reach", text: "-- Can't Reach" },
        { value: 'cold', text: '-- Cold' },
        { value: 'hot', text: '-- Hot' },
        { value: 'prospect', text: '-- Prospect' },
        { value: 'spk', text: 'SPK' },
      ],
      assignedOption: [
        { value: '', text: 'All' },
        { value: 'yes', text: 'Assigned' },
        { value: 'no', text: 'Not Assigned' },
      ],
      sourceOption: [],
      showroomOption: [],
      smOptionBckup: [],
      smOption: [],
      baOptionBckup: [],
      baOption: [],
      enquirySubjectOption: [],
      cityOption: [],
      carOption: [],
      provinceOption: [],
      showFilter: false,
      showMoreFilter: true,
      filterOffsetHeight: 0,
      checkBoxId: [],
      checkBoxIdData: [],
      showAssignModal: false,
      salesManagerList: [],
      brandAssociateList: [],
      isSubmitted: false,
      showWaModal: false,
      showDetail: false,
      isLoading: true,
      newest_enquiry_date: '',
      showOdooModal: false,
      defaultOdooSegmentName: 'Testing',
      odooSegmentName: '',
      isOdooSegmentSubmitted: false,
      odooSegmentForm: true,
      odooExportForm: false,
      isSubmittedOverWriteOdoo: false,
    };

    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkPopoverFilter = this.checkPopoverFilter.bind(this);
    this.checkPopover = this.checkPopover.bind(this);
    this.handleResetFilter = this.handleResetFilter.bind(this);

    /*paging*/
    this.handlePageChange = this.handlePageChange.bind(this);
    /*paging*/

    this.handleResetDatePicker = this.handleResetDatePicker.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.resetField = this.resetField.bind(this);

    /**checkbox */
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.handleCheckAllChange = this.handleCheckAllChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    /**checkbox */

    this.handleChangeField = this.handleChangeField.bind(this);

    this.handleEventDatePicker = this.handleEventDatePicker.bind(this);
    this.handleEventFollowupDatePicker = this.handleEventFollowupDatePicker.bind(this);

    this.handleExcel = this.handleExcel.bind(this);
  }

  getDropdownShowroom() {
    var url = `${process.env.REACT_APP_API_URL}showroom`;
    if (jsCookie.get('showroom_id') !== 'null') {
      url = `${process.env.REACT_APP_API_URL}showroom/${jsCookie.get('showroom_id')}`;
    }
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          showroomOption: data
        }, () => {
          this.getDropdownChannel();
        });
      })
  }

  getDropdownChannel() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/channel`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          sourceOption: res.data,
        }, () => {
          this.getDropdownSm();
        });
      })
  }

  getDropdownSm() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/sm?status=active`;
    let filter = [{ 's': 'active' }];
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let bckupData = res.data;
        let data = bckupData;
        if (this.state.showroomFilter && this.state.showroomFilter != 'all') {
          data = data.filter(elem => {
            return elem.showroom_id == this.state.showroomFilter
          })
        }
        this.setState({
          smOption: data,
          smOptionBckup: bckupData
        }, () => {
          this.getDropdownBa();
        });
      })
  }

  getDropdownBa() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/ba?status=active`;
    let filter = [];
    if (jsCookie.get('privilege') == 'Sales Manager') {
      filter = {
        sales_manager_id: jsCookie.get('uid'),
      };
      url += '&sales_manager_id=' + jsCookie.get('uid');
    }
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let bckupData = res.data;
        let data = bckupData;
        if (this.state.smFilter) {
          data = data.filter(elem => {
            return elem.manager_name.toLowerCase() == this.state.smFilter.toLowerCase()
          })
        }
        this.setState({
          baOption: data,
          baOptionBckup: bckupData,
        }, () => {
          this.getDropdownEnquirySubject();
        });
      })
  }

  getDropdownEnquirySubject() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/enquiry_subject`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          enquirySubjectOption: data,
        }, () => {
          this.getDropdownModel();
        });
      })
  }

  /*getDropdownCity() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/city`;
    let filter = [];
    axios.post(url, filter, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          cityOption: data,
        }, () => {
          this.getDropdownModel();
        });
      })
  }*/

  getDropdownModel() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/car-model-input`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          carOption: data
        }, () => {
          this.getDropdownProvince();
        });
      })
  }

  getDropdownProvince() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/province`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          provinceOption: data,
        }, () => {
          //this.setState({isLoading: false});
        });
      })
  }

  handleGetUser() {
    axios.get(`${process.env.REACT_APP_API_URL}user?s=active`,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(err => {
        throw err;
      })
      .then(res => {
        if (jsCookie.get('privilege') === 'Super Admin') {
          var data = res.data;
          data = data.filter(row => {
            return row.privilege.toLowerCase().match('sales manager') && row.status == 'active'
          });

          if (jsCookie.get('privilege') === 'Sales Manager') {
            data = data.filter(row => {
              return row.id == jsCookie.get('uid') && row.status == 'active'
            });
          }
          this.setState({
            salesManagerList: data,
          })
        }
        if (jsCookie.get('privilege') === 'Sales Manager') {
          var data2 = res.data;
          data2 = data2.filter(row => {
            return row.privilege.toLowerCase().match('brand associate') && row.status == 'active'
          });

          data2 = data2.filter(row => {
            return row.manager_id == jsCookie.get('uid') && row.status == 'active'
          });
          this.setState({
            brandAssociateList: data2,
          })
        }

      });
  }

  handleResize() {
    var filter = document.getElementsByClassName('filter')
    if (this.state.filterOffsetHeight > 100) {
      if (filter[0]) {
        if (this.state.showFilter === true) {
          filter[0].style.height = (this.state.filterOffsetHeight) + 'px'
        } else {
          if (filter[0].style) {
            filter[0].style.height = '90px'
          }
        }
      }
      this.setState({ showMoreFilter: true });
    }
    else {
      this.setState({ showMoreFilter: false });
    }

  }

  getEnquiryData() {
    var getParam = [];
    this.setState({ isLoading: true });

    if (this.state.sales_manager_id !== '') {
      getParam.push('sales_manager_id=' + this.state.sales_manager_id);
    }
    if (this.state.brand_associate_id !== '') {
      getParam.push('brand_associate_id=' + this.state.brand_associate_id);
    }
    if (this.state.nameFilter !== '') {
      getParam.push('name=' + this.state.nameFilter);
    }
    if (this.state.statusFilter !== '') {
      getParam.push('status=' + this.state.statusFilter);
    }
    if (this.state.fromFilter) {
      getParam.push('start_date=' + this.state.fromFilter);
    }
    if (this.state.toFilter) {
      getParam.push('end_date=' + this.state.toFilter);
    }
    if (this.state.followupFilter && this.state.followupFilter != 'all') {
      getParam.push('followup=' + this.state.followupFilter);
    }
    if (this.state.assignedFilter && this.state.assignedFilter != 'all') {
      getParam.push('assigned=' + this.state.assignedFilter);
    }
    if (this.state.smFilter) {
      getParam.push('sm=' + this.state.smFilter);
    }
    if (this.state.baFilter) {
      getParam.push('ba=' + this.state.baFilter);
    }
    if (this.state.enquiryFilter) {
      getParam.push('enquiry=' + this.state.enquiryFilter);
    }
    if (this.state.showroomFilter && this.state.showroomFilter != 'all') {
      getParam.push('showroom=' + this.state.showroomFilter);
    }
    if (this.state.cityFilter) {
      getParam.push('city=' + this.state.cityFilter);
    }
    if (this.state.provinceFilter) {
      getParam.push('province=' + this.state.provinceFilter);
    }
    if (this.state.carFilter) {
      getParam.push('preferred_model=' + encodeURIComponent(this.state.carFilter));
    }
    if (this.state.sourceFilter) {
      getParam.push('source=' + this.state.sourceFilter);
    }

    if (this.state.followupFromFilter) {
      getParam.push('followup_start_date=' + this.state.followupFromFilter);
    }
    if (this.state.followupToFilter) {
      getParam.push('followup_end_date=' + this.state.followupToFilter);
    }

    if (this.state.emailFilter !== '') {
      getParam.push('email=' + this.state.emailFilter);
    }
    if (this.state.phoneFilter !== '') {
      getParam.push('phone=' + this.state.phoneFilter);
    }
    if (this.state.currentPage) {
      getParam.push('currentPage=' + this.state.currentPage);
    }
    if (this.state.filterByName) {
      getParam.push('name=' + this.state.filterByName);
    }

    getParam.push('perPage=' + this.state.perPage);
    /**sort */
    if (this.state.nameSort !== '') {
      getParam.push('sortBy=name-' + this.state.nameSort);
    } else if (this.state.salesManagerSort !== '') {
      getParam.push('sortBy=sales_manager_name-' + this.state.salesManagerSort);
    } else if (this.state.brandAssociateSort !== '') {
      getParam.push('sortBy=brand_associate_name-' + this.state.brandAssociateSort);
    } else if (this.state.statusSort !== '') {
      getParam.push('sortBy=status-' + this.state.statusSort);
    } else if (this.state.lastEnquiryTypeSort !== '') {
      getParam.push('sortBy=subject-' + this.state.lastEnquiryTypeSort);
    } else if (this.state.lastEnquiryStatusSort !== '') {
      getParam.push('sortBy=followup_status-' + this.state.lastEnquiryStatusSort);
    } else if (this.state.emailSort !== '') {
      getParam.push('sortBy=email-' + this.state.emailSort);
    } else if (this.state.phoneSort !== '') {
      getParam.push('sortBy=phone-' + this.state.phoneSort);
    } else if (this.state.citySort !== '') {
      getParam.push('sortBy=city-' + this.state.citySort);
    } else if (this.state.provinceSort !== '') {
      getParam.push('sortBy=province-' + this.state.provinceSort);
    } else if (this.state.showroomSort !== '') {
      getParam.push('sortBy=showroom_name-' + this.state.showroomSort);
    } else if (this.state.dataInSort !== '') {
      getParam.push('sortBy=enquiry_date-' + this.state.dataInSort);
    } else if (this.state.enquiryDateSort !== '') {
      getParam.push('sortBy=followup_date-' + this.state.enquiryDateSort);
    }
    /**sort */
    var url = `${process.env.REACT_APP_API_URL}enquiry?${getParam.join('&')}`
    if (jsCookie.get('privilege') === 'Sales Manager') {
      url = `${process.env.REACT_APP_API_URL}enquiry?sm=${jsCookie.get('name')}&${getParam.join('&')}`
    } else if (jsCookie.get('privilege') === 'Brand Associate') {
      url = `${process.env.REACT_APP_API_URL}enquiry?ba=${jsCookie.get('name')}&${getParam.join('&')}`
    }
    axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
        this.setState({ isLoading: false });
        throw error;
      })
      .then(res => {
        if (res.data !== 'Data Empty' && res.data) {
          let data = res.data.data;
          let totalData = res.data.total_data;

          const checkBoxId = this.state.checkBoxId;
          if (typeof data != 'undefined') {
            data.forEach((rows, keys) => {
              checkBoxId.forEach(function (value, index) {
                if (value == rows.id) {
                  data[keys].checked = 'checked';
                }
              });

              var background = '';
              //if (!rows.sales_manager_id) { //New Enquiry
              //  background = 'bg-table-red';
              //}
              //else 
              if (!rows.sales_manager_id || !rows.brand_associate_id) { // 'Not Assigned'
                background = 'bg-table-yellow';
                data[keys].last_followup_status = 'Not Assigned';
              }
              else if (rows.last_followup_status === '') {
                background = 'bg-table-purple';
              }
              if (rows.last_followup_status === 'Follow Up') {
                background = 'bg-table-blue';
              }
              else if (rows.last_followup_status === "Not Reach") {
                background = 'bg-table-gray';
              }
              else if (rows.last_followup_status === 'SPK') {
                background = 'bg-table-green';
              }
              data[keys].background = background;
            })
          }
          this.setState({
            totalData: totalData,
            totalCustomer: totalData,
            customersData: data ? Array.from(data) : [],
            isLoading: false
          }, () => {
            this.handleCheckAllChange();
            this.handlePaging();
          });
        } else {
          this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
          this.setState({ isLoading: false });
        }
      })
  }

  async getExportedEnquiryData() {
    var getParam = [];
    if (this.state.sales_manager_id !== '') {
      getParam.push('sales_manager_id=' + this.state.sales_manager_id);
    }
    if (this.state.brand_associate_id !== '') {
      getParam.push('brand_associate_id=' + this.state.brand_associate_id);
    }
    if (this.state.nameFilter !== '') {
      getParam.push('name=' + this.state.nameFilter);
    }
    if (this.state.statusFilter !== '') {
      getParam.push('status=' + this.state.statusFilter);
    }
    if (this.state.fromFilter) {
      getParam.push('start_date=' + this.state.fromFilter);
    }
    if (this.state.toFilter) {
      getParam.push('end_date=' + this.state.toFilter);
    }
    if (this.state.followupFilter && this.state.followupFilter != 'all') {
      getParam.push('followup=' + this.state.followupFilter);
    }
    if (this.state.assignedFilter && this.state.assignedFilter != 'all') {
      getParam.push('assigned=' + this.state.assignedFilter);
    }
    if (this.state.smFilter) {
      getParam.push('sm=' + this.state.smFilter);
    }
    if (this.state.baFilter) {
      getParam.push('ba=' + this.state.baFilter);
    }
    if (this.state.enquiryFilter) {
      getParam.push('enquiry=' + this.state.enquiryFilter);
    }
    if (this.state.showroomFilter && this.state.showroomFilter != 'all') {
      getParam.push('showroom=' + this.state.showroomFilter);
    }
    if (this.state.cityFilter) {
      getParam.push('city=' + this.state.cityFilter);
    }
    if (this.state.provinceFilter) {
      getParam.push('province=' + this.state.provinceFilter);
    }
    if (this.state.carFilter) {
      getParam.push('preferred_model=' + encodeURIComponent(this.state.carFilter));
    }
    if (this.state.sourceFilter) {
      getParam.push('source=' + this.state.sourceFilter);
    }

    if (this.state.followupFromFilter) {
      getParam.push('followup_start_date=' + this.state.followupFromFilter);
    }
    if (this.state.followupToFilter) {
      getParam.push('followup_end_date=' + this.state.followupToFilter);
    }

    if (this.state.emailFilter !== '') {
      getParam.push('email=' + this.state.emailFilter);
    }
    if (this.state.phoneFilter !== '') {
      getParam.push('phone=' + this.state.phoneFilter);
    }
    if (this.state.filterByName) {
      getParam.push('name=' + this.state.filterByName);
    }

    /**sort */
    if (this.state.nameSort !== '') {
      getParam.push('sortBy=name-' + this.state.nameSort);
    } else if (this.state.salesManagerSort !== '') {
      getParam.push('sortBy=sales_manager_name-' + this.state.salesManagerSort);
    } else if (this.state.brandAssociateSort !== '') {
      getParam.push('sortBy=brand_associate_name-' + this.state.brandAssociateSort);
    } else if (this.state.statusSort !== '') {
      getParam.push('sortBy=spk_status-' + this.state.statusSort);
    } else if (this.state.lastEnquiryTypeSort !== '') {
      getParam.push('sortBy=last_enquiry-' + this.state.lastEnquiryTypeSort);
    } else if (this.state.lastEnquiryStatusSort !== '') {
      getParam.push('sortBy=followup_status-' + this.state.lastEnquiryStatusSort);
    } else if (this.state.emailSort !== '') {
      getParam.push('sortBy=email-' + this.state.emailSort);
    } else if (this.state.phoneSort !== '') {
      getParam.push('sortBy=phone-' + this.state.phoneSort);
    } else if (this.state.citySort !== '') {
      getParam.push('sortBy=city-' + this.state.citySort);
    } else if (this.state.provinceSort !== '') {
      getParam.push('sortBy=province-' + this.state.provinceSort);
    } else if (this.state.showroomSort !== '') {
      getParam.push('sortBy=showroom_name-' + this.state.showroomSort);
    } else if (this.state.dataInSort !== '') {
      getParam.push('sortBy=last_enquiry_created_date-' + this.state.dataInSort);
    } else if (this.state.enquiryDateSort !== '') {
      getParam.push('sortBy=last_enquiry_date-' + this.state.enquiryDateSort);
    }
    /**sort */
    var url = `${process.env.REACT_APP_API_URL}enquiry?${getParam.join('&')}`
    if (jsCookie.get('privilege') === 'Sales Manager') {
      url = `${process.env.REACT_APP_API_URL}enquiry?sm=${jsCookie.get('name')}&${getParam.join('&')}`
    } else if (jsCookie.get('privilege') === 'Brand Associate') {
      url = `${process.env.REACT_APP_API_URL}enquiry?ba=${jsCookie.get('name')}&${getParam.join('&')}`
    }
    
    await axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
        this.setState({ isLoading: false });
        throw error;
      })
      .then(res => {

        if (res.data !== 'Data Empty' && res.data) {
          let data = res.data.data;
          this.setState({
            checkBoxIdData: data ? Array.from(data) : [],
          });
        } else {
          this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
          this.setState({ isLoading: false });
        }
      })
  }

  getParam() {
    var checkUrlPath = window.location.pathname;
    var myarr = checkUrlPath.split("/");
    let nameFilter = '';
    let statusFilter = '';
    let fromFilter = '';
    let toFilter = '';
    let dateRangeValue = '';
    let followupFilter = '';
    let assignedFilter = '';
    let smFilter = '';
    let baFilter = '';
    let enquiryFilter = '';
    let showroomFilter = '';
    let cityFilter = '';
    let provinceFilter = '';
    let carFilter = '';
    let sourceFilter = '';
    let followupFromFilter = '';
    let followupToFilter = '';
    let followupDateRangeValue = '';
    let phoneFilter = '';
    let emailFilter = '';
    let currentPage = '1';

    if (myarr[2] !== 'null' && typeof myarr[2] !== 'undefined') {
      nameFilter = decodeURI(myarr[2]);
    }
    if (myarr[3] !== 'null' && typeof myarr[3] !== 'undefined') {
      statusFilter = decodeURI(myarr[3]);
    }
    if (myarr[4] !== 'null' && typeof myarr[4] !== 'undefined') {
      fromFilter = myarr[4];
      dateRangeValue = moment(myarr[4]).format('MM/DD/YYYY') + ' - dd/mm/yyyy';
    }
    if (myarr[5] !== 'null' && typeof myarr[5] !== 'undefined') {
      toFilter = myarr[5];
      dateRangeValue = moment(myarr[4]).format('MM/DD/YYYY') + ' - ' + moment(myarr[5]).format('MM/DD/YYYY');
    }
    if (myarr[6] !== 'null' && typeof myarr[6] !== 'undefined') {
      followupFilter = myarr[6];
    }
    if (myarr[7] !== 'null' && typeof myarr[7] !== 'undefined') {
      assignedFilter = myarr[7];
    }
    if (myarr[8] !== 'null' && typeof myarr[8] !== 'undefined') {
      smFilter = decodeURI(myarr[8]);
    }
    if (myarr[9] !== 'null' && typeof myarr[9] !== 'undefined') {
      baFilter = decodeURI(myarr[9]);
    }
    if (myarr[10] !== 'null' && typeof myarr[10] !== 'undefined') {
      enquiryFilter = decodeURI(myarr[10]);
    }
    if (myarr[11] !== 'null' && typeof myarr[11] !== 'undefined') {
      showroomFilter = decodeURI(myarr[11]);
    }
    if (myarr[12] !== 'null' && typeof myarr[12] !== 'undefined') {
      cityFilter = decodeURI(myarr[12]);
    }
    if (myarr[13] !== 'null' && typeof myarr[13] !== 'undefined') {
      provinceFilter = decodeURI(myarr[13]);
    }
    if (myarr[14] !== 'null' && typeof myarr[14] !== 'undefined') {
      carFilter = decodeURI(myarr[14]);
    }
    if (myarr[15] !== 'null' && typeof myarr[15] !== 'undefined') {
      sourceFilter = myarr[15].replace(/%20/g, ' ');
    }
    if (myarr[16] !== 'null' && typeof myarr[16] !== 'undefined') {
      followupFromFilter = myarr[16];
      followupDateRangeValue = moment(myarr[16]).format('MM/DD/YYYY') + ' - dd/mm/yyyy';
    }
    if (myarr[17] !== 'null' && typeof myarr[17] !== 'undefined') {
      followupToFilter = myarr[17];
      followupDateRangeValue = moment(myarr[16]).format('MM/DD/YYYY') + ' - ' + moment(myarr[17]).format('MM/DD/YYYY');
    }
    if (myarr[18] !== 'null' && typeof myarr[18] !== 'undefined') {
      emailFilter = decodeURI(myarr[18]);
    }
    if (myarr[19] !== 'null' && typeof myarr[19] !== 'undefined') {
      phoneFilter = decodeURI(myarr[19]);
    }
    if (myarr[20] !== 'null' && typeof myarr[20] !== 'undefined') {
      currentPage = myarr[20];
    }

    this.setState({
      nameFilter: nameFilter,
      statusFilter: statusFilter,
      followupFilter: followupFilter,
      assignedFilter: assignedFilter,
      smFilter: smFilter,
      baFilter: baFilter,
      enquiryFilter: enquiryFilter,
      showroomFilter: showroomFilter,
      cityFilter: cityFilter,
      provinceFilter: provinceFilter,
      carFilter: carFilter,
      sourceFilter: sourceFilter,
      fromFilter: fromFilter,
      toFilter: toFilter,
      dateRangeValue: dateRangeValue,
      currentPage: currentPage,
      followupFromFilter: followupFromFilter,
      followupToFilter: followupToFilter,
      followupDateRangeValue: followupDateRangeValue,
      emailFilter: emailFilter,
      phoneFilter: phoneFilter,

      nameFilled: nameFilter ? true : false,
      followupFilled: followupFilter ? true : false,
      assignedFilled: assignedFilter ? true : false,
      smFilled: smFilter ? true : false,
      baFilled: baFilter ? true : false,
      enquiryFilled: enquiryFilter ? true : false,
      showroomFilled: showroomFilter ? true : false,
      cityFilled: cityFilter ? true : false,
      provinceFilled: provinceFilter ? true : false,
      carFilled: carFilter ? true : false,
      sourceFilled: sourceFilter ? true : false,
      dateInFilled: fromFilter && toFilter ? true : false,
      followupDateFilled: followupFromFilter && followupToFilter ? true : false,
      emailFilled: emailFilter ? true : false,
      phoneFilled: phoneFilter ? true : false,
    }, () => {
      this.getEnquiryData();
    });
  }

  componentDidMount() {
    if (!auth()) {
      return false;
    }
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('load', this.handleResize);
    this.getDropdownShowroom();
    if (jsCookie.get('privilege') != 'Brand Associate') {
      this.handleGetUser();
    }
    if (document.readyState === 'complete') {
      this.handleResize()
    }
    this.getParam();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.totalCustomer !== this.props.totalCustomer && prevState.totalCustomer != this.props.totalCustomer) {
      this.getParam();
    }
    if (this.state.isFilter !== 'no') {
      const height = this.divElement.offsetHeight;
      if (height !== prevState.filterOffsetHeight) {
        if (height > 100) {
          this.setState({ showMoreFilter: true });
        } else {
          this.setState({ showMoreFilter: false });
        }
        this.setState({ filterOffsetHeight: height });
      }
    }
    if (this.state.reloadPage !== prevState.reloadPage) {
      this.setState({ reloadPage: false });
      this.getEnquiryData();
    }
    if (prevState.newest_enquiry_date != '' && this.state.newest_enquiry_date !== prevState.newest_enquiry_date) {
      this.getParam();
    }
  }

  handlePaging() {

    let currentPage = this.state.currentPage;
    let perpage = this.state.perPage;
    let totalData = this.state.totalData;
    let totalPaging = Math.ceil(totalData / perpage);

    const start = (Number(currentPage) - 1) * perpage;
    const end = Number(start) + Number(perpage);

    let startLoop = 1;
    let endLoop = totalPaging;
    if (Number(currentPage) - 2 > 0 && Number(currentPage) + 2 <= totalPaging) {
      startLoop = Number(currentPage) - 2;
      endLoop = Number(currentPage) + 2;
    } else if (Number(currentPage) - 1 > 0 && Number(currentPage) + 3 <= totalPaging) {
      startLoop = Number(currentPage) - 1;
      endLoop = Number(currentPage) + 3;
    } else if (Number(currentPage) - 3 > 0 && Number(currentPage) + 1 <= totalPaging) {
      startLoop = Number(currentPage) - 3;
      endLoop = Number(currentPage) + 1;
    } else if (currentPage == 1 && Number(currentPage) + 4 <= totalPaging) {
      startLoop = Number(currentPage);
      endLoop = Number(currentPage) + 4;
    } else if (currentPage == totalPaging && Number(currentPage) - 4 > 0) {
      startLoop = Number(currentPage) - 4;
      endLoop = Number(currentPage);
    }

    let pagingPrevTemplate = [];
    let pagingTemplate = [];
    let pagingNextTemplate = [];
    if (Number(currentPage) > 1) {
      pagingPrevTemplate.push(
        <>
          <li key="tofirst">
            <button onClick={e => this.handlePageChange(1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
          <li key="toprev">
            <button onClick={e => this.handlePageChange(currentPage - 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    for (let i = startLoop; i <= endLoop; i++) {
      if (i == currentPage) {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 text-white bg-black">
              {i}
            </button>
          </li>
        )
      } else {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button name={i} onClick={() => this.handlePageChange(i)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              {i}
            </button>
          </li>
        )
      }
    }

    if (currentPage < totalPaging && totalPaging > 1) {
      pagingNextTemplate.push(
        <>
          <li key={`${Number(totalPaging) + 3}`}>
            <button onClick={() => this.handlePageChange(Number(currentPage) + 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
          <li key={`${Number(totalPaging) + 4}`}>
            <button onClick={() => this.handlePageChange(totalPaging)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    if (currentPage > 1) {
      if (currentPage > totalPaging) {
        this.setState({
          currentPage: 1,
        });
      }
    }
    this.setState({
      currentPage: currentPage,
      pagingPrevTemplate: pagingPrevTemplate,
      pagingTemplate: pagingTemplate,
      pagingNextTemplate: pagingNextTemplate,
      startShowing: this.state.customersData.length > 0 ? start + 1 : 0,
      endShowing: start + 1 < totalData ? (end < totalData ? end : totalData) : '',
      totalData: totalData,
    });
  }

  handlePageChange(number) {
    const name = this.state.nameFilter;
    const status = this.state.statusFilter;
    const followup = this.state.followupFilter;
    const assigned = this.state.assignedFilter;
    const sm = this.state.smFilter;
    const ba = this.state.baFilter;
    const enquiry = this.state.enquiryFilter;
    const showroom = this.state.showroomFilter;
    const city = this.state.cityFilter;
    const province = this.state.provinceFilter;
    const car = this.state.carFilter;
    const dateStart = this.state.fromFilter;
    const dateEnd = this.state.toFilter;
    const source = this.state.sourceFilter;
    const followupFromFilter = this.state.followupFromFilter;
    const followupToFilter = this.state.followupToFilter;
    const email = this.state.emailFilter;
    const phone = this.state.phoneFilter;

    var pushUrl = '/' + this.state.path;
    if (name || status || followup || assigned || sm || ba || enquiry || showroom || city || province || car || dateStart || source || followupFromFilter || email || phone || number > 1) {
      pushUrl = '/' + this.state.path +
        '/' + (name ? name.toLowerCase() : 'null') + //0
        '/' + (status ? status.toLowerCase() : 'null') +  //1
        '/' + (dateStart ? dateStart : 'null') + //2
        '/' + (dateEnd ? dateEnd : 'null') + //3
        '/' + (followup ? followup.toLowerCase() : 'all') + //4
        '/' + (assigned ? assigned : 'all') + //5
        '/' + (sm ? sm.toLowerCase() : 'null') + //6
        '/' + (ba ? ba.toLowerCase() : 'null') + //7
        '/' + (enquiry ? enquiry.toLowerCase() : 'null') + //8
        '/' + (showroom ? showroom.toLowerCase() : 'all') + //9
        '/' + (city ? city.toLowerCase() : 'null') + //10
        '/' + (province ? province.toLowerCase() : 'null') + //11
        '/' + (car ? car.toLowerCase() : 'null') + //12
        '/' + (source ? source : 'null') + //13
        '/' + (followupFromFilter ? followupFromFilter : 'null') + //14
        '/' + (followupToFilter ? followupToFilter : 'null') + //15
        '/' + (email ? email : 'null') + //16
        '/' + (phone ? phone : 'null') + //17
        '/' + number //18
    }
    window.history.pushState('page2', 'Title', pushUrl);
    this.setState({ currentPage: number }, () => {
      this.getEnquiryData();
    });
  }

  checkPopoverFilter() {
    this.setState({
      showDatePicker: false,
      showFilter: !this.state.showFilter
    }, () => {
      this.handleResize()
    })
  };

  checkPopover(id) {
    const button_id = 'button' + id;
    const popup_id = 'popup' + id;
    const button = document.querySelector('#' + button_id);
    const popup = document.querySelector('#' + popup_id);

    if (document.getElementById(popup_id).classList.contains('hidden')) {
      createPopper(button, popup, {
        placement: "left-start",
      });

      var inputs = document.querySelectorAll(".popover");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].classList.remove('block');
        inputs[i].classList.add('hidden');
      }

      popup.classList.remove('hidden');
      popup.classList.add('block');
    } else {
      popup.classList.remove('block');
      popup.classList.add('hidden');
    }
  };

  resetField(event) {
    const name = event === 'name' ? '' : this.state.nameFilter;
    const status = event === 'status' ? '' : this.state.statusFilter;
    const followup = event === 'followup' ? '' : this.state.followupFilter;
    const assigned = event === 'assigned' ? '' : this.state.assignedFilter;
    const sm = event === 'sm' ? '' : this.state.smFilter;
    const ba = event === 'ba' ? '' : this.state.baFilter;
    const enquiry = event === 'enquiry' ? '' : this.state.enquiryFilter;
    const showroom = event === 'showroom' ? '' : this.state.showroomFilter;
    const city = event === 'city' ? '' : this.state.cityFilter;
    const province = event === 'province' ? '' : this.state.provinceFilter;
    const car = event === 'car' ? '' : this.state.carFilter;
    const source = event === 'source' ? '' : this.state.sourceFilter;
    const dateStart = this.state.fromFilter;
    const dateEnd = this.state.toFilter;
    const followupFromFilter = this.state.followupFromFilter;
    const followupToFilter = this.state.followupToFilter;
    const email = event === 'email' ? '' : this.state.emailFilter;
    const phone = event === 'phone' ? '' : this.state.phoneFilter;
    const number = 1;
    var pushUrl = '/' + this.state.path
    if (name || status || followup || assigned || sm || ba || enquiry || showroom || city || province || car || dateStart || source || followupFromFilter || email || phone || number > 1) {
      pushUrl = '/' + this.state.path +
        '/' + (name ? name.toLowerCase() : 'null') + //0
        '/' + (status ? status.toLowerCase() : 'null') +  //1
        '/' + (dateStart ? dateStart : 'null') + //2
        '/' + (dateEnd ? dateEnd : 'null') + //3
        '/' + (followup ? followup.toLowerCase() : 'all') + //4
        '/' + (assigned ? assigned : 'all') + //5
        '/' + (sm ? sm.toLowerCase() : 'null') + //6
        '/' + (ba ? ba.toLowerCase() : 'null') + //7
        '/' + (enquiry ? enquiry.toLowerCase() : 'null') + //8
        '/' + (showroom ? showroom.toLowerCase() : 'all') + //9
        '/' + (city ? city.toLowerCase() : 'null') + //10
        '/' + (province ? province.toLowerCase() : 'null') + //11
        '/' + (car ? car.toLowerCase() : 'null') + //12
        '/' + (source ? source : 'null') + //13
        '/' + (followupFromFilter ? followupFromFilter : 'null') + //14
        '/' + (followupToFilter ? followupToFilter : 'null') + //15
        '/' + (email ? email : 'null') + //16
        '/' + (phone ? phone : 'null') + //17
        '/' + number //18
    }
    window.history.pushState('page2', 'Title', pushUrl);

    this.setState({
      nameFilter: name,
      statusFilter: status,
      followupFilter: followup,
      assignedFilter: assigned,
      smFilter: sm,
      baFilter: ba,
      enquiryFilter: enquiry,
      showroomFilter: showroom,
      cityFilter: city,
      provinceFilter: province,
      carFilter: car,
      sourceFilter: source,
      currentPage: number,
      emailFilter: email,
      phoneFilter: phone,

      nameFilled: name ? true : false,
      statusFilled: status ? true : false,
      followupFilled: followup ? true : false,
      assignedFilled: assigned ? true : false,
      smFilled: sm ? true : false,
      baFilled: ba ? true : false,
      enquiryFilled: enquiry ? true : false,
      showroomFilled: showroom ? true : false,
      cityFilled: city ? true : false,
      provinceFilled: province ? true : false,
      carFilled: car ? true : false,
      sourceFilled: source ? true : false,
      dateInFilled: dateStart && dateEnd ? true : false,
      followupDateFilled: followupFromFilter && followupToFilter ? true : false,
      emailFilled: email ? true : false,
      phoneFilled: phone ? true : false,
    }, () => {
      this.getEnquiryData();
    });
  }

  handleResetFilter(event) {
    window.history.pushState('page2', 'Title', '/enquiries');
    this.setState({
      nameFilter: '',
      statusFilter: '',
      followupFilter: '',
      fromFilter: '',
      toFilter: '',
      assignedFilter: '',
      smFilter: '',
      baFilter: '',
      enquiryFilter: '',
      sourceFilter: '',
      showroomFilter: '',
      cityFilter: '',
      provinceFilter: '',
      carFilter: '',
      currentPage: 1,
      dateStart: '',
      dateEnd: '',
      dateRangeValue: '',
      followupFromFilter: '',
      followupToFilter: '',
      followupDateRangeValue: '',
      range: '',
      nameFilled: false,
      dateInFilled: false,
      followupFilled: false,
      assignedFilled: false,
      smFilled: false,
      baFilled: false,
      enquiryFilled: false,
      showroomFilled: false,
      cityFilled: false,
      provinceFilled: false,
      carFilled: false,
      pushUrl: '/enquiries',
    }, () => {
      this.getEnquiryData();
    });
  }

  handleChange(event) {
    const target = event.target;
    const name = target.id === 'name' ? target.value : this.state.nameFilter;
    const status = target.id === 'status' ? target.value : this.state.statusFilter;
    const followup = target.id === 'followup' ? target.value : this.state.followupFilter;
    const assigned = target.id === 'assigned' ? target.value : this.state.assignedFilter;
    let sm = target.id === 'sm' ? target.value : this.state.smFilter;
    let ba = target.id === 'ba' ? target.value : this.state.baFilter;
    const enquiry = target.id === 'enquiry' ? target.value : this.state.enquiryFilter;
    const showroom = target.id === 'showroom' ? target.value : this.state.showroomFilter;
    const city = target.id === 'city' ? target.value : this.state.cityFilter;
    const province = target.id === 'province' ? target.value : this.state.provinceFilter;
    const car = target.id === 'car' ? target.value : this.state.carFilter;
    const source = target.id === 'source' ? target.value : this.state.sourceFilter;
    const dateStart = this.state.fromFilter;
    const dateEnd = this.state.toFilter;
    const followupFromFilter = this.state.followupFromFilter;
    const followupToFilter = this.state.followupToFilter;
    const email = target.id === 'email' ? target.value : this.state.emailFilter;
    const phone = target.id === 'phone' ? target.value : this.state.phoneFilter;
    const number = 1;
    var pushUrl = '/' + this.state.path

    if (target.id === 'showroom') {
      let dataSm = this.state.smOptionBckup;
      let dataBa = this.state.baOptionBckup;
      if (showroom && showroom != 'all') {
        dataSm = dataSm.filter(elem => {
          return elem.showroom_id == showroom
        })
        dataBa = dataBa.filter(elem => {
          return elem.showroom_id == showroom
        })
        sm = ''
        ba = ''
      }
      this.setState({
        smOption: dataSm,
        baOption: dataBa,
      })
    }

    if (target.id === 'sm') {
      let data = this.state.baOptionBckup;
      if (sm) {
        data = data.filter(elem => {
          return elem.manager_name.toLowerCase() === sm.toLowerCase()
        })
        ba = '';
      }
      this.setState({
        baOption: data,
      })
    }
    
    if (name || status || followup || assigned || sm || ba || enquiry || showroom || city || province || car || dateStart || source || followupFromFilter || email || phone || number > 1) {
      pushUrl = '/' + this.state.path +
        '/' + (name ? name.toLowerCase() : 'null') + //0
        '/' + (status ? status.toLowerCase() : 'null') +  //1
        '/' + (dateStart ? dateStart : 'null') + //2
        '/' + (dateEnd ? dateEnd : 'null') + //3
        '/' + (followup ? followup.toLowerCase() : 'all') + //4
        '/' + (assigned ? assigned : 'all') + //5
        '/' + (sm ? sm.toLowerCase() : 'null') + //6
        '/' + (ba ? ba.toLowerCase() : 'null') + //7
        '/' + (enquiry ? enquiry.toLowerCase() : 'null') + //8
        '/' + (showroom ? showroom.toLowerCase() : 'all') + //9
        '/' + (city ? city.toLowerCase() : 'null') + //10
        '/' + (province ? province.toLowerCase() : 'null') + //11
        '/' + (car ? car.toLowerCase() : 'null') + //12
        '/' + (source ? source : 'null') + //13
        '/' + (followupFromFilter ? followupFromFilter : 'null') + //14
        '/' + (followupToFilter ? followupToFilter : 'null') + //15
        '/' + (email ? email : 'null') + //16
        '/' + (phone ? phone : 'null') + //17
        '/' + number //18
    }
    window.history.pushState('page2', 'Title', pushUrl);

    this.setState({
      nameFilter: name,
      statusFilter: status,
      followupFilter: followup,
      assignedFilter: assigned,
      smFilter: sm,
      baFilter: ba,
      enquiryFilter: enquiry,
      showroomFilter: showroom,
      cityFilter: city,
      provinceFilter: province,
      carFilter: car,
      sourceFilter: source,
      currentPage: number,
      emailFilter: email,
      phoneFilter: phone,

      nameFilled: name ? true : false,
      followupFilled: followup ? true : false,
      assignedFilled: assigned ? true : false,
      smFilled: sm ? true : false,
      baFilled: ba ? true : false,
      enquiryFilled: enquiry ? true : false,
      showroomFilled: showroom ? true : false,
      cityFilled: city ? true : false,
      provinceFilled: province ? true : false,
      carFilled: car ? true : false,
      sourceFilled: source ? true : false,
      dateInFilled: dateStart && dateEnd ? true : false,
      followupDateFilled: followupFromFilter && followupToFilter ? true : false,
      emailFilled: email ? true : false,
      phoneFilled: phone ? true : false,
    }, () => {
      this.getEnquiryData();
    });
  }

  handleSort(event) {
    let nameSort = '';
    let salesManagerSort = '';
    let brandAssociateSort = '';
    let statusSort = '';
    let lastEnquiryTypeSort = '';
    let lastEnquiryStatusSort = '';
    let dataInSort = '';
    let emailSort = '';
    let phoneSort = '';
    let citySort = '';
    let provinceSort = '';
    let enquiryDateSort = '';
    let showroomSort = '';

    if (event === 'name') {
      if (this.state.nameSort === '' || this.state.nameSort === 'desc') {
        nameSort = 'asc';
      } else {
        nameSort = 'desc';
      }
    }

    if (event === 'salesManager') {
      if (this.state.salesManagerSort === '' || this.state.salesManagerSort === 'desc') {
        salesManagerSort = 'asc';
      } else {
        salesManagerSort = 'desc';
      }
    }

    if (event === 'brandAssociate') {
      if (this.state.brandAssociateSort === '' || this.state.brandAssociateSort === 'desc') {
        brandAssociateSort = 'asc';
      } else {
        brandAssociateSort = 'desc';
      }
    }
    if (event === 'status') {
      if (this.state.statusSort === '' || this.state.statusSort === 'desc') {
        statusSort = 'asc';
      } else {
        statusSort = 'desc';
      }
    }

    if (event === 'last_enquiry_type') {
      if (this.state.lastEnquiryTypeSort === '' || this.state.lastEnquiryTypeSort === 'desc') {
        lastEnquiryTypeSort = 'asc';
      } else {
        lastEnquiryTypeSort = 'desc';
      }
    }

    if (event === 'last_enquiry_status') {
      if (this.state.lastEnquiryStatusSort === '' || this.state.lastEnquiryStatusSort === 'desc') {
        lastEnquiryStatusSort = 'asc';
      } else {
        lastEnquiryStatusSort = 'desc';
      }
    }

    if (event === 'enquiry_date') {
      if (this.state.enquiryDateSort === '' || this.state.enquiryDateSort === 'desc') {
        enquiryDateSort = 'asc';
      } else {
        enquiryDateSort = 'desc';
      }
    }
    if (event === 'data_in') {
      if (this.state.dataInSort === '' || this.state.dataInSort === 'desc') {
        dataInSort = 'asc';
      } else {
        dataInSort = 'desc';
      }
    }

    if (event === 'email') {
      if (this.state.emailSort === '' || this.state.emailSort === 'desc') {
        emailSort = 'asc';
      } else {
        emailSort = 'desc';
      }
    }
    if (event === 'phone') {
      if (this.state.phoneSort === '' || this.state.phoneSort === 'desc') {
        phoneSort = 'asc';
      } else {
        phoneSort = 'desc';
      }
    }
    if (event === 'city') {
      if (this.state.citySort === '' || this.state.citySort === 'desc') {
        citySort = 'asc';
      } else {
        citySort = 'desc';
      }
    }
    if (event === 'province') {
      if (this.state.provinceSort === '' || this.state.provinceSort === 'desc') {
        provinceSort = 'asc';
      } else {
        provinceSort = 'desc';
      }
    }
    if (event === 'showroom') {
      if (this.state.showroomSort === '' || this.state.showroomSort === 'desc') {
        showroomSort = 'asc';
      } else {
        showroomSort = 'desc';
      }
    }

    this.setState({
      nameSort: nameSort,
      salesManagerSort: salesManagerSort,
      brandAssociateSort: brandAssociateSort,
      statusSort: statusSort,
      lastEnquiryTypeSort: lastEnquiryTypeSort,
      lastEnquiryStatusSort: lastEnquiryStatusSort,
      enquiryDateSort: enquiryDateSort,
      dataInSort: dataInSort,
      emailSort: emailSort,
      phoneSort: phoneSort,
      citySort: citySort,
      provinceSort: provinceSort,
      showroomSort: showroomSort

    }, () => {
      this.getEnquiryData();
    });
  }

  handleCheckAll(event) {
    let customersData = this.state.customersData;
    let checkBoxId = this.state.checkBoxId;
    let checkBoxIdData = this.state.checkBoxIdData;
    var inputs = '';
    if (document.getElementById('checkAll').checked) {
      let inputs = document.querySelectorAll("input[type='checkbox']");
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value !== 'on') {
          checkBoxId.push(inputs[i].value);
          let t_customersData = customersData.filter(function (value) {
            return value.id == inputs[i].value;
          });
          checkBoxIdData.push(t_customersData[0]);
        }
        inputs[i].checked = true;
      }
    } else {
      inputs = document.querySelectorAll("input[type='checkbox']");
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
        checkBoxId = checkBoxId.filter(function (value, key) {
          return value != inputs[i].value
        });
        checkBoxIdData = checkBoxIdData.filter(function (value, key) {
          return value.id != inputs[i].value
        });
      }
    }
    this.setState({
      checkBoxId: Array.from(checkBoxId),
      checkBoxIdData: Array.from(checkBoxIdData),
    });

  }

  handleCheckAllChange(event) {
    var inputs = '';
    var i = 0;
    inputs = document.querySelectorAll("input[type='checkbox']");
    for (i = 0; i < inputs.length; i++) {
      if (inputs[i].getAttribute('data-checked') == 'checked') {
        inputs[i].checked = true;
      } else {
        inputs[i].checked = false;
      }
    }
  }

  handleCheckBoxChange(event) {
    const target = event.target;
    var customersData = this.state.customersData;
    var checkBoxId = this.state.checkBoxId;
    var checkBoxIdData = this.state.checkBoxIdData;
    if (document.getElementById(target.id).checked) {
      checkBoxId.push(target.value);
      let t_checkBoxIdData = customersData.filter(function (value) {
        return value.id == target.value;
      });
      checkBoxIdData.push(t_checkBoxIdData[0]);

    } else {
      document.getElementById('checkAll').checked = false;
      checkBoxId = checkBoxId.filter(function (value, index, arr) {
        return value !== target.value;
      });
      checkBoxIdData = checkBoxIdData.filter(function (value) {
        return value.id != target.value;
      });
    }
    this.setState({
      checkBoxId: Array.from(checkBoxId),
      checkBoxIdData: Array.from(checkBoxIdData),
    });
  }


  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(status, name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className={'notifBar ' + (status == '404' ? 'bg-red' : 'bg-emerald-400')}>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)} />
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  handleChangeField(event) {
    const target = event.target;
    const customers_detail = target.id === 'customers_detail' ? target.checked : this.state.showDetail;
    this.setState({
      showDetail: customers_detail,
    });
  }

  handleEventDatePicker(event, picker) {
    if (event.type === 'apply') {
      this.setState({
        dateRangeValue: picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'),
        fromFilter: picker.startDate.format('YYYY-MM-DD'),
        toFilter: picker.endDate.format('YYYY-MM-DD'),
      }, () => {
        this.handleChange({ target: 'date' });
      });
    } else if (event.type === 'hide') {
      if (this.state.fromFilter == '') {
        this.setState({
          dateRangeValue: '',
        });
      } else {
        this.setState({
          dateRangeValue: moment(this.state.fromFilter).format('MM/DD/YYYY') + ' - ' + moment(this.state.toFilter).format('MM/DD/YYYY'),
        })
      }
    }
  }

  handleResetDatePicker() {
    this.setState({
      fromFilter: '',
      toFilter: '',
      dateRangeValue: '',
    }, () => {
      this.handleChange({ target: 'date' });
    });
  }

  handleEventFollowupDatePicker(event, picker) {
    if (event.type === 'apply') {
      this.setState({
        followupDateRangeValue: picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'),
        followupFromFilter: picker.startDate.format('YYYY-MM-DD'),
        followupToFilter: picker.endDate.format('YYYY-MM-DD'),
      }, () => {
        this.handleChange({ target: 'followupDate' });
      });
    } else if (event.type === 'hide') {
      if (this.state.followupFromFilter == '') {
        this.setState({
          followupDateRangeValue: '',
        });
      } else {
        this.setState({
          followupDateRangeValue: moment(this.state.followupFromFilter).format('MM/DD/YYYY') + ' - ' + moment(this.state.followupToFilter).format('MM/DD/YYYY'),
        })
      }
    }
  }

  handleResetFollowupDatePicker() {
    this.setState({
      followupFromFilter: '',
      followupToFilter: '',
      followupDateRangeValue: '',
    }, () => {
      this.handleChange({ target: 'followupDate' });
    });
  }

  async handleExcel() {
    let validate = 1;
    if (this.state.checkBoxId.length < 1) {
      await this.getExportedEnquiryData();
    } else {
      this.setState({
        checkBoxErrorMessage: '',
      });
    }

    if (validate == 0) {
      this.setState({ isSubmitted: false });
      return false;
    }
    document.getElementById('excel-button').click();
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.successMessage}
          {this.state.errorMessage}
          {this.state.smErrorMessage}
          {this.state.baErrorMessage}
          {this.state.checkBoxErrorMessage}
        </div>
        <div className="flex flex-wrap w-full">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0">

            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="tabel-panel block w-full ">
                <div className="relative flex flex-col  min-w-0 break-words w-full pb-6 shadow-lg rounded bg-white">
                <div className="w-full lg:w-10/12 xl:w-10/12 md:w-10/12 flex-grow flex-1">
                    <div className="float-right m-4">
                    </div>
                  </div>
                  {/* filter */}
                  {this.state.isFilter != 'no' ?
                    <div className="filter grid-2 w-full h-full rounded-tl rounded-tr bg-blueGray-100 items-center px-4"
                      style={{ height: `90px` }}
                    >
                      <div className="flex flex-wrap pb-4"
                        ref={(divElement) => { this.divElement = divElement }}>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="name">Name</label>
                          <div className="relative">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Filter by Name"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.nameFilter}
                              onChange={this.handleChange}
                            />
                            <span className="h-full leading-snug font-normal absolute right-0 inline-flex items-center text-blueGray-300 text-base mr-2">
                              {!this.state.nameFilled ? <i className="fas fa-user"></i> : <i className="cursor-pointer fas fa-times" onClick={() => this.resetField('name')}></i>}
                            </span>
                          </div>
                        </div>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="email">Email</label>
                          <div className="relative">
                            <input
                              type="text"
                              id="email"
                              name="email"
                              placeholder="Filter by email"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.emailFilter}
                              onChange={this.handleChange}
                            />
                            <span className="h-full leading-snug font-normal absolute right-0 inline-flex items-center text-blueGray-300 text-base mr-2">
                              {!this.state.emailFilled ? <i className="fas fa-at"></i> : <i className="cursor-pointer fas fa-times" onClick={() => this.resetField('email')}></i>}
                            </span>
                          </div>
                        </div>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="phone">Phone</label>
                          <div className="relative">
                            <input
                              type="text"
                              id="phone"
                              name="phone"
                              placeholder="Filter by phone"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.phoneFilter}
                              onChange={this.handleChange}
                            />
                            <span className="h-full leading-snug font-normal absolute right-0 inline-flex items-center text-blueGray-300 text-base mr-2">
                              {!this.state.phoneFilled ? <i className="fas fa-phone"></i> : <i className="cursor-pointer fas fa-times" onClick={() => this.resetField('phone')}></i>}
                            </span>
                          </div>
                        </div>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="date_in">Enquiry Date</label>
                          <div className="relative">
                            <DateRangePicker
                              startDate={this.state.fromFilter}
                              endDate={this.state.toFilter}
                              onEvent={this.handleEventDatePicker}
                              initialSettings={{
                                alwaysShowCalendars: true,
                              }}
                            >
                              <input type="text"
                                value={this.state.dateRangeValue}
                                placeholder="dd-mm-yyyy - dd-mm-yyyy"
                                style={{ width: `${200}px` }}
                                className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              />
                            </DateRangePicker>
                            <span className={(this.state.dateRangeValue ? 'mr-2' : '') + "  h-full font-normal absolute right-0 text-center text-blueGray-300 items-center mr-3 py-2"}>
                              {this.state.dateRangeValue ? <i className="cursor-pointer fa fa-times"
                                onClick={() => this.handleResetDatePicker()}
                              ></i> :
                                <i className=" fa fa-calendar-alt"
                                ></i>
                              }

                            </span>
                          </div>
                        </div>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="date_in">Followup Date</label>
                          <div className="relative">
                            <DateRangePicker
                              startDate={'this.state.followupFromFilter'}
                              endDate={this.state.followupToFilter}
                              onEvent={this.handleEventFollowupDatePicker}
                              initialSettings={{
                                alwaysShowCalendars: true,
                              }}
                            >
                              <input type="text"
                                value={this.state.followupDateRangeValue}
                                placeholder="dd-mm-yyyy - dd-mm-yyyy"
                                style={{ width: `${200}px` }}
                                className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              />
                            </DateRangePicker>
                            <span className={(this.state.followupDateRangeValue ? 'mr-2' : '') + "  h-full font-normal absolute right-0 text-center text-blueGray-300 items-center mr-3 py-2"}>
                              {this.state.followupDateRangeValue ? <i className="cursor-pointer fa fa-times"
                                onClick={() => this.handleResetFollowupDatePicker()}
                              ></i> :
                                <i className=" fa fa-calendar-alt"
                                ></i>
                              }

                            </span>
                          </div>
                        </div>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="status">Status</label>
                          <select
                            type="text"
                            id="status"
                            name="status"
                            className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.statusFilter}
                            onChange={this.handleChange}
                            style={{ width: `${130}px` }}
                          >
                            {this.state.statusOption.map((e, key) => {
                              return <option key={'status' + key} value={e.value} >{e.text}</option>;
                            })}
                          </select>
                        </div>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="followup">Followup Status</label>
                          <select
                            type="text"
                            id="followup"
                            name="followup"
                            className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.followupFilter}
                            onChange={this.handleChange}
                            style={{ width: `${130}px` }}
                          >
                            {this.state.followupOption.map((e, key) => {
                              return <option key={'fo' + key} value={e.value} >{e.text}</option>;
                            })}
                          </select>
                        </div>
                        {jsCookie.get('privilege') == 'Super Admin' || jsCookie.get('privilege') == 'Sales Manager' ?
                          <div className="relative my-2 mr-4 xs:w-full">
                            <label className="text-blueGray-400 text-sm w-full block" htmlFor="assigned">Assignee</label>
                            <select
                              type="text"
                              id="assigned"
                              name="assigned"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.assignedFilter}
                              onChange={this.handleChange}
                              style={{ width: `${130}px` }}
                            >
                              {this.state.assignedOption.map((e, key) => {
                                return <option key={'ao' + key} value={e.value} >{e.text}</option>;
                              })}
                            </select>
                          </div> : ''
                        }
                        {jsCookie.get('privilege') == 'Super Admin' ?
                          <div className="relative my-2 mr-4 xs:w-full">
                            <label className="text-blueGray-400 text-sm block" htmlFor="sm">Sales Manager</label>
                            <div className="relative">
                              <select
                                type="text"
                                id="sm"
                                name="sm"
                                className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={this.state.smFilter}
                                onChange={this.handleChange}
                                style={{ width: `${130}px` }}
                              >
                                <option key="uniq1" value="">All</option>
                                {this.state.smOption.map((e, key) => {
                                  return <option key={'sm' + key} value={e.value} >{e.label}</option>;
                                })}
                              </select>
                            </div>
                          </div> : ''
                        }
                        {jsCookie.get('privilege') == 'Super Admin' || jsCookie.get('privilege') == 'Sales Manager' ?
                          <div className="relative my-2 mr-4 xs:w-full">
                            <label className="text-blueGray-400 text-sm block" htmlFor="ba">Brand Associate</label>
                            <div className="relative">
                              <select
                                type="text"
                                id="ba"
                                name="ba"
                                className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={this.state.baFilter}
                                onChange={this.handleChange}
                                style={{ width: `${130}px` }}
                              >
                                <option key="uniq1" value="">All</option>
                                {this.state.baOption.map((e, key) => {
                                  return <option key={'ba' + key} value={e.value} >{e.label}</option>;
                                })}
                              </select>
                            </div>
                          </div> : ''
                        }
                        <div className="relative my-2  mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="enquiry">Enquiry Subject</label>
                          <div className="relative">
                            <select
                              type="text"
                              id="enquiry"
                              name="enquiry"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.enquiryFilter}
                              onChange={this.handleChange}
                              style={{ width: `${(4 * (this.state.enquiryFilter ? this.state.enquiryFilter.length : 0)) + 130}px` }}
                            >
                              <option key="uniq1" value="">All</option>
                              {this.state.enquirySubjectOption.map((e, key) => {
                                return <option key={'enquirySubject' + key} value={e.value} >{e.label}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                        {jsCookie.get('privilege') == 'Super Admin' ?
                          <div className="relative my-2  mr-4 w-auto sm:w-full">
                            <label className="text-blueGray-400 text-sm block" htmlFor="showroom">Gallery</label>
                            <select
                              type="text"
                              id="showroom"
                              name="showroom"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.showroomFilter}
                              style={{ width: `${(8 * (this.state.showroomFilter ? this.state.showroomFilter.length : 0)) + 150}px` }}
                              onChange={this.handleChange}
                            >
                              <option key="uniq1" value="">All</option>
                              {this.state.showroomOption.map((e, key) => {
                                return <option key={'sw' + key} value={e.id}>{e.name}</option>;
                              })}
                            </select>
                          </div> : ''
                        }
                        {/*<div className="relative my-2  mr-4  w-auto sm:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="city">City</label>
                          <div className="relative">
                            <select
                              type="text"
                              id="city"
                              name="city"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.cityFilter}
                              onChange={this.handleChange}
                              style={{ width: `${(4 * (this.state.cityFilter ? this.state.cityFilter.length : 0)) + 130}px` }}
                            >
                              <option key="uniq1" value="">All</option>
                              {this.state.cityOption.map((e, key) => {
                                return <option key={'city' + key} value={e.value.toLowerCase()} >{e.label}</option>;
                              })}
                            </select>
                          </div>
                        </div>*/}
                        <div className="relative my-2  mr-4  w-auto sm:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="province">Province</label>
                          <div className="relative">
                            <select
                              type="text"
                              id="province"
                              name="province"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.provinceFilter}
                              onChange={this.handleChange}
                              style={{ width: `${(4 * (this.state.provinceFilter ? this.state.provinceFilter.length : 0)) + 130}px` }}
                            >
                              <option key="uniq1" value="">All</option>
                              {this.state.provinceOption.map((e, key) => {
                                return <option key={'province' + key} value={e.value} >{e.label}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="relative my-2 mr-4 w-auto sm:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="car">Car Model</label>
                          <div className="relative">
                            <select
                              type="text"
                              id="car"
                              name="car"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.carFilter}
                              onChange={this.handleChange}
                              style={{ width: `${(4 * (this.state.carFilter ? this.state.carFilter.length : 0)) + 130}px` }}
                            >
                              <option key="uniq1" value="">All</option>
                              {this.state.carOption.map((e, key) => {
                                return <option key={'car' + key} value={e.value.toLowerCase()} >{e.label}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="relative my-2 mr-4 w-auto sm:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="source">Channel</label>
                          <div className="relative">
                            <select
                              type="text"
                              id="source"
                              name="source"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.sourceFilter}
                              style={{ width: `${(2 * (this.state.sourceFilter ? this.state.sourceFilter.length : 0)) + 150}px` }}
                              onChange={this.handleChange}
                            >
                              <option key="uniq1" value="">All</option>
                              {this.state.sourceOption.map((e, key) => {
                                return <option key={'sw' + key} value={e.value}>{e.label}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="lg:py-4 lg:flex align-self-start pt-2 lg:pt-4">
                        {this.state.showMoreFilter ?
                          <button
                            className="nofocus rounded text-black text-sm pt-6"
                            href="#"
                            id="filterBtn"
                            onClick={(e) => {
                              e.preventDefault();
                              this.checkPopoverFilter()
                            }}
                          >
                            {!this.state.showFilter ? <>More Filter <i className="fas fa-angle-down ml-1"></i></> : <>Less Filter<i className="fas fa-angle-up ml-2"></i></>}
                          </button> : ''}
                        <button
                          className="nofocus rounded text-black text-sm pt-6 pl-4 "
                          onClick={this.handleResetFilter}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    : ''}
                  {/* filter */}
                  {/* Projects table */}
                  <div className="flex relative bg-blueGray-100 px-4 ">
                    <div className="relative my-2 mr-4 xs:w-full">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="customers_detail"
                          name="customers_detail"
                          placeholder="Filter by Name"
                          className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                          checked={this.state.showDetail}
                          onChange={this.handleChangeField}
                        />
                        <label className="text-blueGray-400 text-sm ml-2" htmlFor="customers_detail">Show Detail</label>
                      </div>
                    </div>
                  </div>
                  <div className={"customer-table relative overflow-x-auto bg-white "}>
                    <table className="items-center w-full h-auto overflow-scroll bg-transparent border-collapse">
                      <thead className="sticky-top">
                        <tr className="border border-l-0 border-r-0 border-blueGray-200">
                          <th className={"px-2 align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 pl-4"} style={{ width: `${30}px` }}>
                            <input
                              name="checkAll"
                              id="checkAll"
                              type="checkbox"
                              className="rounded nofocus"
                              onChange={this.handleCheckAll} />
                          </th>
                          <th className={"relative cursor-pointer px-2  align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500 pl-4"} onClick={() => this.handleSort('name')}>
                            <div className="enquiry-table-head">
                              Name
                              <i className={`fas ${this.state.nameSort === 'desc' ? "fa-sort-down" : (this.state.nameSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </div>
                          </th>
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('email')}>
                              <div className="enquiry-table-head">
                                Email
                                <i className={`fas ${this.state.emailSort === 'desc' ? "fa-sort-down" : (this.state.emailSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                              </div>
                            </th> : null}
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('phone')}>
                              <div className="enquiry-table-head">
                                Phone
                                <i className={`fas ${this.state.phoneSort === 'desc' ? "fa-sort-down" : (this.state.phoneSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                              </div>
                            </th> : null}
                          {/*this.state.showDetail ?
                            <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('city')}>
                              City
                              <i className={`fas ${this.state.citySort === 'desc' ? "fa-sort-down" : (this.state.citySort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th> : null*/}
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('province')}>
                              <div className="enquiry-table-head">
                                Province
                                <i className={`fas ${this.state.provinceSort === 'desc' ? "fa-sort-down" : (this.state.provinceSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                              </div>
                            </th> : null}
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('showroom')}>
                              <div className="enquiry-table-head">
                                Gallery
                                <i className={`fas ${this.state.showroomSort === 'desc' ? "fa-sort-down" : (this.state.showroomSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                              </div>
                            </th> : null}
                          <th className={(jsCookie.get('privilege') == 'Brand Associate' || jsCookie.get('privilege') == 'Sales Manager' ? 'hidden' : null) + " minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('salesManager')}>
                            <div className="enquiry-table-head">
                              Sales Manager
                              <i className={`fas ${this.state.salesManagerSort === 'desc' ? "fa-sort-down" : (this.state.salesManagerSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </div>
                          </th>
                          <th className={(jsCookie.get('privilege') == 'Brand Associate' ? 'hidden' : null) + " minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('brandAssociate')}>
                            <div className="enquiry-table-head">
                              Brand Associate
                              <i className={`fas ${this.state.brandAssociateSort === 'desc' ? "fa-sort-down" : (this.state.brandAssociateSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </div>
                          </th>
                          <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('status')}>
                            <div className="enquiry-table-head">
                              Status
                              <i className={`fas ${this.state.statusSort === 'desc' ? "fa-sort-down" : (this.state.statusSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </div>
                          </th>
                          <th className={"minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('last_enquiry_type')}>
                            <div className="enquiry-table-head">
                              Enquiry Subject
                              <i className={`fas ${this.state.lastEnquiryTypeSort === 'desc' ? "fa-sort-down" : (this.state.lastEnquiryTypeSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </div>
                          </th>
                          <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('data_in')}>
                            <div className="enquiry-table-head">
                              Enquiry Date
                              <i className={`fas ${this.state.dataInSort === 'desc' ? "fa-sort-down" : (this.state.dataInSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </div>
                          </th>
                          <th className={"minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('enquiry_date')}>
                            <div className="enquiry-table-head">
                              Followup Date
                              <i className={`fas ${this.state.enquiryDateSort === 'desc' ? "fa-sort-down" : (this.state.enquiryDateSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </div>
                          </th>
                          {this.state.showDetail ?
                            <th className={"minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('last_enquiry_type')}>
                              <div className="enquiry-table-head">
                                Preferred Model
                                <i className={`fas ${this.state.lastEnquiryTypeSort === 'desc' ? "fa-sort-down" : (this.state.lastEnquiryTypeSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                              </div>
                            </th> : null}
                          {this.state.showDetail ?
                            <th className={"minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('preferred_model')}>
                              <div className="enquiry-table-head">
                                Followup Preferred Model
                              </div>
                            </th> : null}
                          <th className={"minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('last_enquiry_status')}>
                            <div className="enquiry-table-head">
                              Followup Status
                              <i className={`fas ${this.state.lastEnquiryStatusSort === 'desc' ? "fa-sort-down" : (this.state.lastEnquiryStatusSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </div>
                          </th>
                          {this.state.showDetail ?
                            <th className={"minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('preferred_model')}>
                              <div className="enquiry-table-head">
                                Followup Notes
                              </div>
                            </th> : null}
                          <th className={"minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('enquiry_date')}>
                            <div className="enquiry-table-head">
                              Channel
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.isLoading ?
                          <tr key="notfound" className="border border-l-0 border-r-0 border-blueGray-200">
                            <td colSpan="17" className="align-middle text-sm text-center whitespace-wrap py-4 pr-4">
                              <ClipLoader size={20} color="black" />
                            </td>
                          </tr> :
                          this.state.customersData.length > 0 ?
                            this.state.customersData.map((row, key) => (
                              <tr key={'cd' + key} className={(row.background) + " border border-l-0 border-r-0 border-blueGray-200"}>
                                <td className="px-2 align-middle text-sm whitespace-wrap px-4 py-4">
                                  <input
                                    name={`checked${row.id}`}
                                    id={`checked${row.id}`}
                                    type="checkbox"
                                    className="checkbox rounded nofocus"
                                    value={row.id}
                                    data-checked={row.checked}
                                    onChange={this.handleCheckBoxChange} />
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap px-4">
                                  <Link
                                    className={"text-sm py-2 font-normal block w-full whitespace-wrap bg-transparent text-blueGray-700"}
                                    to={{
                                      pathname: `/leads/${row.customers_id}`, state: [{ backUrl: this.state.pushUrl }]
                                    }}>
                                    {row.salutation ? row.salutation + '. ' : ''}{row.first_name}{" "}{row.last_name}
                                  </Link>
                                </td>
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4 whitespace-wrap">
                                    {row.email}
                                  </td> : null}
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4 whitespace-wrap">
                                    {row.phone}
                                  </td> : null}
                                {/*this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.city ? row.city : '--'}
                                  </td> : null*/}
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.province ? row.province : '--'}
                                  </td> : null}
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.showroom_name ? row.showroom_name : '--'}
                                  </td> : null}
                                <td className={(jsCookie.get('privilege') == 'Brand Associate' || jsCookie.get('privilege') == 'Sales Manager' ? 'hidden' : null) + " px-2 align-middle text-sm whitespace-wrap py-4"}>
                                  {row.sales_manager_name ? row.sales_manager_name : '--'}
                                </td>
                                <td className={(jsCookie.get('privilege') == 'Brand Associate' ? 'hidden' : null) + " px-2 align-middle text-sm whitespace-wrap py-4"}>
                                  {row.brand_associate_name ? row.brand_associate_name : '--'}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.total_spk > 0 ? 'Customer' : 'Leads'}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.subject ? (row.subject + (row.enquiry_type ? ' - ' + row.enquiry_type : '')) : '--'}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.enquiry_date != null && row.enquiry_date != '--' ? DateFormat(row.enquiry_date, 'Mdy') : '--'}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.last_followup_date != null && row.last_followup_date != '--' ? DateFormat(row.last_followup_date, 'Mdy') : '--'}
                                </td>
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.preferred_model}
                                  </td> : null}
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                    {row.last_followup_preferred_model ? row.last_followup_preferred_model : '--'}
                                  </td> : null}
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.last_followup_followup_status ? row.last_followup_status+' - '+row.last_followup_followup_status :
                                    (row.last_followup_status == 'Not Reach' ? "Can't Reach" : (row.last_followup_status == '' ? 'Not Followed Up' : row.last_followup_status))}
                                </td>
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.last_followup_notes}
                                  </td> : null}
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  <img
                                    src={require("assets/img/logo/" + getBadge(row.source.toLowerCase())).default}
                                    alt={row.source}
                                    className={"w-8 h-8 rounded-full border-2 border-blueGray-50 shadow bg-white "}
                                  />
                                </td>
                              </tr>
                            )) :
                            <tr key="uniq2" className="border border-l-0 border-r-0 border-blueGray-200">
                              <td colSpan="17" className="align-middle text-sm text-center whitespace-wrap py-4 pr-4">
                                Data Not Available
                              </td>
                            </tr>}
                      </tbody>
                    </table>
                  </div>
                  <div className="py-4">
                    <nav className="block flex justify-between">
                      <div className="w-4/12">
                        {this.state.isExportExcel != 'no' ?
                          <>
                            <button
                              className={`button-gold  ml-4 mr-2 text-white active:bg-blueGray-600 text-2xs tracking-wide01 uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ${jsCookie.get('privilege') == 'Brand Associate' ? ' ml-2' : ''}`}
                              onClick={this.handleExcel}
                            >

                              Export Excel</button>
                            <ExcelFile
                              filename={"Enquiry Data " + moment(new Date(), 'DD-MM-YYYY', true).format('DD-MM-YYYY') + ".csv"}
                              element={
                                <button
                                  className="hidden"
                                  id='excel-button'
                                >

                                  Export Excel</button>
                              }
                            >
                              <ExcelSheet data={this.state.checkBoxIdData} name="Leads"
                              >
                                <ExcelColumn label="First Name" value="first_name" />
                                <ExcelColumn label="Last_name" value="last_name" />
                                <ExcelColumn label="Email" value="email" />
                                <ExcelColumn label="Phone" value="phone" />
                                <ExcelColumn label="Province" value="province" />
                                <ExcelColumn label="Gallery" value="showroom_name" />
                                <ExcelColumn label="Sales Manager" value="sales_manager_name" />
                                <ExcelColumn label="Brand Associate" value="brand_associate_name" />
                                <ExcelColumn label="Enquiry Subject" value="subject" />
                                <ExcelColumn label="Enquiry Type" value="enquiry_type" />
                                <ExcelColumn label="Notes" value="notes" />
                                <ExcelColumn label="Preferred Model" value="preferred_model" />
                                <ExcelColumn label="Test Drive Location" value="test_drive_location" />
                                <ExcelColumn label="Enquiry Date"
                                  value={(col) => col.enquiry_date ? DateFormat(col.enquiry_date, 'ymd') : ''} />
                                <ExcelColumn label="Channel" value="source" />
                                <ExcelColumn label="Last Followup Notes" value="last_followup_notes" />
                                <ExcelColumn label="Last Followup Preferred Model" value="last_followup_preferred_model" />
                                <ExcelColumn label="Last Followup Status" 
                                  value={(col) => col.last_followup_followup_status? col.last_followup_status +' - '+ col.last_followup_followup_status : col.last_followup_status  } />
                                <ExcelColumn label="Last Followup Date"
                                  value={(col) => col.last_followup_date ? DateFormat(col.last_followup_date, 'ymd') : ''} />
                              </ExcelSheet>
                            </ExcelFile>
                          </>
                          : ''}
                      </div>
                      <div className="w-8/12 legend flex justify-between mr-5">
                        <div className={`${jsCookie.get('privilege') == 'Sales Manager' || jsCookie.get('privilege') == 'Brand Associate' ? 'hidden' : ''} flex my-auto`}><div className="bg-table-red legend-color border-2 my-auto ml-2 mr-2"></div>New Enquiry</div>
                        <div className={`${jsCookie.get('privilege') == 'Brand Associate' ? 'hidden' : ''} flex my-auto`}><div className="bg-table-yellow legend-color border-2 my-auto ml-2 mr-2"></div>Not Assigned</div>
                        <div className="flex my-auto"><div className="bg-table-purple legend-color border-2 my-auto ml-2 mr-2"></div>Not Followed Up</div>
                        <div className="flex my-auto"><div className="bg-table-blue legend-color border-2 my-auto ml-2 mr-2"></div>Followed Up</div>
                        <div className="flex my-auto"><div className="bg-table-gray legend-color border-2 my-auto ml-2 mr-2"></div>Can't Reach</div>
                        <div className="flex my-auto"><div className="bg-table-green legend-color border-2 my-auto ml-2 mr-2"></div>SPK</div>
                      </div>
                    </nav>
                    <nav className="block flex justify-end mt-5">
                      {this.state.isPaging != 'no' ?
                        <ul className="flex rounded list-none flex-wrap float-right mx-4">
                          <div className="mx-4 my-auto">
                            Showing {this.state.startShowing} {this.state.endShowing ? 'to ' + this.state.endShowing : ''} of {this.state.totalData} Enquiries
                          </div>
                          {/*pagingTmp*/}
                          {this.state.pagingPrevTemplate}
                          {this.state.pagingTemplate}
                          {this.state.pagingNextTemplate}
                        </ul>
                        : ""}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

