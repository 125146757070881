import React from 'react'
import {Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import auth from 'global/auth.js';

import jsCookie from 'js-cookie';
import { createPopper } from "@popperjs/core";

import ClipLoader from "react-spinners/ClipLoader";
/*eslint-disable eqeqeq*/

export default function Users() {
  if (jsCookie.get('privilege') == 'Uploader') {
    return <Redirect to="/404" />;
  }
  return (
    <>
      <UsersClass />
    </>
  );
}
class UsersClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backupData: [],
      showroomData: [],
      isLoading: true,
      successMessage: '',
    };
    this.checkPopover = this.checkPopover.bind(this);

  }

  getShowroomData() {
    var url = `${process.env.REACT_APP_API_URL}showroom`;
    if (jsCookie.get('showroom_id') !== 'null') {
      url = `${process.env.REACT_APP_API_URL}showroom/${jsCookie.get('showroom_id')}`;
    }
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          showroomData: data,
          isLoading: false,
        });
      })
  }

  componentDidMount() {
    this.getShowroomData();
  }


  checkPopover(id) {
    const button_id = 'button' + id;
    const popup_id = 'popup' + id;
    const button = document.querySelector('#' + button_id);
    const popup = document.querySelector('#' + popup_id);

    if (document.getElementById(popup_id).classList.contains('hidden')) {
      createPopper(button, popup, {
        placement: "left-start",
      });

      var inputs = document.querySelectorAll(".popover");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].classList.remove('block');
        inputs[i].classList.add('hidden');
      }
      popup.classList.remove('hidden');
      popup.classList.add('block');
    } else {
      popup.classList.remove('block');
      popup.classList.add('hidden');
    }
  };


  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.errorMessage}
        </div>
        <div className="flex flex-wrap ">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="block w-full  ">
                <div className="relative flex flex-col  min-w-0 break-words w-full pb-6 shadow-lg rounded bg-white">
                  <div className="block w-full min-h-screen-75">
                    <div className="w-full lg:w-10/12 xl:w-10/12 md:w-10/12 flex-grow flex-1">
                      <div className="float-right m-4">
                      </div>
                    </div>
                    <div className="filter grid-2 w-full h-full rounded-tl rounded-tr bg-blueGray-100 items-center px-4"
                    >
                    </div>
                    {/* Projects table */}
                    <div className={"customer-table relative overflow-x-auto bg-white "}>
                      <table className={"items-center bg-white w-full bg-transparent border-collapse overflow-x-scroll"}>
                        <thead>
                          <tr className="border border-l-0 border-r-0 border-blueGray-200">
                            <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500 pl-4"}
                            >
                              Showroom
                            </th>
                            <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500"}
                              onClick={() => this.handleSort('phone')}
                            >
                              Total City Naming
                            </th>
                            <th className={"px-2  align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-blueGray-500 pr-4"}
                              style={{ width: `${30}px` }}
                            >
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isLoading ?
                            <tr key="notfound" className="border border-l-0 border-r-0 border-blueGray-200">
                              <td colSpan="7" className="align-middle text-sm text-center whitespace-nowrap py-4 pr-4">
                                <ClipLoader size={20} color="black" />
                              </td>
                            </tr> :
                            this.state.showroomData.length > 0 ?
                              this.state.showroomData.map((row) => (
                                <tr key={row.id} className={"border border-l-0 border-r-0 border-blueGray-200"}>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap px-4 py-4">
                                  <Link to={`/automate-assign-city/`+row.id}>{row.name}</Link>
                                  </td>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap py-4">
                                    {row.total_city_naming}
                                  </td>
                                  <td className="px-2 align-middle text-sm whitespace-nowrap py-4 pr-4">
                                    <div
                                      className="cursor-pointer text-blueGray-500 py-1 px-3"
                                      href="#"
                                      id={`button${row.id}`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.checkPopover(row.id)
                                      }}
                                    >
                                      <i className="fas fa-ellipsis-v"></i>
                                    </div>
                                    <div
                                      id={`popup${row.id}`}
                                      className={
                                        "popover hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
                                      }
                                    >
                                      <a
                                        href={`/automate-assign-city/${row.id}`}
                                        className={
                                          "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                                        }
                                      >
                                        Detail
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))
                              :
                              <tr key="notfound" className="border border-l-0 border-r-0 border-blueGray-200">
                                <td colSpan="7" className="align-middle text-sm text-center whitespace-nowrap py-4 pr-4">
                                  Data Not Available
                                </td>
                              </tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

