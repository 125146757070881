import React from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import axios from 'axios';
import auth from 'global/auth.js';

import jsCookie from 'js-cookie';
import { DateFormat } from "global/custom.js";
import { createPopper } from "@popperjs/core";
import ClipLoader from "react-spinners/ClipLoader";

import { getBadge } from "global/data.js";
import * as moment from 'moment'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
/*eslint-disable eqeqeq*/

export default function Users() {
  var id = useParams();
  if (jsCookie.get('privilege') == 'Uploader'  ) {
    return <Redirect to="/404" />;
  }
  return (
    <>
      <UsersClass id={id.userId} />
    </>
  );
}
class UsersClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sales_manager_id: this.props.sales_manager_id ? this.props.sales_manager_id : '',
      brand_associate_id: this.props.brand_associate_id ? this.props.brand_associate_id : '',
      path: 'leads/assign/' + this.props.id,
      backPath: '/users/' + this.props.id,
      /**filter */
      nameFilter: '',
      statusFilter: '',
      toFilter: '',
      fromFilter: '',
      dateRangeValue: '',
      followupFilter: '',
      assignedFilter: '',
      enquiryFilter: '',
      showroomFilter: '',
      cityFilter: '',
      provinceFilter: '',
      carFilter: '',
      smFilter: '',
      baFilter: '',
      /**filter */
      /**icon */
      nameFilled: false,
      dateInFilled: false,
      followupFilled: false,
      assignedFilled: false,
      smFilled: false,
      baFilled: false,
      enquiryFilled: false,
      showroomFilled: false,
      cityFilled: false,
      provinceFilled: false,
      carFilled: false,
      /**icon */
      /**sort */
      nameSort: '',
      assignedSort: '',
      salesManagerSort: '',
      brandAssociateSort: '',
      statusSort: '',
      lastEnquiryTypeSort: '',
      dataInSort: 'desc',
      emailSort: '',
      phoneSort: '',
      citySort: '',
      provinceSort: '',
      showroomSort: '',
      lastEnquiryStatusSort: '',
      enquiryDateSort: '',
      /**sort */

      totalData: 0,
      currentPage: '1',
      perPage: '10',
      pagingPrevTemplate: '',
      pagingTemplate: '',
      pagingNextTemplate: '',
      page: '',
      customersData: [],
      totalPaging: '',
      statusOption: [
        { value: '', text: 'All' },
        { value: 'customer', text: 'Customer' },
        { value: 'leads', text: 'Leads' },
      ],
      followupOption: [
        { value: '', text: 'All' },
        { value: 'new_enquiry', text: 'New Enquiry' },
        { value: 'not_assigned', text: 'Not Assigned' },
        { value: 'no', text: 'Not Followed Up' },
        { value: 'follow_up', text: 'Followed Up' },
        { value: "cant_reach", text: "Can't Reach" },
        { value: 'spk', text: 'SPK' },
      ],
      assignedOption: [
        { value: '', text: 'All' },
        { value: 'yes', text: 'Assigned' },
        { value: 'no', text: 'Not Assigned' },
      ],
      sourceOption: [],
      showroomOption: [],
      smOptionBckup: [],
      smOption: [],
      baOptionBckup: [],
      baOption: [],
      enquirySubjectOption: [],
      cityOption: [],
      provinceOption: [],
      carOption: [],
      selectionRange: [{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }],
      showFilter: false,
      showMoreFilter: true,
      filterOffsetHeight: 0,
      checkBoxId: [],
      showModal: false,
      salesManagerList: [],
      brandAssociateList: [],
      isSubmitted: false,
      showWaModal: false,
      showDetail: false,
      isLoading: true,
      urlStatus: true,
    };

    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkPopoverFilter = this.checkPopoverFilter.bind(this);
    this.checkPopover = this.checkPopover.bind(this);
    this.handleResetFilter = this.handleResetFilter.bind(this);

    /*paging*/
    this.handlePageChange = this.handlePageChange.bind(this);
    /*paging*/

    this.handleResetDatePicker = this.handleResetDatePicker.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.resetField = this.resetField.bind(this);

    /**checkbox */
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.handleCheckAllChange = this.handleCheckAllChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleAssignMultiple = this.handleAssignMultiple.bind(this);
    /**checkbox */

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleAssignChange = this.handleAssignChange.bind(this);
    this.handleShowWaModal = this.handleShowWaModal.bind(this);

    this.handleChangeField = this.handleChangeField.bind(this);

    this.handleEventDatePicker = this.handleEventDatePicker.bind(this);

  }

  getUserDetailData() {
    axios.get(`${process.env.REACT_APP_API_URL}user/${this.props.id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.setState({
          urlStatus: false,
        });
        throw error;
      })
      .then(res => {
        if (res) {
          this.setState({
            user_id: res.data[0].id,
            user_privilege: res.data[0].privilege,
            user_name: res.data[0].name,
            user_phone: res.data[0].phone,
            user_sales_manager_id: res.data[0].manager_id,
            userData: res.data
          }, () => {
            this.getNewestEnquiryDateCustomerData();
            this.getParam();
            this.getDropdownShowroom();
          });
        } else {
          this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
          this.setState({ isLoading: false });
        }
      })
  }

  getDropdownShowroom() {
    var url = `${process.env.REACT_APP_API_URL}showroom`;
    if (jsCookie.get('showroom_id') !== 'null') {
      url = `${process.env.REACT_APP_API_URL}showroom/${jsCookie.get('showroom_id')}`;
    }
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({ 
          showroomOption: data 
        }, () => {
          this.getDropdownChannel();
        });

      })
  }

  getDropdownChannel() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/channel`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          sourceOption: res.data,
        }, () => {
          this.getDropdownSm();
        });
      })
  }

  getDropdownSm() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/sm?status=active`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let bckupData = res.data;
        let data = bckupData;
        if (this.state.showroomFilter && this.state.showroomFilter != 'all') {
          data = data.filter(elem => {
            return elem.showroom_id == this.state.showroomFilter
          })
        }
        this.setState({
          smOption: data,
          smOptionBckup: bckupData
        }, () => {
          this.getDropdownBa();
        });
      })
  }

  getDropdownBa() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/ba?status=active`;
    if (jsCookie.get('privilege') == 'Sales Manager') {
      url += '&sales_manager_id='+jsCookie.get('uid');
    }
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let bckupData = res.data;
        let data = bckupData;
        if (this.state.smFilter) {
          data = data.filter(elem => {
            return elem.manager_name == this.state.smFilter
          })
        }
        this.setState({
          baOption: data,
          baOptionBckup: bckupData,
        }, () => {
          this.getDropdownEnquirySubject();
        });
      })
  }

  getDropdownEnquirySubject() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/enquiry_subject`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          enquirySubjectOption: data,
        }, () => {
          this.getDropdownModel();
        });
      })
  }

  /*getDropdownCity() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/city`;
    let filter = [];
    axios.post(url, filter, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          cityOption: data,
        }, () => {
          this.getDropdownModel();
        });
      })
  }*/

  getDropdownModel() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/car-model-input`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          carOption: data,
        }, () => {
          this.getDropdownProvince();
        });
      })
  }

  getDropdownProvince() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/province`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          provinceOption: data,
        }, () => {
        });
      })
  }

  handleResize() {
    var filter = document.getElementsByClassName('filter')
    if (this.state.filterOffsetHeight > 100) {
      if (filter[0]) {
        if (this.state.showFilter === true) {
          filter[0].style.height = (this.state.filterOffsetHeight) + 'px'
        } else {
          if (filter[0].style) {
            filter[0].style.height = '90px'
          }
        }
      }
      this.setState({ showMoreFilter: true });
    }
    else {
      this.setState({ showMoreFilter: false });
    }
  }

  getNewestEnquiryDateCustomerData() {
    var getParam = [];
    getParam.push('currentPage=1');
    getParam.push('perPage=1');
    getParam.push('sortBy=last_enquiry_created_date-desc');
    /**sort */
    var url = `${process.env.REACT_APP_API_URL}customers?${getParam.join('&')}`
    if (jsCookie.get('privilege') === 'Sales Manager') {
      url = `${process.env.REACT_APP_API_URL}customers?sales_manager_id=${jsCookie.get('uid')}&${getParam.join('&')}`
    } else if (jsCookie.get('privilege') === 'Brand Associate') {
      url = `${process.env.REACT_APP_API_URL}customers?brand_associate_id=${jsCookie.get('uid')}&${getParam.join('&')}`
    }
    axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let newest_enquiry_date = '';
        if (res.data.total_data > 0) {
          newest_enquiry_date = res.data.customers_data[0].last_enquiry_created_date
        }
        this.setState({
          newest_enquiry_date: newest_enquiry_date
        });
      })
  }

  getCustomerData() {
    var getParam = [];
    this.setState({ isLoading: true });
    if (this.state.user_privilege == 'Sales Manager') {
      getParam.push('not_sales_manager_id=' + this.state.user_id);
    }
    if (this.state.user_privilege == 'Brand Associate') {
      getParam.push('not_brand_associate_id=' + this.state.user_id);
    }
    if (this.state.sales_manager_id !== '') {
      getParam.push('sales_manager_id=' + this.state.sales_manager_id);
    }
    if (this.state.brand_associate_id !== '') {
      getParam.push('brand_associate_id=' + this.state.brand_associate_id);
    }
    if (this.state.nameFilter !== '') {
      getParam.push('name=' + this.state.nameFilter);
    }
    if (this.state.statusFilter !== '') {
      getParam.push('status=' + this.state.statusFilter);
    }
    if (this.state.fromFilter) {
      getParam.push('start_date=' + this.state.fromFilter);
    }
    if (this.state.toFilter) {
      getParam.push('end_date=' + this.state.toFilter);
    }
    if (this.state.followupFilter && this.state.followupFilter != 'all') {
      getParam.push('followup=' + this.state.followupFilter);
    }
    if (this.state.assignedFilter && this.state.assignedFilter != 'all') {
      getParam.push('assigned=' + this.state.assignedFilter);
    }
    if (this.state.smFilter) {
      getParam.push('sm=' + this.state.smFilter);
    }
    if (this.state.baFilter) {
      getParam.push('ba=' + this.state.baFilter);
    }
    if (this.state.enquiryFilter) {
      getParam.push('enquiry=' + this.state.enquiryFilter);
    }
    if (this.state.showroomFilter && this.state.showroomFilter != 'all') {
      getParam.push('showroom=' + this.state.showroomFilter);
    }
    if (this.state.cityFilter) {
      getParam.push('city=' + this.state.cityFilter);
    }
    if (this.state.provinceFilter) {
      getParam.push('province=' + this.state.provinceFilter);
    }
    if (this.state.carFilter) {
      getParam.push('preferred_model=' + this.state.carFilter);
    }
    if (this.state.sourceFilter) {
      getParam.push('source=' + this.state.sourceFilter);
    }

    if (this.state.currentPage) {
      getParam.push('currentPage=' + this.state.currentPage);
    }
    if (this.state.filterByName) {
      getParam.push('name=' + this.state.filterByName);
    }

    getParam.push('perPage=' + this.state.perPage);
    /**sort */
    if (this.state.nameSort !== '') {
      getParam.push('sortBy=name-' + this.state.nameSort);
    } else if (this.state.salesManagerSort !== '') {
      getParam.push('sortBy=sales_manager_name-' + this.state.salesManagerSort);
    } else if (this.state.brandAssociateSort !== '') {
      getParam.push('sortBy=brand_associate_name-' + this.state.brandAssociateSort);
    } else if (this.state.statusSort !== '') {
      getParam.push('sortBy=spk_status-' + this.state.statusSort);
    } else if (this.state.lastEnquiryTypeSort !== '') {
      getParam.push('sortBy=last_enquiry-' + this.state.lastEnquiryTypeSort);
    } else if (this.state.lastEnquiryStatusSort !== '') {
      getParam.push('sortBy=followup_status-' + this.state.lastEnquiryStatusSort);
    } else if (this.state.emailSort !== '') {
      getParam.push('sortBy=email-' + this.state.emailSort);
    } else if (this.state.phoneSort !== '') {
      getParam.push('sortBy=phone-' + this.state.phoneSort);
    } else if (this.state.citySort !== '') {
      getParam.push('sortBy=city-' + this.state.citySort);
    } else if (this.state.provinceSort !== '') {
      getParam.push('sortBy=province-' + this.state.provinceSort);
    } else if (this.state.showroomSort !== '') {
      getParam.push('sortBy=showroom_name-' + this.state.showroomSort);
    } else if (this.state.dataInSort !== '') {
      getParam.push('sortBy=last_enquiry_created_date-' + this.state.dataInSort);
    } else if (this.state.enquiryDateSort !== '') {
      getParam.push('sortBy=last_enquiry_date-' + this.state.enquiryDateSort);
    }
    /**sort */
    var url = `${process.env.REACT_APP_API_URL}customers?${getParam.join('&')}`
    if (this.state.user_privilege === 'Brand Associate') {
      url = `${process.env.REACT_APP_API_URL}customers?sales_manager_id=${this.state.user_sales_manager_id}&${getParam.join('&')}`;
    }
    axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
        this.setState({ isLoading: false });
        throw error;
      })
      .then(res => {
        if (res.data !== 'Data Empty' && res) {
          let data = res.data.customers_data;
          let totalData = res.data.total_data;
          var created_date = this.state.newest_enquiry_date;

          const checkBoxId = this.state.checkBoxId;
          if (typeof data != 'undefined') {
            data.forEach((rows, keys) => {
              data[keys].followup_status = rows.followup_status == 'Not Reach' ? "Can't Reach" : (rows.followup_status == '' ? 'Not Followed Up' : rows.followup_status)
              checkBoxId.forEach(function (value, index) {
                if (value == rows.id) {
                  data[keys].checked = 'checked';
                }
              });

              var background = '';
              if (moment(created_date).format('YYYY-MM-DD') == moment(rows.last_enquiry_created_date).format('YYYY-MM-DD') && rows.sales_manager_assigned === 'No' && rows.brand_associate_assigned === 'No') {
                background = 'bg-table-red';
              }
              else {
                if (rows.sales_manager_assigned === 'No' || rows.brand_associate_assigned === 'No') {
                  background = 'bg-table-yellow';
                }
              }
              if (rows.followup_status === 'Follow Up') {
                background = 'bg-table-blue';
              }

              if (rows.followup_status === 'Not Followed Up' && rows.sales_manager_assigned === 'Yes' && rows.brand_associate_assigned === 'Yes') {
                background = 'bg-table-purple';
              }
              if (rows.followup_status === 'SPK') {
                background = 'bg-table-green';
              }
              if (rows.followup_status === "Can't Reach") {
                background = 'bg-table-gray';
              }
              if (rows.last_enquiry_date === '--') {
                background = '';
              }
              data[keys].background = background;
            })
          }
          this.setState({
            totalData: totalData,
            customersData: data ? Array.from(data) : [],
            isLoading: false,
          }, () => {
            this.handleCheckAllChange();
            this.handlePaging();
          });
        } else {
          this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
          this.setState({ isLoading: false });
        }
      })
  }

  getParam() {
    var checkUrlPath = window.location.pathname;
    var myarr = checkUrlPath.split("/");
    let nameFilter = '';
    let statusFilter = '';
    let fromFilter = '';
    let toFilter = '';
    let dateRangeValue = '';
    let followupFilter = '';
    let assignedFilter = '';
    let smFilter = '';
    let baFilter = '';
    let enquiryFilter = '';
    let showroomFilter = '';
    let cityFilter = '';
    let provinceFilter = '';
    let carFilter = '';
    let sourceFilter = '';
    let currentPage = '1';
    if (myarr[4] !== 'null' && typeof myarr[4] !== 'undefined') {
      nameFilter = decodeURI(myarr[4]);
    }
    if (myarr[5] !== 'null' && typeof myarr[5] !== 'undefined') {
      statusFilter = myarr[5];
    }
    if (myarr[6] !== 'null' && typeof myarr[6] !== 'undefined') {
      fromFilter = myarr[6];
      dateRangeValue = moment(myarr[6]).format('MM/DD/YYYY') + ' to dd/mm/yyyy';
    }
    if (myarr[7] !== 'null' && typeof myarr[7] !== 'undefined') {
      toFilter = myarr[7];
      dateRangeValue = moment(myarr[6]).format('MM/DD/YYYY') + ' - ' + moment(myarr[7]).format('MM/DD/YYYY');
    }
    if (myarr[8] !== 'null' && typeof myarr[8] !== 'undefined') {
      followupFilter = myarr[8];
    }
    if (myarr[9] !== 'null' && typeof myarr[9] !== 'undefined') {
      assignedFilter = myarr[9];
    }
    if (myarr[10] !== 'null' && typeof myarr[10] !== 'undefined') {
      smFilter = decodeURI(myarr[10]);
    }
    if (myarr[11] !== 'null' && typeof myarr[11] !== 'undefined') {
      baFilter = decodeURI(myarr[11]);
    }
    if (myarr[12] !== 'null' && typeof myarr[12] !== 'undefined') {
      enquiryFilter = decodeURI(myarr[12]);
    }
    if (myarr[13] !== 'null' && typeof myarr[13] !== 'undefined') {
      showroomFilter = decodeURI(myarr[13]);
    }
    if (myarr[14] !== 'null' && typeof myarr[14] !== 'undefined') {
      cityFilter = decodeURI(myarr[14]);
    }
    if (myarr[15] !== 'null' && typeof myarr[15] !== 'undefined') {
      provinceFilter = decodeURI(myarr[15]);
    }
    if (myarr[16] !== 'null' && typeof myarr[16] !== 'undefined') {
      carFilter = decodeURI(myarr[16]);
    }
    if (myarr[17] !== 'null' && typeof myarr[17] !== 'undefined') {
      sourceFilter = decodeURI(myarr[17]);
    }
    if (myarr[18] !== 'null' && typeof myarr[18] !== 'undefined') {
      currentPage = myarr[18];
    }

    this.setState({
      nameFilter: nameFilter,
      statusFilter: statusFilter,
      fromFilter: fromFilter,
      toFilter: toFilter,
      dateRangeValue: dateRangeValue,
      followupFilter: followupFilter,
      assignedFilter: assignedFilter,
      smFilter: smFilter,
      baFilter: baFilter,
      enquiryFilter: enquiryFilter,
      showroomFilter: showroomFilter,
      cityFilter: cityFilter,
      provinceFilter: provinceFilter,
      carFilter: carFilter,
      sourceFilter: sourceFilter,
      currentPage: currentPage
    }, () => {
      this.getCustomerData();
    });
  }

  componentDidMount() {
    if (!auth()) {
      return false;
    }
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('load', this.handleResize);
    this.getUserDetailData();
    if (document.readyState === 'complete') {
      this.handleResize()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.isFilter !== 'no') {
      const height = this.divElement.offsetHeight;
      if (height !== prevState.filterOffsetHeight) {
        if (height > 100) {
          this.setState({ showMoreFilter: true });
        } else {
          this.setState({ showMoreFilter: false });
        }
        this.setState({ filterOffsetHeight: height });
      }
    }
    if (this.state.reloadPage !== prevState.reloadPage) {
      this.setState({ reloadPage: false });
      this.getCustomerData();
    }
    if (this.state.newest_enquiry_date !== prevState.newest_enquiry_date) {
      this.getParam();
    }
  }

  handlePaging() {

    let currentPage = this.state.currentPage;
    let perpage = this.state.perPage;
    let totalData = this.state.totalData;
    let totalPaging = Math.ceil(totalData / perpage);

    const start = (Number(currentPage) - 1) * perpage;
    const end = Number(start) + Number(perpage);

    let startLoop = 1;
    let endLoop = totalPaging;
    if (Number(currentPage) - 2 > 0 && Number(currentPage) + 2 <= totalPaging) {
      startLoop = Number(currentPage) - 2;
      endLoop = Number(currentPage) + 2;
    } else if (Number(currentPage) - 1 > 0 && Number(currentPage) + 3 <= totalPaging) {
      startLoop = Number(currentPage) - 1;
      endLoop = Number(currentPage) + 3;
    } else if (Number(currentPage) - 3 > 0 && Number(currentPage) + 1 <= totalPaging) {
      startLoop = Number(currentPage) - 3;
      endLoop = Number(currentPage) + 1;
    } else if (currentPage == 1 && Number(currentPage) + 4 <= totalPaging) {
      startLoop = Number(currentPage);
      endLoop = Number(currentPage) + 4;
    } else if (currentPage == totalPaging && Number(currentPage) - 4 > 0) {
      startLoop = Number(currentPage) - 4;
      endLoop = Number(currentPage);
    }

    let pagingPrevTemplate = [];
    let pagingTemplate = [];
    let pagingNextTemplate = [];
    if (Number(currentPage) > 1) {
      pagingPrevTemplate.push(
        <>
          <li key="tofirst">
            <button onClick={e => this.handlePageChange(1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
          <li key="toprev">
            <button onClick={e => this.handlePageChange(currentPage - 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-left -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    for (let i = startLoop; i <= endLoop; i++) {
      if (i == currentPage) {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 text-white bg-black">
              {i}
            </button>
          </li>
        )
      } else {
        pagingTemplate.push(
          <li key={i.toString()}>
            <button name={i} onClick={() => this.handlePageChange(i)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              {i}
            </button>
          </li>
        )
      }
    }

    if (currentPage < totalPaging && totalPaging > 1) {
      pagingNextTemplate.push(
        <>
          <li key={`${Number(totalPaging) + 3}`}>
            <button onClick={() => this.handlePageChange(Number(currentPage) + 1)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
          <li key={`${Number(totalPaging) + 4}`}>
            <button onClick={() => this.handlePageChange(totalPaging)} className="nofocus first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-black-500">
              <i className="fas fa-chevron-right -ml-px"></i>
              <i className="fas fa-chevron-right -ml-px"></i>
            </button>
          </li>
        </>
      )
    }
    if (currentPage > 1) {
      if (currentPage > totalPaging) {
        this.setState({
          currentPage: 1,
        });
      }
    }

    this.setState({
      pagingPrevTemplate: pagingPrevTemplate,
      pagingTemplate: pagingTemplate,
      pagingNextTemplate: pagingNextTemplate,
      startShowing: this.state.customersData.length > 0 ? start + 1 : 0,
      endShowing: start + 1 < totalData ? (end < totalData ? end : totalData) : '',
      totalData: totalData,
    });
  }

  handlePageChange(number) {
    const name = this.state.nameFilter;
    const status = this.state.statusFilter;
    const followup = this.state.followupFilter;
    const assigned = this.state.assignedFilter;
    const sm = this.state.smFilter;
    const ba = this.state.baFilter;
    const enquiry = this.state.enquiryFilter;
    const showroom = this.state.showroomFilter;
    const city = this.state.cityFilter;
    const province = this.state.provinceFilter;
    const car = this.state.carFilter;
    const dateStart = this.state.fromFilter;
    const dateEnd = this.state.toFilter;
    const source = this.state.sourceFilter;

    var pushUrl = '/' + this.state.path;
    if (name || status || followup || assigned || sm || ba || enquiry || showroom || city || province || car || dateStart || source || number > 1) {
      pushUrl = '/' + this.state.path +
        '/' + (name ? name.toLowerCase() : 'null') + //0
        '/' + (status ? status.toLowerCase() : 'null') +  //1
        '/' + (dateStart ? dateStart : 'null') + //2
        '/' + (dateEnd ? dateEnd : 'null') + //3
        '/' + (followup ? followup.toLowerCase() : 'all') + //4
        '/' + (assigned ? assigned : 'all') + //5
        '/' + (sm ? sm.toLowerCase() : 'null') + //6
        '/' + (ba ? ba.toLowerCase() : 'null') + //7
        '/' + (enquiry ? enquiry.toLowerCase() : 'null') + //8
        '/' + (showroom ? showroom.toLowerCase() : 'all') + //9
        '/' + (city ? city.toLowerCase() : 'null') + //10
        '/' + (province ? province.toLowerCase() : 'null') + //11
        '/' + (car ? car.toLowerCase() : 'null') + //12
        '/' + (source ? source : 'null') + //13
        '/' + number //14
    }
    window.history.pushState('page2', 'Title', pushUrl);
    this.setState({ currentPage: number }, () => {
      this.getCustomerData();
    });
  }

  checkPopoverFilter() {
    this.setState({
      showFilter: !this.state.showFilter
    }, () => {
      this.handleResize()
    })
  };

  checkPopover(id) {
    const button_id = 'button' + id;
    const popup_id = 'popup' + id;
    const button = document.querySelector('#' + button_id);
    const popup = document.querySelector('#' + popup_id);

    if (document.getElementById(popup_id).classList.contains('hidden')) {
      createPopper(button, popup, {
        placement: "left-start",
      });

      var inputs = document.querySelectorAll(".popover");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].classList.remove('block');
        inputs[i].classList.add('hidden');
      }

      popup.classList.remove('hidden');
      popup.classList.add('block');
    } else {
      popup.classList.remove('block');
      popup.classList.add('hidden');
    }
  };

  resetField(event) {
    const name = event === 'name' ? '' : this.state.nameFilter;
    const status = event === 'status' ? '' : this.state.statusFilter;
    const followup = event === 'followup' ? '' : this.state.followupFilter;
    const assigned = event === 'assigned' ? '' : this.state.assignedFilter;
    const sm = event === 'sm' ? '' : this.state.smFilter;
    const ba = event === 'ba' ? '' : this.state.baFilter;
    const enquiry = event === 'enquiry' ? '' : this.state.enquiryFilter;
    const showroom = event === 'showroom' ? '' : this.state.showroomFilter;
    const city = event === 'city' ? '' : this.state.cityFilter;
    const car = event === 'car' ? '' : this.state.carFilter;
    const source = event === 'source' ? '' : this.state.sourceFilter;
    const dateStart = this.state.fromFilter;
    const dateEnd = this.state.toFilter;
    const number = 1;
    var pushUrl = '/' + this.state.path
    if (name || status || followup || assigned || sm || ba || enquiry || showroom || city || car || dateStart || source || number > 1) {
      pushUrl = '/' + this.state.path + '/' + (name ? name.toLowerCase() : 'null') + '/' + (status ? status.toLowerCase() : 'null') + '/' + (dateStart ? dateStart : 'null') + '/' + (dateEnd ? dateEnd : 'null') + '/' + (followup ? followup.toLowerCase() : 'all') + '/' + (assigned ? assigned : 'all') + '/' + (sm ? sm.toLowerCase() : 'null') + '/' + (ba ? ba.toLowerCase() : 'null') + '/' + (enquiry ? enquiry.toLowerCase() : 'null') + '/' + (showroom ? showroom.toLowerCase() : 'all') + '/' + (city ? city.toLowerCase() : 'null') + '/' + (car ? car.toLowerCase() : 'null') + '/' + (source ? source : 'null') + '/' + number
    }
    window.history.pushState('page2', 'Title', pushUrl);

    this.setState({
      nameFilter: name,
      statusFilter: status,
      followupFilter: followup,
      assignedFilter: assigned,
      smFilter: sm,
      baFilter: ba,
      enquiryFilter: enquiry,
      showroomFilter: showroom,
      cityFilter: city,
      carFilter: car,
      sourceFilter: source,
      currentPage: number,

      nameFilled: name ? true : false,
      statusFilled: status ? true : false,
      followupFilled: followup ? true : false,
      assignedFilled: assigned ? true : false,
      smFilled: sm ? true : false,
      baFilled: ba ? true : false,
      enquiryFilled: enquiry ? true : false,
      showroomFilled: showroom ? true : false,
      cityFilled: city ? true : false,
      carFilled: car ? true : false,
      sourceFilled: source ? true : false,
      dateInFilled: dateStart && dateEnd ? true : false,
    }, () => {
      this.getCustomerData();
    });
  }

  handleResetFilter(event) {
    window.history.pushState('page2', 'Title', '/' + this.state.path);
    this.setState({
      nameFilter: '',
      statusFilter: '',
      followupFilter: '',
      fromFilter: '',
      toFilter: '',
      assignedFilter: '',
      smFilter: '',
      baFilter: '',
      enquiryFilter: '',
      sourceFilter: '',
      showroomFilter: '',
      cityFilter: '',
      provinceFilter: '',
      carFilter: '',
      currentPage: 1,
      dateStart: '',
      dateEnd: '',
      dateRangeValue: '',
      range: '',
      nameFilled: false,
      dateInFilled: false,
      followupFilled: false,
      assignedFilled: false,
      smFilled: false,
      baFilled: false,
      enquiryFilled: false,
      showroomFilled: false,
      cityFilled: false,
      provinceFilled: false,
      carFilled: false,
      pushUrl: '/leads',
    }, () => {
      this.getCustomerData();
    });
  }

  handleChange(event) {
    const target = event.target;
    const name = target.id === 'name' ? target.value : this.state.nameFilter;
    const status = target.id === 'status' ? target.value : this.state.statusFilter;
    const followup = target.id === 'followup' ? target.value : this.state.followupFilter;
    const assigned = target.id === 'assigned' ? target.value : this.state.assignedFilter;
    let sm = target.id === 'sm' ? target.value : this.state.smFilter;
    let ba = target.id === 'ba' ? target.value : this.state.baFilter;
    const enquiry = target.id === 'enquiry' ? target.value : this.state.enquiryFilter;
    const showroom = target.id === 'showroom' ? target.value : this.state.showroomFilter;
    const city = target.id === 'city' ? target.value : this.state.cityFilter;
    const province = target.id === 'province' ? target.value :this.state.provinceFilter;
    const car = target.id === 'car' ? target.value : this.state.carFilter;
    const source = target.id === 'source' ? target.value : this.state.sourceFilter;
    const dateStart = this.state.fromFilter;
    const dateEnd = this.state.toFilter;
    const number = 1;
    
    if (target.id === 'showroom') {
      let dataSm = this.state.smOptionBckup;
      let dataBa = this.state.baOptionBckup;
      if (showroom && showroom != 'all') {
        dataSm = dataSm.filter(elem => {
          return elem.showroom_id == showroom
        })
        dataBa = dataBa.filter(elem => {
          return elem.showroom_id == showroom
        })
        sm = ''
        ba = ''
      }
      this.setState({
        smOption: dataSm,
        baOption: dataBa,
      })
    }

    if (target.id === 'sm') {
      let data = this.state.baOptionBckup;
      if (sm) {
        data = data.filter(elem => {
          return elem.manager_name == sm
        })
        ba = '';
      }
      this.setState({
        baOption: data,
      })
    }

    var pushUrl = '/' + this.state.path
    if (name || status || followup || assigned || sm || ba || enquiry || showroom || city || province || car || dateStart || source || number > 1) {
      pushUrl = '/' + this.state.path +
        '/' + (name ? name.toLowerCase() : 'null') + //0
        '/' + (status ? status.toLowerCase() : 'null') +  //1
        '/' + (dateStart ? dateStart : 'null') + //2
        '/' + (dateEnd ? dateEnd : 'null') + //3
        '/' + (followup ? followup.toLowerCase() : 'all') + //4
        '/' + (assigned ? assigned : 'all') + //5
        '/' + (sm ? sm.toLowerCase() : 'null') + //6
        '/' + (ba ? ba.toLowerCase() : 'null') + //7
        '/' + (enquiry ? enquiry.toLowerCase() : 'null') + //8
        '/' + (showroom ? showroom.toLowerCase() : 'all') + //9
        '/' + (city ? city.toLowerCase() : 'null') + //10
        '/' + (province ? province.toLowerCase() : 'null') + //11
        '/' + (car ? car.toLowerCase() : 'null') + //12
        '/' + (source ? source : 'null') + //13
        '/' + number //14
    }
    window.history.pushState('page2', 'Title', pushUrl);

    this.setState({
      nameFilter: name,
      statusFilter: status,
      followupFilter: followup,
      assignedFilter: assigned,
      smFilter: sm,
      baFilter: ba,
      enquiryFilter: enquiry,
      showroomFilter: showroom,
      cityFilter: city,
      provinceFilter: province,
      carFilter: car,
      sourceFilter: source,
      currentPage: number,

      nameFilled: name ? true : false,
      followupFilled: followup ? true : false,
      assignedFilled: assigned ? true : false,
      smFilled: sm ? true : false,
      baFilled: ba ? true : false,
      enquiryFilled: enquiry ? true : false,
      showroomFilled: showroom ? true : false,
      cityFilled: city ? true : false,
      provinceFilled: province ? true : false,
      carFilled: car ? true : false,
      sourceFilled: source ? true : false,
      dateInFilled: dateStart && dateEnd ? true : false,
    }, () => {
      this.getCustomerData();
    });
  }

  handleSort(event) {
    let nameSort = '';
    let salesManagerSort = '';
    let brandAssociateSort = '';
    let statusSort = '';
    let lastEnquiryTypeSort = '';
    let lastEnquiryStatusSort = '';
    let dataInSort = '';
    let emailSort = '';
    let phoneSort = '';
    let citySort = '';
    let provinceSort = '';
    let enquiryDateSort = '';
    let showroomSort = '';

    if (event === 'name') {
      if (this.state.nameSort === '' || this.state.nameSort === 'desc') {
        nameSort = 'asc';
      } else {
        nameSort = 'desc';
      }
    }

    if (event === 'salesManager') {
      if (this.state.salesManagerSort === '' || this.state.salesManagerSort === 'desc') {
        salesManagerSort = 'asc';
      } else {
        salesManagerSort = 'desc';
      }
    }

    if (event === 'brandAssociate') {
      if (this.state.brandAssociateSort === '' || this.state.brandAssociateSort === 'desc') {
        brandAssociateSort = 'asc';
      } else {
        brandAssociateSort = 'desc';
      }
    }
    if (event === 'status') {
      if (this.state.statusSort === '' || this.state.statusSort === 'desc') {
        statusSort = 'asc';
      } else {
        statusSort = 'desc';
      }
    }

    if (event === 'last_enquiry_type') {
      if (this.state.lastEnquiryTypeSort === '' || this.state.lastEnquiryTypeSort === 'desc') {
        lastEnquiryTypeSort = 'asc';
      } else {
        lastEnquiryTypeSort = 'desc';
      }
    }

    if (event === 'last_enquiry_status') {
      if (this.state.lastEnquiryStatusSort === '' || this.state.lastEnquiryStatusSort === 'desc') {
        lastEnquiryStatusSort = 'asc';
      } else {
        lastEnquiryStatusSort = 'desc';
      }
    }

    if (event === 'enquiry_date') {
      if (this.state.enquiryDateSort === '' || this.state.enquiryDateSort === 'desc') {
        enquiryDateSort = 'asc';
      } else {
        enquiryDateSort = 'desc';
      }
    }
    if (event === 'data_in') {
      if (this.state.dataInSort === '' || this.state.dataInSort === 'desc') {
        dataInSort = 'asc';
      } else {
        dataInSort = 'desc';
      }
    }

    if (event === 'email') {
      if (this.state.emailSort === '' || this.state.emailSort === 'desc') {
        emailSort = 'asc';
      } else {
        emailSort = 'desc';
      }
    }
    if (event === 'phone') {
      if (this.state.phoneSort === '' || this.state.phoneSort === 'desc') {
        phoneSort = 'asc';
      } else {
        phoneSort = 'desc';
      }
    }
    if (event === 'city') {
      if (this.state.citySort === '' || this.state.citySort === 'desc') {
        citySort = 'asc';
      } else {
        citySort = 'desc';
      }
    }
    if (event === 'province') {
      if (this.state.provinceSort === '' || this.state.provinceSort === 'desc') {
        provinceSort = 'asc';
      } else {
        provinceSort = 'desc';
      }
    }
    if (event === 'showroom') {
      if (this.state.showroomSort === '' || this.state.showroomSort === 'desc') {
        showroomSort = 'asc';
      } else {
        showroomSort = 'desc';
      }
    }

    this.setState({
      nameSort: nameSort,
      salesManagerSort: salesManagerSort,
      brandAssociateSort: brandAssociateSort,
      statusSort: statusSort,
      lastEnquiryTypeSort: lastEnquiryTypeSort,
      lastEnquiryStatusSort: lastEnquiryStatusSort,
      enquiryDateSort: enquiryDateSort,
      dataInSort: dataInSort,
      emailSort: emailSort,
      phoneSort: phoneSort,
      citySort: citySort,
      provinceSort: provinceSort,
      showroomSort: showroomSort

    }, () => {
      this.getCustomerData();
    });
  }

  handleResetDatePicker() {
    this.setState({
      fromFilter: '',
      toFilter: '',
      dateRangeValue: '',
      range: '',
    }, () => {
      this.handleChange({ target: 'date' });
    });
  }

  handleCheckAll(event) {
    let checkBoxId = this.state.checkBoxId;
    var inputs = '';
    if (document.getElementById('checkAll').checked) {
      let inputs = document.querySelectorAll("input[type='checkbox']");
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value !== 'on') {
          checkBoxId.push(inputs[i].value);
        }
        inputs[i].checked = true;
      }
    } else {
      inputs = document.querySelectorAll("input[type='checkbox']");
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
        checkBoxId = checkBoxId.filter(function (value, key) {
          return value != inputs[i].value
        });
      }
    }
    this.setState({
      checkBoxId: Array.from(checkBoxId),
    });

  }

  handleCheckAllChange(event) {
    var inputs = '';
    var i = 0;
    inputs = document.querySelectorAll("input[type='checkbox']");
    for (i = 0; i < inputs.length; i++) {
      if (inputs[i].getAttribute('data-checked') == 'checked') {
        inputs[i].checked = true;
      } else {
        inputs[i].checked = false;
      }
    }
  }

  handleCheckBoxChange(event) {
    const target = event.target;

    var checkBoxId = this.state.checkBoxId;
    if (document.getElementById(target.id).checked) {
      checkBoxId.push(target.value);
    } else {
      document.getElementById('checkAll').checked = false;
      checkBoxId = checkBoxId.filter(function (value, index, arr) {
        return value !== target.value;
      });
    }
    this.setState({ checkBoxId: Array.from(checkBoxId) });
  }

  handleAssignMultiple(event) {
    if (this.state.checkBoxId.length > 0) {
      var inputData = [];
      var modifier = jsCookie.get('uid');
      if (this.state.user_privilege === 'Sales Manager') {
        this.state.checkBoxId.forEach((row) => {
          var tmpInputData = {
            id: row,
            sales_manager_id: this.state.user_id,
            modified_by: modifier
          }
          inputData.push(tmpInputData);
        });
      } else if (this.state.user_privilege === 'Brand Associate') {
        this.state.checkBoxId.forEach((row) => {
          var tmpInputData = {
            id: row,
            sales_manager_id: this.state.user_sales_manager_id,
            brand_associate_id: this.state.user_id,
            modified_by: modifier
          }
          inputData.push(tmpInputData);
        });
      }

      axios.post(`${process.env.REACT_APP_API_URL}customers/assign`, inputData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
          }
        })
        .catch(error => {
          throw error;
        })
        .then(res => {
          var totalCustomer = inputData.length
          this.createErrorMessage(200, inputData.length + ' Customer has been assign to ' + this.state.user_privilege + ': ' + this.state.user_name, 'success', 'successMessage');
          let customers_list = ''
          res.data.customers_list.forEach((elem, key) => {
            customers_list += (key + 1) + '. ' + elem.name + ' - +' + elem.phone + ' - ' + elem.last_enquiry + ' - ' + elem.last_enquiry_car_preference + '\n\n'
          })
          var whatsappMsg = encodeURIComponent('Hi ' + this.state.user_name + ', ' + totalCustomer + ' Customers just assigned by ' + jsCookie.get('name') + ' to you.\n\n' + customers_list + '\nPlease Check the update on Lexus CDP Dashboard \n' + process.env.REACT_APP_URL);
          this.setState({
            reloadPage: true,
            showWaModal: true,
            whatsappLink: 'https://wa.me/' + this.state.user_phone + '?text=' + whatsappMsg
          });
          this.setState({ checkBoxId: [] })
          var inputs = document.querySelectorAll("input[type='checkbox']");
          for (var i = 0; i < inputs.length; i++) {
            inputs[i].checked = false;
          }
          this.getCustomerData();
        })
    } else {
      this.createErrorMessage(404, 'Please Select at least One Customer.', 'error', 'errorMessage');

    }
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(status, name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className={'notifBar ' + (status == '404' ? 'bg-red' : 'bg-emerald-400')}>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)} />
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  handleAssignChange(event) {
    const target = event.target;
    const sales_manager = target.id === 'sales_manager' ? target.value : this.state.sales_manager_assign;
    const brand_associate = target.id === 'brand_associate' ? target.value : this.state.brand_associate_assign;

    this.setState({
      sales_manager_assign: sales_manager,
      brand_associate_assign: brand_associate,
    });
  }

  handleCloseModal() {
    this.setState({
      showModal: false
    });
  }

  handleShowWaModal(event) {
    this.setState({
      showWaModal: event,
    });
  }

  handleChangeField(event) {

    const target = event.target;
    const customers_detail = target.id === 'customers_detail' ? target.checked : this.state.showDetail;
    this.setState({
      showDetail: customers_detail,
    });
  }

  handleEventDatePicker(event, picker) {
    if (event.type === 'apply') {
      this.setState({
        dateRangeValue: picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'),
        fromFilter: picker.startDate.format('YYYY-MM-DD'),
        toFilter: picker.endDate.format('YYYY-MM-DD'),
      }, () => {
        this.handleChange({ target: 'date' });
      });
    } else if (event.type === 'hide') {
      if (this.state.fromFilter == '') {
        this.setState({
          dateRangeValue: '',
        });
      } else {
        this.setState({
          dateRangeValue: moment(this.state.fromFilter).format('MM/DD/YYYY') + ' - ' + moment(this.state.toFilter).format('MM/DD/YYYY'),
        })
      }
    }
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (!this.state.urlStatus) {
      window.location.href = '/404'
    }
    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.successMessage}
          {this.state.errorMessage}
          {this.state.smErrorMessage}
          {this.state.baErrorMessage}
          {this.state.checkBoxErrorMessage}
        </div>
        <div className="flex flex-wrap ">
          <div className="w-full text-white z-30 pb-4 pr-4">
            <Link to={this.state.backPath}
              className="text-white cursor-pointer"><i className="fa fa-angle-left"></i> Back
            </Link>
          </div>
          <div className={` w-full xl:w-12/12 z-30 mb-12 xl:mb-0 text-lg text-white pb-2`}>
            Assign Leads to {this.state.user_privilege} <span className="text-gold">{this.state.user_name} </span>
          </div>
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0">

            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="tabel-panel block w-full ">
                <div className="relative flex flex-col  min-w-0 break-words w-full pb-6 shadow-lg rounded bg-white">
                  <div className="w-full lg:w-10/12 xl:w-10/12 md:w-10/12 flex-grow flex-1">
                    <h3 className="font-semibold text-lg text-blueGray-700 w-6/12 float-left py-4 px-4">
                      Leads
                    </h3>
                    <div className="float-right m-4">
                    </div>
                  </div>
                  {this.state.isFilter != 'no' ?
                    <div className="filter grid-2 w-full h-full rounded-tl rounded-tr bg-blueGray-100 items-center px-4"
                      style={{ height: `90px` }}
                    >
                      <div className="flex flex-wrap pb-4"
                        ref={(divElement) => { this.divElement = divElement }}>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="name">Name</label>
                          <div className="relative">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Filter by Name"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.nameFilter}
                              onChange={this.handleChange}
                            />
                            <span className="h-full leading-snug font-normal absolute right-0 inline-flex items-center text-blueGray-300 text-base mr-2">
                              {!this.state.nameFilled ? <i className="fas fa-user"></i> : <i className="cursor-pointer fas fa-times" onClick={() => this.resetField('name')}></i>}
                            </span>
                          </div>
                        </div>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="status">Status</label>
                          <select
                            type="text"
                            id="status"
                            name="status"
                            className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.statusFilter}
                            onChange={this.handleChange}
                            style={{ width: `${130}px` }}
                          >
                            {this.state.statusOption.map((e, key) => {
                              return <option key={'status' + key} value={e.value} >{e.text}</option>;
                            })}
                          </select>
                        </div>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="date_in">Date In</label>
                          <div className="relative">
                            <DateRangePicker
                              startDate={this.state.fromFilter}
                              endDate={this.state.toFilter}
                              onEvent={this.handleEventDatePicker}
                            >
                              <input type="text"
                                value={this.state.dateRangeValue}
                                placeholder="dd-mm-yyyy - dd-mm-yyyy"
                                style={{ width: `${200}px` }}
                                className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              />
                            </DateRangePicker>
                            <span className={(this.state.dateRangeValue ? 'mr-2' : '') + "  h-full font-normal absolute right-0 text-center text-blueGray-300 items-center mr-3 py-2"}>
                              {this.state.dateRangeValue ? <i className="cursor-pointer fa fa-times"
                                onClick={() => this.handleResetDatePicker()}
                              ></i> :
                                <i className=" fa fa-calendar-alt"
                                ></i>
                              }

                            </span>
                          </div>
                        </div>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="followup">Last Enquiry Status</label>
                          <select
                            type="text"
                            id="followup"
                            name="followup"
                            className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.followupFilter}
                            onChange={this.handleChange}
                            style={{ width: `${130}px` }}
                          >
                            {this.state.followupOption.map((e, key) => {
                              return <option key={'fo' + key} value={e.value} >{e.text}</option>;
                            })}
                          </select>
                        </div>
                        <div className="relative my-2 mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm w-full block" htmlFor="assigned">Assignee</label>
                          <select
                            type="text"
                            id="assigned"
                            name="assigned"
                            className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.assignedFilter}
                            onChange={this.handleChange}
                            style={{ width: `${130}px` }}
                          >
                            {this.state.assignedOption.map((e, key) => {
                              return <option key={'ao' + key} value={e.value} >{e.text}</option>;
                            })}
                          </select>
                        </div>
                        {jsCookie.get('privilege') == 'Super Admin' ?
                          <div className="relative my-2 mr-4 xs:w-full">
                            <label className="text-blueGray-400 text-sm block" htmlFor="sm">Sales Manager</label>
                            <div className="relative">
                              <select
                                type="text"
                                id="sm"
                                name="sm"
                                className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={this.state.smFilter}
                                onChange={this.handleChange}
                                style={{ width: `${130}px` }}
                              >
                                <option key="uniq1" value="">All</option>
                                {this.state.smOption.map((e, key) => {
                                  return <option key={'sm' + key} value={e.value} >{e.label}</option>;
                                })}
                              </select>
                            </div>
                          </div> : ''
                        }
                        {jsCookie.get('privilege') == 'Super Admin' || jsCookie.get('privilege') == 'Sales Manager' ?
                          <div className="relative my-2 mr-4 xs:w-full">
                            <label className="text-blueGray-400 text-sm block" htmlFor="ba">Brand Associate</label>
                            <div className="relative">
                              <select
                                type="text"
                                id="ba"
                                name="ba"
                                className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={this.state.baFilter}
                                onChange={this.handleChange}
                                style={{ width: `${130}px` }}
                              >
                                <option key="uniq1" value="">All</option>
                                {this.state.baOption.map((e, key) => {
                                  return <option key={'ba' + key} value={e.value} >{e.label}</option>;
                                })}
                              </select>
                            </div>
                          </div> : ''
                        }
                        <div className="relative my-2  mr-4 xs:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="enquiry">Enquiry Subject</label>
                          <div className="relative">
                            <select
                              type="text"
                              id="enquiry"
                              name="enquiry"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.enquiryFilter}
                              onChange={this.handleChange}
                              style={{ width: `${(4 * (this.state.enquiryFilter ? this.state.enquiryFilter.length : 0)) + 130}px` }}
                            >
                              <option key="uniq1" value="">All</option>
                              {this.state.enquirySubjectOption.map((e, key) => {
                                return <option key={'enquirySubject' + key} value={e.value} >{e.label}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="relative my-2  mr-4 w-auto sm:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="showroom">Gallery</label>
                          <select
                            type="text"
                            id="showroom"
                            name="showroom"
                            className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.showroomFilter}
                            style={{ width: `${(8 * (this.state.showroomFilter ? this.state.showroomFilter.length : 0)) + 150}px` }}
                            onChange={this.handleChange}
                          >
                            <option key="uniq1" value="">All</option>
                            {this.state.showroomOption.map((e, key) => {
                              return <option key={'sw' + key} value={e.id}>{e.name}</option>;
                            })}
                          </select>
                        </div>
                        {/*<div className="relative my-2  mr-4  w-auto sm:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="city">City</label>
                          <div className="relative">
                            <select
                              type="text"
                              id="city"
                              name="city"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.cityFilter}
                              onChange={this.handleChange}
                              style={{ width: `${(4 * (this.state.cityFilter ? this.state.cityFilter.length : 0)) + 130}px` }}
                            >
                              <option key="uniq1" value="">All</option>
                              {this.state.cityOption.map((e, key) => {
                                return <option key={'city' + key} value={e.value.toLowerCase()} >{e.label}</option>;
                              })}
                            </select>
                          </div>
                        </div>*/}
                        <div className="relative my-2  mr-4  w-auto sm:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="province">Province</label>
                          <div className="relative">
                            <select
                              type="text"
                              id="province"
                              name="province"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.provinceFilter}
                              onChange={this.handleChange}
                              style={{ width: `${(4 * (this.state.provinceFilter ? this.state.provinceFilter.length : 0)) + 130}px` }}
                            >
                              <option key="uniq1" value="">All</option>
                              {this.state.provinceOption.map((e, key) => {
                                return <option key={'province' + key} value={e.value} >{e.label}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="relative my-2 mr-4 w-auto sm:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="car">Car Model</label>
                          <div className="relative">
                            <select
                              type="text"
                              id="car"
                              name="car"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.carFilter}
                              onChange={this.handleChange}
                              style={{ width: `${(4 * (this.state.carFilter ? this.state.carFilter.length : 0)) + 130}px` }}
                            >
                              <option key="uniq1" value="">All</option>
                              {this.state.carOption.map((e, key) => {
                                return <option key={'car' + key} value={e.value.toLowerCase()} >{e.label}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="relative my-2 mr-4 w-auto sm:w-full">
                          <label className="text-blueGray-400 text-sm block" htmlFor="source">Channel</label>
                          <div className="relative">
                            <select
                              type="text"
                              id="source"
                              name="source"
                              className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={this.state.sourceFilter}
                              style={{ width: `${(2 * (this.state.sourceFilter ? this.state.sourceFilter.length : 0)) + 150}px` }}
                              onChange={this.handleChange}
                            >
                              <option key="uniq1" value="">All</option>
                              {this.state.sourceOption.map((e, key) => {
                                return <option key={'sw' + key} value={e.value}>{e.label}</option>;
                              })}
                            </select>
                            <span className="h-full leading-snug font-normal absolute right-0 inline-flex items-center text-blueGray-300 text-base mr-2">
                              {!this.state.sourceFilled ? <i className="fas fa-source"></i> : <i className="cursor-pointer fas fa-times" onClick={() => this.resetField('source')}></i>}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="lg:py-4 lg:flex align-self-start pt-2 lg:pt-4">
                        {this.state.showMoreFilter ?
                          <button
                            className="nofocus rounded text-black text-sm pt-6"
                            href="#"
                            id="filterBtn"
                            onClick={(e) => {
                              e.preventDefault();
                              this.checkPopoverFilter()
                            }}
                          >
                            {!this.state.showFilter ? <>More Filter <i className="fas fa-angle-down ml-1"></i></> : <>Less Filter<i className="fas fa-angle-up ml-2"></i></>}
                          </button> : ''}
                        <button
                          className="nofocus rounded text-black text-sm pt-6 pl-4 "
                          onClick={this.handleResetFilter}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    : ''}
                  {/* Projects table */}
                  <div className={"customer-table relative overflow-x-auto bg-white "}>

                    <div className="flex bg-blueGray-100 px-4 ">
                      <div className="relative my-2 mr-4 xs:w-full">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="customers_detail"
                            name="customers_detail"
                            placeholder="Filter by Name"
                            className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                            checked={this.state.showDetail}
                            onChange={this.handleChangeField}
                          />
                          <label className="text-blueGray-400 text-sm ml-2" htmlFor="customers_detail">Show Detail</label>
                        </div>
                      </div>
                    </div>
                    <table className="items-center w-full h-auto overflow-scroll bg-transparent border-collapse">
                      <thead className="sticky-top">
                        <tr className="border border-l-0 border-r-0 border-blueGray-200">
                          <th className={"px-2 align-middle py-3 text-xs uppercase whitespace-wrap font-semibold text-left text-blueGray-500 pl-4"} style={{ width: `${30}px` }}>
                            <input
                              name="checkAll"
                              id="checkAll"
                              type="checkbox"
                              className="rounded nofocus"
                              onChange={this.handleCheckAll} />
                          </th>
                          <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500 pl-4"} onClick={() => this.handleSort('name')}>
                            Name
                            <i className={`fas ${this.state.nameSort === 'desc' ? "fa-sort-down" : (this.state.nameSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('email')}>
                              Email
                              <i className={`fas ${this.state.emailSort === 'desc' ? "fa-sort-down" : (this.state.emailSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th> : null}
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2  align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('phone')}>
                              Phone
                              <i className={`fas ${this.state.phoneSort === 'desc' ? "fa-sort-down" : (this.state.phoneSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th> : null}
                          {/*this.state.showDetail ?
                            <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('city')}>
                              City
                              <i className={`fas ${this.state.citySort === 'desc' ? "fa-sort-down" : (this.state.citySort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th> : null*/}
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('province')}>
                              Province
                              <i className={`fas ${this.state.provinceSort === 'desc' ? "fa-sort-down" : (this.state.provinceSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th> : null}
                          {this.state.showDetail ?
                            <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('showroom')}>
                              Gallery
                              <i className={`fas ${this.state.showroomSort === 'desc' ? "fa-sort-down" : (this.state.showroomSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                            </th> : null}
                          <th className={(jsCookie.get('privilege') == 'Brand Associate' || jsCookie.get('privilege') == 'Sales Manager' ? 'hidden' : null) + " minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('salesManager')}>
                            Sales Manager
                            <i className={`fas ${this.state.salesManagerSort === 'desc' ? "fa-sort-down" : (this.state.salesManagerSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={(jsCookie.get('privilege') == 'Brand Associate' ? 'hidden' : null) + " minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('brandAssociate')}>
                            Brand Associate
                            <i className={`fas ${this.state.brandAssociateSort === 'desc' ? "fa-sort-down" : (this.state.brandAssociateSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('status')}>
                            Status
                            <i className={`fas ${this.state.statusSort === 'desc' ? "fa-sort-down" : (this.state.statusSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={"minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('last_enquiry_type')}>
                            Last Enquiry Subject
                            <i className={`fas ${this.state.lastEnquiryTypeSort === 'desc' ? "fa-sort-down" : (this.state.lastEnquiryTypeSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          {this.state.showDetail ?
                            <th className={"minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('preferred_model')}>
                              Last Enquiry Preferred Model
                            </th> : null}
                          {this.state.showDetail ?
                            <th className={"minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('preferred_model')}>
                              Last Notes
                            </th> : null}
                          <th className={"minwidth150 cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('last_enquiry_status')}>
                            Last Enquiry Status
                            <i className={`fas ${this.state.lastEnquiryStatusSort === 'desc' ? "fa-sort-down" : (this.state.lastEnquiryStatusSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('enquiry_date')}>
                            Last Enquiry Date
                            <i className={`fas ${this.state.enquiryDateSort === 'desc' ? "fa-sort-down" : (this.state.enquiryDateSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={"cursor-pointer px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"} onClick={() => this.handleSort('data_in')}>
                            Data In
                            <i className={`fas ${this.state.dataInSort === 'desc' ? "fa-sort-down" : (this.state.dataInSort === 'asc' ? "fa-sort-up" : "fa-sort")} mr-2 text-sm opacity-50 px-2`}></i>
                          </th>
                          <th className={"px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500"}>
                            Channel
                          </th>
                          <th className={"px-2 align-middle py-3 text-xs uppercase  font-semibold text-left text-blueGray-500 pr-4"} style={{ width: `${20}px` }}>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.isLoading ?
                          <tr key="notfound" className="border border-l-0 border-r-0 border-blueGray-200">
                            <td colSpan="7" className="align-middle text-sm text-center whitespace-wrap py-4 pr-4">
                              <ClipLoader size={20} color="black" />
                            </td>
                          </tr> :
                          this.state.customersData.length > 0 ?
                            this.state.customersData.map((row, key) => (
                              <tr key={'cd' + key} className={(row.background) + " border border-l-0 border-r-0 border-blueGray-200"}>
                                <td className="px-2 align-middle text-sm whitespace-wrap px-4 py-4">
                                  <input
                                    name={`checked${row.id}`}
                                    id={`checked${row.id}`}
                                    type="checkbox"
                                    className="checkbox rounded nofocus"
                                    value={row.id}
                                    data-checked={row.checked}
                                    onChange={this.handleCheckBoxChange} />
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap px-4">
                                  <Link
                                    className={"text-sm py-2 font-normal block w-full whitespace-wrap bg-transparent text-blueGray-700"}
                                    to={{
                                      pathname: `/leads/${row.id}`, state: [{ backUrl: this.state.pushUrl }]
                                    }}>
                                    {row.salutation?row.salutation+'. ':' '}{row.first_name}{" "}{row.last_name}
                                  </Link>
                                </td>
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.email}
                                  </td> : null}
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.phone}
                                  </td> : null}
                                {/*this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.city?row.city:'--'}
                                </td> : null*/}
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.province?row.province:'--'}
                                  </td> : null}
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.showroom_name ? row.showroom_name : '--'}
                                  </td> : null}
                                <td className={(jsCookie.get('privilege') == 'Brand Associate' || jsCookie.get('privilege') == 'Sales Manager' ? 'hidden' : null) + " px-2 align-middle text-sm whitespace-wrap py-4"}>
                                  {row.sales_manager_name ? row.sales_manager_name : '--'}
                                </td>
                                <td className={(jsCookie.get('privilege') == 'Brand Associate' ? 'hidden' : null) + " px-2 align-middle text-sm whitespace-wrap py-4"}>
                                  {row.brand_associate_name ? row.brand_associate_name : '--'}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.spk_status > 0 ? 'Customer' : 'Leads'}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.last_enquiry ? row.last_enquiry : '--'}
                                </td>
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.last_enquiry_car_preference}
                                  </td> : null}
                                {this.state.showDetail ?
                                  <td className="px-2 align-middle text-sm py-4">
                                    {row.last_followup_notes}
                                  </td> : null}
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.customer_status}
                                  {/*row.followup_status === '' ? 'Not Followed Up' : (row.followup_status === 'Follow Up' ? 'Followed Up' : row.followup_status)*/}
                                  
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.last_enquiry_date != null && row.last_enquiry_date != '--' ? DateFormat(row.last_enquiry_date, 'Mdy') : '--'}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  {row.last_enquiry_created_date != null && row.last_enquiry_created_date != '--' ? DateFormat(row.last_enquiry_created_date, 'Mdy') : '--'}
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4">
                                  <div className="flex">
                                    {row.platform_groups.map(function (row_platform_user, index) {
                                      return (<img key={'p' + index}
                                        src={require("assets/img/logo/" + getBadge(row_platform_user.toLowerCase())).default}
                                        alt={row_platform_user}
                                        className={"w-8 h-8 rounded-full border-2 border-blueGray-50 shadow bg-white " + (index > 0 ? '-ml-3 md:-ml-3' : '')}
                                      ></img>
                                      )
                                    })}
                                  </div>
                                </td>
                                <td className="px-2 align-middle text-sm whitespace-wrap py-4 pr-4">
                                  <div
                                    className="cursor-pointer text-blueGray-500 py-1 px-3"
                                    id={`button${row.id}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.checkPopover(row.id)
                                    }}
                                  >
                                    <i className="fas fa-ellipsis-v"></i>
                                  </div>
                                  <div
                                    id={`popup${row.id}`}
                                    className={"popover hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"}
                                  >
                                    <Link
                                      className={"text-sm py-2 px-4 font-normal block w-full whitespace-wrap bg-transparent text-blueGray-700"}
                                      to={{
                                        pathname: `/leads/${row.id}`, state: [{ backUrl: this.state.pushUrl }]
                                      }}>
                                      Edit
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            )) :
                            <tr key="uniq2" className="border border-l-0 border-r-0 border-blueGray-200">
                              <td colSpan="14" className="align-middle text-sm text-center whitespace-wrap py-4 pr-4">
                                Data Not Available
                              </td>
                            </tr>}
                      </tbody>
                    </table>
                  </div>
                  <div className="py-4">
                    <nav className="block flex justify-between">
                      <button
                        className="button-gold mx-4 text-white active:bg-blueGray-600 text-2xs tracking-wide01 uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={this.handleAssignMultiple}
                      >
                        Assign
                      </button>
                      <div className="w-8/12 legend flex justify-between mr-5">
                        <div className={`${jsCookie.get('privilege') == 'Sales Manager' || jsCookie.get('privilege') == 'Brand Associate' ? 'hidden' : ''} flex my-auto`}><div className="bg-table-red legend-color border-2 my-auto ml-2 mr-2"></div>New Enquiry</div>
                        <div className={`${jsCookie.get('privilege') == 'Brand Associate' ? 'hidden' : ''} flex my-auto`}><div className="bg-table-yellow legend-color border-2 my-auto ml-2 mr-2"></div>Not Assigned</div>
                        <div className="flex my-auto"><div className="bg-table-purple legend-color border-2 my-auto ml-2 mr-2"></div>Not Followed Up</div>
                        <div className="flex my-auto"><div className="bg-table-blue legend-color border-2 my-auto ml-2 mr-2"></div>Followed Up</div>
                        <div className="flex my-auto"><div className="bg-table-gray legend-color border-2 my-auto ml-2 mr-2"></div>Can't Reach</div>
                        <div className="flex my-auto"><div className="bg-table-green legend-color border-2 my-auto ml-2 mr-2"></div>SPK</div>
                      </div>
                    </nav>
                    <nav className="block flex justify-end mt-5">
                      {this.state.isPaging != 'no' ?
                        <ul className="flex rounded list-none flex-wrap float-right mx-4">
                          <div className="mx-4 my-auto">
                            Showing {this.state.startShowing} {this.state.endShowing ? 'to ' + this.state.endShowing : ''} of {this.state.totalData}
                          </div>
                          {/*pagingTmp*/}
                          {this.state.pagingPrevTemplate}
                          {this.state.pagingTemplate}
                          {this.state.pagingNextTemplate}
                        </ul>
                        : ""}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.showWaModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto mx-auto items-center">
                    <button
                      className="bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => this.handleShowWaModal(false)}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        <i className="fa fa-times" />
                      </span>
                    </button>
                    <img
                      src={require("assets/img/logo/whatsapp-logo.svg").default}
                      className="relative border-none max-w-150-px  mx-auto"
                      alt="whatsapp-logo"
                    />
                    <h6 className="text-center text-black font-bold mx-auto">Send Whatsapp Messages</h6>
                    <p className="mt-4 text-blueGray-500 text-sm text-center leading-relaxed">
                      Send assigned information message to Sales Manager through Whatsapp
                    </p>
                  </div>
                  <div className="flex items-center justify-center pb-6 rounded-b text-center">
                    <a
                      href={this.state.whatsappLink}
                      target="_new"
                      className="w-10/12 button-gold bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                    >
                      Open Whatsapp
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
}

