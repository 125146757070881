import React from "react";
import auth from 'global/auth.js';
import jsCookie from 'js-cookie';

import 'assets/styles/custom.css';

export default function Login() {

  return (
    <>
      <NotFoundClass />
    </>
  );
}

class NotFoundClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url: '' };
  }
  componentDidMount() {
    if (auth()) {
      if (jsCookie.get('privilege') === 'Uploader') {
        this.setState({
          url: "/uploader"
        });
      } else {
        this.setState({
          url: "/dashboard/audience"
        });
      }
    } else {
      this.setState({
        url: "/login"
      });

    }
  }
  render() {

    return (
      <>
        <main>
          <section className="relative w-full h-full pt-40 min-h-screen">
            <div
              className="absolute top-0 w-full h-full bg-login bg-no-repeat bg-full">
            </div>
            <div className="container mx-auto px-4 h-full">
              <div className="flex justify-center h-full">
                <img
                  alt="..."
                  src={require("assets/img/logo/logo-white.svg").default}
                  className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                />
              </div>
              <div className="flex content-center items-center justify-center h-full">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words w-full  ">
                    <img
                      alt="..."
                      src={require("assets/img/404-error.svg").default}
                      className="w-6/12 text-center mx-auto"
                    />
                    <div className="mt-4 text-white text-xl text-center leading-relaxed"> Page Not Found</div>
                    <div className="mt-2 text-gray text-md text-center leading-relaxed"> The page you are looking for doesn’t exist on the server. </div>

                    <a href={this.state.url} className="mt-4 w-10/12 button-login  text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 text-center justify-center mx-auto">
                      Go to dashboard
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}
