import jsCookie from 'js-cookie';
var md5 = require("md5"); 

export default function auth() {
    const token = jsCookie.get('token');
    const token2 = jsCookie.get('token2');
    const name = jsCookie.get('name');
    const privilege = jsCookie.get('privilege');
    if(md5(md5(token+privilege)+'unitywork') === token2 && name){
        return true;
    }
  return false;
}