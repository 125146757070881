import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import auth from 'global/auth.js';

import jsCookie from 'js-cookie';
import { DateFormat } from "global/custom.js";
//import Customers from "views/customer/Customer.js";
import { numberFormat } from "global/custom.js";
/*eslint-disable eqeqeq*/

class ProfileClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      user_name: '',
      user_email: '',
      user_phone: '',
      user_showroom_id: '',
      user_showroom_name: '',
      user_privilege: '',
      user_sales_manager_id: '',
      user_sales_manager_name: '',
      user_status: '',
      user_total_customer: 0,
      user_total_assign_to_sm: 0,
      user_total_assign_to_ba: 0,
      user_total_sm: 0,
      user_total_ba: 0,
      user_total_followup_customer: 0,
      user_total_not_followup_customer: 0,
      user_total_cant_reach_customer: 0,
      user_total_spk_followup_customer: 0,
      user_total_assign: 0,
      user_total_not_assign: 0,
      isLoading: true,
      urlStatus: true,
    };

  }

  getUserDetailData() {
    axios.get(`${process.env.REACT_APP_API_URL}user/${jsCookie.get('uid')}`,
      {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(err => {
        this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
        this.setState({
          urlStatus: false,
          isLoading: false
        });
        throw err;
      })
      .then(res => {
        const data = res.data[0];
        if (data.privilege === 'Super Admin') {
          this.setState({ storeDisabled: 'disabled' })
        }
        else if (data.privilege === 'Sales Manager') {
          this.setState({ storeDisabled: '' })
        }
        else if (data.privilege === 'Brand Associate') {
          this.setState({ storeDisabled: '' })
          this.setState({ salesManagerStatus: true })
        }
        else {
          this.setState({ storeDisabled: 'disabled' })
          this.setState({ salesManagerStatus: false })
        }
        this.setState({
          user_id: data.id,
          user_name: data.name,
          user_email: data.email ? data.email : '',
          user_phone: data.phone ? data.phone : '',
          user_showroom_id: data.showroom_id ? data.showroom_id : '',
          user_showroom_name: data.showroom_name,
          user_privilege: data.privilege ? data.privilege : '',
          user_sales_manager_id: data.manager_id ? data.manager_id : '',
          user_sales_manager_name: data.sales_manager_name ? data.sales_manager_name : '',
          user_status: data.status ? data.status : '',
          user_total_sm: data.total_sales_manager ? data.total_sales_manager : 0,
          user_total_ba: data.total_brand_associate ? data.total_brand_associate : 0,
          user_created_date: data.created_date ? DateFormat(data.created_date, 'Mdy') : '-',
          user_last_login: data.last_login ? DateFormat(data.last_login, 'Mdy') : '-',
          isLoading: false,
        }, () => {
          if(jsCookie.get('privilege') !== 'Uploader'){
          this.getCustomerStatistic();
          this.getEnquiryStatistic();
          }
        });
      })
  }

  getCustomerStatistic() {
    var url = `${process.env.REACT_APP_API_URL}customers_statistic`;
    if (this.state.user_privilege === 'Sales Manager') {
      url = `${process.env.REACT_APP_API_URL}customers_statistic?sales_manager_id=${this.state.user_id}`;

    }
    else if (this.state.user_privilege === 'Brand Associate') {
      url = `${process.env.REACT_APP_API_URL}customers_statistic?brand_associate_id=${this.state.user_id}`;
    }
    axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        console.log(error);
        throw error;
      })
      .then(res => {
        let data = res.data;
        if (data !== 'Data Empty') {
          this.setState({
            user_total_customer: numberFormat(data.total_customers),
            user_total_assign: numberFormat(data.total_assigned_leads),
            user_total_not_assign: numberFormat(data.total_not_assigned_leads),
          });
        }
      })
  }

  getEnquiryStatistic() {
    var url = `${process.env.REACT_APP_API_URL}enquiry_statistic`;
    if (this.state.user_privilege === 'Sales Manager') {
      url = `${process.env.REACT_APP_API_URL}enquiry_statistic?sales_manager_id=${this.state.user_id}`;

    }
    else if (this.state.user_privilege === 'Brand Associate') {
      url = `${process.env.REACT_APP_API_URL}enquiry_statistic?brand_associate_id=${this.state.user_id}`;
    }
    axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        console.log(error);
        throw error;
      })
      .then(res => {
        let data = res.data;
        if (data !== 'Data Empty') {
          this.setState({
            user_total_spk_followup_customer: numberFormat(data.total_spk),
            user_total_cant_reach_customer: numberFormat(data.total_cant_reach),
            user_total_followup_customer: numberFormat(data.total_followup),
            user_total_not_followup_customer: numberFormat(data.total_not_followup),
          });
        }
      })
  }

  componentDidMount() {
    document.getElementById('search').value = '';
    this.getUserDetailData();
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(status, name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className={'notifBar ' + (status == '404' ? 'bg-red' : 'bg-emerald-400')}>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)} />
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.errorMessage}
        </div>
        <div className="flex flex-wrap ">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0 md:mb-0 sm:mb-0 ">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6  shadow-lg rounded bg-blueGray-100 border-0">
              <div className="block w-full overflow-x-auto ">
                <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white">
                  <div className="relative rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center text-left font-bold ">
                      <div className={` w-full lg:w-auto mb-2 pr-10 `}>
                        <div className="relative w-full ">
                          <label
                            className="block uppercase text-blueGray-600 text-xs "
                          >
                            Name
                          </label>
                          {this.state.user_name}
                        </div>
                      </div>
                      <div className={`${this.state.user_sales_manager_name ? '' : 'hidden'} w-full lg:w-auto mb-2 pr-10`}>
                        <div className="relative w-full  ">
                          <label
                            className="block uppercase text-blueGray-600 text-xs "
                          >
                            Sales Manager
                          </label>
                          {this.state.user_sales_manager_name}
                        </div>
                      </div>
                      <div className={` w-full lg:w-auto mb-2 pr-10`}>
                        <div className="relative w-full">
                          <label
                            className="block uppercase text-blueGray-600 text-xs "
                          >
                            Role
                          </label>
                          {/*this.state.user_privilege=='Super Admin'?'Lexus Indonesia':this.state.user_privilege*/}
                          {this.state.user_privilege}
                        </div>
                      </div>
                        <div className={`${this.state.user_showroom_name ? '' : 'hidden'} w-auto mb-2 pr-10`}>
                          <div className="relative w-full">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold"
                              htmlFor="grid-password"
                            >
                              Gallery
                            </label>
                            {this.state.user_showroom_name?this.state.user_showroom_name:'-'}
                          </div>
                        </div>
                      <div className={` w-full lg:w-auto mb-2 pr-10`}>
                        <div className="relative w-full">
                          <label
                            className="block uppercase text-blueGray-600 text-xs "
                          >
                            Join Date
                          </label>
                          {this.state.user_created_date}
                        </div>
                      </div>
                      <div className={` w-full lg:w-auto  mb-2 pr-10`}>
                        <div className="relative w-full">
                          <label
                            className="block uppercase text-blueGray-600 text-xs "
                          >
                            Last Login
                          </label>
                          {this.state.user_last_login}
                        </div>
                      </div>
                    </div>
                    <div className={`absolute top-0 right-0 mt-4 w-auto  mb-2 pr-6 float-right`}>
                      <Link
                        to="/profile/edit"
                      >
                        <i className="fa fa-user-edit" />
                      </Link>
                    </div>
                  </div>

                </div>

                <div className="flex-auto px-6 py-10 pt-10">
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-4/12 pr-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="opacity-100 disabled border-0 px-3 py-3 placeholder-blueGray-200 text-blueGray-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          disabled='disabled'
                          value={this.state.user_email}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 pr-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Phone Number
                        </label>
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          className="opacity-100 disabled border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          disabled='disabled'
                          value={this.state.user_phone}
                        />
                      </div>
                    </div>
                   
                  </div>
                </div>

              </div>
            </div>
          </div>
          {jsCookie.get('privilege') !== 'Uploader'?
          <>
          <div className={` w-full xl:w-12/12 mb-12 xl:mb-0 md:mb-0 sm:mb-0 text-lg text-white pb-2`}>
            {this.state.user_name} Overall Performance Overview
          </div>
          <div className="w-full xl:w-3/12 md:w-6/12 mb-12 xl:mb-0 md:mb-0 sm:mb-0 lg:pr-4 md:pr-4 ">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-blueGray-600  text-xs font-bold"
                >
                  Total Customer
                </label>
                {this.state.user_total_customer}
              </div>
            </div>
          </div>
          <div className={`${this.state.user_privilege !== 'Brand Associate' ? '' : 'hidden'} w-full xl:w-3/12 md:w-6/12 mb-12 xl:mb-0 md:mb-0 sm:mb-0 pr-5 lg:pr-4 md:pr-0`}>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold"
                >
                  Leads Assigned to {this.state.user_privilege === 'Super Admin' ? 'Sales Manager' : 'Brand Associate'}
                </label>
                {/*this.state.user_privilege === 'Super Admin' ? this.state.user_total_assign_to_sm : this.state.user_total_assign_to_ba*/}
                {this.state.user_total_assign}
              </div>
            </div>
          </div>
          <div className={`${this.state.user_privilege !== 'Brand Associate' ? '' : 'hidden'} w-full xl:w-3/12 md:w-6/12 mb-12 xl:mb-0 md:mb-0 sm:mb-0 pr-5 lg:pr-4 `}>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold"
                >
                  Leads Not Assigned to {this.state.user_privilege === 'Super Admin' ? 'Sales Manager' : 'Brand Associate'}
                </label>
                {this.state.user_total_not_assign}
              </div>
            </div>
          </div>
          <div className={`${this.state.user_privilege !== 'Brand Associate' ? '' : 'hidden'} w-full xl:w-3/12 md:w-6/12  mb-12 xl:mb-0 md:mb-0 sm:mb-0 md:pr-0`}>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold"
                >
                  Total {this.state.user_privilege === 'Super Admin' ? 'Sales Manager' : 'Brand Associate'}
                </label>
                {this.state.user_privilege === 'Super Admin' ? this.state.user_total_sm : this.state.user_total_ba}
              </div>
            </div>
          </div>

          <div className={` w-full xl:w-12/12 mb-12 xl:mb-0 md:mb-0 sm:mb-0 text-lg text-white pb-2`}>
            Enquiry Follow Up Statistic
          </div>
          <div className="w-full xl:w-3/12 md:w-6/12  mb-12 xl:mb-0 sm:mb-0 md:mb-0 pr-4 sm:pr-0">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold"
                >
                  SPK
                </label>
                {this.state.user_total_spk_followup_customer}
              </div>
            </div>
          </div>
          <div className="w-full xl:w-3/12 md:w-6/12  mb-12 xl:mb-0 sm:mb-0 md:mb-0 pr-4 sm:pr-0">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold"
                >
                  Followed Up
                </label>
                {this.state.user_total_followup_customer}
              </div>
            </div>
          </div>
          <div className={` w-full xl:w-3/12 md:w-6/12 mb-12 xl:mb-0 md:mb-0 sm:mb-0 pr-4 lg:pr-4 md:pr-0 sm:pr-0`}>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold"
                >
                  Not Followed Up
                </label>
                {this.state.user_total_not_followup_customer}
              </div>
            </div>
          </div>
          <div className={` w-full xl:w-3/12 md:w-6/12 mb-12 xl:mb-0 md:mb-0 sm:mb-0 pr-4 lg:pr-4 sm:pr-0`}>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded ">
              <div className="relative rounded-t my-auto px-4 py-3 border-0">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold"
                >
                  can't Reached
                </label>
                {this.state.user_total_cant_reach_customer}
              </div>
            </div>
          </div>
          </>
          :''}
          {/*<div className="flex flex-wrap w-full xl:w-12/12 mb-12 xl:mb-0">
            {!this.state.isLoading && this.state.urlStatus ?
              <Customers path='profile' assign='no' label='Leads' exports="no" /> : ''}
          </div>*/}
        </div>
      </>
    );
  }
}

export default ProfileClass