import React from "react";
import axios from 'axios';
import jsCookie from 'js-cookie';
import 'react-funnel-pipeline/dist/index.css'
import ReactTooltip from "react-tooltip";
/*eslint-disable eqeqeq*/

export default function CardFunnelConversionTotal({ title, filterBA, filterShowroom, filterSeries, filterModel, filterChannel, filterFrom, filterTo, areaFilter }) {
  return (
    <CardFunnelConversionTotalClass title={title} filterBA={filterBA} filterShowroom={filterShowroom} filterSeries={filterSeries} filterModel={filterModel} filterChannel={filterChannel} filterFrom={filterFrom} filterTo={filterTo} areaFilter={areaFilter} />
  )
}

class CardFunnelConversionTotalClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      filterSeries: this.props.filterSeries,
      filterModel: this.props.filterModel,
      filterChannel: this.props.filterChannel,
      filterFrom: this.props.filterFrom,
      filterTo: this.props.filterTo,
      filterShowroom: this.props.filterShowroom,
      filterBA: this.props.filterBA,
      areaFilter:this.props.areaFilter,
      data: [],
      chart: '',
      chartCreated: false,
      dataEmpty: false,
      total: 0,
      followup: 0,
      followupPercentage: '0%',
      spk: 0,
      spkPercentage: '0%',
      funnelPercentageHeight: '0px',
      lastFunnelPercentageHeight: '0px',
      followup3: [],
      columnHeight: '0px'
    };
  }

  getData() {
    var url = `${process.env.REACT_APP_API_URL}dashboard/conversion/total`;
    const filter = {
      series: this.state.filterSeries,
      model: this.state.filterModel,
      channel: this.state.filterChannel,
      dateFrom: this.state.filterFrom,
      dateTo: this.state.filterTo,
      leads: "yes",
      level: jsCookie.get('privilege'),
      id: jsCookie.get('uid'),
      showroom: this.state.filterShowroom,
      ba: this.state.filterBA,
      area: this.state.areaFilter,
    };
    axios.post(url, filter, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          total: data.total,
          totalFollowup: data.total_followup,
          totalFollowupPercentage: data.total_followup_percentage,
          cold: data.cold,
          coldPercentage: data.cold_percentage,
          hot: data.hot,
          hotPercentage: data.hot_percentage,
          prospect: data.prospect,
          prospectPercentage: data.prospect_percentage,
          notReach: data.notReach,
          notReachPercentage: data.notReach_percentage,
          followup: data.followup,
          followupPercentage: data.followup_percentage,
          spk: data.spk,
          spkPercentage: data.spk_percentage,
        });
      })
  }

  componentDidMount() {
    this.getData()
    let followup3 = document.querySelectorAll('.funnel-chart div');
    let funnel1_height = followup3[0].offsetHeight;
    let funnel2_height = followup3[3].offsetHeight;

    this.setState({
      followup3: followup3,
      funnelPercentageHeight: funnel1_height + "px",
      lastFunnelPercentageHeight: (funnel1_height + funnel2_height) + "px",
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevProps.filterSeries != this.props.filterSeries) ||
      (prevProps.filterModel != this.props.filterModel) ||
      (prevProps.filterChannel != this.props.filterChannel) ||
      (prevProps.filterFrom != this.props.filterFrom) ||
      (prevProps.filterTo != this.props.filterTo)||
      (prevProps.areaFilter != this.props.areaFilter)
    ) {
      this.setState({
        filterSeries: this.props.filterSeries == 'Series' ? '' : this.props.filterSeries,
        filterModel: this.props.filterModel,
        filterChannel: this.props.filterChannel == 'Channel' ? '' : this.props.filterChannel,
        filterFrom: this.props.filterFrom,
        filterTo: this.props.filterTo,
        areaFilter: this.props.areaFilter,
      }, () => {
        this.getData();
      });
    }
  }

  render() {
    return (
      <>
        <div className="rounded-t mb-0 px-2 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1 text-center h-30-px">
              <h3 className="text-blueGray-700 text-base font-bold">
                {this.state.title}
              </h3>
            </div>
          </div>
        </div>
        <div className="relative p-2 h-full">
          <div className="funnel-chart flex flex-wrap justify-between bg-white w-full h-80 text-center">
            <div data-tip data-for={this.state.title.replace(/ /g, '') + 'EnquiryTooltip'} className="columnFunnel relative w-full mb-2px" style={{ backgroundColor: "#f14c14", height: "60px" }}>
              <div className="columnFunnelContent">Enquiry<div>{this.state.total}</div>
              </div>
            </div>
            <div data-tip data-for={this.state.title.replace(/ /g, '') + 'FollowupTooltip'} className="columnFunnel relative w-full mb-2px" style={{ backgroundColor: "#f39c35", height: "60px" }}>
              <div className="columnFunnelContent">Follow Up<div>{this.state.totalFollowup}</div>
              </div>
            </div>
            <div data-tip data-for={this.state.title.replace(/ /g, '') + 'SpkTooltip'} className="columnFunnel relative w-full" style={{ backgroundColor: "#68bc00", height: "100px" }}>
              <div className="columnFunnelContent">SPK<div>{this.state.spk}</div>
              </div>
            </div>
          </div>
          <div className="funnelPercentage" style={{ position: 'absolute', top: this.state.funnelPercentageHeight }}>{this.state.totalFollowupPercentage}</div>
          <div className="lastFunnelPercentage" style={{ position: 'absolute', top: this.state.lastFunnelPercentageHeight }}>{this.state.spkPercentage}</div>

          <ReactTooltip id={this.state.title.replace(/ /g, '') + 'EnquiryTooltip'} className="bg-tooltip">
            <span>Enquiry: {this.state.total}</span></ReactTooltip>
          <ReactTooltip id={this.state.title.replace(/ /g, '') + 'FollowupTooltip'} className="bg-tooltip">
            <div>Follow Up: {this.state.followup}</div>
            <div>Cold: {this.state.cold + ' (' + this.state.coldPercentage + ')'}</div>
            <div>Hot: {this.state.hot + ' (' + this.state.hotPercentage + ')'}</div>
            <div>Prospect: {this.state.prospect + ' (' + this.state.prospectPercentage + ')'}</div>
            <div>Can't Reach: {this.state.notReach + ' (' + this.state.notReachPercentage + ')'}</div>
            </ReactTooltip>
          <ReactTooltip id={this.state.title.replace(/ /g, '') + 'SpkTooltip'} className="bg-tooltip">
            <span>SPK: {this.state.spk + ' (' + this.state.spkPercentage + ')'}</span></ReactTooltip>
        </div>
      </>
    );
  }

}