import React from "react";
import { Redirect   } from 'react-router-dom'
// components
import jsCookie from 'js-cookie';
import auth from 'global/auth.js';


export default function Integrations() {

  if(!auth()){
    return <Redirect to="/login" />;
  }
  return (
    <IntegrationsClass />
  )

}

class IntegrationsClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      privilege:jsCookie.get('privilege'),
    };
  } 

  render() {
    return (
      <>
        <div className="flex flex-wrap">
          
      <div className="w-full xl:w-12/12 mb-12 xl:mb-0">
        
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="block w-full overflow-x-auto ">
            <div className="relative flex flex-col min-w-0 break-words w-full pb-6 shadow-lg rounded bg-white">
          <div className="w-full px-4 xl:mt-5">
            Page Under Construction
          </div>
          </div>
          </div>
          </div>
          </div>
        </div>
      </>
    );
  }
}