import React from "react";
import axios from 'axios';
import jsCookie from 'js-cookie'
import { Link } from 'react-router-dom'
/*eslint-disable eqeqeq*/

export default function CardStatsNewCustomer({ filterSeries, filterModel, filterChannel, filterFrom, filterTo }) {
  return (
    <CardStatsNewCustomerClass filterSeries={filterSeries} filterModel={filterModel} filterChannel={filterChannel} filterFrom={filterFrom} filterTo={filterTo} />
  )
}

class CardStatsNewCustomerClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filterSeries: this.props.filterSeries,
      filterModel: this.props.filterModel,
      filterChannel: this.props.filterChannel,
      filterFrom: this.props.filterFrom,
      filterTo: this.props.filterTo,
      value: 0,
    };
  }

  getData() {
    var url = `${process.env.REACT_APP_API_URL}dashboard/leads/total`;
    const filter = {
      series: this.state.filterSeries,
      model: this.state.filterModel,
      channel: this.state.filterChannel,
      dateFrom: this.state.filterFrom,
      dateTo: this.state.filterTo,
      leads: "yes",
      level: jsCookie.get('privilege'),
      id: jsCookie.get('uid')
    };
    axios.post(url, filter, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          value: res.data.total,
        });
      })
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevProps.filterSeries != this.props.filterSeries) ||
      (prevProps.filterModel != this.props.filterModel) ||
      (prevProps.filterChannel != this.props.filterChannel) ||
      (prevProps.filterFrom != this.props.filterFrom) ||
      (prevProps.filterTo != this.props.filterTo)
    ) {
      this.setState({
        filterSeries: this.props.filterSeries == 'Series' ? '' : this.props.filterSeries,
        filterModel: this.props.filterModel,
        filterChannel: this.props.filterChannel == 'Channel' ? '' : this.props.filterChannel,
        filterFrom: this.props.filterFrom,
        filterTo: this.props.filterTo,
      }, () => {
        this.getData();
      });
    }
  }

  render() {

    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white rounded shadow-lg">
          <div className="flex-auto p-4">
            <div className="flex flex-wrap">
              <div className="relative w-full max-w-full flex-grow flex-1  text-center">
                <h3 className="text-blueGray-700  font-bold ">
                  <span className=" mr-1">Total Leads</span>
                </h3>
                <div className="w-full text-blueGray-700 text-center my-4">
                  <Link
                    to={`/leads/null/null/${this.state.filterFrom}/${this.state.filterTo}/all/all/null/null/null/all/null/null/null/null/1`}
                  >
                    {this.state.value}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

}