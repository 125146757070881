import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import auth from 'global/auth.js';

import jsCookie from 'js-cookie';
import ClipLoader from "react-spinners/ClipLoader";
import Select from 'react-select'
import { Validation } from "global/custom.js";
import * as moment from 'moment'

/*eslint-disable eqeqeq*/

export default function Customers({ label, filter, paging, assign, assignBtn, path, sales_manager_id, brand_associate_id, showMore, name, exports, exports_odoo }) {
  return (
    <>
      <CustomersClass label={label} filter={filter} paging={paging} assign={assign} assignBtn={assignBtn} path={path} sales_manager_id={sales_manager_id} brand_associate_id={brand_associate_id} showMore={showMore} name={name} exports={exports} exports_odoo={typeof exports_odoo != 'undefined' ? exports_odoo : 'no'} />
    </>
  );
}
class CustomersClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      path: this.props.path ? this.props.path : 'enquiry',
      headerLabel: this.props.label,

      sales_manager_id: this.props.sales_manager_id ? this.props.sales_manager_id : '',
      showSalesManager: false,
      brand_associate_id: this.props.brand_associate_id ? this.props.brand_associate_id : '',
      showBrandAssociate: false,

      salutation: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      notes: '',

      preferredModel: [],
      preferredModelOption: [],

      enquirySubject: [],
      enquirySubjectOption: [],

      enquiryType: [],
      enquiryTypeOption: [
        { value: 'General Enquiry', label: 'General Enquiry' },
        { value: 'Car Models', label: 'Car Models' },
        { value: 'Corporate Sales', label: 'Corporate Sales' },
      ],
      showEnquiryType: false,

      smOptionBckup: [],
      smOption: [],

      baOptionBckup: [],
      baOption: [],

      province: [],
      provinceOption: [],

      source: [],
      sourceOption: [],

      showWaModal: false,
      whatsappSmLink: '',
      whatsappBaLink: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.resetField = this.resetField.bind(this);
    this.handleChangePreferredModel = this.handleChangePreferredModel.bind(this);
    this.handleChangeEnquirySubject = this.handleChangeEnquirySubject.bind(this);
    this.handleChangeEnquiryType = this.handleChangeEnquiryType.bind(this);
    this.handleChangeProvince = this.handleChangeProvince.bind(this);
    this.handleChangeSm = this.handleChangeSm.bind(this);
    this.handleChangeBa = this.handleChangeBa.bind(this);
    this.handleChangeSource = this.handleChangeSource.bind(this);
    
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleShowWaModal = this.handleShowWaModal.bind(this);

  }

  getDropdownSm() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/sm?status=active`;
    let filter = [{ 's': 'active' }];
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let bckupData = res.data;
        let data = bckupData;
        if (this.state.showroomFilter && this.state.showroomFilter != 'all') {
          data = data.filter(elem => {
            return elem.showroom_id == this.state.showroomFilter
          })
        }
        this.setState({
          smOption: data,
          smOptionBckup: bckupData
        }, () => {
          this.getDropdownBa();
        });
      })
  }

  getDropdownBa() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/ba?status=active`;
    let filter = [];
    if (jsCookie.get('privilege') == 'Sales Manager') {
      filter = {
        sales_manager_id: jsCookie.get('uid'),
      };
      url += '&sales_manager_id=' + jsCookie.get('uid');
    }
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let bckupData = res.data;
        let data = bckupData;
        if (this.state.smFilter) {
          data = data.filter(elem => {
            return elem.manager_name.toLowerCase() == this.state.smFilter.toLowerCase()
          })
        }
        this.setState({
          baOption: data,
          baOptionBckup: bckupData,
        }, () => {
          this.getDropdownEnquirySubject();
        });
      })
  }

  getDropdownEnquirySubject() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/enquiry_subject`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          enquirySubjectOption: data,
        }, () => {
          this.getDropdownModel();
        });
      })
  }

  getDropdownModel() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/car-model-input?type=enquiry`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          preferredModelOption: data
        }, () => {
          this.getDropdownProvince();
        });
      })
  }

  getDropdownProvince() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/province`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        let data = res.data;
        this.setState({
          provinceOption: data,
        }, () => {
          this.getDropdownChannel();
          //this.setState({isLoading: false});
        });
      })
  }

  getDropdownChannel() {
    var url = `${process.env.REACT_APP_API_URL}dropdown/channel?category=offline`;
    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          sourceOption: res.data,
        });
      })
  }


  getCustomerData() {
    var getParam = [];
    this.setState({ isLoading: true });

    if (this.state.sales_manager_id !== '') {
      getParam.push('sales_manager_id=' + this.state.sales_manager_id);
    }
    if (this.state.brand_associate_id !== '') {
      getParam.push('brand_associate_id=' + this.state.brand_associate_id);
    }
    if (this.state.nameFilter !== '') {
      getParam.push('name=' + this.state.nameFilter);
    }
    if (this.state.statusFilter !== '') {
      getParam.push('status=' + this.state.statusFilter);
    }
    if (this.state.fromFilter) {
      getParam.push('start_date=' + this.state.fromFilter);
    }
    if (this.state.toFilter) {
      getParam.push('end_date=' + this.state.toFilter);
    }
    if (this.state.followupFilter && this.state.followupFilter != 'all') {
      getParam.push('followup=' + this.state.followupFilter);
    }
    if (this.state.assignedFilter && this.state.assignedFilter != 'all') {
      getParam.push('assigned=' + this.state.assignedFilter);
    }
    if (this.state.smFilter) {
      getParam.push('sm=' + this.state.smFilter);
    }
    if (this.state.baFilter) {
      getParam.push('ba=' + this.state.baFilter);
    }
    if (this.state.enquiryFilter) {
      getParam.push('enquiry=' + this.state.enquiryFilter);
    }
    if (this.state.showroomFilter && this.state.showroomFilter != 'all') {
      getParam.push('showroom=' + this.state.showroomFilter);
    }
    if (this.state.cityFilter) {
      getParam.push('city=' + this.state.cityFilter);
    }
    if (this.state.provinceFilter) {
      getParam.push('province=' + this.state.provinceFilter);
    }
    if (this.state.carFilter) {
      getParam.push('preferred_model=' + this.state.carFilter);
    }
    if (this.state.sourceFilter) {
      getParam.push('source=' + this.state.sourceFilter);
    }

    if (this.state.followupFromFilter) {
      getParam.push('followup_start_date=' + this.state.followupFromFilter);
    }
    if (this.state.followupToFilter) {
      getParam.push('followup_end_date=' + this.state.followupToFilter);
    }

    if (this.state.emailFilter !== '') {
      getParam.push('email=' + this.state.emailFilter);
    }
    if (this.state.phoneFilter !== '') {
      getParam.push('phone=' + this.state.phoneFilter);
    }
    if (this.state.currentPage) {
      getParam.push('currentPage=' + this.state.currentPage);
    }
    if (this.state.filterByName) {
      getParam.push('name=' + this.state.filterByName);
    }

    getParam.push('perPage=' + this.state.perPage);
    /**sort */
    if (this.state.nameSort !== '') {
      getParam.push('sortBy=name-' + this.state.nameSort);
    } else if (this.state.salesManagerSort !== '') {
      getParam.push('sortBy=sales_manager_name-' + this.state.salesManagerSort);
    } else if (this.state.brandAssociateSort !== '') {
      getParam.push('sortBy=brand_associate_name-' + this.state.brandAssociateSort);
    } else if (this.state.statusSort !== '') {
      getParam.push('sortBy=spk_status-' + this.state.statusSort);
    } else if (this.state.lastEnquiryTypeSort !== '') {
      getParam.push('sortBy=last_enquiry-' + this.state.lastEnquiryTypeSort);
    } else if (this.state.lastEnquiryStatusSort !== '') {
      getParam.push('sortBy=followup_status-' + this.state.lastEnquiryStatusSort);
    } else if (this.state.emailSort !== '') {
      getParam.push('sortBy=email-' + this.state.emailSort);
    } else if (this.state.phoneSort !== '') {
      getParam.push('sortBy=phone-' + this.state.phoneSort);
    } else if (this.state.citySort !== '') {
      getParam.push('sortBy=city-' + this.state.citySort);
    } else if (this.state.provinceSort !== '') {
      getParam.push('sortBy=province-' + this.state.provinceSort);
    } else if (this.state.showroomSort !== '') {
      getParam.push('sortBy=showroom_name-' + this.state.showroomSort);
    } else if (this.state.dataInSort !== '') {
      getParam.push('sortBy=last_enquiry_created_date-' + this.state.dataInSort);
    } else if (this.state.enquiryDateSort !== '') {
      getParam.push('sortBy=last_enquiry_date-' + this.state.enquiryDateSort);
    }
    /**sort */
    var url = `${process.env.REACT_APP_API_URL}enquiry?${getParam.join('&')}`
    if (jsCookie.get('privilege') === 'Sales Manager') {
      url = `${process.env.REACT_APP_API_URL}enquiry?sm=${jsCookie.get('name')}&${getParam.join('&')}`
    } else if (jsCookie.get('privilege') === 'Brand Associate') {
      url = `${process.env.REACT_APP_API_URL}enquiry?ba=${jsCookie.get('name')}&${getParam.join('&')}`
    }
    axios.get(url,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
        this.setState({ isLoading: false });
        throw error;
      })
      .then(res => {

        if (res.data !== 'Data Empty' && res.data) {
          let data = res.data.data;
          let totalData = res.data.total_data;

          const checkBoxId = this.state.checkBoxId;
          if (typeof data != 'undefined') {
            data.forEach((rows, keys) => {
              checkBoxId.forEach(function (value, index) {
                if (value == rows.id) {
                  data[keys].checked = 'checked';
                }
              });

              var background = '';
              //if (!rows.sales_manager_id) { //New Enquiry
              //  background = 'bg-table-red';
              //}
              //else 
              if (!rows.sales_manager_id || !rows.brand_associate_id) { // 'Not Assigned'
                background = 'bg-table-yellow';
                data[keys].last_followup_status = 'Not Assigned';
              }
              else if (rows.last_followup_status === '') {
                background = 'bg-table-purple';
              }
              if (rows.last_followup_status === 'Follow Up') {
                background = 'bg-table-blue';
              }
              else if (rows.last_followup_status === "Not Reach") {
                background = 'bg-table-gray';
              }
              else if (rows.last_followup_status === 'SPK') {
                background = 'bg-table-green';
              }
              data[keys].background = background;
            })
          }
          this.setState({
            totalData: totalData,
            totalCustomer: totalData,
            customersData: data ? Array.from(data) : [],
          }, () => {
            this.handleCheckAllChange();
            this.handlePaging();
            this.setState({ isLoading: false });
          });
        } else {
          this.createErrorMessage(404, 'Please check connection.', '', 'errorMessage')
          this.setState({ isLoading: false });
        }
      })
  }

  componentDidMount() {
    if (!auth()) {
      return false;
    }
    this.getDropdownSm();
    if (jsCookie.get('privilege') == 'Super Admin') {
      this.setState({
        showSalesManager: true,
        showBrandAssociate: true,
      });
    } else if (jsCookie.get('privilege') == 'Sales Manager') {
      this.setState({
        showBrandAssociate: true,
      });
    }
  }

  resetField(event) {
    const name = event === 'name' ? '' : this.state.nameFilter;
    const status = event === 'status' ? '' : this.state.statusFilter;
    const followup = event === 'followup' ? '' : this.state.followupFilter;
    const assigned = event === 'assigned' ? '' : this.state.assignedFilter;
    const sm = event === 'sm' ? '' : this.state.smFilter;
    const ba = event === 'ba' ? '' : this.state.baFilter;
    const enquiry = event === 'enquiry' ? '' : this.state.enquiryFilter;
    const showroom = event === 'showroom' ? '' : this.state.showroomFilter;
    const city = event === 'city' ? '' : this.state.cityFilter;
    const province = event === 'province' ? '' : this.state.provinceFilter;
    const car = event === 'car' ? '' : this.state.carFilter;
    const source = event === 'source' ? '' : this.state.sourceFilter;
    const dateStart = this.state.fromFilter;
    const dateEnd = this.state.toFilter;
    const followupFromFilter = this.state.followupFromFilter;
    const followupToFilter = this.state.followupToFilter;
    const email = event === 'email' ? '' : this.state.emailFilter;
    const phone = event === 'phone' ? '' : this.state.phoneFilter;
    const number = 1;
    var pushUrl = '/' + this.state.path
    if (name || status || followup || assigned || sm || ba || enquiry || showroom || city || province || car || dateStart || source || followupFromFilter || email || phone || number > 1) {
      pushUrl = '/' + this.state.path +
        '/' + (name ? name.toLowerCase() : 'null') + //0
        '/' + (status ? status.toLowerCase() : 'null') +  //1
        '/' + (dateStart ? dateStart : 'null') + //2
        '/' + (dateEnd ? dateEnd : 'null') + //3
        '/' + (followup ? followup.toLowerCase() : 'all') + //4
        '/' + (assigned ? assigned : 'all') + //5
        '/' + (sm ? sm.toLowerCase() : 'null') + //6
        '/' + (ba ? ba.toLowerCase() : 'null') + //7
        '/' + (enquiry ? enquiry.toLowerCase() : 'null') + //8
        '/' + (showroom ? showroom.toLowerCase() : 'all') + //9
        '/' + (city ? city.toLowerCase() : 'null') + //10
        '/' + (province ? province.toLowerCase() : 'null') + //11
        '/' + (car ? car.toLowerCase() : 'null') + //12
        '/' + (source ? source : 'null') + //13
        '/' + (followupFromFilter ? followupFromFilter : 'null') + //14
        '/' + (followupToFilter ? followupToFilter : 'null') + //15
        '/' + (email ? email : 'null') + //16
        '/' + (phone ? phone : 'null') + //17
        '/' + number //18
    }
    window.history.pushState('page2', 'Title', pushUrl);

    this.setState({
      nameFilter: name,
      statusFilter: status,
      followupFilter: followup,
      assignedFilter: assigned,
      smFilter: sm,
      baFilter: ba,
      enquiryFilter: enquiry,
      showroomFilter: showroom,
      cityFilter: city,
      provinceFilter: province,
      carFilter: car,
      sourceFilter: source,
      currentPage: number,
      emailFilter: email,
      phoneFilter: phone,

      nameFilled: name ? true : false,
      statusFilled: status ? true : false,
      followupFilled: followup ? true : false,
      assignedFilled: assigned ? true : false,
      smFilled: sm ? true : false,
      baFilled: ba ? true : false,
      enquiryFilled: enquiry ? true : false,
      showroomFilled: showroom ? true : false,
      cityFilled: city ? true : false,
      provinceFilled: province ? true : false,
      carFilled: car ? true : false,
      sourceFilled: source ? true : false,
      dateInFilled: dateStart && dateEnd ? true : false,
      followupDateFilled: followupFromFilter && followupToFilter ? true : false,
      emailFilled: email ? true : false,
      phoneFilled: phone ? true : false,
    }, () => {
      this.getCustomerData();
    });
  }

  handleChange(event) {
    const target = event.target;
    const salutation = target.id === 'salutation' ? target.value : this.state.salutation;
    const first_name = target.id === 'first_name' ? target.value : this.state.firstName;
    const last_name = target.id === 'last_name' ? target.value : this.state.lastName;
    const email = target.id === 'email' ? target.value : this.state.email;
    const phone = target.id === 'phone' ? target.value : this.state.phone;
    const notes = target.id === 'notes' ? target.value : this.state.notes;
    if (target.id === 'first_name') {
      this.handleValidation('text', first_name, 'firstNameError', 'firstNameErrorMessage', 'First Name');
    }
    if (target.id === 'email') {
      this.handleValidation('email', email, 'emailError', 'emailErrorMessage', 'Email');
    }
    if (target.id === 'phone') {
      this.handleValidation('phone', phone, 'phoneError', 'phoneErrorMessage', 'Phone');
    }
    /*if (target.id === 'email' || target.id === 'phone') {
      if (!email && !phone) {
        this.handleValidation('email', email, 'emailError', 'emailErrorMessage', 'Email');
        this.handleValidation('phone', phone, 'phoneError', 'phoneErrorMessage', 'Phone');
      } else {
        if (email && !phone) {
          this.handleValidation('email', email, 'emailError', 'emailErrorMessage', 'Email');
          this.handleValidation('phone', '1234', 'phoneError', 'phoneErrorMessage', 'Phone');
        } else if (!email && phone) {
          this.handleValidation('email', 'a@a.com', 'emailError', 'emailErrorMessage', 'Email');
          this.handleValidation('phone', phone, 'phoneError', 'phoneErrorMessage', 'Phone');
        } else {
          this.handleValidation('email', email, 'emailError', 'emailErrorMessage', 'Email');
          this.handleValidation('phone', phone, 'phoneError', 'phoneErrorMessage', 'Phone');
        }
      }
    }*/
    this.setState({
      salutation: salutation,
      firstName: first_name,
      lastName: last_name,
      email: email,
      phone: phone,
      notes: notes,
    });
  }

  handleCloseAlert(errMsgName) {
    this.setState({ [errMsgName]: '' });
  }

  createErrorMessage(name, errMsg, errMsgName) {
    let msg = <><div id='alerts' className='notifBar bg-red'>
      <div id="ofBar-content" className="notifBar-content">
        <b>{name}</b>
      </div>
      <div id="ofBar-right" className="notifBar-right">
        <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert(errMsgName)} />
      </div>
    </div></>
    this.setState({
      [errMsg]: true,
      [errMsgName]: msg
    });
  }

  handleValidation(type, value, errorName, errorMessageName, fieldName) {
    var valid = Validation(type, value, errorName, errorMessageName, fieldName);
    if (valid.status == 404) {
      this.createErrorMessage(valid.msg, errorName, errorMessageName);
      return false;
    } else {
      this.setState({
        [errorName]: '',
        [errorMessageName]: ''
      });
      return true;
    }
  }

  handleChangePreferredModel(selected) {
    if (!this.handleValidation('select2', selected, 'preferredModelError', 'preferredModelErrorMessage', 'Preferred Model')) {
    }

    this.setState({
      preferredModel: selected,
    })
  };

  handleChangeProvince(selected) {
    if (!this.handleValidation('select2', [selected], 'provinceError', 'provinceErrorMessage', 'Province')) {
    }
    this.setState({
      province: selected,
    })
  };

  handleChangeEnquirySubject(selected) {
    let showEnquiryType = false;
    if (!this.handleValidation('select2', [selected], 'enquirySubjectError', 'enquirySubjectErrorMessage', 'Enquiry Subject')) {
    } else {
      if (selected.value.match('online enquiry')) {
        showEnquiryType = true;
      }
    }
    this.setState({
      enquirySubject: selected,
      showEnquiryType: showEnquiryType
    })
  };

  handleChangeEnquiryType(selected) {
    this.handleValidation('select2', [selected], 'enquiryTypeError', 'enquiryTypeErrorMessage', 'Enquiry Type');
    this.setState({
      enquiryType: selected,
    })
  };

  handleChangeSm(selected) {
    let baOption = this.state.baOptionBckup;
    if (selected) {
      baOption = this.state.baOptionBckup.filter(e => {
        return selected.label === e.manager_name;
      })
    }
    this.setState({
      sm: selected,
      baOption: baOption,
    })
  };

  handleChangeBa(selected) {
    let smOption = this.state.smOptionBckup;
    let smSelected = [];
    if (selected) {
      smOption = this.state.smOptionBckup.filter(e => {
        return selected.manager_name === e.label;
      })
      smSelected = smOption[0];
    }

    this.setState({
      sm: smSelected,
      smOption: smOption,
      ba: selected,
    })
  };


  handleChangeSource(selected) {
    if (!this.handleValidation('select2', [selected], 'sourceError', 'sourceErrorMessage', 'Source')) {
    }
    this.setState({
      source: selected,
    })
  };

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitted: true });
    var validate = 1;
    let inputData = [];
    if (jsCookie.get('privilege') != 'Uploader') {
      if (!this.handleValidation('text', this.state.firstName, 'firstNameError', 'firstNameErrorMessage', 'First Name')) {
        validate = 0;
      }
      //if (!this.state.email && !this.state.phone) {
      if (!this.handleValidation('email', this.state.email, 'emailError', 'emailErrorMessage', 'Email')) {
        validate = 0;
      }

      if (!this.handleValidation('phone', this.state.phone, 'phoneError', 'phoneErrorMessage', 'Phone')) {
        validate = 0;
      }
      //}
      if (!this.handleValidation('text', this.state.province.label, 'provinceError', 'provinceErrorMessage', 'Province')) {
        validate = 0;
      }

      if (!this.handleValidation('text', this.state.enquirySubject.label, 'enquirySubjectError', 'enquirySubjectErrorMessage', 'Enquiry Subject')) {
        validate = 0;
      } else {
        if (this.state.enquirySubject.value.match('online enquiry')) {
          if (!this.handleValidation('text', this.state.enquiryType.label, 'enquiryTypeError', 'enquiryTypeErrorMessage', 'Enquiry Type')) {
            validate = 0;
          }
        }
      }
      if (!this.handleValidation('select2', this.state.preferredModel, 'preferredModelError', 'preferredModelErrorMessage', 'Preferred Model')) {
        validate = 0;
      }
      if (!this.handleValidation('text', this.state.source.label, 'sourceError', 'sourceErrorMessage', 'Source')) {
        validate = 0;
      }

      if (validate == 0) {
        this.setState({ isSubmitted: false });
        return false;
      }
      let preferred_model = [];
      this.state.preferredModel.forEach(e => {
        preferred_model.push(e.value);
      })
      inputData = {
        salutation: this.state.salutation,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        province: this.state.province.label,
        enquiry_type: this.state.enquiryType ? this.state.enquiryType.label : '',
        subject: this.state.enquirySubject.label,
        preferred_models: preferred_model.join(', '),
        sales_manager_id: this.state.sm ? this.state.sm.id : '',
        brand_associate_id: this.state.ba ? this.state.ba.id : '',
        notes: this.state.notes,
        source: this.state.source.label,
        uid: jsCookie.get('uid'),
        date: moment().format(),
      };
      if (jsCookie.get('privilege') === 'Sales Manager') {
        inputData.sales_manager_id = jsCookie.get('uid');
      } else if (jsCookie.get('privilege') === 'Brand Associate') {
        inputData.sales_manager_id = jsCookie.get('muid');
        inputData.brand_associate_id = jsCookie.get('uid');
      }
      if (!inputData.brand_associate_id) {
        delete (inputData.brand_associate_id)
      }
      if (!inputData.sales_manager_id) {
        delete (inputData.sales_manager_id)
      }
    }
    axios.post(`${process.env.REACT_APP_API_URL}enquiry`, inputData,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
        }
      })
      .catch(error => {
        this.setState({ isSubmitted: false });
        throw error;
      })
      .then(res => {
        this.setState({
          isSubmitted: false,
          successMessage:
            <div id='alerts' className='notifBar bg-emerald-400'>
              <div id="ofBar-content" className="notifBar-content">
                <b>Data has been submitted.</b>
                {res.data.data.sales_manager_info.length > 0 ?'':<><br/><b>Leads has been assigned to others SM</b></>}
                {res.data.data.brand_associate_info.length > 0?'':<><br/><b>Leads has been assigned to others BA</b></>}
              </div>
              <div id="ofBar-right" className="notifBar-right">
                <i className="fa fa-times" id="close-bar" onClick={e => this.handleCloseAlert('successMessage')} />
              </div>
            </div>
        });
        var sm = '';
        var ba = '';
        var waSmMsg = '';
        var waBaMsg = '';
        var whatsappMsg = '';
        var showWaModal = false;
        let customers_list = ''

        if (res.data.data.new_customer_data.length > 0) {
          customers_list += '1. ' + res.data.data.new_customer_data[0].first_name + ' ' + res.data.data.new_customer_data[0].last_name + ' - +' + res.data.data.new_customer_data[0].phone + ' - ' + res.data.data.new_customer_data[0].subject + ' - ' + res.data.data.new_customer_data[0].preferred_model + '\n\n'
        }

        if (jsCookie.get('privilege') === 'Super Admin') {
          if (res.data.data.sales_manager_info.length > 0) {
            whatsappMsg = encodeURIComponent('Hi ' + res.data.data.sales_manager_info[0].name + ', 1 Customers just assigned by ' + jsCookie.get('name') + ' to you.\n\n' + customers_list + '\nPlease Check the update on Lexus CDP Dashboard \n' + process.env.REACT_APP_URL);
            waSmMsg = 'https://wa.me/' + res.data.data.sales_manager_info[0].phone + '?text=' + whatsappMsg
            showWaModal = true;
          }
        }
        if (jsCookie.get('privilege') === 'Sales Manager') {
          if (res.data.data.brand_associate_info.length > 0) {
            whatsappMsg = encodeURIComponent('Hi ' + res.data.data.brand_associate_info[0].name + ', 1 Customers just assigned by ' + jsCookie.get('name') + ' to you.\n\n' + customers_list + '\nPlease Check the update on Lexus CDP Dashboard \n' + process.env.REACT_APP_URL);
            waBaMsg = 'https://wa.me/' + res.data.data.brand_associate_info[0].phone + '?text=' + whatsappMsg
            showWaModal = true;
          }
        }
        this.setState({
          showWaModal: showWaModal,
          reloadPage: true,
          isSubmitted: false,
          whatsappSmLink: waSmMsg,
          whatsappBaLink: waBaMsg,
        });

        setTimeout(
           function () {
             if (!showWaModal) {
              this.setState({
              redirectPage: true
              });
             }
           }
             .bind(this),
           1000
         );
      })
  }

  handleShowWaModal(event) {

    this.setState({
      showWaModal: event,
    });
    if (event == false) {
      this.setState({
        redirectPage: true
      });
    }
  }

  render() {
    if (!auth()) {
      return <Redirect to="/login" />;
    }
    if (this.state.redirectPage) {
      return <Redirect to='/enquiries' />;
    }
    return (
      <>
        <div id="notificationArea" className="notificationArea w-full lg:w-4/12 ">
          {this.state.successMessage}
          {this.state.errorMessage}
          {this.state.salutationErrorMessage}
          {this.state.firstNameErrorMessage}
          {this.state.lastNameErrorMessage}
          {this.state.emailErrorMessage}
          {this.state.phoneErrorMessage}
          {this.state.provinceErrorMessage}
          {this.state.enquirySubjectErrorMessage}
          {this.state.enquiryTypeErrorMessage}
          {this.state.preferredModelErrorMessage}
          {this.state.sourceErrorMessage}
        </div>
        <div className="flex flex-wrap w-full">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0">
            <div className="relative flex flex-wrap min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded bg-blueGray-100 pt-6 px-4">

              <div className="customerinfo-field w-full lg:w-6/12">
                <div className="relative w-full mb-3 flex">
                  <div className='w-auto place-self-flex-end mr-2 pl-4'>
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Mr / Mrs
                    </label>
                    <select
                      type="text"
                      id="salutation"
                      name="salutation"
                      className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150`}
                      value={this.state.salutation}
                      onChange={this.handleChange}
                      placeholder="Please Choose one"
                      style={{ width: `${65}px` }}
                    >
                      <option value="" >-</option>;
                      <option value="Mr" >Mr</option>;
                      <option value="Mrs" >Mrs</option>;
                      <option value="Ms" >Ms</option>;
                    </select>
                  </div>
                  <div className='w-full'>
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="first_name" >
                      First Name
                    </label>
                    <input
                      id="first_name"
                      name="first_name"
                      placeholder="First Name"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                    />
                    {this.state.firstNameError ?
                      <>
                        <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                          <i className="fas fa-exclamation"></i>
                        </span>
                      </>
                      : ''}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 mb-2">
                <div className="relative w-full mb-4 px-4">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="last_name">Last Name</label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    placeholder="Last Name"
                    className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 mb-2">
                <div className="relative w-full mb-4 px-4">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="email">Email</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                  {this.state.emailError ?
                    <>
                      <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-4 py-3 mr-2">
                        <i className="fas fa-exclamation"></i>
                      </span>
                    </>
                    : ''}
                </div>
              </div>
              <div className="w-full lg:w-6/12 mb-2">
                <div className="relative w-full mb-4 px-4">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Phone"
                    className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={this.state.phone}
                    onChange={this.handleChange}
                  />
                  {this.state.phoneError ?
                    <>
                      <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-4 py-3 mr-2">
                        <i className="fas fa-exclamation"></i>
                      </span>
                    </>
                    : ''}
                </div>
              </div>
              <div className="w-full lg:w-6/12 mb-2">
                <div className="relative w-full mb-4 px-4">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="province">Province</label>
                  <Select
                    id="province"
                    className='select2'
                    options={this.state.provinceOption}
                    value={this.state.province}
                    onChange={this.handleChangeProvince}
                  />
                  {this.state.provinceError ?
                    <>
                      <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-2 pr-4 py-2 mr-2 bottom-0">
                        <i className="fas fa-exclamation"></i>
                      </span>
                    </>
                    : ''}
                </div>
              </div>
              <div className="w-full lg:w-6/12 mb-2">
                <div className="relative w-full mb-4 px-4">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="enquirySubject">Enquiry Subject</label>
                  <Select
                    id="enquirySubject"
                    className='select2'
                    options={this.state.enquirySubjectOption}
                    value={this.state.enquirySubject}
                    onChange={this.handleChangeEnquirySubject}
                  />
                  {this.state.enquirySubjectError ?
                    <>
                      <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-2 pr-4 py-2 mr-2 bottom-0">
                        <i className="fas fa-exclamation"></i>
                      </span>
                    </>
                    : ''}
                </div>
              </div>
              {this.state.showEnquiryType ?
                <div className="w-full lg:w-6/12 mb-2">
                  <div className="relative w-full mb-4 px-4">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="enquiryType">Enquiry Type</label>

                    <Select
                      id="enquiryType"
                      className='select2'
                      options={this.state.enquiryTypeOption}
                      value={this.state.enquiryType}
                      onChange={this.handleChangeEnquiryType}
                    />
                    {this.state.enquiryTypeError ?
                      <>
                        <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-2 pr-4 py-2 mr-2 bottom-0">
                          <i className="fas fa-exclamation"></i>
                        </span>
                      </>
                      : ''}
                  </div>
                </div> : ''}
              <div className="w-full lg:w-6/12 mb-2">
                <div className="relative w-full mb-4 px-4">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="preferred_model">Preferred Models</label>
                  <Select
                    id="preferred_model"
                    options={this.state.preferredModelOption}
                    value={this.state.preferredModel}
                    onChange={this.handleChangePreferredModel}
                    isMulti
                  />
                  {this.state.preferredModelError ?
                    <>
                      <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-2 pr-4 py-2 mr-2 bottom-0">
                        <i className="fas fa-exclamation"></i>
                      </span>
                    </>
                    : ''}
                </div>
              </div>
              <div className="w-full mb-2">
                <div className="relative w-full mb-4 px-4">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="notes">Notes</label>
                  <textarea
                    type="text"
                    id="notes"
                    name="notes"
                    placeholder="Notes"
                    className="border-0 px-3 py-3 placeholder-blueGray-200 text-gray bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={this.state.notes}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 mb-2">
                <div className="relative w-full mb-4 px-4">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="source">Channel</label>
                  <Select
                    id="source"
                    options={this.state.sourceOption}
                    value={this.state.source}
                    onChange={this.handleChangeSource}
                  />
                  {this.state.sourceError ?
                    <>
                      <span className="z-10 leading-snug font-normal absolute text-center text-red absolute bg-transparent rounded text-base items-center justify-center w-8 right-2 pr-4 py-2 mr-2 bottom-0">
                        <i className="fas fa-exclamation"></i>
                      </span>
                    </>
                    : ''}
                </div>
              </div>
              
              <div className="w-full lg:w-6/12 mb-2">
                <div className="relative w-full mb-4 px-4"></div>
                </div>
              {this.state.showSalesManager ?
                <div className="w-full lg:w-6/12 mb-2">
                  <div className="relative w-full mb-4 px-4">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="sm">Sales Manager</label>
                    <Select
                      id="sm"
                      options={this.state.smOption}
                      value={this.state.sm}
                      onChange={this.handleChangeSm}
                      isClearable={true}
                    />
                  </div>
                </div> : ''}
              {this.state.showBrandAssociate ?
                <div className="w-full lg:w-6/12 mb-2">
                  <div className="relative w-full mb-4 px-4">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="ba">Brand Associate</label>
                    <Select
                      id="ba"
                      options={this.state.baOption}
                      value={this.state.ba}
                      onChange={this.handleChangeBa}
                      isClearable={true}
                    />
                  </div>
                </div> : ''}
              <div className="w-full mb-6 px-4">
                {jsCookie.get('privilege') != 'Brand Associate2' ?
                  this.state.isSubmitted ? <button
                    className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  ><ClipLoader size={15} color="white" /></button> :
                    <button
                      className="button-gold text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="submit"
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button> : ''}
              </div>
            </div>
          </div>
        </div>

        {this.state.showWaModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto mx-auto items-center">
                    <button
                      className="bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => this.handleShowWaModal(false)}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        <i className="fa fa-times" />
                      </span>
                    </button>
                    <img
                      src={require("assets/img/logo/whatsapp-logo.svg").default}
                      className="relative border-none max-w-150-px  mx-auto"
                      alt="whatsapp-logo"
                    />
                    <h6 className="text-center text-black font-bold mx-auto">Send Whatsapp Messages</h6>
                    <p className="mt-4 text-blueGray-500 text-sm text-center leading-relaxed">
                      Send assigned information message to Sales Manager through Whatsapp
                    </p>
                  </div>
                  {this.state.whatsappSmLink ?
                    <div className="flex items-center justify-center pb-6 rounded-b text-center">
                      <a
                        href={this.state.whatsappSmLink}
                        target="_new"
                        className="w-10/12 button-gold bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      >
                        Open Whatsapp for Sales Manager
                      </a>
                    </div> : ''}
                  {this.state.whatsappBaLink ?
                    <div className="flex items-center justify-center pb-6 rounded-b text-center">
                      <a
                        href={this.state.whatsappBaLink}
                        target="_new"
                        className="w-10/12 button-gold bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-center"
                      >
                        Open Whatsapp for Brand Associate
                      </a>
                    </div> : ''}
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
}

