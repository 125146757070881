
const getBadge = platform => {
    switch (platform) {
      case 'excel': return 'excel-logo.svg'
      case 'email': return 'browser.svg'
      case 'website': return 'browser.svg'
      case 'chat bot': return 'chatbot-logo.png'
      case 'facebook': return 'facebook-logo.png'
      case 'instagram ads': return 'instagram-logo.png'
      case 'instagram dm': return 'instagram-logo.png'
      case 'lexus reach': return 'lexusreach-logo.png'
      case 'lexus sales enquiry': return 'sales-logo.png'
      case 'live chat': return 'live-chat.svg'
      case 'sem-google ads': return 'sem-logo.png'
      case 'twitter': return 'twitter.svg'
      case 'lexus customer database': return 'lexuscustomer-logo.png'
      case 'youtube': return 'youtube-logo.svg'
      case 'odoo': return 'odoo-logo.png'
      case 'walk-in': return 'walkin-logo.jpg'
      case 'phone-in': return 'phonein-logo.jpg'
      case 'contact center': return 'contactcenter-logo.png'
      case 'recommendation': return 'recommendation-logo.png'
      case 'repeat': return 'repeat-logo.png'
      case 'event': return 'event-logo.png'
      
      default: return 'excel-logo.svg'
    }
  }

  const colorsDefault = [
    'rgb(5, 141, 199)',
    'rgb(255 150 85)',
    'rgb(200 200 200)',
    'rgb(237, 239, 0)',
    'rgb(80, 180, 50)',
    'rgb(237, 86, 27)',
    'rgb(36, 203, 229)',
    'rgb(255 0 0)'
  ]

  module.exports = { getBadge,colorsDefault }
