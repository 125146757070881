import React from "react";
import axios from 'axios';
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { colorsDefault } from 'global/data'
import jsCookie from 'js-cookie';
var randomColor = require('randomcolor'); // import the script
/*eslint-disable eqeqeq*/


export default function CardChartEnquiryByLocation({ filterSeries, filterModel, filterChannel, filterFrom, filterTo }) {
  return (
    <CardChartEnquiryByLocationClass filterSeries={filterSeries} filterModel={filterModel} filterChannel={filterChannel} filterFrom={filterFrom} filterTo={filterTo} />
  )
}

class CardChartEnquiryByLocationClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filterSeries: this.props.filterSeries,
      filterModel: this.props.filterModel,
      filterChannel: this.props.filterChannel,
      filterFrom: this.props.filterFrom,
      filterTo: this.props.filterTo,
      data: [],
      chart: '',
      chartCreated: false,
      dataEmpty: false,
    };
  }

  getData() {
    var url = `${process.env.REACT_APP_API_URL}dashboard/leads/location`;
    const filter = {
      series: this.state.filterSeries,
      model: this.state.filterModel,
      channel: this.state.filterChannel,
      dateFrom: this.state.filterFrom,
      dateTo: this.state.filterTo,
      leads: "yes",
      level: jsCookie.get('privilege'),
      id: jsCookie.get('uid')
    };
    axios.post(url, filter, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
      }
    })
      .catch(error => {
        throw error;
      })
      .then(res => {
        this.setState({
          data: res.data,
        }, () => {
          this.processData();
        });
      })
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevProps.filterSeries != this.props.filterSeries) ||
      (prevProps.filterModel != this.props.filterModel) ||
      (prevProps.filterChannel != this.props.filterChannel) ||
      (prevProps.filterFrom != this.props.filterFrom) ||
      (prevProps.filterTo != this.props.filterTo)
    ) {
      this.setState({
        filterSeries: this.props.filterSeries == 'Series' ? '' : this.props.filterSeries,
        filterModel: this.props.filterModel,
        filterChannel: this.props.filterChannel == 'Channel' ? '' : this.props.filterChannel,
        filterFrom: this.props.filterFrom,
        filterTo: this.props.filterTo,
      }, () => {
        this.getData();
      });
    }
  }

  processData() {
    let data = this.state.data;
    if (data) {
      let labels = [];
      let datasets = [{
        data: [],
        backgroundColor: [],
        hoverOffset: 3
      }]
      data.forEach((element, key) => {
        labels.push(element.label)
        let color = colorsDefault[key] ? colorsDefault[key] : randomColor({
          luminosity: 'bright',
          format: 'rgb'
        });
        datasets[0].data.push(element.data)
        datasets[0].backgroundColor.push(color)
      });
      
      this.createChart(labels, datasets);
    } else {
      this.setState({
        chart: '',
        chartCreated: false
      });
    }
  }

  createChart(labels,datasets) {
    let config = {
      type: "pie",
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            display: false,
            text: "Orders Chart",
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: tooltipItems => {
                let sum = 0;
                let dataArr = this.state.chart.data.datasets[0].data;
                dataArr.forEach(data => {
                  sum += data;
                });
                let percentage = (tooltipItems.parsed * 100 / sum).toFixed(2) + "%";
                let title = ' ' + tooltipItems.label + ': ' + tooltipItems.parsed + ' (' + percentage + ')';
                return title
              },
            },
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          legend: {
            display: true,
            labels: {
              color: "rgba(0,0,0)",
              font: {
                size: 14
              }
            },
            align: "center",
            position: "bottom",
          },
          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.forEach(data => {
                sum += data;
              });
              let percentage = (value * 100 / sum).toFixed(2) + "%";
              return percentage;
            },
            color: '#000',
            align: 'end',
            offset: 20,
            font: {
              size: 14,
            }
          }
        }
      },
      plugins: [ChartDataLabels],
    };
    if (!this.state.chartCreated) {
      let ctx = document.getElementById("CardChartEnquiryByLocationBar").getContext("2d");
      var chart = new Chart(ctx, config);
      this.setState({
        chart: chart,
        chartCreated: true
      })
    } else {
      chart = this.state.chart;
      chart.data.labels = labels;
      chart.data.datasets = datasets;
      chart.update();
    }
  }

  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded h-full">
          <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1 text-center">
                <h3 className="text-blueGray-700 text-base font-bold">
                  Leads By Location
                </h3>
              </div>
            </div>
          </div>
          <div className="p-4 flex-auto">
            <div className={"relative h-350-px "}>
              {this.state.data.length>0?'':
              <div className="w-full text-center">Data Not Available</div>}
              <canvas id="CardChartEnquiryByLocationBar"></canvas>
            </div>
          </div>
        </div>
      </>
    );
  }

}